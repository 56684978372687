import React, { useEffect, useState } from 'react'
import { Col, Form, Input, Row, Select, Grid } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { GetLoggedInUser } from '../../utils';
import { useNavigate } from 'react-router-dom';
import HeadingBack from '../../component/HeadingBack';
import FileUpload from './FileUpload';
import { BannerServices } from '../../redux/Services';
import { CouponServices } from '../../redux/Services';
import { usePermission } from '../../hooks/usePermission';
import { AutoComplete } from 'antd';

const zoneOptions = [
    {
      value: 'ZH1',
    },
    {
      value: 'ZH16',
    },
    {
      value: 'ZH17',
    },
    {
      value: 'ZH18',
    },
    {
      value: 'ZH19',
    },
    {
      value: 'ZH22',
    },
    {
      value: 'ZH24',
    },
    {
      value: 'ZH26',
    },
    {
      value: 'ZH28',
    },
    {
      value: 'ZH29',
    },
    {
      value: 'ZH30',
    },
    {
      value: 'ZH32',
    },
    {
      value: 'ZH33',
    },
    {
      value: 'ZH5',
    },
  ];
const { Option } = Select;
const AddOffers = () => {
    const permission = usePermission("BTL Management", "Add");
    var newOptions = [];

    const [file, setFile] = useState("");
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const couponCategoryList = useSelector(state => state.coupon.couponCategoryList || []);
    const TestCodeList = useSelector(state => state.coupon.TestCodeList || []);
    const { mutate: fetchCouponCategory, isLoading } = useMutation((data) => dispatch(CouponServices.fetchCouponCategoryApi(data)));
    const { mutate: addBtlOffer } = useMutation((data) => dispatch(CouponServices.addBtlOfferApi(data, navigate)));
    const { mutate: BannerImageAdd } = useMutation((data) => dispatch(BannerServices.BannerImageAddApi(data)));
    const { mutate: fetchTestCode } = useMutation((data) => dispatch(CouponServices.fetchTestCode(data)));
    const [options, setOptions] = useState([]);
    const [boxType, setBoxType] = useState("");
    const BtlOffersId = [
        4, 5
    ];

    // console.log (sort)
    useEffect(() => {
        fetchCouponCategory({ isActive: "1" });
        return () => { }
    }, [])
    useEffect(() => {
        fetchTestCode({});
        return () => { }
    }, [])
    const handleUpload = (file) => {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("dir_location", "btl-users");
        BannerImageAdd(formData, {
            onSuccess: (res) => {
                var fileName = res.data?.data?.key;
                setFile(fileName)
            },
        })
    }
    useEffect(() => {
        form.setFieldsValue({
            Banner: file,
        })
    }, [file])


    const changeItemType = (id) => {
        setBoxType(id);
    }
    const handleSubmit = () => {
        form.validateFields().then((values) => {
            let _data = { ...values };
            _data.UserId = LOG_IN_USER?.id;
            if (values.Name) {
              } else {
                _data.Name = "BTL Banners";
              }
            addBtlOffer(_data)
    
        })
    }

// console.log(zoneOptions)

    return (
        <>
            <HeadingBack title={"Add BTL/Non BTL Offers"} subHeading="" />
            <div className='p-20'>

                < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                    initialValues={{ id: "4", Status: "1" }}
                >
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Category"
                                name="CategoryId"
                                initialValues={{ id: "4" }}
                                rules={[{ required: true, message: 'Category is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Category'
                                    autoComplete="new-off"
                                    name="CategoryId"
                                    initialValues={{ id: "4" }}
                                    onChange={(event) => { changeItemType(event) }}
                                >
                                    <Option className='text-capitalize'>Select Category</Option>

                                    {couponCategoryList?.length > 0 && couponCategoryList?.map((item, idx) =>
                                        (BtlOffersId.includes(item.Id)) ?
                                            <Option key={idx} className='text-capitalize' value={item.Id}>{item.Name}</Option>
                                            : ''
                                    )}
                                </Select>

                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Zone"
                                    name="Zone"
                                    rules={[{ required: true, message: 'Zone is required' }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select Zone"
                                        name="Code"
                                    >
                                        {zoneOptions?.length > 0 && zoneOptions?.map((item, idx) =>
                                            <Option key={idx} className='text-capitalize' value={item.value}>{item.value}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col >
                        {(boxType == 5) ?
                            ''
                            : <Col lg={12} md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Test Code"
                                    name="Code"
                                    rules={[{ required: true, message: 'Test Code is required' }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select Test Code"
                                        name="Code"
                                    >
                                        {TestCodeList?.length > 0 && TestCodeList?.map((item, idx) =>
                                            <Option key={idx} className='text-capitalize' value={item.TestCode}>{item.TestCode}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col >
                        }
                        {(boxType == 5) ?
                            <Col lg={12} md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Name"
                                    name="Name"
                                    rules={[{ required: true, message: 'Name is required' }]}
                                >
                                    <Input autoComplete="new-off" className='form-control' placeholder='Name' />
                                </Form.Item>
                            </Col >
                            :
                            ''
                        }
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                    name="Status"
                                >
                                    <Option className='text-capitalize' value={"0"}>Inactive</Option>
                                    <Option className='text-capitalize' value={"1"}>Active</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Offer Image"
                                name="Banner"
                                rules={[{ required: true, message: 'Offer Image is required' }]}
                            >
                                <FileUpload file={file} setFile={setFile} handleUpload={handleUpload} />
                                <div className='recom-t'>
                                    <p>Accept JPG,PNG Only</p>
                                    <p>Recommended Size:350*150</p>
                                    <p>Maximum Resolution should be 1MB</p>
                                </div>
                            </Form.Item>
                        </Col >
                    </Row >
                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit}>
                            Submit
                        </button>
                    </div>
                </Form>
            </div >
        </>
    )
}

export default AddOffers