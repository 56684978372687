import {
  FileExcelTwoTone,
  FilePdfTwoTone,
  FileWordTwoTone,
  LoadingOutlined,
  PictureTwoTone,
  PlusOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';
import { Upload, Form } from 'antd';
import { useState, useEffect, React } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { TicketServices } from '../../../redux/Services'
import API_URL from '../../../api/url';
const FileUpload = ({ attachedFilesArr, setattachedFilesArr }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const dispatch = useDispatch();
  const { mutate: addattachment } = useMutation((data) => dispatch(TicketServices.addattachmentApi(data)));
  const [fileList, setFileList] = useState([]);
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);

      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const handleChange = ({ fileList }) => {

    setFileList(fileList);

  };

  const uploadImage = (options) => {
    const { onSuccess: onSuccessUpload, onError, file, onProgress } = options;

    let formData = new FormData();
    formData.append("image", file);
    formData.append("dir_location", 'ticket-management');
    addattachment(formData, {
      onSuccess: (res) => {
        onSuccessUpload(res.data?.data?.key);
        var fileName = res.data?.data?.key;
        setattachedFilesArr([...attachedFilesArr, fileName])
      },
    })
  }
  const removeImage = (options) => {
    const { onSuccess: onSuccessUpload, onError, file, onProgress } = options;
    var fileName = options.response; 
    var attachedFilesArrNew = attachedFilesArr.filter(function (filename) {
      return filename !== fileName
    }); 
    setattachedFilesArr(attachedFilesArrNew);
  }


  // console.log(fileList)
  // console.log('attachedFilesArr', attachedFilesArr)
  const HandleIconRender = (file, listType) => {
    console.log(file, listType)
    const fileSufIconList = [
      {
        type: <FilePdfTwoTone />,
        suf: ['.pdf'],
      },
      {
        type: <FileExcelTwoTone />,
        suf: ['.xlsx', '.xls', '.csv'],
      },
      {
        type: <FileWordTwoTone />,
        suf: ['.doc', '.docx'],
      },
      {
        type: <PictureTwoTone />,
        suf: ['.webp', '.svg', '.png', '.gif', '.jpg', '.jpeg', '.jfif', '.bmp', '.dpg'],
      },
    ];
    // console.log(1, file, listType);
    let icon = file.status === 'uploading' ? <LoadingOutlined /> : <PaperClipOutlined />;
    if (listType === 'picture' || listType === 'picture-card') {
      if (listType === 'picture-card' && file.status === 'uploading') {
        icon = <LoadingOutlined />; // or icon = 'uploading...';
      } else {
        fileSufIconList.forEach((item) => {
          if (item.suf.includes(file.name.slice(file.name.lastIndexOf(".")))) {
            icon = item.type;
          }
        });
      }
    }
    return icon;
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <>
      <Form.Item
        name="Attachments"
        label="Upload"
      >
        <Upload
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
          iconRender={HandleIconRender}
          customRequest={uploadImage}
          onRemove={removeImage}
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
      </Form.Item>
    </>
  );
};
export default FileUpload