import { Col, Input, Row, Select,Form } from 'antd'
import { notification } from "antd";
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { InvestorCategoriesServices } from '../../../redux/Services';

const { Option } = Select;

const InvestorSubFilter = ({ InvestorCategoryList, filter, setFilter, setPage, setOffset }) => {
    const [values, setValues] = useState({
        CategoryId: "",
        ParentId: "",
        IsActive: "",
    });
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const InvestorCategoryListSub = useSelector(state => state.invesotorCategory.InvestorCategoryListDrop2 || []);
    const InvestorCategoryListSub3 = useSelector(state => state.invesotorCategory.InvestorCategoryListDrop3 || []);
    const InvestorCategoryListSub4 = useSelector(state => state.invesotorCategory.InvestorCategoryListDrop4 || []);
    const { mutate: InvestorSubCategoryListdropSub2 } = useMutation((data) => dispatch(InvestorCategoriesServices.fetchInvestorCategoryListApi2(data)));
    const { mutate: InvestorSubCategoryListdropSub3 } = useMutation((data) => dispatch(InvestorCategoriesServices.fetchInvestorCategoryListApi3(data)));
    const { mutate: InvestorSubCategoryListdropSub4 } = useMutation((data) => dispatch(InvestorCategoriesServices.fetchInvestorCategoryListApi4(data)));
    const [subCategoryH3, setSubCategoryH3] = useState(false);
    const FilterInvesotorCategoryListDrop = (value, name) => { 
        let newCat = { ParentId: value,IsActive:1 };
        InvestorSubCategoryListdropSub2(newCat);
  
    }
    const getSubCategory = (value, name) => {
        // form.setFieldValue('value', "");
        let newCat = { ParentId: value,IsActive:1};
        InvestorSubCategoryListdropSub3(newCat);

    }
    
    const getSubCategory4 = (value, name) => {
        form.setFieldValue('SubCategoryId3', "");
        let newCat = { ParentId: value };
        InvestorSubCategoryListdropSub4(newCat);

    }
    
    console.log(values)
    // const handleChange = (name, value) => {
    //     setValues({ ...value, [name]: value });
    //     setPage(1);
    //     setOffset(0);
    // };

    const handleFilter = () => {
        console.log('values', values);
        if (values.ParentId) { 
            setFilter({ ...values });
            setPage(1);
            setOffset(0);
        }

    }
    // const handleFilterReset = () => {
    //     setFilter({});
    //     setValues({
    //         ...values,
    //         ParentCategory: "",
    //         Category: "",
    //         ParentId: "",

    //     })
    //     setPage(1);
    // }
    function refreshPage() {
        window.location.reload(false);
      }
    return (
        <div className='table_filter w-90'>
            <Row gutter={[12, 24]} className="ml-10">
                {/* <Col md={4} sm={12} xs={24}>
                    <strong><p className='mb-2 f-14'>Category</p></strong>
                    <Select
                        className='text-capitalize w-100'
                        placeholder='Select Category'
                        autoComplete="new-off"
                        onChange={(value) => { FilterInvesotorCategoryListDrop(value); setValues({ ...values, "ParentId": value }) }}
                        value={values?.ParentCategory}
                    >
                        <Option className='text-capitalize' value="">Select Category</Option>
                        {InvestorCategoryList?.length > 0 && InvestorCategoryList?.map((item, idx) =>
                            <Option key={idx} className='text-capitalize' value={item.Id}>{item.Category}</Option>
                        )}
                    </Select>
                </Col> */}
                {/* <Col md={4} sm={12} xs={24}>
                    <strong><p className='mb-2 f-14'>Sub Category (H1)</p></strong>
                    <Select
                        className='text-capitalize w-100'
                        name="SubCategoryId2"
                        placeholder='Select Sub Category (H1) '
                        autoComplete="new-off"
                        onChange={(value) => { getSubCategory(value); setValues({ ...values,"SubCategoryId1": value }) }}
                        value={values?.value}
                    >
                        <Option className='text-capitalize' value="">Select Sub Category (H1)</Option>
                        {InvestorCategoryListSub?.length > 0 && InvestorCategoryListSub?.map((item, idx) =>
                            <Option key={idx} className='text-capitalize' value={item.Id}>{item.Category}</Option>
                        )}
                    </Select>
                </Col> */}
                {/* <Col md={4} sm={12} xs={24}>
                <strong><p className='mb-2 f-14'>Sub Category (H2)</p></strong>
                    <Select
                        className='text-capitalize w-100'
                        placeholder='Select Sub Category (H2)'
                        name="SubCategoryId3"
                        autoComplete="new-off"
                        onChange={(value) => { getSubCategory4(value); setValues({ ...values,"SubCategoryId2": value }) }}
                        value={values?.value}
                    >
                        <Option className='text-capitalize' value="">Select Sub Category (H2)</Option>
                        {InvestorCategoryListSub3?.length > 0 && InvestorCategoryListSub3?.map((item, idx) =>
                            <Option key={idx} className='text-capitalize' value={item.Id}>{item.Category}</Option>
                        )}
                    </Select>
                </Col>  */}
                {/* {(InvestorCategoryListSub4?.length > 0 && InvestorCategoryListSub3?.length > 0 ) &&
                  <Col md={4} sm={12} xs={24}>
                <strong><p className='mb-2 f-14'>Sub Category (H3)</p></strong>
                    <Select
                        className='text-capitalize w-100'
                        placeholder='Select Sub Category (H2)'
                        name="SubCategoryId4"
                        autoComplete="new-off"
                        onChange={(value) => setValues({ ...values, "SubCategoryId3": value })}
                        value={values?.SubCategory}
                    >
                        <Option className='text-capitalize' value="">Select Sub Category (H2)</Option>
                        {InvestorCategoryListSub4?.length > 0 && InvestorCategoryListSub4?.map((item, idx) =>
                            <Option key={idx} className='text-capitalize' value={item.Id}>{item.Category}</Option>
                        )}
                    </Select>
                </Col>
} */}
                <Col md={3} sm={12} xs={24}>

                    <strong><p className='mb-2'>Select Status</p></strong>
                    <Select
                        className='text-capitalize w-100'
                        placeholder='Select Status'
                        autoComplete="new-off"
                        onChange={(value) => setValues({ ...values, "IsActive": value })}
                        value={values?.IsActive}
                    >
                        <Option className='text-capitalize' value="">All</Option>
                        <Option className='text-capitalize' value={"0"}>Inactive</Option>
                        <Option className='text-capitalize' value={"1"}>Active</Option>
                    </Select>
                </Col>
                <Col md={3} sm={12} xs={24}>
                    <div className='flex gap10 mt-20'>
                        <button className='filter-button' onClick={() => handleFilter()}>Filter</button>
                        <button className='clear-button'  onClick={refreshPage}>Reset</button>
                    </div>
                </Col>
            </Row>
        </div >
    )
}

export default InvestorSubFilter