import { Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeadingBack from '../../../component/HeadingBack';
import SvgIcon from '../../../const/SvgIcon';
import { InvestorItemServices } from '../../../redux/Services';
import { InvestorCategoriesServices } from '../../../redux/Services';
import InvestorFinancialsFilter from './financialsFilter';
import moment from 'moment';
import { usePermission } from '../../../hooks/usePermission';
import SearchFilter from '../SearchFilter';
import { getFilterTableData } from '../../../utils';

const Finacial = () => {
    const permission = usePermission("Seo Pages", "List");
    const navigate = useNavigate();
    const [filter, setFilter] = useState({});
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [filterData, setFilterdData] = useState([]);
    const InvestorItemLists = useSelector(state => state.investoritem.InvestorItemList || []);
    const InvestorCategoryList = useSelector(state => state.invesotorCategory.InvestorCategoryListDrop || []);
    const InvestorCategoryListFilterArr = useSelector(state => state.invesotorCategory.InvestorCategoryListFilter || []);
    const totalItem = useSelector(state => state.investoritem.TotalRecord || 0);
    const [FilterInvesotorCategoryList, setInvestorCategoryList] = useState({});
    const { mutate: InvestorItemList, isLoading } = useMutation((data) => dispatch(InvestorItemServices.fetchInvestorListApi(limit, offset, data)));
    const { mutate: InvestorSubCategoryListdrop } = useMutation((data) => dispatch(InvestorCategoriesServices.fetchInvestorCategoryListApi(data)));
    const { mutate: InvestorSubCategoryList } = useMutation((data) => dispatch(InvestorCategoriesServices.fetchInvestorListDataApi(limit, offset, data)));

    useEffect(() => {
        InvestorItemList(filter)
        return () => { }
    }, [/* filter, offset */])
    useEffect(() => {
        if (Object.keys(filter).length > 0) {
            InvestorSubCategoryList(filter);
        }
        return () => { }
    }, [filter])
    useEffect(() => {
        InvestorSubCategoryListdrop({ IsActive: "1" }, FilterInvesotorCategoryList)
        return () => { }
    }, [FilterInvesotorCategoryList])
    useEffect(() => {
        if (InvestorCategoryListFilterArr.length > 0)
            InvestorCategoryList = InvestorCategoryListFilterArr;
        return () => { }
    }, [InvestorCategoryListFilterArr])

    useEffect(() => {
        setFilterdData(InvestorItemLists);
    }, [InvestorItemLists])

    const handleSearch = (val) => {
        let _Dtt = getFilterTableData(InvestorItemLists, val);
        setFilterdData(_Dtt);
    }

    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
            render: (value, item, index) => (page - 1) * 10 + index + 1
        },

        // {
        //     title: 'Category',
        //     dataIndex: 'ItemName',
        //     key: 'ItemName',
        //     className: 'text-capitalize',
        //     render: (value, item, index) => {
        //         return (
        //             <span>{item?.Category?.Category}</span>
        //         )
        //     }
        // },  
        {
            title: 'Sub Category (H1)',
            dataIndex: 'SubCategory1',
            key: 'SubCategory1',
            className: 'text-capitalize',
            render: (value, item, index) => {
                return (
                    <span>{item?.SubCategory1?.Category}</span>
                )
            }
        },
        {
            title: 'Sub Category (H2)',
            dataIndex: 'SubCategory2',
            key: 'SubCategory2',
            className: 'text-capitalize',
            render: (value, item, index) => {
                return (
                    <span>{item?.SubCategory2?.Category}</span>
                )
            }
        },
        {
            title: 'Sub Category (H3)',
            dataIndex: 'SubCategory3',
            key: 'SubCategory3',
            className: 'text-capitalize',
            render: (value, item, index) => {
                return (
                    <span>{item?.SubCategory3?.Category}</span>
                )
            }
        },
        {
            title: 'Item',
            dataIndex: 'ItemName',
            key: 'ItemName',
            className: 'text-capitalize',
        },
        {
            title: 'Attachment',
            dataIndex: 'AttachmentUrl',
            key: 'AttachmentUrl',
            render: (value, item, index) => {
                return (
                    item.AttachmentUrl ? <a style={{ textDecoration: "underline" }} href={item.AttachmentUrl} target="_blank">View</a> : 'NA'
                )
            }
        },
        // {
        //     title: 'Audio',
        //     dataIndex: 'AudioUrl',
        //     key: 'AudioUrl',
        //     render: (value, item, index) => {
        //         return (
        //             item.AudioUrl ? <a style={{ textDecoration: "underline" }} href={ item.AudioUrl}>View</a> : 'NA'
        //         )
        //     }
        // },

        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            render: (value, item, index) => {
                return (
                    <span className={item?.Status ? "status_active" : "status_inactive"}>{item?.Status ? "Active" : "Inactive"}</span>
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            render: (value, item, index) => {
                return (
                    <Tooltip title="Edit">
                        <span className='cursor-pointer' onClick={() => navigate(`/investor-financials/edit/${item?.Id}`)}>
                            <SvgIcon.Edit className="scale-icon edit-icon" />
                        </span>
                    </Tooltip>
                )
            }
        },
    ];
    return (
        <div>
            <div className='flex'>
                <HeadingBack title={"Financials"} subHeading={`Total ${totalItem || 0} Financials`} />
                <SearchFilter onChange={handleSearch} />
                {/* <InvestorFinancialsFilter filter={filter} setFilter={setFilter} setPage={setPage} setOffset={setOffset} InvestorCategoryList={InvestorCategoryList} FilterInvesotorCategoryList={FilterInvesotorCategoryList} setInvestorCategoryList={setInvestorCategoryList} /> */}
                <div className='flex job-post-add'>
                    <Tooltip title="Add"><span onClick={() => navigate("/investor-financials/add")} className='jop_btn'>Add<SvgIcon.Add width={40} height={40} className="cursor-pointer" /></span></Tooltip>
                </div>
            </div>
            <div className='tableResponsive mt-10'>
                <Table
                    rowKey='UsrId'
                    className={isLoading ? 'table antd_table no_data_found_hide' : 'table antd_table'}
                    bordered
                    columns={columns}
                    dataSource={Array.isArray(filterData) && filterData?.length > 0 ? filterData : []}
                    pagination={{
                        current: page,
                        pageSize: limit,
                        total: filterData?.length ,
                        onChange(current, pageSize) {
                            setPage(current);
                            setOffset(current == 1 ? 0 : (limit * current));
                        },
                        hideOnSinglePage: true
                    }}
                />
            </div>
        </div>
    )
}

export default Finacial