import React, { useEffect, useState } from "react";
import { Col, Form, Input, notification, Row, Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import CustomEditor from "../../component/CustomEditor/CustomEditor";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { GetLoggedInUser } from "../../utils";
import HeadingBack from "../../component/HeadingBack";
import { BannerServices } from "../../redux/Services";
import { usePermission } from "../../hooks/usePermission";
import FileUpload from "./FileUpload";
import { ReportDownloadServices } from "../../redux/Services";
import API_URL from "../../api/url";
const { Option } = Select;
const EditReport = () => {
  const permission = usePermission("Manage Download Report", "Edit");
  const { Id } = useParams();
  let LOG_IN_USER = GetLoggedInUser();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const DownloadReportData = useSelector(
    (state) => state.DownloadReport.DownloadReportDetail || {}
  );
  const { mutate: detailManageDownloadReport } = useMutation((data) =>
    dispatch(
      ReportDownloadServices.detailManageDownloadReportApi(data, { Id: Id })
    )
  );
  const { mutate: updateManageDownloadReport } = useMutation((data) =>
    dispatch(
      ReportDownloadServices.updateManageDownloadReportApi(data, Id, navigate)
    )
  );
  const { mutate: BannerImageAdd } = useMutation((data) =>
    dispatch(BannerServices.BannerImageAddApi(data))
  );
  const [file, setFile] = useState("");
  const [fileBanner, setfileBanner] = useState("");
  useEffect(() => {
    detailManageDownloadReport({});

    return () => {};
  }, []);
  useEffect(() => {
    let Logo =
      DownloadReportData?.Logo?.replace(
        API_URL.S3_BANNER_BASE?.toString(),
        ""
      ) || "";
    let Banner =
      DownloadReportData?.Banner?.replace(
        API_URL.S3_BANNER_BASE?.toString(),
        ""
      ) || "";
    if (DownloadReportData && Object.keys(DownloadReportData)?.length > 0) {
      form.setFieldValue("Name", DownloadReportData?.Name);
      form.setFieldValue("Code", DownloadReportData?.Code);
      form.setFieldsValue({
        ...DownloadReportData,
        Logo: Logo,
        Banner: Banner,
      });
      setFile(Logo);
      setfileBanner(Banner);
      form.setFieldValue("Details", DownloadReportData?.Details);
      form.setFieldValue("Status", DownloadReportData?.Status);
    }
  }, [DownloadReportData]);

  const handleUpload = (file) => {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("dir_location", "download-report");
    BannerImageAdd(formData, {
      onSuccess: (res) => {
        var fileName = res.data?.data?.key;
        setFile(fileName);
      },
    });
  };
  const handleUploadBanner = (file) => {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("dir_location", "download-report");
    BannerImageAdd(formData, {
      onSuccess: (res) => {
        var fileName = res.data?.data?.key;
        setfileBanner(fileName);
      },
    });
  };
  useEffect(() => {
    form.setFieldsValue({
      Logo: file,
      Banner: fileBanner,
    });
  }, [file, fileBanner]);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      if (updateManageDownloadReport) {
        let _data = { ...values };
        _data.Id = Id;
        _data.UserId = LOG_IN_USER?.id;
        updateManageDownloadReport(_data);
      }
    });
  };

  return (
    <>
      <HeadingBack title={"Edit Team Member"} subHeading="" />
      <div className="p-20">
        <Form form={form} name="basic" layout={"vertical"} autoComplete="off">
          <Row gutter={[24, 0]}>
            <Col lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label="Name"
                name="Name"
                rules={[{ required: true, message: "Name is required" }]}
              >
                <Input
                  autoComplete="new-off"
                  className="form-control"
                  placeholder="Name"
                />
              </Form.Item>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label="Code"
                name="Code"
                rules={[
                  {
                    required: true,
                    message: "Code is required",
                  },
                  {
                    pattern: /^[0-9a-zA-Z\-]+$/,
                    message: 'Code can only contain hyphen and integer characters',
                  },
                ]}
              >
                <Input
                  autoComplete="new-off"
                  className="form-control"
                  placeholder="Code"
                />
              </Form.Item>
              <div className="logo-hyperlink">
                <p>
                  <strong>Logo Hyperlink:</strong> https://www.lalpathlabs.com/our-subsidiaries/{form.getFieldValue("Code")}
                </p>
              </div>
            </Col>

            <Col lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label="Logo"
                name="Logo"
                rules={[{ required: true, message: "Logo is required" }]}
              >
                <FileUpload
                  file={file}
                  setFile={setFile}
                  handleUpload={handleUpload}
                />
                <div className="recom-t">
                  <p>Recommended Size:200 × 65 px</p>
                  {/* <p>Maximum Resolution should be 1MB</p> */}
                </div>
              </Form.Item>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label="Banner"
                name="Banner"
                rules={[{ required: true, message: "Banner is required" }]}
              >
                <FileUpload
                  file={fileBanner}
                  setFile={setfileBanner}
                  handleUpload={handleUploadBanner}
                />
                <div className="recom-t">
                  <p>Recommended Size:1068 × 600 px</p>
                  {/* <p>Maximum Resolution should be 1MB</p> */}
                </div>
              </Form.Item>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label="Status"
                name="Status"
                rules={[{ required: true, message: "Status is required" }]}
              >
                <Select
                  className="from-control text-capitalize"
                  placeholder="Select Status"
                  autoComplete="new-off"
                  name="Status"
                >
                  <Option className="text-capitalize" value={0}>
                    Inactive
                  </Option>
                  <Option className="text-capitalize" value={1}>
                    Active
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            <Col lg={24} md={24} sm={24} xs={24}>
              <Form.Item label="Details" name="Details">
                <CustomEditor
                  value={form.getFieldValue("Details")}
                  onEditorChange={(value) =>
                    form.setFieldsValue({ Details: value })
                  }
                />
                {/* <textarea rows="4" cols="50" className='form-control' value={form.getFieldValue("SeoKeywords ")} onEditorChange={(value) => form.setFieldsValue({ SeoKeywords: value })} placeholder="Team Member Profile" /> */}
              </Form.Item>
            </Col>
          </Row>
          <div className="text-center">
            <button
              key="submit"
              type="submit"
              className="gradient-button w-auto"
              onClick={handleSubmit}
            >
              Update
            </button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default EditReport;
