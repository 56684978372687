import { notification } from "antd";
import Api from "../../api";
import API_URL from "../../api/url";
import { setTestDepartmentDetail, setTestDepartmentList } from "../Slices/TestdepartmentSlice";

export const fetchTestDeepartmentListApi = (data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.TEST_DEPARTMENT_LIST, data);
        dispatch(setTestDepartmentList({ list: res.data.Data, total: res.data.TotalRecord}));
        return res;
    } catch (error) {
        return error
    }
};

export const fetchTestDepartmentDetailApi = (data, id) => async (dispatch) => {
    try {
        const res = await Api.post(`${API_URL.TEST_DEPARTMENT_DETAILS}/${id}`);
        dispatch(setTestDepartmentDetail(res.data.Data));
        return res;
    } catch (error) {
        return error
    }
};
export const addTestDepartmentApi = (data, navigate) => async (dispatch) => {
    try {
                const res = await Api.post(API_URL.TEST_DEPARTMENT_ADD, data);
                navigate("/test-management");
                notification["success"]({
                    message: "Test created successfully!"
                });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const TestDeepartmentDetailUpdateApi = (data, id, navigate) => async (dispatch) => {
    try {
        const res = await Api.put(`${API_URL.TEST_DEPARTMENT_UPDATE}/${id}`, data);
        navigate("/test-management");
        notification["success"]({
            message: "Test Management updated successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
