import { notification } from "antd";
import Api from "../../api";
import API_URL from "../../api/url";
import { setZoneList, setZoneListByIdDetail, setLeadPageList,setLeadPageDetail} from "../Slices/leadPageSlice";

export const fetchLeadPageApi = (data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.LEAD_PAGE_LIST, data);
        dispatch(setLeadPageList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const addLeadPageApi = (data, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.LEAD_PAGE_ADD, data);
        navigate("/manage-page");
        notification["success"]({
            message: "Page added successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};

export const updateLeadPageApi = (data, id, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(`${API_URL.LEAD_PAGE_UPDATE}/${id}`, data);
        navigate("/manage-page");
        notification["success"]({
            message: "Page updated successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const fetchLeadPageDetailByIdApi = (data, id) => async (dispatch) => {
    try {
        const res = await Api.get(`${API_URL.LEAD_PAGE_DETAIL}/${id}`);
        dispatch(setLeadPageDetail(res.data.Data));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchZoneListApi = (data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.ZONE_LIST, data);
        dispatch(setZoneList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const addZoneApi = (data, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.ZONE_ADD, data);
        navigate("/manage-zone");
        notification["success"]({
            message: "Zone/Region created successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const updateZoneApi = (data, id, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(`${API_URL.ZONE_UPDATE}/${id}`, data);
        navigate("/manage-zone");
        notification["success"]({
            message: "Zone/Region updated successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const fetchZoneListByIdApi = (data, id) => async (dispatch) => {
    try {
        const res = await Api.get(`${API_URL.ZONE_DETAILS_BY_ID}/${id}`);
        dispatch(setZoneListByIdDetail(res.data.Data));
        return res;
    } catch (error) {
        return error
    }
};
