import { current } from '@reduxjs/toolkit';
import { Col, Input, Row, Select, message, Tooltip } from 'antd'
import React, { useState, useEffect } from 'react'
import { FileExcelFilled } from '@ant-design/icons';
import { CSVLink } from "react-csv"
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getBusinessProfession } from "../../utils";

const { Option } = Select;
const HomeCollectionFilter = ({ filter, setFilter, setPage, setOffset, limit, isFilter, }) => {
    const [values, setValues] = useState({
        Package: "",
        PhoneNumber: "",

    });
    const [FilteredData, setFilteredData] = useState([]);
    const HomeCollectionList = useSelector(state => state.jobAppliction.HomeCollectionList || []);
    useEffect(() => {
        
        if (HomeCollectionList && HomeCollectionList.length > 0) {
            var data = [];
            HomeCollectionList.map((value, index) => {
                var item = {
                    "ID": value?.ID,
                    "Name": value?.Name,
                    "Package": value?.Package,
                    "Mobile": value?.PhoneNumber,
                    "DOB": value?.DOB,
                    "Address": value?.Address,
                    "Gender": value?.Gender,
                    "State": value?.State,
                    "City": value?.City,
                    "PreferredSlot": value?.PreferredSlot,
                    "BookingDate": value?.BookingDate,
                    "Business_profession": value?.Business_profession ? getBusinessProfession(value?.Business_profession) : 'UA',
                    "IsOwnSpace": value?.IsOwnSpace,
                    "Association": value?.Association,
                    "Is_Relative_working": value?.Is_Relative_working,
                    "Is_Relative_Centers": value?.Is_Relative_Centers,
                    "Is_Relative_direct_competition": value?.Is_Relative_direct_competition,
                    "CreatedAt": value?.CreatedAt,
                    "Status": value?.Status
                } 

                data.push(item);
                // setFilteredDat([...item])
            })
            setFilteredData(data);
        }
        return () => { }
    }, [HomeCollectionList])
    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value });
    }
    const handleFilter = () => {
        setFilter({ ...filter, ...values });
        setPage(1);
        setOffset(0);
        limit(100);
        isFilter(1);
    }
    const handleFilterReset = () => {
        setFilter({});
        limit(100);
        setValues({
            ...values,
            Package: "",
            PhoneNumber: "",

        })
        setPage(1);
        isFilter(0);
    },
        headers = [
            { label: "Name", key: "Name" },
            { label: "Package", key: "Package" },
            { label: "Date Of Birth", key: "DOB" },
            { label: "Gender", key: "Gender" },
            { label: "Mobile", key: "PhoneNumber" },
            { label: "Address", key: "Address" },
            { label: "State", key: "State" },
            { label: "City", key: "City" },
            { label: "Booking Date", key: "BookingDate" },
            { label: "Preferred Slot", key: "PreferredSlot" },
            { label: "Created Date", key: "CreatedAt" },
        ];
    return (
        <div className='table_filter w-80'>
            <Row gutter={[10, 20]} className="ml-10">
                <Col md={6} sm={6} xs={24}>
                <strong>  <p className='mb-2'>Package</p></strong>
                    <Input
                        type="text"
                        autoComplete="new-off"
                        className='form-control'
                        onChange={(e) => handleChange("Package", e.target.value)}
                        placeholder='Search by Package'
                        value={values?.Package}
                    />
                </Col>
                <Col md={6} sm={6} xs={24}>
                <strong>  <p className='mb-2'>Mobile</p></strong>
                    <Input
                        type="text"
                        autoComplete="new-off"
                        className='form-control'
                        onChange={(e) => handleChange("PhoneNumber", e.target.value)}
                        placeholder='Search by Mobile'
                        value={values?.PhoneNumber}
                    />
                </Col>
                <Col md={4} sm={12} xs={24}>
                    <div className='flex gap10 mt-20'>
                        <button className='filter-button' onClick={() => handleFilter()}>Filter</button>
                        <button className='clear-button' onClick={() => handleFilterReset()}>Reset</button>
                    </div>
                </Col>
                <Col md={4} sm={12} xs={24} className='mt-20'>
                    <CSVLink
                        filename={"Home Collection.csv"}
                        data={FilteredData}
                        headers={headers}
                        className="ex-export2 part-export"
                        onClick={() => {
                            message.success("The file is downloading")
                        }}
                    >
                        <Tooltip placement="top" title={"A user can download only single page data once."} arrow={false}>
                            <FileExcelFilled />
                        </Tooltip>
                    </CSVLink>
                </Col>
            </Row>

        </div >
    )
}

export default HomeCollectionFilter