import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   couponCategoryList: [],
   TestCodeList: [],
    totalcouponCategoryList: "",
    CouponCategoryDetail: {},
    couponDetail: {},
    totalcouponList: "",
    totalBtlCustomerList: "",
}

export const couponSlice = createSlice({
    name: 'coupon',
    initialState,
    reducers: {
        setCouponCategoryList: (state, action) => {
            state.couponCategoryList = action.payload.list;
            state.totalcouponCategoryList = action.payload.total;
        },
        setCouponCategoryDetail: (state, action) => {
            state.CouponCategoryDetail = action.payload;
        },
        setCouponList: (state, action) => {
            state.CouponList = action.payload.list;
            state.totalcouponList = action.payload.total;
        },
        setCouponDetail: (state, action) => {
            state.couponDetail = action.payload;
        },
        setTestCodeList: (state, action) => {
            state.TestCodeList = action.payload.list;
          state.TotalRecord = action.payload.total;
        },
      
        setBtlOffersList: (state, action) => {
            state.BtlOffersList = action.payload.list;
            state.totalBtlOffersList = action.payload.total;
        },
        setBtlCustomerList: (state, action) => {
            state.BtlCustomer = action.payload.list;
            state.totalBtlCustomerList = action.payload.total;
        },
    },
})

export const { setCouponCategoryList, setCouponCategoryDetail, setCouponList,setCouponDetail,setTestCodeList,setBtlOffersList,setBtlCustomerList} = couponSlice.actions

export default couponSlice.reducer