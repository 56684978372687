import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    TeamMemberList: [],
    totalTeamMemberList: [],
    TeamMemberDetail: {},
}

export const teamSlice = createSlice({
    name: 'team',
    initialState,
    reducers: {
      
        setTeamMemberList: (state, action) => {
            state.TeamMemberList = action.payload.list;
            state.totalTeamMemberList = action.payload.total;
        },
        setTeamMemberDetail: (state, action) => {
            state.teamDetail = action.payload;
        },
    },
})

export const { setTeamMemberList, setTeamMemberDetail} = teamSlice.actions

export default teamSlice.reducer