import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    SmsPartnerList: [],
}

export const smsSlice = createSlice({
    name: 'sms',
    initialState,
    reducers: {
      
        setsmsPartnerList: (state, action) => {
            state.SmsPartnerList = action.payload.list;
        },
    },
})

export const { setsmsPartnerList} = smsSlice.actions

export default smsSlice.reducer