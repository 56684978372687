import React, { useEffect } from 'react'
import { Col, Form, Input, Row, Select } from 'antd'
import { useNavigate, useParams } from 'react-router-dom';
import { UserServices } from '../../../redux/Services';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { GetLoggedInUser } from '../../../utils';

const { Option } = Select;

const ChangePassword = () => {
    const { userId } = useParams();
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { mutate: updatePassword } = useMutation((data) => dispatch(UserServices.updateUserPasswordApi(data, userId, navigate)));

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            if (userId) {
                let _data = { ...values };
                updatePassword(_data);
            }
        })
    }

    return (
        <>
            <div className='p-20'>
                <h1>Change Password</h1>
                < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                >
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="New Password"
                                name="NewPassword"
                                rules={[{ required: true, message: 'New Password is required' }]}
                            >
                                <Input type="password" autoComplete="new-off" className='form-control' placeholder='New Password' />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Confirm Password"
                                name="ConfirmPassword"
                                rules={[{ required: true, message: 'Confirm Password is required' }]}
                            >
                                <Input type="password" autoComplete="new-off" className='form-control' placeholder='Confirm Password' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit}>
                            Update Password
                        </button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default ChangePassword