import React, { useEffect } from 'react'
import { Col, Form, Input, Row, Select } from 'antd'
import { GetLoggedInUser, onlyMobileInput } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { UserServices } from '../../../redux/Services';
import HeadingBack from '../../../component/HeadingBack';
import { usePermission } from '../../../hooks/usePermission';

const { Option } = Select;

const AddUser = () => {
    const permission = usePermission("Users", "Add");
    onlyMobileInput();
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const roleList = useSelector(state => state.user.roleList || []);
    const { mutate: fetchRoles } = useMutation((data) => dispatch(UserServices.fetchRolesApi(data)));
    const { mutate: addUser } = useMutation((data) => dispatch(UserServices.addUsersApi(data, navigate)));
    useEffect(() => {
        fetchRoles({});
        return () => { }
    }, [])
    const handleSubmit = () => {
        form.validateFields().then((values) => {
            let _data = { ...values };
            _data.UserId = LOG_IN_USER?.id;
            addUser(_data)
        })
    }
    const handleProfileUpload = (e) => {
        let file = e.target.files[0];
        /* form.setFieldValue('ProfilePhoto', file); */
    }
    return (
        <>
            <HeadingBack title={"User Add"} subHeading="" />
            <div className='p-20'>
                < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                >
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Name"
                                name="Name"
                                rules={[{ required: true, message: 'Name is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Name' />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Mobile"
                                name="Mobile"
                                rules={[
                                    { required: true, message: 'Mobile is required' },
                                    { min: 10, message: "Enter min 10 Number" }
                                ]}
                            >
                                <Input type="text" id="only_mobile" maxLength={10} autoComplete="new-off" className='form-control' placeholder='Mobile' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Email"
                                name="Email"
                                rules={[
                                    { required: true, message: 'Email is required' },
                                    { type: 'email', message: 'Email is Invalid' }
                                ]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Email' />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Password"
                                name="Password"
                                rules={[{ required: true, message: 'Password is required' }]}
                            >
                                <Input.Password className='form-control' autoComplete="new-off" placeholder='Password' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Role"
                                name="RoleId"
                                rules={[{ required: true, message: 'Role is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Role'
                                    autoComplete="new-off"
                                    name="RoleId"
                                >
                                    <Option className='text-capitalize' value={""}>Select Role</Option>
                                    {roleList?.length > 0 && roleList?.map((item, idx) =>
                                        <Option key={idx} className='text-capitalize' value={item.RoleId}>{item.Role}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Profile Photo"
                            >
                                <input type="file" name="ProfilePhoto" onChange={(e) => handleProfileUpload(e)} className='form-control' accept='image/*' autoComplete="new-off" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit}>
                            Submit
                        </button>
                    </div>
                </Form >
            </div>
        </>
    )
}

export default AddUser