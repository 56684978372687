
import { GetLoggedInUser } from ".";

export default function PermissionAccess(module) {
    let LOGGED_IN_USER = GetLoggedInUser();
    let List = true;
    let Add = true;
    let Edit = true;
    let Delete = true;
    if (LOGGED_IN_USER?.role_id == 1) {
        return { List, Add, Edit, Delete }
    } else {
        let permission = JSON.parse(LOGGED_IN_USER?.permission || "[]");
        let obj = permission.find(ele => ele.module == module);
        if (obj?.permission == 1) {
            List = true;
            Add = false;
            Edit = false;
            Delete = false;
        } else if (obj?.permission == 2) {
            List = true;
            Add = true;
            Edit = false;
            Delete = false;
        }
        else if (obj?.permission == 3) {
            List = true;
            Add = false;
            Edit = true;
            Delete = false;
        }
        else if (obj?.permission == 4) {
            List = true;
            Add = true;
            Edit = true;
            Delete = false;
        } else if (obj?.permission == 5) {
            List = true;
            Add = true;
            Edit = true;
            Delete = true;
        } else {
            List = false;
            Add = false;
            Edit = false;
            Delete = false;
        }
        return { List, Add, Edit, Delete }
    }
}