import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    attachmentList: [],
    TotalRecord: '',
  
}

export const BlogAttachmentSlice = createSlice({
    name: 'BlogAttachment',
    initialState,
    reducers: {
        setattachmentList: (state, action) => {
            state.attachmentList = action.payload.list;
          state.TotalRecord = action.payload.total;
        },
      
    
    },
})

export const { setattachmentList} = BlogAttachmentSlice.actions

export default BlogAttachmentSlice.reducer