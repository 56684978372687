import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    bannerList: [],
    TotalRecord: '',
    bannerDetail: {},
  
}

export const WhatsNewbannerSlice = createSlice({
    name: 'whatsNewBanner',
    initialState,
    reducers: {
        setWhatsNewBannerList: (state, action) => {
            state.bannerList = action.payload.list;
          state.TotalRecord = action.payload.total;
        },
      
        setWhatsNewBannerBannerDetail: (state, action) => {
            state.bannerDetail = action.payload;
        },
    },
})

export const { setWhatsNewBannerList, setWhatsNewBannerBannerDetail} = WhatsNewbannerSlice.actions

export default WhatsNewbannerSlice.reducer