import { Upload } from 'antd';
import React, { useEffect, useState } from 'react'
import API_URL from '../../../api/url';

const FileUpload = ({ file, setFile, handleUpload }) => {
    const [fileList, setFileList] = useState([]);
    const onPreview = async (file) => {
        let src = file.url;
        window.open(src, "_blank");
    };
    const onRemove = () => {
        setFile("");
        setFileList([]);
    }
    useEffect(() => {
        if (file) {
            setFileList([{
                crossOrigin: "anonymous",
                uid: '-1',
                name: "Banner",
                status: 'done',
                url: API_URL.S3_BANNER_BASE + file,
                thumbUrl: API_URL.S3_BANNER_BASE + file
            }])
        }
    }, [file])

    return (
        <Upload
            className='upload_img'
            accept="image/jpeg,image/png,image/jpg"
            multiple={false}
            listType="picture-card"
            onPreview={onPreview}
            onRemove={onRemove}
            customRequest={({ file }) =>
                handleUpload(file)
            }
            fileList={fileList}
        >
            {fileList.length < 1 && '+ Upload'}
        </Upload>
    )
}

export default FileUpload