import React, { useEffect } from 'react'
import { Col, Form, Input, Row } from 'antd'
import { useNavigate, useParams } from 'react-router-dom';
import { GetLoggedInUser } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { UserServices } from '../../../redux/Services';
import HeadingBack from '../../../component/HeadingBack';
import { usePermission } from '../../../hooks/usePermission';

const EditRoles = () => {
    const permission = usePermission("Roles", "Edit");
    let LOG_IN_USER = GetLoggedInUser();
    const { roleId } = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const roleData = useSelector(state => state.user.roleDetail || {});
    const { mutate: fetchRoleById } = useMutation((data) => dispatch(UserServices.fetchRolesByIdApi(data, roleId)));
    const { mutate: updateRole } = useMutation((data) => dispatch(UserServices.updateRolesApi(data, roleId, navigate)));
    useEffect(() => {
        if (roleId) {
            fetchRoleById({});
        }
        return () => { }
    }, [roleId])

    useEffect(() => {
        if (roleData && Object.keys(roleData)?.length > 0) {
            form.setFieldValue('Role', roleData?.Role);
            form.setFieldValue('AdditionalInfo', roleData?.AdditionalInfo);
        }
    }, [roleData])

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            if (roleId) {
                let _data = { ...values };
                _data.UserId = LOG_IN_USER?.id;
                updateRole(_data)
            }
        })
    }
    return (
        <>
            <HeadingBack title={"Role Edit"} subHeading="" />
            <div className='p-20'>
                <Row>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        < Form
                            form={form}
                            name="basic"
                            layout={"vertical"}
                            initialValues={{
                                Role: "",
                                AdditionalInfo: ""
                            }}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Role"
                                name="Role"
                                rules={[{ required: true, message: 'Role is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Role' />
                            </Form.Item>
                            <Form.Item
                                label="Additional Field"
                                name="AdditionalInfo"
                            >
                                <Input.TextArea autoComplete="new-off" className='form-control' placeholder='Enter Text' rows={4} maxLength={100} />
                            </Form.Item>
                            <div className='text-center'>
                                <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit}>
                                    Update
                                </button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default EditRoles