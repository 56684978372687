import Api from "../../api";
import API_URL from "../../api/url";
import { setFaqsCount, setUsersCount, setJobAddCount, setJobAppCount } from "../Slices/dashboardSlice";


export const fetchUserCountApi = (data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.DASHBOARD_USER_COUNT, data);
        dispatch(setUsersCount(res.data?.Data));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchFaqCountApi = (data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.DASHBOARD_FAQ_COUNT, data);
        dispatch(setFaqsCount(res.data?.Data));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchJobAddCountApi = (data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.DASHBOARD_JOB_ADD_COUNT, data);
        dispatch(setJobAddCount(res.data?.Data));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchJobApplicationsListApi = (limit, offset, data) => async (dispatch) => {
    try {
        // var params = params;
        data.Limit = limit;
        data.Offset = offset;
        // var params = new URLSearchParams(data).toString();
        // var params = JSON.stringify(data, '==');
        // console.log(params);
        // params = encodeURIComponent(params);
        // params = encodeURI(params);
        const res = await Api.post(API_URL.JOBAPPLICATIONS_LIST, data);
        dispatch(setJobAppCount({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};