import { notification } from "antd";
import Api from "../../api";
import API_URL from "../../api/url";
import { setSeoList, setSeoDetails } from "../Slices/SeoSlice";

export const fetchSeoListApi = (limit, offset,data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.SEO_LIST, data);
        dispatch(setSeoList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};

export const fetchSeoDetailsApi = (id) => async (dispatch) => {
    try {
        var url = API_URL.SEO_DETAILS + '/'+ id;
        const res = await Api.post(url);
        dispatch(setSeoDetails({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const SeoDetailUpdateApi = (data, id, navigate) => async (dispatch) => {
    try {
        const res = await Api.put(`${API_URL.SEO_UPDATE}/${id}`, data);
        navigate("/seo-management");
        notification["success"]({
            message: "Seo updated successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const addseoApi = (data, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.SEO_ADD, data);
        navigate("/seo-management");
        notification["success"]({
            message: "Seo add successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};

