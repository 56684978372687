import { notification } from "antd";
import Api from "../../api";
import API_URL from "../../api/url";
import { setDownloadReportList, setDownloadReportDetail} from "../Slices/ReportDownloadSlice";

export const fetchManageDownloadReportApi = (data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.MANAGE_DOWNLOAD_REPORT_LIST, data);
        dispatch(setDownloadReportList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const addManageDownloadReportApi = (data, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.MANAGE_DOWNLOAD_REPORT_ADD, data);
        if(res.data?.StatusCode === 500){
            notification["error"]({
                message: res.data?.Message || "Something is not right"
        });
        }else{
        navigate("/manage-download-report");
        notification["success"]({
            message: "Download Report added successfully"
        });
        return res;
    }
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const detailManageDownloadReportApi = (data, Id) => async (dispatch) => {
    try {
        // const res = await Api.post(`${API_URL.TEAM_DETAIL}/${id}`);
        const res = await Api.post(API_URL.MANAGE_DOWNLOAD_REPORT_DETAIL, Id);
        dispatch(setDownloadReportDetail(res.data.Data));
        return res;
    } catch (error) {
        return error
    }
};
export const updateManageDownloadReportApi = (data, id, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(`${API_URL.MANAGE_DOWNLOAD_REPORT_UPDATE}`, data);
        navigate("/manage-download-report");
        notification["success"]({
            message: "Download Report updated successfully"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};

