import { Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeadingBack from '../../component/HeadingBack';
import SvgIcon from '../../const/SvgIcon';
import { SeoServices } from '../../redux/Services';
import SeoFilter from '../SeoManagement/seoFilter';
import moment from 'moment';
import { usePermission } from '../../hooks/usePermission';

const Seo = () => {
    const permission = usePermission("Seo Pages", "List");
    const navigate = useNavigate();
    const [filter, setFilter] = useState({});
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const SeoLists = useSelector(state => state.seo.SeoList || []);
    const totalSeo = useSelector(state => state.seo.TotalRecord || 0);
    const { mutate: SeoList, isLoading } = useMutation((data) => dispatch(SeoServices.fetchSeoListApi(limit, offset, data)));
    useEffect(() => {
        SeoList(filter)
        return () => { }
    }, [filter, offset])
    console.log(filter)
    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
            className:'table_first_col',
            render: (value, item, index) => (page - 1) * 10 + index + 1
        },

        {
            title: 'Reference Name',
            dataIndex: 'PageName',
            key: 'PageName',
            className: 'text-capitalize',
        },
        {
            title: 'Slug',
            dataIndex: 'Slug',
            key: 'Slug',
            className: 'text-capitalize',
        },
        {
            title: 'Seo Title',
            dataIndex: 'SeoTitle',
            key: 'SeoTitle',
        },
        {
            title: 'Seo Keywords',
            dataIndex: 'SeoKeywords',
            key: 'SeoKeywords',
        },

        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            className: 'table_status_col',
            render: (value, item, index) => {
                return (
                    <span className={item?.Status ? "status_active" : "status_inactive"}>{item?.Status ? "Active" : "Inactive"}</span>
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            className: 'table_action_col',
            render: (value, item, index) => {
                return (
                    <Tooltip title="Edit">
                        <span className='cursor-pointer' onClick={() => navigate(`/seo-management/edit/${item?.Id}`)}>
                            <SvgIcon.Edit className="scale-icon edit-icon" />
                        </span>
                    </Tooltip>
                )
            }
        },
    ];
    return (
        <div>
            <div className='flex'>
                <HeadingBack title={"Total Meta Tags"} subHeading={`Total ${totalSeo || 0} Meta Tags`} />
                <SeoFilter filter={filter} setFilter={setFilter} setPage={setPage} setOffset={setOffset} />
                <div className='flex job-post-add'>
                    <Tooltip title="Add"><span className='jop_btn'onClick={() => navigate("/seo-management/add")}>Add<SvgIcon.Add width={40} height={40}  className="cursor-pointer" /></span></Tooltip>
                </div>
            </div>
            <div className='tableResponsive mt-10'>
                <Table
                    rowKey='UsrId'
                    className={isLoading ? 'table antd_table no_data_found_hide' : 'table antd_table'}
                    bordered
                    columns={columns}
                    dataSource={Array.isArray(SeoLists) && SeoLists?.length > 0 ? SeoLists : []}
                    pagination={{
                        current: page,
                        pageSize: limit,
                        total: totalSeo,
                        onChange(current, pageSize) {
                            setPage(current);
                            setOffset(current == 1 ? 0 : (limit * current));
                        },
                        hideOnSinglePage: true
                    }}

                />
            </div>
        </div>
    )
}

export default Seo