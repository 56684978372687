import React, { useState } from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom'
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import { UserServices } from '../redux/Services';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
const { Header, Content,Footer } = Layout;

const LayoutMain = () => {
    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useState(false);
    const { mutate: getFilterData, isLoading } = useMutation((data) => dispatch(UserServices.fetchFilterDataApi(data)));

    useEffect(()=> {
        getFilterData({})
    },[])
    return (
        <>
            <Layout className='layout_css'>
                <Sidebar collapsed={collapsed} />
                <Layout className="site-layout">
                    <Header className="site-layout-background" style={{ padding: 0 }}>
                        <TopBar collapsed={collapsed} setCollapsed={setCollapsed} />
                    </Header>
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '6px',
                            padding: 10,
                            minHeight: 280,
                            overflowY: 'auto'
                        }}
                    >
                        <Outlet />
                    </Content>
                    {/* <Footer></Footer> */}
                </Layout>
            </Layout>
        </>
    )
}

export default LayoutMain