
import { Col, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { EyeOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeadingBack from '../../component/HeadingBack';
import SvgIcon from '../../const/SvgIcon';
import { TestDepartmentServices } from '../../redux/Services';
import { FaqServices } from '../../redux/Services';
import { DepartmentServices } from '../../redux/Services';
import TestFilter from '../TestManagement/TestManagementfilter'
import { usePermission } from '../../hooks/usePermission';

const TestManagement = () => {
    const permission = usePermission("Test Management", "List");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const TestdepartmentList = useSelector(state => state.Testdepartment.TestdepartmentList || []);
    const totalTestdepartment = useSelector(state => state.Testdepartment.TotalRecord || 0);
    const faqCategoryList = useSelector(state => state.faq.faqCategoryList || []);
    const departmentList = useSelector(state => state.department.departmentList || []);
    const { mutate: fetchDepartmentList } = useMutation((data) => dispatch(DepartmentServices.fetchDeepartmentListApi(data)));
    const { mutate: fetchFaqCategory } = useMutation((data) => dispatch(FaqServices.fetchFaqCategoryApi(data)));
    const { mutate: fetchTestDepartmentList, isLoading } = useMutation((data) => dispatch(TestDepartmentServices.fetchTestDeepartmentListApi(data)));
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState({});
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    useEffect(() => {
        fetchDepartmentList({ params: { isActive: "1" } });
        return () => { }
    }, [])
    useEffect(() => {
        fetchTestDepartmentList(filter);
        return () => { }
    }, [filter, offset])
    useEffect(() => {
        fetchFaqCategory({ isActive: "1" });
        return () => { }
    }, [])



    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
            className:'table_first_col',
            render: (value, item, index) => (page - 1) * 10 + index + 1
        },
        {
            title: 'Test Name',
            dataIndex: 'TestName',
            key: 'TestName',
            className: 'text-capitalize'
        },
        {
            title: 'Test Code',
            dataIndex: 'TestCode',
            key: 'DepartmentName',
            className: 'text-capitalize'
        },
        {
            title: 'Related Test Codes',
            dataIndex: 'RelatedTestCode',
            key: 'RelatedTestCode',
            className: 'text-capitalize'
        },
        {
            title: 'Department',
            dataIndex: 'Departments',
            key: 'Departments',
            className: 'text-capitalize',
            render: (value, item, index) => {
                return (
                    <>{item?.Departments ? item?.Departments.Department_Name : "NA"} </>
                )
            }
        },
        {
            title: 'Category',
            dataIndex: 'CategoryId',
            key: 'Category',
            className: 'text-capitalize',
            render: (value, item, index) => {
                return (
                    <>{item?.FaqCategory ? item?.FaqCategory.Category : "NA"} </>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            className: 'table_status_col',
            render: (value, item, index) => {
                return (
                    <span className={item?.Status ? "status_active" : "status_inactive"}>{item?.Status ? "Active" : "Inactive"}</span>
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            className: 'table_action_col',
            render: (value, item, index) => {
                return (
                    <>
                        <Tooltip title="Edit"><span className='cursor-pointer' onClick={() => navigate(`/test-management/edit/${item?.Id}`)}><SvgIcon.Edit className="scale-icon edit-icon" /></span></Tooltip>
                        <Tooltip title="View Faqs"> <span className='cursor-pointer' onClick={() => navigate(`/faqs?TestCode=${item?.TestCode}`)} ><EyeOutlined className="scale-icon faq-icon" /></span></Tooltip>
                    </>
                )
            }
        }
    ];
    return (

        <><Row gutter={[10, 10]} align={"middle"}>
            <Col lg={4} md={24} sm={24} xs={24}>
                <HeadingBack title={"Test Management"} subHeading={`Total ${totalTestdepartment || 0} Tests`} />
            </Col>
            <Col lg={16} md={20} sm={24} xs={24}>
                <TestFilter filter={filter} setFilter={setFilter} setPage={setPage} faqCategoryList={faqCategoryList} departmentList={departmentList} />
            </Col>
            <Col lg={4} md={30} sm={24} xs={24}>
                <div className='flex1 flex justify-content-end'>
                    <Tooltip title="Add"><span className='jop_btn' onClick={() => navigate("/test-management/add")}>Add<SvgIcon.Add width={40} height={40} className="cursor-pointer" /></span></Tooltip>
                </div>
            </Col>
        </Row><div className='tableResponsive mt-10'>
                <Table
                    rowKey='Id'
                    className={isLoading ? 'table antd_table no_data_found_hide' : 'table antd_table'}
                    bordered
                    columns={columns}
                    dataSource={Array.isArray(TestdepartmentList) && TestdepartmentList?.length > 0 ? TestdepartmentList : []}
                    pagination={{
                        current: page,
                        pageSize: limit,
                        onChange(current, pageSize) {
                            setPage(current);
                            setOffset(current == 1 ? 0 : (limit * current));
                        },
                        hideOnSinglePage: true
                    }} />
                {/* <table className='table table-bordered'>
        <thead>
            <tr>
                <th>S.No.</th>
                <th>Category</th>
                <th>Status</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            {faqCategoryList && faqCategoryList?.length > 0 && faqCategoryList?.map((item, idx) => (
                <tr key={idx}>
                    <td>{++idx}</td>
                    <td className='text-capitalize'>{item?.Category}</td>
                    <td ><span className={item?.Status ? "status_active" : "status_inactive"}>{item?.Status ? "Active" : "Inactive"}</span></td>
                    <td><span className='cursor-pointer' onClick={() => navigate(`/faq-category/edit/${item?.Id}`)}><SvgIcon.Pencil className="scale-icon" /></span></td>
                </tr>
            ))
            }
        </tbody>
    </table> */}
            </div></>

    )
}

export default TestManagement