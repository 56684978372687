import { notification } from "antd";
import Api from "../../api";
import API_URL from "../../api/url";
import { setAddattachmentApi,setTicketList,setTicketDetails } from "../Slices/TicketSlice";
 
export const addattachmentApi = (data) => async (dispatch) => {
    try { 
        const res = await Api.post(API_URL.BANNER_IMG_ADD, data);
        dispatch(setAddattachmentApi({ list: res.data.fileName, total: 0 }));
        notification["success"]({
            message: "Attachment add successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const addTicketApi = (data, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.TICKET_ADD, data);
        navigate("/ticket-management");
        notification["success"]({
            message: "Ticket add successfully!"
            
        });
        return res;
  
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const fetchTicketListApi = (limit, offset,data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.TICKET_LIST, data);
        dispatch(setTicketList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchTicketDetailsApi = (id) => async (dispatch) => {
    try {
        var url = API_URL.TICKET_DETAILS + '/'+ id;
        const res = await Api.post(url);
        dispatch(setTicketDetails({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const TicketDetailUpdateApi = (data, id, navigate) => async (dispatch) => {
    try {
        const res = await Api.put(`${API_URL.TICKET_UPDATE}/${id}`, data);
        navigate(`/ticket-management/edit/${id}`);
        notification["success"]({
            message: "Ticket updated successfully!"
        });
        window.location.reload(true);
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};

