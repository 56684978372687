import { notification } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NO_PERMISSION_MESSAGE } from "../const/ConstData";
import PermissionAccess from "../utils/Permission";
import { ipValidation } from "../hooks/ipValidation";

export const usePermission = (module, type) => {
    // useEffect(() => {
    //     ipValidation();
    // }, []);
    const [permission, setPermission] = useState(false);
    const navigate = useNavigate();
    let Permission = PermissionAccess(module)
    useEffect(() => {
        if (Permission && Permission[type]) {
            setPermission(true);
        } else {
            setPermission(false);
            navigate("/", { replace: true });
            notification.destroy()
            notification["error"]({
                message: NO_PERMISSION_MESSAGE
            })
        }
    }, [Permission])
    return permission
};