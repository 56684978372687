import { notification } from "antd";
import Api from "../../api";
import API_URL from "../../api/url";
import {setattachmentList} from "../Slices/blogAttachmentSlice";

export const fetchBlogAttachmentApi = (data) => async (dispatch) => {
    try {
        const res = await Api.get(API_URL.BLOG_ATTACHMENTS_LIST, data);
        dispatch(setattachmentList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};

export const addBlogAttachmentApi = (data, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL. BLOG_ATTACHMENTS__ADD, data);
        navigate(-1);
        notification["success"]({
            message: "Blog Attachment added successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const blogImageAddApi = (data) => async (dispatch) => {
    try {
        const res = await Api.post(`${API_URL. BLOG_IMG_ADD}`, data);
        notification["success"]({
            message: "Image added successfully"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const deleteBlogAttachmentApi = (data, id) => async (dispatch) => {
    try {
        const res = await Api.put(`${API_URL.BTL_OFFERS_STATUS_UPDATE}/${id}`, data);
        notification["success"]({
            message: "Offer Status updated successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
