import { Table, Tag, Tooltip, message } from 'antd';
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FileExcelFilled } from '@ant-design/icons';
import HeadingBack from '../../component/HeadingBack';
import { CSVLink } from "react-csv"
import { JobApplicationsServices } from '../../redux/Services';
import HomeCollectionFilter from './homecollectionFilter';
// import URL from "../../../api/url";
import { usePermission } from '../../hooks/usePermission';
import moment from 'moment';
import { JSONParse, getBusinessProfession } from "../../utils";

const HomeCollection = () => {

    const permission = usePermission("Home Collection", "List");
    const navigate = useNavigate();
    const [filter, setFilter] = useState({});
    const [limit, setLimit] = useState(100);
    const [offset, setOffset] = useState(0);
    const [isFilter, setisFilter] = useState(0);
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const HomeCollectionList = useSelector(state => state.jobAppliction.HomeCollectionList || []);
    const totalHomeCollection = useSelector(state => state.jobAppliction.totalHomeCollectionList || 0);
    const { mutate: getHomeCollectionList, isLoading } = useMutation((data) => dispatch(JobApplicationsServices.HomeCollectionApi(limit, offset, data)));

    useEffect(() => {
        getHomeCollectionList(filter);
        return () => { }
    }, [filter, offset, limit])

    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
            className: 'table_first_col',
            render: (value, item, index) => (page - 1) * 100 + index + 1
        },
        {
            title: 'Name',
            dataIndex: 'Name',
            key: 'Name',
            className: 'text-capitalize',
        },
        {
            title: 'Date Of Birth',
            dataIndex: 'DOB',
            key: 'DOB',
            render: (value, item, index) => {
                return (
                    <>{moment(item.DOB).format('DD-MM-YYYY')}</>
                )
            }
        },
        {
            title: 'Gender',
            dataIndex: 'Gender',
            key: 'Gender',
        },
        {
            title: 'Mobile',
            dataIndex: 'PhoneNumber',
            key: 'PhoneNumber',
        },
        {
            title: 'Package',
            dataIndex: 'Package',
            key: 'Package',
            render: (value, item,) => {
                let Package = JSONParse(item?.Package);
                return (
                    <>
                        {Package?.length > 0 && Package?.map((item) => (<Tag key={item?.item_id}>{item?.package} Price:{item?.price}</Tag>))}
                    </>
                )
            }
        },
        {
            title: 'Amount',
            dataIndex: 'Amount',
            key: 'Amount',
        },
        {
            title: 'Address',
            dataIndex: 'Address',
            key: 'Address',
        },
        {
            title: 'State',
            dataIndex: 'State',
            key: 'State',
        },
        {
            title: 'City',
            dataIndex: 'City',
            key: 'City',
            render: (value, item, index) => {
                return (
                    <span>{(item.City) ? item.City : item.CandidateLocation}</span>
                )
            }
        },
        {
            title: 'Booking Date',
            dataIndex: 'BookingDate',
            key: 'BookingDate',
            render: (value, item, index) => {
                return (
                    <>{moment(item.BookingDate).format('DD-MM-YYYY')}</>
                )
            }
        },
        {
            title: 'Preferred Slot',
            dataIndex: 'PreferredSlot',
            key: 'PreferredSlot',
        },
        {
            title: 'Created At',
            dataIndex: 'CreatedAt',
            key: 'CreatedAt',
            render: (value, item, index) => {
                return (
                    <>{moment(item.CreatedAt).format('DD-MM-YYYY H:mm:ss')}</>
                )
            }
        },

    ];
    return (
        <div>
            <div className='position-relative part-filter'>
                <HeadingBack title={"Total Leads"} subHeading={`Total ${totalHomeCollection || 0} Leads`} />
                <HomeCollectionFilter filter={filter} setFilter={setFilter} setPage={setPage} setOffset={setOffset} limit={setLimit} isFilter={setisFilter} />


            </div>
            <div className='tableResponsive mt-10'>
                <Table
                    rowKey='CreatedAt'
                    className={isLoading ? 'table antd_table no_data_found_hide' : 'table antd_table'}
                    bordered
                    columns={columns}
                    dataSource={Array.isArray(HomeCollectionList) && HomeCollectionList?.length > 0 ? HomeCollectionList : []}
                    pagination={{
                        current: page,
                        pageSize: limit,
                        total: totalHomeCollection,
                        onChange(current, pageSize) {
                            setPage(current);
                            setOffset(current == 1 ? 0 : (((current - 1) * limit)));
                        },
                        hideOnSinglePage: true
                    }}

                />
            </div>
        </div>
    )
}

export default HomeCollection