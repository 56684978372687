import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import PageRoutes from './routes';

function App() {
  return (
    <Router>
      <PageRoutes />
    </Router>
  );
}

export default App;
