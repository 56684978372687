import { notification } from "antd";
import Api from "../../api";
import API_URL from "../../api/url";
import { setDepartmentDetail, setDepartmentList } from "../Slices/departmentSlice";

export const fetchDeepartmentListApi = (data) => async (dispatch) => {
    try {
        const res = await Api.get(API_URL.DEPARTMENT_LIST, data);
        dispatch(setDepartmentList({ list: res.data.Data, total: res.data.TotalRecord}));
        return res;
    } catch (error) {
        return error
    }
};

export const fetchDepartmentDetailApi = (data, id) => async (dispatch) => {
    try {
        const res = await Api.post(`${API_URL.DEPARTMENT_DETAILS}/${id}`);
        dispatch(setDepartmentDetail(res.data.Data));
        return res;
    } catch (error) {
        return error
    }
};
export const addDepartmentApi = (data, navigate) => async (dispatch) => {
    try {
                const res = await Api.post(API_URL.DEPARTMENT_ADD, data);
                navigate("/department");
                notification["success"]({
                    message: "Department created successfully!"
                });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const DeepartmentDetailUpdateApi = (data, id, navigate) => async (dispatch) => {
    try {
        const res = await Api.put(`${API_URL.DEPARTMENT_UPDATE}/${id}`, data);
        navigate("/department");
        notification["success"]({
            message: "Deepartment updated successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
