import { Col, Row } from 'antd'
import React, { useEffect } from 'react'
import { useMutation } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { DashboardServices } from '../../redux/Services'
import { JobApplicationsServices } from '../../redux/Services';
import Boxes from './Boxes/Boxes'
import CenteralHealthLine from './CenteralHealthLineChart/CenteralHealthLine'

const Dashboard = () => {
    const dispatch = useDispatch();
    // const jobApplicationsList = useSelector(state => state.jobAppliction.jobApplicationList || []);
    const { mutate: getUserCount } = useMutation((data) => dispatch(DashboardServices.fetchUserCountApi(data)));
    const { mutate: getFaqCount } = useMutation((data) => dispatch(DashboardServices.fetchFaqCountApi(data)));
    const { mutate: getJobApplicationAddCount } = useMutation((data) => dispatch(DashboardServices.fetchJobAddCountApi(data)));
    const { mutate: getJobApplicationsList } = useMutation((data) => dispatch(JobApplicationsServices.fetchJobApplicationsListApi(10, 0, data)));
    useEffect(() => {
        getUserCount({});
        getFaqCount({});
        getJobApplicationAddCount({});
        getJobApplicationsList({});
    }, [])
    return (
        <div>
            <Row gutter={[20, 20]} type="flex">
                <Col lg={18} md={24} sm={24} xs={24}>
                    <div className="dashboardBox p-10">
                        <CenteralHealthLine />
                    </div>
                </Col>
                <Col lg={6} md={24} sm={24} xs={24}>
                    <div>
                        <Boxes />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Dashboard