import React, { useEffect,useState } from 'react'
import { Col, Form, Input, Row, Select } from 'antd'
import CustomEditor from '../../component/CustomEditor/CustomEditor';
import { GetLoggedInUser, onlyMobileInput } from '../../utils';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { TicketServices } from '../../redux/Services'
import HeadingBack from '../../component/HeadingBack';
import { usePermission } from '../../hooks/usePermission';
import FileUpload from './fileUpload';
const { Option } = Select;

const AddTicket = () => {
    const permission = usePermission("Ticket Management", "Add");
    onlyMobileInput();
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { mutate: addTicket, isLoading: addLoading } = useMutation((data) => dispatch(TicketServices.addTicketApi(data, navigate)));
  
    const handleSubmit = () => {
        form.validateFields().then((values) => {
            let _data = { ...values };
            _data.UserId = LOG_IN_USER?.id;
            _data.Attachments = attachedFilesArr?attachedFilesArr:[];
            addTicket(_data)
        })
       
    }
    const [attachedFilesArr, setattachedFilesArr] = useState([]);
    return (
        <>
            <HeadingBack title={"Ticket Add"} subHeading="" />
            <div className='p-20'>
                < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                >
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Subject"
                                name="Subject"
                                rules={[{ required: true, message: 'Subject is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Subject' />
                            </Form.Item>
                        </Col >
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label="Description"
                                name="Description"
                                rules={[{ required: true, message: 'Description is required' }]}
                            >
                                {/* <CustomEditor value={form.getFieldValue("SeoDescription ")} onEditorChange={(value) => form.setFieldsValue({ SeoDescription: value })} /> */}
                                <textarea rows="8" cols="50" className='form-control' value={form.getFieldValue("Description ")} onEditorChange={(value) => form.setFieldsValue({ Description: value })} />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                        <Form.Item
                                label="Attachment File"
                                name="Attachments"
                                rules={[{ required: true, message: 'Attachment File is required' }]}
                            >
                                     <FileUpload attachedFilesArr= {attachedFilesArr} setattachedFilesArr={setattachedFilesArr} />
                            </Form.Item>
                        </Col >
                    </Row>
                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit} disabled={addLoading}>
                            Submit
                        </button>
                    </div>
                </Form >
            </div>
        </>
    )
}

export default AddTicket