import { notification } from "antd";
import Api from "../../api";
import API_URL from "../../api/url";
import { setsmsPartnerList} from "../Slices/SmsSlice";

export const fetchSmsPartnerApi = (data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.SMS_PARTNERLIST, data);
        dispatch(setsmsPartnerList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const updateSmsPartnerApi = (data, id, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(`${API_URL.SMS_PARTNERLIST_UPDATE}`, data);
        navigate("/sms-partners");
        notification["success"]({
            message: "SMS Partner Status Changed Successfully."
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};

