import {Table,Tooltip} from 'antd';
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeadingBack from '../../component/HeadingBack';
import { FellowshipServices, JobApplicationsServices } from '../../redux/Services';
import { usePermission } from '../../hooks/usePermission';
import moment from 'moment';
import FellowShipFilter from './FellowShipFilter';
import { EyeTwoTone } from '@ant-design/icons';
import DownLoadDocLink from './DownLoadDocLink';
import { JSONParse } from '../../utils';

const FellowshipProgram = () => {
    const permission = usePermission("Fellowship Program", "List");
    const navigate = useNavigate();
    const [filter, setFilter] = useState({Limit: 100, Offset:0});
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const FellowshipList = useSelector(state => state.FellowShip.FellowshipList || []);
    const TotalFellowship = useSelector(state => state.FellowShip.TotalFellowship || 0);
    const { mutate: getFellowshipList, isLoading } = useMutation((data) => dispatch(FellowshipServices.fetchFellowshipListApi(data)));
    const { mutate: getStateCityList } = useMutation((data) => dispatch(JobApplicationsServices.fetchStateCityListApi("","",data)));
    useEffect(() => {
        getFellowshipList(filter);
        return () => { }
    }, [filter])

    useEffect(() => {
        getStateCityList({});
        return () => { }
    }, [])

    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
            className: 'table_first_col',
            render: (value, item, index) => filter?.Offset + index + 1
        },
        {
            title: 'Name',
            dataIndex: 'FirstName',
            key: 'FirstName',
            className: 'text-capitalize',
        },
        // {
        //     title: 'Last Name',
        //     dataIndex: 'LastName',
        //     key: 'LastName',
        //     className: 'text-capitalize',
        // },
        {
            title: 'Applied Date',
            dataIndex: 'CreatedAt',
            key: 'CreatedAt',
            render: (value, item, index) => {
                return (
                    <>{item.CreatedAt ? moment(item.CreatedAt).format('DD-MM-YYYY H:mm:ss') : ""}</>
                )
            }
        },
        {
            title: 'Mobile Number',
            dataIndex: 'MobileNo',
            key: 'MobileNo',
        },
        {
            title: 'Email ID',
            dataIndex: 'EmailId',
            key: 'EmailId',
        },
        // {
        //     title: 'Program',
        //     dataIndex: 'Program',
        //     key: 'Program',
        // },
        // {
        //     title: 'Course Applied For',
        //     dataIndex: 'CourseApplied',
        //     key: 'CourseApplied',
        //     render: (value, item, index) => {
                
        //         let applyFor = JSONParse(item?.ApplyingCourses);
        //         return (
        //             <>
        //             {applyFor?.length > 0 && applyFor?.map((item)=> (<div key={item?.item_id}>{item?.item_text}</div>))}
        //             </>
        //         )
        //     }
        // },
        {
            title: 'Course Applied For',
            dataIndex: 'CourseApplied',
            key: 'CourseApplied',
            render: (value, item, index) => {
                
                let applyFor = item?.ApplyingCourses ? JSON.parse(item.ApplyingCourses) : null;
                
                if (applyFor && applyFor.length > 0) {
                    return (
                        <>
                            {applyFor.map((course) => (
                                <div key={course?.item_id}>{course?.item_text}</div>
                            ))}
                        </>
                    );
                } else {
                    return (
                        <div>{item?.Program}</div>
                    );
                }
            }
        },
        {
            title: 'Statement of Purpose',
            dataIndex: 'UploadedStatement',
            key: 'UploadedStatement',
            className:'text-center',
            render: (value, item, index) => <DownLoadDocLink file={item?.UploadedStatement} downloadName="UploadedStatement"/> 
        },
        {
            title: 'CV',
            dataIndex: 'CV',
            key: 'CV',
            className:'text-center',
            render: (value, item, index) => <DownLoadDocLink file={item?.UploadedCV} downloadName="UploadCV"/> 
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            className:'text-center',
            render: (value, item, index) => {
                return (
                    <Tooltip title="View Details"><EyeTwoTone onClick={() => navigate(`/fellowship-program/view/${item?.Id}`)} style={{ fontSize: '30px'}}/></Tooltip>
                )
            }
        }
    ];
    return (
        <div>
            <div className='position-relative part-filter'>
                <HeadingBack title={"Fellowship Program"} subHeading={`Total ${TotalFellowship || 0} Fellowship Program`} />
                <FellowShipFilter filter={filter} setFilter={setFilter} setPage={setPage}/>
            </div>
            <div className='tableResponsive mt-10'>
                <Table
                    rowKey='Id'
                    className={isLoading ? 'table antd_table no_data_found_hide' : 'table antd_table'}
                    bordered
                    columns={columns}
                    dataSource={Array.isArray(FellowshipList) && FellowshipList?.length > 0 ? FellowshipList : []}
                    pagination={{
                        current: page,
                        pageSize: filter?.Limit,
                        total: TotalFellowship,
                        onChange(current, pageSize) {
                            let Offset = current == 1 ? 0 : (((current - 1) * filter?.Limit));
                            setPage(current);
                            setFilter({...filter,Offset:Offset});
                        },
                        hideOnSinglePage: true
                    }}
                />
            </div>
        </div>
    )
}

export default FellowshipProgram