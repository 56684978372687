import React, { useEffect, useState } from 'react'
import { Form, Input, Select, Row, Col, Upload } from 'antd'
import { GetLoggedInUser } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { BannerServices, CommonServices, WhatsNewBannerServices, InvestorItemServices, TicketServices, UserServices } from '../../redux/Services';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import HeadingBack from '../../component/HeadingBack';
import { usePermission } from '../../hooks/usePermission';
import FileUpload from './FileUpload';
import API_URL from '../../api/url';
const { Option } = Select;

const WhatsBannerEdit = () => {
    const permission = usePermission("Whats New Management", "Edit");
    let LOG_IN_USER = GetLoggedInUser();
    const { bannerId } = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [file, setFile] = useState("");
    const [mFile, setMFile] = useState("");
    const BannerDetail = useSelector(state => state.whatsNewBanner.bannerDetail);
    const { mutate: BannerImageAdd } = useMutation((data) => dispatch(WhatsNewBannerServices.WhatsnewImageAddApi(data)));
    const { mutate: fetchBannerDetail } = useMutation(() => dispatch(WhatsNewBannerServices.fetchWhatsBannerDetailApi(bannerId)));
    const { mutate: BannerDetailUpdate, isLoading: EditLoading } = useMutation((data) => dispatch(WhatsNewBannerServices.WhatsBannerDetailUpdateApi(data, bannerId, navigate)));

    useEffect(() => {
        fetchBannerDetail();
    }, [])

    useEffect(() => {
        let WebBanner = BannerDetail?.WebBanner?.replace(API_URL.S3_BANNER_BASE?.toString(), "") || "";
        let MobileBanner = BannerDetail?.MobileBanner?.replace(API_URL.S3_BANNER_BASE?.toString(), "") || "";
        let Cities = BannerDetail?.Cities ? BannerDetail?.Cities?.includes(",") ? BannerDetail?.Cities?.split(",") : [BannerDetail?.Cities] : [];
        form.setFieldsValue({
            ...BannerDetail,
            Cities: Cities,
            WebBanner: WebBanner,
            MobileBanner: MobileBanner
        });
        setFile(WebBanner);
        setMFile(MobileBanner);
    }, [BannerDetail])

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            let _data = { ...values };
            _data.UserId = LOG_IN_USER?.id;
            BannerDetailUpdate(_data)
        })
    }
    const handleUpload = (file) => {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("dir_location", "whatsnew_banner");
        BannerImageAdd(formData, {
            onSuccess: (res) => {
                var fileName = res.data?.data?.key;
                setFile(fileName)
            },
        })
    }
    const handleUpload2 = (file) => {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("dir_location", "whatsnew_banner");
        BannerImageAdd(formData, {
            onSuccess: (res) => {
                var fileName = res.data?.data?.key;
                setMFile(fileName)
            },
        })
    }
    useEffect(() => {
        form.setFieldsValue({
            WebBanner: file,
            MobileBanner: mFile
        })
    }, [file, mFile])

    return (
        <>
            <HeadingBack title={"Whats New Management Edit"} subHeading="" />
            <div className='p-20'>
                < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                >
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Banner Label"
                                name="BannerLabel"
                                rules={[{ required: true, message: 'Banner Label is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Banner Label' />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Alt Tag"
                                name="AltTag"
                                rules={[{ required: true, message: 'Alt Tag is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Alt Tag' />
                            </Form.Item>
                        </Col>
                        {/* <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Order By"
                                name="OrderBy"
                                rules={[{ required: true, message: 'Order By is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Order By'
                                    autoComplete="new-off"
                                >
                                    {[...Array(5).keys()].map(i => (
                                        <Option className='text-capitalize' value={i + 1} key={i}>{i + 1}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col> */}
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Web Banner"
                                name="WebBanner"
                                rules={[{ required: true, message: 'Web Banner is required' }]}
                            >
                                <FileUpload file={file} setFile={setFile} handleUpload={handleUpload} />
                            </Form.Item>
                        </Col>
                        {/* <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Mobile Banner"
                                name="MobileBanner"
                                rules={[{ required: false, message: 'Mobile Banner is required' }]}
                            >
                                <FileUpload file={mFile} setFile={setMFile} handleUpload={handleUpload2} />
                            </Form.Item>
                        </Col>  */}
                      
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                >
                                    <Option className='text-capitalize' value={0}>Inactive</Option>
                                    <Option className='text-capitalize' value={1}>Active</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit} disabled={EditLoading}>
                            Update
                        </button>
                    </div>
                </Form >
            </div >
        </>
    )
}

export default WhatsBannerEdit