import React, { useEffect, useState } from 'react'
import { Col, Form, Input, notification, Row, Select } from 'antd'
import { useNavigate, useParams } from 'react-router-dom';
import CustomEditor from '../../component/CustomEditor/CustomEditor';
import { useDispatch, useSelector } from 'react-redux';
import { FaqServices } from '../../redux/Services';
import { useMutation } from 'react-query';
import { GetLoggedInUser } from '../../utils';
import HeadingBack from '../../component/HeadingBack';
import { CouponServices,BannerServices } from '../../redux/Services';
import { AutoComplete } from 'antd';
import { usePermission } from '../../hooks/usePermission';
import FileUpload from './FileUpload';
import { TeamServices } from '../../redux/Services';
import API_URL from '../../api/url';
const { Option } = Select;
const EditTeam = () => {
    const permission = usePermission("Team Management", "Edit");
    const { Id } = useParams();
    var newOptions = [];
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const TeamMemberData = useSelector(state => state.team.teamDetail || {});
    const { mutate: fetchTeamMemberById } = useMutation((data) => dispatch(TeamServices.fetchTeamMemberByIdApi(data, {Id:Id})));
    const { mutate: updateTeamMember } = useMutation((data) => dispatch(TeamServices.updateTeamMemberApi(data, Id, navigate)));
    const { mutate: BannerImageAdd } = useMutation((data) => dispatch(BannerServices.BannerImageAddApi(data)));
    const [file, setFile] = useState("");
    const [options, setOptions] = useState([]);
    useEffect(() => {
  
            fetchTeamMemberById({});

        return () => { }
    }, [])
    useEffect(() => {
        let DisplayPhoto = TeamMemberData?.DisplayPhoto?.replace(API_URL.S3_BANNER_BASE?.toString(), "") || "";
        if (TeamMemberData && Object.keys(TeamMemberData)?.length > 0) {
            form.setFieldValue('Category', TeamMemberData?.Category);
            form.setFieldValue('Name', TeamMemberData?.Name);
            form.setFieldValue('OrderBy', TeamMemberData?.OrderBy);
            form.setFieldsValue({
                ...TeamMemberData,
                DisplayPhoto: DisplayPhoto
            });
            setFile(DisplayPhoto);
            form.setFieldValue('AboutProfile', TeamMemberData?.AboutProfile);
            form.setFieldValue('Status', TeamMemberData?.Status);
        }
    }, [TeamMemberData])

    const handleUpload = (file) => {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("dir_location", "team-members");
        BannerImageAdd(formData, {
            onSuccess: (res) => {
                var fileName = res.data?.data?.key;
                setFile(fileName)
            },
        })
    }
    useEffect(() => {
        form.setFieldsValue({
            DisplayPhoto: file,
        })
    }, [file])

 
   
    const handleSubmit = () => {
        form.validateFields().then((values) => {
            if (updateTeamMember) {
                let _data = { ...values };
                _data.Id = Id;
                _data.UserId = LOG_IN_USER?.id;
                updateTeamMember(_data);
            }
        })
    }

    useEffect(() => {
        form.setFieldsValue({
            Banner: file,
     
        })
    }, [file,])

    return (
        <>
            <HeadingBack title={"Edit Team Member"} subHeading="" />
            <div className='p-20'>
           < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                >
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Category"
                                name="Category"
                                rules={[{ required: true, message: 'Category is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Category'
                                    autoComplete="new-off"
                                    name="Category"
                                    // initialValues={{ id: "4" }}
                                >
                                    
                                    <Option className='text-capitalize'>Select Category</Option>
                                    <Option className='text-capitalize'value={1}>Board of Directors</Option>
                                    <Option className='text-capitalize' value={2}>Senior Management</Option>

                                </Select>
          

                            </Form.Item>
                            
                        </Col >
                            <Col lg={12} md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Name"
                                    name="Name"
                                    rules={[{ required: true, message: 'Name is required' }]}
                                >
                                    <Input autoComplete="new-off" className='form-control' placeholder='Name' />
                                </Form.Item>
                            </Col >
                            <Col lg={12} md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Designation"
                                    name="Designation"
                                    rules={[{ required: true, message: 'Designation is required' }]}
                                >
                                    <Input autoComplete="new-off" className='form-control' placeholder='Designation' />
                                </Form.Item>
                            </Col >
                            <Col lg={12} md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Order By"
                                    name="OrderBy"
                                    rules={[{ required: true, message: 'Order By is required' }]}
                                >
                                    <Input type ="number" autoComplete="new-off" className='form-control' placeholder='Order By' />
                                </Form.Item>
                            </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                    name="Status"
                                >
                                    <Option className='text-capitalize' value={0}>Inactive</Option>
                                    <Option className='text-capitalize' value={1}>Active</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Image"
                                name="DisplayPhoto"
                                rules={[{ required: true, message: 'Image is required' }]}
                            >
                                <FileUpload file={file} setFile={setFile} handleUpload={handleUpload} />
                                <div className='recom-t'>
                                    <p>Recommended Size:308 × 265</p>
                                    {/* <p>Maximum Resolution should be 1MB</p> */}
                                </div>
                            </Form.Item>
                        </Col >
                   
                         <Col lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Team Member Profile"
                                name="AboutProfile"
                                rules={[{ required: true, message: 'Team Member Profile is required' }]}
                            >
                                <CustomEditor value={form.getFieldValue("AboutProfile")} onEditorChange={(value) => form.setFieldsValue({ AboutProfile: value })} />
                                {/* <textarea rows="4" cols="50" className='form-control' value={form.getFieldValue("SeoKeywords ")} onEditorChange={(value) => form.setFieldsValue({ SeoKeywords: value })} placeholder="Team Member Profile" /> */}
                            </Form.Item>
                       
                        </Col>
                      
                      
                        
                    </Row >
                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit}>
                            Update
                        </button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default EditTeam