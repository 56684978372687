import React, { useEffect, useState } from 'react'
import { Col, Form, Input, Row, Select } from 'antd'
import CustomEditor from '../../../component/CustomEditor/CustomEditor';
import { GetLoggedInUser, getNameValue, onlyMobileInput } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { InvestorCategoriesServices } from '../../../redux/Services';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { SeoServices } from '../../../redux/Services';
import HeadingBack from '../../../component/HeadingBack';
import { usePermission } from '../../../hooks/usePermission';
import { InvestorItemServices } from '../../../redux/Services';
import TextArea from 'antd/lib/input/TextArea';
import AttachmentUpload from '../AttachmentUpload';

const { Option } = Select;

const AddInvestorInformation = () => {
    const permission = usePermission("Seo Pages", "Add");
    onlyMobileInput();
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [H1List, setH1List] = useState([]);
    const [H2List, setH2List] = useState([]);
    const [H3List, setH3List] = useState([]);
    const { mutate: fetchCategory } = useMutation((data) => InvestorCategoriesServices.getInvestorCategoryList(data));
    const { mutate: addInvesotorItem, isLoading: addLoading } = useMutation((data) => dispatch(InvestorItemServices.addInvesotorItemInformationApi(data, navigate)));
    const [boxType, setBoxType] = useState("");
    const [file, setFile] = useState("");
    useEffect(() => {
        getNestedCategory("2", "H1");
        return () => { }
    }, [])

    const getNestedCategory = (ParentId, type) => {
        fetchCategory({ ParentId, IsActive: "1" }, {
            onSuccess: (res) => {
                if (type === "H1") {
                    setH1List(res.data.Data);
                    setH2List([]);
                    setH3List([]);
                } if (type === "H2") {
                    form.setFieldValue('SubCategoryId2', "");
                    form.setFieldValue('SubCategoryId3', "");
                    setH2List(res.data.Data);
                    setH3List([]);
                } if (type === "H3") {
                    form.setFieldValue('SubCategoryId3', "");
                    setH3List(res.data.Data);
                }
            }
        })
    }

    const changeItemType = (value) => {
        setBoxType(value);
    }
    const handleSubmit = () => {
        form.validateFields().then((values) => {
            let _data = { ...values, CategoryId: "2" };
            _data.UserId = LOG_IN_USER?.id;
            _data.SubCategoryId1 = values?.SubCategoryId1 || "";
            _data.SubCategoryId2 = values?.SubCategoryId2 || "";
            _data.SubCategoryId3 = values?.SubCategoryId3 || "";
            _data.SubCategoryId1Name = getNameValue(H1List, values?.SubCategoryId1) || "";
            _data.SubCategoryId2Name = getNameValue(H2List, values?.SubCategoryId2) || "";
            _data.SubCategoryId3Name = getNameValue(H3List, values?.SubCategoryId3) || "";
            addInvesotorItem(_data)
        })
    }
    return (
        <>
            <HeadingBack title={"Investor Information Add"} subHeading="" />
            <div className='p-20'>
                < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                >
                    <Row gutter={[24, 0]}>
                        <Col lg={8} md={8} sm={24} xs={24}>
                            <Form.Item
                                label="Sub Category (H1)"
                                name="SubCategoryId1"
                                rules={[{ required: true, message: 'Category is required' }]}
                            >
                                <Select
                                    className='text-capitalize w-100'
                                    placeholder='Select Sub Category (H1)'
                                    autoComplete="new-off"
                                    onChange={(value) => { getNestedCategory(value, "H2"); }}
                                >
                                    <Option className='text-capitalize' value="" disabled>Select Sub Category (H1)</Option>
                                    {H1List?.length > 0 && H1List?.map((item, idx) =>
                                        <Option key={idx} className='text-capitalize' value={item.Id}>{item.Category}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col >
                        <Col lg={8} md={8} sm={24} xs={24}>
                            {H2List?.length > 0 &&
                                <Form.Item
                                    label="Sub Category (H2)"
                                    name="SubCategoryId2"
                                    rules=""
                                >
                                    <Select
                                        className='text-capitalize w-100'
                                        placeholder='Select Sub Category (H2)'
                                        autoComplete="new-off"
                                        onChange={(value) => { getNestedCategory(value, "H3"); }}
                                    >
                                        <Option className='text-capitalize' value="" disabled>Select Sub Category (H2)</Option>
                                        {H2List?.length > 0 && H2List?.map((item, idx) =>
                                            <Option key={idx} className='text-capitalize' value={item.Id}>{item.Category}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            }
                        </Col>
                        <Col lg={8} md={8} sm={24} xs={24}>
                            {(H3List?.length > 0) &&
                                <Form.Item
                                    label="Sub Category (H3)"
                                    name="SubCategoryId3"
                                    rules=""
                                >
                                    <Select
                                        className='text-capitalize w-100'
                                        placeholder='Select Sub Category (H3)'
                                    >
                                        <Option className='text-capitalize' value="" disabled>Select Sub Category (H3)</Option>
                                        {H3List?.length > 0 && H3List?.map((item, idx) =>
                                            <Option key={idx} className='text-capitalize' value={item.Id}>{item.Category}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            }
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Item Name"
                                name="ItemName"
                                rules={[{ required: true, message: 'Item Name is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Item Name' />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Item Type"
                                name="ItemType"
                                rules={[{ required: true, message: 'Type is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Type'
                                    autoComplete="new-off"
                                    name="Type"
                                    onChange={(event) => { changeItemType(event) }}
                                >
                                    <Option className='text-capitalize' value={"1"}>List</Option>
                                    <Option className='text-capitalize' value={"2"}>Accordion</Option>
                                    <Option className='text-capitalize' value={"3"}>Box</Option>
                                    <Option className='text-capitalize' value={"4"}>Textarea</Option>
                                </Select>
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Item Slug"
                                name="ItemSlug"
                                rules={[{ required: true, message: 'Item Slug is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Item Slug' />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Attachment"
                                name="AttachmentUrl"
                                rules=""
                            >
                                <AttachmentUpload
                                        file={file}
                                        setFile={setFile}
                                        handleChange={(name) => {
                                            setFile(name);
                                            form.setFieldsValue({ AttachmentUrl: name })
                                        }}
                                        accept="image/jpeg,image/png,image/jpg, application/pdf"
                                    />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                    name="Status"
                                >
                                    <Option className='text-capitalize' value={"0"}>Inactive</Option>
                                    <Option className='text-capitalize' value={"1"}>Active</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    {(boxType == 3) ? <Row gutter={[24, 0]} className="frameSet">
                        <Col lg={24} md={24} sm={24} xs={24}>
                            <div><strong>Committee Details</strong></div>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Committee Name"
                                name="ComitteeName"
                                rules={[{ required: true, message: 'Committee Name is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Committee Name' />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Committee Designation"
                                name="ComitteeDesignation"
                                rules={[{ required: false, message: 'Committee Designation is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Committee Designation' />
                            </Form.Item>
                        </Col>
                        <Col lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Committee Address"
                                name="ComitteeAddress"
                                rules={[{ required: true, message: 'Committee Address is required' }]}
                            >
                                <TextArea autoComplete="new-off" className='form-control' placeholder='' />
                            </Form.Item>
                        </Col>
                    </Row>
                        : ''}
                    {(boxType == 4) ?
                        <Row gutter={[24, 0]}>
                            <Col lg={24} md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Item Details"
                                    name="ItemTeaxtarea"
                                    rules={[{ required: true, message: 'Item Details is required' }]}
                                >
                                    {/* <TextArea autoComplete="new-off" className='form-control' placeholder='' /> */}
                                    <CustomEditor value={form.getFieldValue("ItemTeaxtarea")} onEditorChange={(value) => form.setFieldsValue({ ItemTeaxtarea: value })} />
                                </Form.Item>
                            </Col >
                        </Row>
                        :
                        ""}
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label="Item Remark"
                                name="Remarks"
                            // rules={[{ required: true, message: 'Item Remark is required' }]}
                            >
                                <textarea className='form-control' value={form.getFieldValue("Remarks ")} onEditorChange={(value) => form.setFieldsValue({ Remarks: value })} />
                            </Form.Item>
                        </Col>

                    </Row>
                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit} disabled={addLoading}>
                            Submit
                        </button>
                    </div>
                </Form >
            </div>
        </>
    )
}

export default AddInvestorInformation