import React, { useEffect } from 'react'
import { Col, Form, Input, notification, Row, Select } from 'antd'
import { useNavigate, useParams } from 'react-router-dom';
import { UserServices } from '../../../redux/Services';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { GetLoggedInUser, onlyMobileInput } from '../../../utils';
import HeadingBack from '../../../component/HeadingBack';
import { usePermission } from '../../../hooks/usePermission';
import ChangePassword from './ChangePassword';

const { Option } = Select;

const EditUser = () => {
    const permission = usePermission("Users", "Edit");
    onlyMobileInput();
    const { userId } = useParams();
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const roleList = useSelector(state => state.user.roleList || []);
    const userData = useSelector(state => state.user.userDetail || {});
    const { mutate: fetchRoles } = useMutation((data) => dispatch(UserServices.fetchRolesApi(data)));
    const { mutate: fetchUserById } = useMutation((data) => dispatch(UserServices.fetchUsersByIdApi(data, userId)));
    const { mutate: updateUser } = useMutation((data) => dispatch(UserServices.updateUsersApi(data, userId, navigate)));

    useEffect(() => {
        fetchRoles({});
        return () => { }
    }, [])

    useEffect(() => {
        if (userId) {
            fetchUserById({});
        }
        return () => { }
    }, [userId])

    useEffect(() => {
        if (userData && Object.keys(userData)?.length > 0) {
            form.setFieldValue('Name', userData?.Name);
            form.setFieldValue('Mobile', userData?.Mobile);
            form.setFieldValue('Email', userData?.Email);
            form.setFieldValue('RoleId', userData?.RoleId);
            form.setFieldValue('ProfilePhoto', userData?.ProfilePhoto);
        }
    }, [userData])

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            if (userId) {
                let _data = { ...values };
                _data.UserId = LOG_IN_USER?.id;
                updateUser(_data);
            }
        })
    }
    const handleProfileUpload = (e) => {
        let file = e.target.files[0];
        console.log(file);
    }
    return (
        <>
            <HeadingBack title={"User Edit"} subHeading="" />
            <div className='p-20'>
                < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                >
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Name"
                                name="Name"
                                rules={[{ required: true, message: 'Name is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Name' />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Mobile"
                                name="Mobile"
                                rules={[
                                    { required: true, message: 'Mobile is required' },
                                    { min: 10, message: "Enter min 10 Number" }
                                ]}
                            >
                                <Input type="text" id="only_mobile" maxLength={10} autoComplete="new-off" className='form-control' placeholder='Mobile' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Email"
                                name="Email"
                                rules={[
                                    { required: true, message: 'Email is required' },
                                    { type: 'email', message: 'Email is Invalid' }
                                ]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Email' />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Role"
                                name="RoleId"
                                rules={[{ required: true, message: 'Role is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Role'
                                    autoComplete="new-off"
                                    name="RoleId"
                                >
                                    <Option className='text-capitalize' value={""}>Select Role</Option>
                                    {roleList?.length > 0 && roleList?.map((item, idx) =>
                                        <Option key={idx} className='text-capitalize' value={item.RoleId}>{item.Role}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Profile Photo"
                            >
                                <input type="file" name="ProfilePhoto" onChange={(e) => handleProfileUpload(e)} className='form-control' accept='image/*' autoComplete="new-off" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit}>
                            Update
                        </button>
                    </div>
                </Form>
            </div>
            <hr></hr>
            <ChangePassword />
        </>
    )
}

export default EditUser