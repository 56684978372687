import { CopyTwoTone } from '@ant-design/icons'
import { Col, Row, message } from 'antd'
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

const JsonShow = ({ data }) => {
    return (
        <React.Fragment>
            {data && Object.keys(data)?.length > 0 && Object.keys(data)?.map((ele, key) => (
                <Row wrap={false} gutter={16} style={{ marginBottom: "10px" }} key={key}>
                    <Col>{ele}</Col>
                    <Col>:</Col>
                    <Col>
                        <CopyToClipboard text={data[ele]}
                            onCopy={() => message.success("Copy Successfully")}>
                            <CopyTwoTone />
                        </CopyToClipboard>
                    </Col>
                    <Col>{data[ele]}</Col>
                </Row>
            ))}
        </React.Fragment>
    )
}

export default JsonShow