import React, { useEffect, useState } from 'react'
import { EyeOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row, Select, Button, Image, Typography } from 'antd'
import CustomEditor from '../../../component/CustomEditor/CustomEditor';
import { GetLoggedInUser, getNameValue, onlyMobileInput } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { InvestorCategoriesServices } from '../../../redux/Services';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { SeoServices } from '../../../redux/Services';
import HeadingBack from '../../../component/HeadingBack';
import { usePermission } from '../../../hooks/usePermission';
import { InvestorItemServices } from '../../../redux/Services';
import TextArea from 'antd/lib/input/TextArea';
import FileUpload from './fileUpload';
import API_URL from '../../../api/url';
import AttachmentUpload from '../AttachmentUpload';
const { Option } = Select;

const EditCorporategovernance = () => {
    const { corporatGovernanceId } = useParams();
    const permission = usePermission("Seo Pages", "Add");
    onlyMobileInput();
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [H1List, setH1List] = useState([]);
    const [H2List, setH2List] = useState([]);
    const [H3List, setH3List] = useState([]);
    const [file, setFile] = useState("");
    const [profileFile, setProfileFile] = useState("");
    const InvestorItemDetail = useSelector(state => state.investoritem.InvestorItemDetails);
    const { mutate: fetchCategory } = useMutation((data) => InvestorCategoriesServices.getInvestorCategoryList(data));
    const { mutate: InvestorItemDetails } = useMutation((data) => dispatch(InvestorItemServices.fetchInvestorItemDetails3Api(data, corporatGovernanceId)));
    const { mutate: InvestorItemDetailsUpdate, isLoading: addLoading } = useMutation((data) => dispatch(InvestorItemServices.InvestorItemlUpdate3Api(data, corporatGovernanceId, navigate)));
    const [boxType, setBoxType] = useState("");
    const [defaultFile, setDefaultFile] = useState("");

    useEffect(() => {
        InvestorItemDetails(corporatGovernanceId);
        return () => { }
    }, [])

    useEffect(() => {
        if (InvestorItemDetail && Object.keys(InvestorItemDetail).length > 0 && Object.keys(InvestorItemDetail.list)?.length > 0) {
            const ItemData = InvestorItemDetail.list;
            form.setFieldValue('ItemId', ItemData?.ItemId);
            form.setFieldValue('SubCategoryId1', ItemData?.SubCategoryId1 || "");
            form.setFieldValue('SubCategoryId2', ItemData?.SubCategoryId2 || "");
            form.setFieldValue('SubCategoryId3', ItemData?.SubCategoryId3 || "");
            form.setFieldValue('ItemName', ItemData?.ItemName);
            form.setFieldValue('ItemType', ItemData?.ItemType);
            form.setFieldValue('ItemSlug', ItemData?.ItemSlug);
            form.setFieldValue('AttachmentUrl', ItemData?.AttachmentUrl);
            form.setFieldValue('Status', ItemData?.Status);
            form.setFieldValue('Remarks', ItemData?.Remarks);
            form.setFieldValue('ProfileDetail', ItemData?.ProfileDetails);
            form.setFieldValue('ProfileName', ItemData?.ProfileName);
            form.setFieldValue('Designation', ItemData?.ProfileDesignation);
            form.setFieldValue('Attachments', ItemData?.ProfileAttachment);
            setDefaultFile(ItemData?.ProfileAttachment);
            setFile(ItemData?.AttachmentUrl || "");
            setProfileFile(ItemData?.ProfileAttachment || "");
            if (ItemData?.ItemType == 3) {
                let nItem = ItemData?.ComitteeDetails || [];
                if (nItem && Array.isArray(nItem) && nItem?.length > 0) {
                    form.setFieldValue("ComitteeDetails", nItem)
                } else {
                    form.setFieldValue("ComitteeDetails", [{
                        ComitteeName: "",
                        ComitteeDesignation: ""/* ,
                        ComitteeAddress: "" */
                    }])
                }
            }
            setBoxType(ItemData?.ItemType);
            fetchCategory({ ParentId: 3, IsActive: "1" }, {
                onSuccess: (res) => {
                    setH1List(res.data.Data);
                    if (ItemData?.SubCategoryId1) {
                        fetchCategory({ ParentId: ItemData?.SubCategoryId1, IsActive: "1" }, {
                            onSuccess: (res) => {
                                setH2List(res.data.Data);
                                if (ItemData?.SubCategoryId2) {
                                    fetchCategory({ ParentId: ItemData?.SubCategoryId2, IsActive: "1" }, {
                                        onSuccess: (res) => {
                                            setH3List(res.data.Data);
                                        }
                                    })
                                }
                            }
                        })
                    }
                }
            })
        }
    }, [InvestorItemDetail])

    const getNestedCategory = (ParentId, type, manually = false) => {
        fetchCategory({ ParentId, IsActive: "1" }, {
            onSuccess: (res) => {
                if (type == "H1") {
                    setH1List(res.data.Data);
                    if (manually) {
                        setH2List([]);
                        setH3List([]);
                    }
                } else if (type == "H2") {
                    setH2List(res.data.Data);
                    if (manually) {
                        form.setFieldValue('SubCategoryId2', "");
                        form.setFieldValue('SubCategoryId3', "");
                        setH3List([]);
                    }
                } else if (type == "H3") {
                    setH3List(res.data.Data);
                    if (manually) {
                        form.setFieldValue('SubCategoryId3', "");
                    }
                }
            }
        })
    }
    const changeItemType = (value) => {
        setBoxType(value);
        if (value == 3) {
            form.setFieldValue("ComitteeDetails", [{
                ComitteeName: "",
                ComitteeDesignation: ""/* ,
                ComitteeAddress: "" */
            }])
        }
    }

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            let _data = { ...values, ItemId: corporatGovernanceId, CategoryId: "3" };
            _data.UserId = LOG_IN_USER?.id;
            _data.SubCategoryId1 = values?.SubCategoryId1 || "";
            _data.SubCategoryId2 = values?.SubCategoryId2 || "";
            _data.SubCategoryId3 = values?.SubCategoryId3 || "";
            _data.SubCategoryId1Name = getNameValue(H1List, values?.SubCategoryId1) || "";
            _data.SubCategoryId2Name = getNameValue(H2List, values?.SubCategoryId2) || "";
            _data.SubCategoryId3Name = getNameValue(H3List, values?.SubCategoryId3) || "";
            if (boxType == 3) {
                _data.ComitteeDetails = JSON.stringify(_data?.ComitteeDetails || "[]");
            }
            InvestorItemDetailsUpdate(_data);
        })
    }
    return (
        <>
            <HeadingBack title={"Corporate Governance Edit"} subHeading="" />
            <div className='p-20'>
                < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                >
                    <Row gutter={[24, 0]}>
                        <Col lg={8} md={8} sm={24} xs={24}>
                            <Form.Item
                                label="Sub Category (H1)"
                                name="SubCategoryId1"
                                rules={[{ required: true, message: 'Category is required' }]}
                            >
                                <Select
                                    className='text-capitalize w-100'
                                    placeholder='Select Sub Category (H1)'
                                    autoComplete="new-off"
                                    onChange={(value) => { getNestedCategory(value, "H2", true); }}
                                >
                                    <Option className='text-capitalize' value="" disabled>Select Sub Category (H1)</Option>
                                    {H1List?.length > 0 && H1List?.map((item, idx) =>
                                        <Option key={idx} className='text-capitalize' value={item?.Id}>{item?.Category}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={8} md={8} sm={24} xs={24}>
                            {H2List?.length > 0 &&
                                <Form.Item
                                    label="Sub Category (H2)"
                                    name="SubCategoryId2"
                                    rules=""
                                >
                                    <Select
                                        className='text-capitalize w-100'
                                        placeholder='Select Sub Category (H2)'
                                        autoComplete="new-off"
                                        onChange={(value) => { getNestedCategory(value, "H3", true); }}
                                    >
                                        <Option className='text-capitalize' value="" disabled>Select Sub Category (H2)</Option>
                                        {H2List?.length > 0 && H2List?.map((item, idx) =>
                                            <Option key={idx} className='text-capitalize' value={item?.Id}>{item?.Category}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            }
                        </Col>
                        <Col lg={8} md={8} sm={24} xs={24}>
                            {(H3List?.length > 0) &&
                                <Form.Item
                                    label="Sub Category (H3)"
                                    name="SubCategoryId3"
                                    rules=""
                                >
                                    <Select
                                        className='text-capitalize w-100'
                                        placeholder='Select Sub Category (H3)'
                                    >
                                        <Option className='text-capitalize' value="" disabled>Select Sub Category (H3)</Option>
                                        {H3List?.length > 0 && H3List?.map((item, idx) =>
                                            <Option key={idx} className='text-capitalize' value={item.Id}>{item.Category}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            }
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Item Name"
                                name="ItemName"
                                rules={[{ required: true, message: 'Item Name is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Item Name' />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Item Type"
                                name="ItemType"
                                rules={[{ required: true, message: 'Type is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Type'
                                    autoComplete="new-off"
                                    name="Type"
                                    onChange={(event) => { changeItemType(event) }}
                                >
                                    <Option className='text-capitalize' value={1}>List</Option>
                                    {/* <Option className='text-capitalize' value={2}>Accordion</Option> */}
                                    <Option className='text-capitalize' value={3}>Box</Option>
                                    {/* <Option className='text-capitalize' value={4}>Text Area</Option> */}
                                    <Option className='text-capitalize' value={5}>Profile</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        {(boxType == "3") ?
                            <Col span={24}>
                                <Row gutter={[24, 0]} className="frameSet">
                                    <Col lg={24} md={24} sm={24} xs={24}>
                                        <div><strong>Committee Details</strong></div>
                                    </Col>
                                    {/* <Col lg={24} md={24} sm={24} xs={24}>
                                        <div><strong>Comittee Details</strong></div>
                                    </Col>
                                    <Col lg={12} md={24} sm={24} xs={24}>
                                        <Form.Item
                                            label="Comittee Name"
                                            name="ComitteeName"
                                            rules=""
                                        >
                                            <Input type="text" autoComplete="new-off" className='form-control' placeholder='Comittee Name' />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={12} md={24} sm={24} xs={24}>
                                        <Form.Item
                                            label="Comittee Designation"
                                            name="ComitteeDesignation"
                                            rules=""
                                        >
                                            <Input type="text" autoComplete="new-off" className='form-control' placeholder='Comittee Designation' />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={24} md={24} sm={24} xs={24}>
                                        <Form.Item
                                            label="Comittee Address"
                                            name="ComitteeAddress"
                                            rules=""
                                        >
                                            <TextArea autoComplete="new-off" className='form-control' placeholder='' />
                                        </Form.Item>
                                    </Col> */}
                                    <Col lg={24} md={24} sm={24} xs={24}>
                                        {/* <Form
                                            name="dynamic_form_item"
                                        > */}
                                        <Form.List
                                            name="ComitteeDetails"
                                        /* rules={[
                                            {
                                                validator: async (_, names) => {
                                                    if (!names || names.length < 2) {
                                                        return Promise.reject(new Error('At least 2 passengers'));
                                                    }
                                                },
                                            },
                                        ]} */
                                        >
                                            {(fields, { add, remove }, { errors }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }) => (
                                                        <Row align={"top"} justify={"center"} className='frameSet'>
                                                            <Col span={21}>
                                                                <Row gutter={[24, 0]}>
                                                                    <Col lg={12} md={24} sm={24} xs={24}>
                                                                        <Form.Item
                                                                            label="Committee Name"
                                                                            {...restField}
                                                                            name={[name, 'ComitteeName']}
                                                                            rules={[{ required: true, message: 'Committee Name is required' }]}
                                                                        >
                                                                            <Input type="text" autoComplete="new-off" className='form-control' placeholder='Committee Name' />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col lg={12} md={24} sm={24} xs={24}>
                                                                        <Form.Item
                                                                            label="Committee Designation"
                                                                            name={[name, 'ComitteeDesignation']}
                                                                            {...restField}
                                                                            rules={[{ required: true, message: 'Committee Designation is required' }]}
                                                                        >
                                                                            <Input type="text" autoComplete="new-off" className='form-control' placeholder='Committee Designation' />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    {/* <Col lg={24} md={24} sm={24} xs={24}>
                                                                        <Form.Item
                                                                            label="Comittee Address"
                                                                            name={[name, 'ComitteeAddress']}
                                                                            rules={[{ required: true, message: 'Comittee Address is required' }]}
                                                                            {...restField}
                                                                        >
                                                                            <TextArea autoComplete="new-off" className='form-control' placeholder='' />
                                                                        </Form.Item>
                                                                    </Col> */}
                                                                </Row>
                                                            </Col>
                                                            <Col span={3} className='text-right'>
                                                                {fields.length > 1 ? (
                                                                    <MinusCircleOutlined
                                                                        style={{ fontSize: "30px", color: "red" }}
                                                                        className="dynamic-delete-button delete_field"
                                                                        onClick={() => remove(name)}
                                                                    />
                                                                ) : null}
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                    <div className='text-center'>
                                                        <Form.Item>
                                                            <Button
                                                                type="dashed"
                                                                onClick={() => add()}
                                                                style={{
                                                                    width: '20%',
                                                                }}
                                                                icon={<PlusOutlined />}
                                                            >
                                                                Add field
                                                            </Button>
                                                        </Form.Item>
                                                    </div>
                                                </>
                                            )}
                                        </Form.List>
                                        {/* </Form> */}
                                    </Col>
                                </Row>
                            </Col>
                            : null}
                        {(boxType == "5") ?
                            <Col span={24}>
                                <Row gutter={[24, 0]} className="frameSet">
                                    <Col lg={24} md={24} sm={24} xs={24}>
                                        <div><strong>Profile Details</strong></div>
                                    </Col>
                                    <Col lg={8} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            label="Profile Image URL"
                                            name="Attachments"
                                            rules={[{ required: true, message: 'Profile Image URL is required' }]}
                                        >
                                            <AttachmentUpload
                                                file={profileFile}
                                                setFile={setProfileFile}
                                                handleChange={(name) => {
                                                    setProfileFile(name);
                                                    form.setFieldsValue({ Attachments: name })
                                                }}
                                                accept="image/jpeg,image/png,image/jpg"
                                            />
                                        </Form.Item>
                                        {defaultFile && <div style={{ textAlign: "right", fontWeight: "500", marginTop: "-15px" }}><Typography.Link href={defaultFile} target='_blank'>View Image</Typography.Link></div>}
                                    </Col >
                                    <Col className='mb_0' lg={8} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            label="Profile Name"
                                            name="ProfileName"
                                            rules={[{ required: true, message: 'Profile Name is required' }]}
                                        >
                                            <Input type="text" autoComplete="new-off" className='form-control' placeholder='Profile Name' />
                                        </Form.Item>
                                    </Col>
                                    <Col className='mb_0' lg={8} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            label="Designation"
                                            name="Designation"
                                            rules={[{ required: true, message: 'Designation is required' }]}
                                        >
                                            <Input type="text" autoComplete="new-off" className='form-control' placeholder='Designation' />
                                        </Form.Item>
                                    </Col>
                                    <Col className="mb_0" span={24}>
                                        <Form.Item
                                            label="Profile Details"
                                            name="ProfileDetail"
                                            className='profile-detail'
                                            rules={[{ required: true, message: 'Profile Details is required' }]}
                                        >
                                            <textarea className='form-control' value={form.getFieldValue("ProfileDetail ")} onEditorChange={(value) => form.setFieldsValue({ ProfileDetail: value })} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            :
                            null
                        }
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Item Slug"
                                name="ItemSlug"
                                rules={[{ required: true, message: 'Item Slug is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Item Slug' />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Attachment"
                                name="AttachmentUrl"
                                rules=""
                            >
                                <AttachmentUpload
                                    file={file}
                                    setFile={setFile}
                                    handleChange={(name) => {
                                        setFile(name);
                                        form.setFieldsValue({ AttachmentUrl: name })
                                    }}
                                    accept="image/jpeg,image/png,image/jpg, application/pdf"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                    name="Status"
                                >
                                    <Option className='text-capitalize' value={0}>Inactive</Option>
                                    <Option className='text-capitalize' value={1}>Active</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Item Remark"
                                name="Remarks"
                            // rules={[{ required: true, message: 'Item Remark is required' }]}
                            >
                                <textarea className='form-control'value={form.getFieldValue("Remarks ")} onEditorChange={(value) => form.setFieldsValue({ Remarks: value })} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit} disabled={addLoading}>
                            Update
                        </button>
                    </div>
                </Form >
            </div>
        </>
    )
}

export default EditCorporategovernance