import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    faqList: [],
    totalFaq: "",
    faqDetail: {},
    faqCategoryList: [],
    totalFaqCategory: '',
    faqCategoryDetail: {}
}

export const faqSlice = createSlice({
    name: 'faq',
    initialState,
    reducers: {
        setFaqList: (state, action) => {
            state.faqList = action.payload.list;
            state.totalFaq = action.payload.total;
        },
        setFaqDetail: (state, action) => {
            state.faqDetail = action.payload;
        },
        setFaqCategoryList: (state, action) => {
            state.faqCategoryList = action.payload.list;
            state.totalFaqCategory = action.payload.total;
        },
        setFaqCategoryDetail: (state, action) => {
            state.faqCategoryDetail = action.payload;
        },
    },
})

export const { setFaqList, setFaqDetail, setFaqCategoryList, setFaqCategoryDetail } = faqSlice.actions

export default faqSlice.reducer