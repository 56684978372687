import { Col, Input, Row, Select,Form } from 'antd'
import React, { useState, useEffect } from 'react'
const { Option } = Select;

const OffersFilter = ({ filter, setFilter, setPage, Category }) => {

    const [values, setValues] = useState({
        IsActive: "",
        Category: "",
        Name: "",
    });
    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value });
    }
    const handleFilter = () => {
        setFilter({ ...filter, ...values });
        setPage(1);
    }
    const handleFilterReset = () => {
        setFilter({});
        setValues({
            ...values,
            IsActive: "",
            Name: "",
            Category: "",
        })
        setPage(1);
    }


    return (
        <div className='table_filter'>
            <Row gutter={[12, 24]}>
                <Col md={5} sm={5} xs={24}>
                    <Select
                        className='text-capitalize w-100'
                        placeholder='Select Category'
                        autoComplete="new-off"
                        onChange={(value) => handleChange("Category", value)}
                        value={values?.Category || undefined}
                    >
                        <Option className='text-capitalize'>Select Category</Option>

                       
                                <Option className='text-capitalize' value={'1'}>Board of Directors</Option>
                                <Option className='text-capitalize' value={'2'}>Senior Management</Option>
                         
                      
                    </Select>
                </Col>
                <Col md={6} sm={6} xs={24}>
                    <Input
                        type="text"
                        autoComplete="new-off"
                        className='form-control'
                        onChange={(e) => handleChange("Name", e.target.value)}
                        placeholder='Search by Name'
                        value={values?.Name}
                    />
                </Col>
              
                <Col md={4} sm={12} xs={24}>
                    <Select
                        className='text-capitalize w-100'
                        placeholder='Select Status'
                        autoComplete="new-off"
                        onChange={(value) => handleChange("IsActive", value)}
                        value={values?.IsActive}
                    >
                        <Option className='text-capitalize' value="">All</Option>
                        <Option className='text-capitalize' value={"0"}>Inactive</Option>
                        <Option className='text-capitalize' value={"1"}>Active</Option>
                    </Select>
                </Col>
                <Col md={6} sm={12} xs={24}>
                    <div className='flex gap10'>
                        <button className='filter-button' onClick={() => handleFilter()}>Filter</button>
                        <button className='clear-button' onClick={() => handleFilterReset()}>Reset</button>
                    </div>
                </Col>
            </Row>
        </div >
    )
}

export default OffersFilter