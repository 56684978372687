import React, { useEffect, useState } from 'react'
import { Col, Form, Input, notification, Row, Select } from 'antd'
import { useNavigate, useParams } from 'react-router-dom';
import CustomEditor from '../../../component/CustomEditor/CustomEditor';
import { useDispatch, useSelector } from 'react-redux';
import { FaqServices } from '../../../redux/Services';
import { useMutation } from 'react-query';
import { GetLoggedInUser } from '../../../utils';
import HeadingBack from '../../../component/HeadingBack';
import { CouponServices,BannerServices } from '../../../redux/Services';
import { AutoComplete } from 'antd';
import { usePermission } from '../../../hooks/usePermission';
import FileUpload from './FileUpload';
import API_URL from '../../../api/url';
const { Option } = Select;

const EditOffer = () => {
    const permission = usePermission("Coupon Management", "Edit");
    const { CouponId } = useParams();
    var newOptions = [];
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const couponData = useSelector(state => state.coupon.couponDetail || {});
    const couponCategoryList = useSelector(state => state.coupon.couponCategoryList || []);
    const { mutate: fetchCouponCategory, isLoading } = useMutation((data) => dispatch(CouponServices.fetchCouponCategoryApi(data)));
    const { mutate: fetchCouponById } = useMutation((data) => dispatch(CouponServices.fetchCouponByIdApi(data, CouponId)));
    const { mutate: updateCoupon } = useMutation((data) => dispatch(CouponServices.updateCouponApi(data, CouponId, navigate)));
    const { mutate: BannerImageAdd } = useMutation((data) => dispatch(BannerServices.BannerImageAddApi(data)));
    const [file, setFile] = useState("");
    useEffect(() => {
        fetchCouponById({ isActive: "1" });
        return () => { }
    }, [])
    useEffect(() => {
        fetchCouponCategory({ isActive: "1" });
        return () => { }
    }, [])
    useEffect(() => {
        if (CouponId) {
            fetchCouponById({});
        }
        return () => { }
    }, [CouponId])

    useEffect(() => {
        let Banner = couponData?.Banner?.replace(API_URL.S3_BANNER_BASE?.toString(), "") || "";
        if (couponData && Object.keys(couponData)?.length > 0) {
            form.setFieldValue('CategoryId', couponData?.CategoryId);
            form.setFieldValue('Name', couponData?.Name);
            form.setFieldsValue({
                ...couponData,
                Banner: Banner
            });
            setFile(Banner);
            form.setFieldValue('Code', couponData?.Code);
            form.setFieldValue('Status', couponData?.Status);
            form.setFieldValue('Description', couponData?.Description);
        }
    }, [couponData])

    const handleUpload = (file) => {
        const formData = new FormData();
        formData.append("image", file);
        BannerImageAdd(formData, {
            onSuccess: (res) => {
                var fileName = res.data?.data?.key;
                setFile(fileName)
            },
        })
    }
    const handleSubmit = () => {
        form.validateFields().then((values) => {
            if (CouponId) {
                let _data = { ...values };
                _data.UserId = LOG_IN_USER?.id;
                updateCoupon(_data);
            }
        })
    }

    useEffect(() => {
        form.setFieldsValue({
            Banner: file,
     
        })
    }, [file,])
    return (
        <>
            <HeadingBack title={"Offers Edit"} subHeading="" />
            <div className='p-20'>
                < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                >
                   <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Category"
                                name="CategoryId"
                                rules={[{ required: true, message: 'Category is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Category'
                                    autoComplete="new-off"
                                    name="CategoryId"
                                >
                                    <Option className='text-capitalize' value={""}>Select Category</Option>
                                    {couponCategoryList?.length > 0 && couponCategoryList?.map((item, idx) =>
                                        <Option key={idx} className='text-capitalize' value={item.Id}>{item.Name}</Option>
                                    )}
                                </Select>

                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Label"
                                name="Name"
                                rules={[{ required: true, message: 'Label is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Label' />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                        <Form.Item
                                label="Offer Image"
                                name="Banner"
                                rules={[{ required: true, message: 'Offer Image is required' }]}
                            >
                                <FileUpload file={file} setFile={setFile} handleUpload={handleUpload} />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Coupon Code"
                                name="Code"
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Coupon Code' />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                        <Form.Item
                                label="Alt Tag"
                                name="BannerAltTag"
                                rules={[{ required: true, message: 'Alt Tag is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Alt Tag' />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                    name="Status"
                                >
                                    <Option className='text-capitalize' value={0}>Inactive</Option>
                                    <Option className='text-capitalize' value={1}>Active</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Description"
                                name="Description"
                                rules={[{ required: true, message: 'Description is required' }]}
                            >
                                <CustomEditor value={form.getFieldValue("Description")} onEditorChange={(value) => form.setFieldsValue({ Description: value })} />
                            </Form.Item>
                        </Col>
                    </Row >
                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit}>
                            Update
                        </button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default EditOffer