import { notification } from "antd";
import Api from "../../api";
import API_URL from "../../api/url";
import { SetToken } from "../../utils";
import { setUserList, setRoleList, setRoleDetail, setUserDetail, setFilterData } from "../Slices/userSlice";

export const loginApi = (data, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.LOGIN, data);
        if (res.status === 200) {
            SetToken(res?.data?.Token || "");
            navigate("/", { replace: true });
            notification["success"]({
                message: "Login Successfully"
            });
        }
        return res;
    } catch (err) {
        if (err.response?.data?.Message) {
            notification["error"]({
                message: err.response.data.Message
            });
        }
        return err
    }
};
export const fetchRolesApi = (data) => async (dispatch) => {
    try {
        const res = await Api.get(API_URL.ROLE_LIST);
        dispatch(setRoleList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const addRolesApi = (data, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.ROLE_ADD, data);
        navigate("/roles");
        notification["success"]({
            message: "Role created successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message?.role || error.message
        });
        return error
    }
};
export const updateRolesApi = (data, id, navigate) => async (dispatch) => {
    try {
        const res = await Api.put(`${API_URL.ROLE_UPDATE}/${id}`, data);
        navigate("/roles");
        notification["success"]({
            message: "Role updated successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message?.role || error.message
        });
        return error
    }
};
export const fetchRolesByIdApi = (data, id) => async (dispatch) => {
    try {
        const res = await Api.post(`${API_URL.ROLE_BY_ID}/${id}`);
        dispatch(setRoleDetail(res.data.Data));
        return res;
    } catch (error) {
        return error
    }
};

export const fetchFilterDataApi = (data) => async (dispatch) => {
    try {
        const res = await Api.get(API_URL.FILTER_DATA);
        dispatch(setFilterData(res.data?.FilterData || {}));
        return res;
    } catch (error) {
        return error
    }
};
export const updatePermissionApi = (data, id, navigate) => async (dispatch) => {
    try {
        const res = await Api.put(`${API_URL.PERMISSION_UPDATE}/${id}`, data);
        navigate("/roles");
        notification["success"]({
            message: "Permission updated successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message || ""
        });
        return error
    }
};
export const fetchUsersApi = (data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.USER_LIST);
        dispatch(setUserList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const addUsersApi = (data, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.USER_ADD, data);
        navigate("/users");
        notification["success"]({
            message: "User created successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message?.email || error.message
        });
        return error
    }
};
export const updateUsersApi = (data, id, navigate) => async (dispatch) => {
    try {
        const res = await Api.put(`${API_URL.USER_UPDATE}/${id}`, data);
        navigate("/users");
        notification["success"]({
            message: "User updated successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message?.email || error.message
        });
        return error
    }
};
export const updateUserStatusApi = (data, id) => async (dispatch) => {
    try {
        const res = await Api.put(`${API_URL.USER_STATUS_UPDATE}/${id}`, data);
        notification["success"]({
            message: "User Status updated successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const updateUserPasswordApi = (data, id, navigate) => async (dispatch) => {
    try {
        const res = await Api.put(`${API_URL.UPDATE_PASSWORD}/${id}`, data);
        navigate("/users");
        notification["success"]({
            message: "User password changed successfully."
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const fetchUsersByIdApi = (data, id) => async (dispatch) => {
    try {
        const res = await Api.post(`${API_URL.USER_BY_ID}/${id}`);
        dispatch(setUserDetail(res.data.Data));
        return res;
    } catch (error) {
        return error
    }
};