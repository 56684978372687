import { Col, Row, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeadingBack from '../../../component/HeadingBack';
import SvgIcon from '../../../const/SvgIcon';
import { usePermission } from '../../../hooks/usePermission';
import { FaqServices } from '../../../redux/Services';
import FaqFilter from './FaqComponent/FaqFilter';
const Faqs = () => {
    const permission = usePermission("Faqs", "List");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const faqList = useSelector(state => state.faq.faqList || []);
    const totalFaq = useSelector(state => state.faq.totalFaq || "");
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState({});
    const faqCategoryList = useSelector(state => state.faq.faqCategoryList || []);
    const { mutate: fetchFaqCategory } = useMutation((data) => dispatch(FaqServices.fetchFaqCategoryApi(data)));
    const { mutate: getfaqList, isLoading } = useMutation((data) => dispatch(FaqServices.fetchFaqApi(data)));

    const queryParams = new URLSearchParams(window.location.search);
    const TestCode = queryParams.get('TestCode');
    useEffect(() => {
        if (TestCode) {
            filter.TestId = TestCode;
        }
        getfaqList(filter);
        return () => { }
    }, [filter])

    useEffect(() => {
        fetchFaqCategory({ isActive: "1" });
        return () => { }
    }, [])

    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
            className:'table_first_col',
            render: (value, item, index) => (page - 1) * 10 + index + 1
        },
        {
            title: 'Category',
            dataIndex: 'Category',
            key: 'Category',
            className: 'text-capitalize',
            render: (value, item, index) => {
                return (
                    <span>{item?.FaqCategory?.Category}</span>
                )
            }
        },
        {
            title: 'Title',
            dataIndex: 'Title',
            key: 'Title',
        },
        {
            title: 'Test Id',
            dataIndex: 'ReferenceId',
            key: 'ReferenceId',
            className: 'table_status_col',
        },
        {
            title: 'Sequence',
            dataIndex: 'Sequence',
            key: 'Sequence',
            className: 'table_action_col',
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            className: 'table_status_col',
            render: (value, item, index) => {
                return (
                    <span className={item?.Status ? "status_active" : "status_inactive"}>{item?.Status ? "Active" : "Inactive"}</span>
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            className: 'table_action_col',
            render: (value, item, index) => {
                return (
                    <Tooltip title="Edit">
                        <span className='cursor-pointer' onClick={() => navigate(`/faq/edit/${item?.Id}`)}><SvgIcon.Edit className="scale-icon edit-icon" /></span>
                    </Tooltip>
                )
            }
        },
    ];
    return (
        <div>
            <Row gutter={[10, 10]} align={"middle"}>
                <Col lg={3} md={24} sm={24} xs={24}>
                    <HeadingBack title={"FAQs"} subHeading={`Total ${totalFaq || 0} FAQs`} />
                </Col>
                <Col lg={19} md={12} sm={24} xs={24}>
                    <FaqFilter filter={filter} setFilter={setFilter} setPage={setPage} faqCategoryList={faqCategoryList} />
                </Col>
                <Col lg={2} md={24} sm={24} xs={24}>
                    <div className='flex justify-content-end align-items-center'>
                        <Tooltip title="Add"><span className='jop_btn' onClick={() => navigate("/faq/add")}>Add<SvgIcon.Add width={40} height={40} className="cursor-pointer" /></span></Tooltip>
                    </div>
                </Col>
            </Row>
            <div className='tableResponsive mt-10'>
                <Table
                    rowKey='Id'
                    className={isLoading ? 'table antd_table no_data_found_hide' : 'table antd_table'}
                    bordered
                    columns={columns}
                    dataSource={Array.isArray(faqList) && faqList?.length > 0 ? faqList : []}
                    pagination={{
                        current: page,
                        pageSize: 10,
                        onChange(current, pageSize) {
                            setPage(current);
                        },
                        hideOnSinglePage: true,
                        /* total: totalFaq,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        responsive: true */
                    }}
                />
            </div>
        </div>
    )
}

export default Faqs