import React, { useEffect } from 'react'
import { Form, Input, Select, Row, Col } from 'antd'
import { GetLoggedInUser } from '../../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { InvestorCategoriesServices } from '../../../redux/Services';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import HeadingBack from '../../../component/HeadingBack';
import { usePermission } from '../../../hooks/usePermission';
const { Option } = Select;

const EditInvestorCategory = () => {
    const permission = usePermission("Faq Category", "Edit");
    let LOG_IN_USER = GetLoggedInUser();
    const { Id } = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const InvestorCategoryData = useSelector(state => state.invesotorCategory.InvestorCategoryDetails || {});
    const { mutate: fetchInviestroCategoryById } = useMutation((data) => dispatch(InvestorCategoriesServices.fetchInvestorCategroyDetailsApi( Id)));
    const { mutate: updateInvestorCategory } = useMutation((data) => dispatch(InvestorCategoriesServices.InvestorCategoryUpdateApi(data,Id, navigate)));
    useEffect(() => {
        if (Id) {
            fetchInviestroCategoryById({});
        }
        return () => { }
    }, [Id])
console.log(InvestorCategoryData)
    useEffect(() => {
        if (InvestorCategoryData && Object.keys(InvestorCategoryData)?.length > 0) {
            form.setFieldValue('Category', InvestorCategoryData?.list.Category);
            form.setFieldValue('Slug', InvestorCategoryData?.list.Slug);
            form.setFieldValue('Status', InvestorCategoryData?.list.Status);
        }
    }, [InvestorCategoryData])

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            if (Id) {
                let _data = { ...values };
                _data.UserId = LOG_IN_USER?.id;
                updateInvestorCategory(_data)
            }
        })
    }
    return (
        <>
            <HeadingBack title={"Investor Category Edit"} subHeading="" />
            <div className='p-20'>
                <Row>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        < Form
                            form={form}
                            name="basic"
                            layout={"vertical"}
                            autoComplete="off"
                        >
                            <Row gutter={[24, 0]}> 
                            <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Category"
                                name="Category"
                                rules={[{ required: true, message: 'Category is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Category' />
                            </Form.Item> 
                            </Col>
                            <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Slug"
                                name="Slug"
                                rules={[{ required: true, message: 'Slug is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Slug' />
                            </Form.Item>
                            </Col>
                            </Row>
                            <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                    name="Status"
                                >
                                    <Option className='text-capitalize' value={0}>Inactive</Option>
                                    <Option className='text-capitalize' value={1}>Active</Option>
                                </Select>
                            </Form.Item>
                            </Col>
                            <div className='text-center'>
                                <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit}>
                                    Update
                                </button>
                            </div>
                        </Form >
                    </Col>
                </Row>
            </div >
        </>
    )
}

export default EditInvestorCategory