import { Col, Input, Row, message, Tooltip, Select } from 'antd'
import React, { useState, useEffect } from 'react'
import { FileExcelFilled } from '@ant-design/icons';
import { CSVLink } from "react-csv"
import { useSelector } from 'react-redux';
const { Option } = Select;

const FellowShipFilter = ({ filter, setFilter, setPage}) => {
    const [values, setValues] = useState({
        Country: "",
        State: "",
        City: "",
        Experience: "",
        Course: ""
    });
    const [stateList, setStateList] = useState({});
    const [cityList, setCityList] = useState({});
    const [FilteredData, setFilteredData] = useState([]);
    const FellowshipList = useSelector(state => state.FellowShip.FellowshipList || []);
    const StateCityList = useSelector(state => state.jobAppliction.StateCityList || []);
    useEffect(() => {
        var data = [];
        if (FellowshipList && FellowshipList.length > 0) {
            FellowshipList.map((value, index) => {
                let applyFor = (JSON.parse(value?.ApplyingCourses || "[]") || [])?.map((item) => item["item_text"])?.join(", ");
                var item = {
                    "ID": value?.Id,
                    "FirstName": value?.FirstName,
                    // "LastName": value?.LastName,
                    "Mobile": value?.MobileNo,
                    "EmailId": value?.EmailId,
                    "Program": value?.Program,
                    "Gender": value?.Gender,
                    "Address": value?.CurrentAddress,
                    "State": value?.State,
                    "City": value?.City,
                    "Country": value?.Country,
                    "Applied Date": value?.CreatedAt,
                    "Statement of Purpose": value?.UploadedStatement,
                    "CV": value?.UploadedCV,
                    "Highest Education": value?.HighestEducation,
                    "College/Institute": value?.InstituteName,
                    "Year of Passing": value?.PassingYear,
                    "Current Organisation": value?.CurrentOrganisation,
                    "Designation": value?.CurrentDesignation,
                    "Experience post DNB/MD": value?.Experience,
                    "Published article/journals/presentations Topic": value?.Topic,
                    "Program": value?.Program,
                    "Course Applying For": applyFor
                }
                data.push(item);
            })
        }
        setFilteredData(data);
        return () => { }
    }, [FellowshipList])

    useEffect(() => {
        setCityList(StateCityList);
        const StateList = unqiueByKey(StateCityList, "stateid");
        setStateList(StateList);
    }, [StateCityList])

    const unqiueByKey = (list, key) => {
        const arrayUniqueByKey = [...new Map(list.map(item =>
            [item[key], item])).values()];
        return arrayUniqueByKey
    }
    const filterCityList = (stateId) => {
        setCityList(StateCityList.filter(state => state.stateid === stateId));
    }
    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value });
    }
    const handleFilter = () => {
        setFilter({ ...filter, ...values, Offset:0, Limit: 100 });
        setPage(1);
    }
    const handleFilterReset = () => {
        setFilter({Offset:0, Limit:100});
        setPage(1);
        setValues({
            ...values,
            Country: "",
            State: "",
            City: "",
            Experience: "",
            Course: ""
        })
    }
    return (
        <div className='table_filter w-80'>
            <Row gutter={[10, 0]} className="ml-10" align="bottom">
                <Col md={6} sm={6} xs={24}>
                    <Input
                        type="text"
                        autoComplete="new-off"
                        className='form-control'
                        onChange={(e) => handleChange("Country", e.target.value)}
                        placeholder='Filter by Country'
                        value={values?.Country}
                    />
                </Col>
                <Col md={6} sm={6} xs={24}>
                    <Select
                        className='text-capitalize w-100'
                        placeholder='Select State'
                        autoComplete="new-off"
                        onChange={(value) => { handleChange("State", value); filterCityList(value) }}
                        value={values?.State}
                    >
                        <Option className='text-capitalize' value="">Select State</Option>
                        {stateList?.length > 0 && stateList?.map((item) =>
                            <Option key={item?.stateid} className='text-capitalize' value={item?.stateid}>{item?.statename}</Option>
                        )}
                    </Select>
                </Col>
                <Col md={6} sm={6} xs={24}>
                    <Select
                        className='text-capitalize w-100'
                        placeholder='Select City'
                        autoComplete="new-off"
                        onChange={(value) => handleChange("City", value)}
                        value={values?.City}
                    >
                        <Option className='text-capitalize' value="">Select City</Option>
                        {cityList?.length > 0 && cityList?.map((item) =>
                            <Option key={item.cityid} className='text-capitalize' value={item.cityid}>{item.cityname}</Option>
                        )}
                    </Select>
                </Col>
                <Col md={6} sm={6} xs={24}>
                    <Select
                        className='text-capitalize w-100'
                        placeholder='Select Experience'
                        autoComplete="new-off"
                        onChange={(value) => handleChange("Experience", value)}
                        value={values?.Experience}
                    >
                        <Option className='text-capitalize' value="">Select Experience</Option>
                        {[...Array(12)]?.map((item, i) =>
                            <Option key={i + 1} className='text-capitalize' value={i + 1}>{i + 1}</Option>
                        )}
                    </Select>
                </Col>
                <Col md={6} sm={6} xs={24}>
                    <Input
                        type="text"
                        autoComplete="new-off"
                        className='form-control'
                        onChange={(e) => handleChange("Course", e.target.value)}
                        placeholder='Filter by Course'
                        value={values?.Course}
                    />
                </Col>
                <Col md={4} sm={12} xs={24}>
                    <div className='flex gap10 mt-20'>
                        <button className='filter-button' onClick={() => handleFilter()}>Filter</button>
                        <button className='clear-button' onClick={() => handleFilterReset()}>Reset</button>
                    </div>
                </Col>
                <Col md={4} sm={12} xs={24} className='mt-20'>
                    <CSVLink
                        filename={"Become a Partner.csv"}
                        data={FilteredData}
                        className="ex-export2 part-export"
                        onClick={() => {
                            if (FilteredData?.length > 0) {
                                message.success("The file is downloading");
                            } else {
                                message.info("No data to download");
                                return false;
                            }
                        }}
                    >
                        <Tooltip placement="top" title={"A user can download only single page data once."} arrow={false}>
                            <FileExcelFilled />
                        </Tooltip>
                    </CSVLink>
                </Col>
            </Row>
        </div >
    )
}

export default FellowShipFilter