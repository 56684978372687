import { Col, Row, Table, Tooltip,Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeadingBack from '../../../component/HeadingBack';
import SvgIcon from '../../../const/SvgIcon';
import { usePermission } from '../../../hooks/usePermission';
import { FaqServices } from '../../../redux/Services';
import { CouponServices } from '../../../redux/Services';
import OffersFilter from './OffersComponent/OffersFilter';
import { FileImageTwoTone } from '@ant-design/icons';
const PromotionItem = () => {
    const permission = usePermission("Coupon Management", "List");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const CouponList = useSelector(state => state.coupon.CouponList || []);
    const totalcouponList = useSelector(state => state.coupon.totalcouponList || "");
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState({});
    const couponCategoryList = useSelector(state => state.coupon.couponCategoryList || []);
    const { mutate: fetchCouponCategory } = useMutation((data) => dispatch(CouponServices.fetchCouponCategoryApi(data)));
    const { mutate: getCouponList, isLoading } = useMutation((data) => dispatch(CouponServices.fetchCouponApi(data)));
console.log(couponCategoryList)

    useEffect(() => {
        fetchCouponCategory({ isActive: "1" });
        return () => { }
    }, [])
    useEffect(() => {
        getCouponList(filter)
        return () => { }
    }, [filter])
    // console.log(couponList)
    // console.log(filter)
    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
            className: 'table_first_col',
            render: (value, item, index) => (page - 1) * 10 + index + 1
        },
        {
            title: 'Category',
            dataIndex: 'Category',
            key: 'Category',
            className: 'text-capitalize',
            render: (value, item, index) => {
                return (
                    <span>{item?.CouponCategory?.Name}</span>
                )
            }
        },
        {
            title: 'Label',
            dataIndex: 'Name',
            key: 'Title',
        },
        {
            title: 'Offer Image',
            dataIndex: 'Banner',
            key: 'ReferenceId',
            className: 'table_status_col',
            render: (value, item, index) => <span>{item?.Banner && <Typography.Link href={item?.Banner} target='_blank'><FileImageTwoTone style={{ fontSize: "30px" }} /></Typography.Link>}</span>
        },
        {
            title: 'Alt Tag',
            dataIndex: 'BannerAltTag',
            key: 'Sequence',
            className: 'table_action_col',
        },
        {
            title: 'Coupon Code ',
            dataIndex: 'Code',
            key: 'Sequence',
            className: 'table_action_col',
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            className: 'table_status_col',
            render: (value, item, index) => {
                return (
                    <span className={item?.Status ? "status_active" : "status_inactive"}>{item?.Status ? "Active" : "Inactive"}</span>
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            className: 'table_action_col',
            render: (value, item, index) => {
                return (
                    <Tooltip title="Edit">
                        <span className='cursor-pointer' onClick={() => navigate(`/coupons/edit/${item?.Id}`)}><SvgIcon.Edit className="scale-icon edit-icon" /></span>
                    </Tooltip>
                )
            }
        },
    ];
    return (
        <div>
            <Row gutter={[10, 10]} align={"middle"}>
                <Col lg={22} md={22} sm={24} xs={24}>
                    <HeadingBack title={"Offers"} subHeading={`Total ${totalcouponList || 0} Offers`} />
                </Col>
                {/* <Col lg={19} md={12} sm={24} xs={24}>
                    <OffersFilter filter={filter} setFilter={setFilter} setPage={setPage} couponCategoryList={couponCategoryList} />
                </Col> */}
                <Col lg={2} md={24} sm={24} xs={24}>
                    <div className='flex justify-content-end align-items-center'>
                        <Tooltip title="Add"><span className='jop_btn' onClick={() => navigate("/coupons/add")}>Add<SvgIcon.Add width={40} height={40} className="cursor-pointer" /></span></Tooltip>
                    </div>
                </Col>
            </Row>
            <div className='tableResponsive mt-10'>
                <Table
                    rowKey='Id'
                    className={isLoading ? 'table antd_table no_data_found_hide' : 'table antd_table'}
                    bordered
                    columns={columns}
                    dataSource={Array.isArray(CouponList) && CouponList?.length > 0 ? CouponList : []}
                    pagination={{
                        current: page,
                        pageSize: 10,
                        onChange(current, pageSize) {
                            setPage(current);
                        },
                        hideOnSinglePage: true,
                        /* total: totalFaq,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        responsive: true */
                    }}
                />
            </div>
        </div>
    )
}

export default PromotionItem