import React, { useEffect, useState } from 'react'
import { Col, Form, Input, notification, Row, Select } from 'antd'
import { useNavigate, useParams } from 'react-router-dom';
import CustomEditor from '../../../component/CustomEditor/CustomEditor';
import { useDispatch, useSelector } from 'react-redux';
import { FaqServices } from '../../../redux/Services';
import { useMutation } from 'react-query';
import { GetLoggedInUser } from '../../../utils';
import HeadingBack from '../../../component/HeadingBack';
import { TestDepartmentServices } from '../../../redux/Services';
import { AutoComplete } from 'antd';
import { usePermission } from '../../../hooks/usePermission';
const { Option } = Select;

const EditFaq = () => {
    const permission = usePermission("Faqs", "Edit");
    const { faqId } = useParams();
    var newOptions = [];
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const faqData = useSelector(state => state.faq.faqDetail || {});
    const faqCategoryList = useSelector(state => state.faq.faqCategoryList || []);
    const TestdepartmentList = useSelector(state => state.Testdepartment.TestdepartmentList || []);
    const { mutate: fetchFaqCategory } = useMutation((data) => dispatch(FaqServices.fetchFaqCategoryApi(data)));
    const { mutate: fetchFaqById } = useMutation((data) => dispatch(FaqServices.fetchFaqsByIdApi(data, faqId)));
    const { mutate: updateFaq } = useMutation((data) => dispatch(FaqServices.updateFaqsApi(data, faqId, navigate)));
    const { mutate: fetchTestDepartmentList, isLoading } = useMutation((data) => dispatch(TestDepartmentServices.fetchTestDeepartmentListApi(data)));
    const [options, setOptions] = useState([
        {
            value: 'Burns Bay Road',
        },
        {
            value: 'Downing Street',
        },
        {
            value: 'Wall Street',
        },
    ]);
    useEffect(() => {
        fetchFaqCategory({ isActive: "1" });
        return () => { }
    }, [])
    useEffect(() => {
        newOptions = [];
        TestdepartmentList.map((value, key) => {
            newOptions.push({
                value: value.TestCode
            })
        })
        setOptions(newOptions);
        return () => { }
    }, [TestdepartmentList])
    useEffect(() => {
        if (faqId) {
            fetchFaqById({});
        }
        return () => { }
    }, [faqId])

    useEffect(() => {
        if (faqData && Object.keys(faqData)?.length > 0) {
            form.setFieldValue('FaqCategoryId', faqData?.FaqCategoryId);
            form.setFieldValue('Title', faqData?.Title);
            form.setFieldValue('Sequence', faqData?.Sequence);
            form.setFieldValue('ReferenceId', faqData?.ReferenceId);
            form.setFieldValue('Status', faqData?.Status);
            form.setFieldValue('Description', faqData?.Description);
        }
    }, [faqData])

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            if (faqId) {
                let _data = { ...values };
                _data.UserId = LOG_IN_USER?.id;
                updateFaq(_data);
            }
        })
    }
    const onInputChange = (value) => {
        if (value && value.length >= 2) {
            fetchTestDepartmentList({ "TestCode": value });
            // setValue(value);
        }
    };
    console.log(options);
    return (
        <>
            <HeadingBack title={"Faq Edit"} subHeading="" />
            <div className='p-20'>
                < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                >
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Category"
                                name="FaqCategoryId"
                                rules={[{ required: true, message: 'Category is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Category'
                                    autoComplete="new-off"
                                    name="FaqCategoryId"
                                >
                                    <Option className='text-capitalize' value="">Select Category</Option>
                                    {faqCategoryList?.length > 0 && faqCategoryList?.map((item, idx) =>
                                        <Option key={idx} className='text-capitalize' value={item.Id}>{item.Category}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Title"
                                name="Title"
                                rules={[{ required: true, message: 'Title is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Title' />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Test Id"
                                name="ReferenceId"
                                rules={[{ required: true, message: 'Test Id is required' }]}
                            >
                                <AutoComplete

                                    options={options}
                                    onSearch={onInputChange}
                                    placeholder="Test Id"
                                // filterOption={(inputValue, option) =>
                                //     option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                // }
                                />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Sequence"
                                name="Sequence"
                            >
                                <Input type="number" autoComplete="new-off" className='form-control' placeholder='Sequence' />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                    name="Status"
                                >
                                    <Option className='text-capitalize' value={0}>Inactive</Option>
                                    <Option className='text-capitalize' value={1}>Active</Option>
                                </Select>
                            </Form.Item>
                        </Col >
                        <Col span={24}>
                            <Form.Item
                                label="Description"
                                name="Description"
                                rules={[{ required: true, message: 'Description is required' }]}
                            >
                                <CustomEditor value={form.getFieldValue("Description")} onEditorChange={(value) => form.setFieldsValue({ Description: value })} />
                            </Form.Item>
                        </Col>
                    </Row >
                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit}>
                            Update
                        </button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default EditFaq