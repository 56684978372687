import { Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeadingBack from '../../component/HeadingBack';
import SvgIcon from '../../const/SvgIcon'
import { usePermission } from '../../hooks/usePermission';
import { DepartmentServices } from '../../redux/Services';

const Department = () => {
    const permission = usePermission("Department Management", "List");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const departmentList = useSelector(state => state.department.departmentList || []);
    const totaldepartment = useSelector(state => state.department.TotalRecord || 0);
    const { mutate: fetchDepartmentList, isLoading } = useMutation((data) => dispatch(DepartmentServices.fetchDeepartmentListApi(data)));
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    useEffect(() => {
        fetchDepartmentList({});
        return () => { }
    }, [])

    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
            className:'table_first_col',
            render: (value, item, index) => (page - 1) * 10 + index + 1
        },
        {
            title: 'Department Name',
            dataIndex: 'DepartmentName',
            key: 'DepartmentName',
            className: 'text-capitalize'
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            className: 'table_status_col',
            render: (value, item, index) => {
                return (
                    <span className={item?.Status ? "status_active" : "status_inactive"}>{item?.Status ? "Active" : "Inactive"}</span>
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            className: 'table_action_col',
            render: (value, item, index) => {
                return (
                    <Tooltip title="Edit"><span className='cursor-pointer' onClick={() => navigate(`/department/edit/${item?.Id}`)}><SvgIcon.Edit className="scale-icon edit-icon" /></span></Tooltip>
                )
            }
        }
    ];
    return (
        <div>
            <div className='flex'>
                <div className='w-100'>
                    <HeadingBack title={"Departments"} subHeading={`Total ${totaldepartment || 0} Departments`} />
                </div>
                <div className=''>
                    <Tooltip title="Add"><span className='jop_btn' onClick={() => navigate("/department/add")}>Add<SvgIcon.Add width={40} height={40} className="cursor-pointer" /></span></Tooltip>
                </div>
            </div>
            <div className='tableResponsive mt-10'>
                <Table
                    rowKey='Id'
                    className={isLoading ? 'table antd_table no_data_found_hide' : 'table antd_table'}
                    bordered
                    columns={columns}
                    dataSource={Array.isArray(departmentList) && departmentList?.length > 0 ? departmentList : []}
                    pagination={{
                        current: page,
                        pageSize: limit,
                        total: totaldepartment,
                        onChange(current, pageSize) {
                            console.log(current);
                            setPage(current);
                            setOffset(current == 1 ? 0 : (limit * current));
                        },
                        hideOnSinglePage: true
                    }}
                />
                {/* <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th>Category</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {faqCategoryList && faqCategoryList?.length > 0 && faqCategoryList?.map((item, idx) => (
                            <tr key={idx}>
                                <td>{++idx}</td>
                                <td className='text-capitalize'>{item?.Category}</td>
                                <td ><span className={item?.Status ? "status_active" : "status_inactive"}>{item?.Status ? "Active" : "Inactive"}</span></td>
                                <td><span className='cursor-pointer' onClick={() => navigate(`/faq-category/edit/${item?.Id}`)}><SvgIcon.Pencil className="scale-icon" /></span></td>
                            </tr>
                        ))
                        }
                    </tbody>
                </table> */}
            </div>
        </div>
    )
}

export default Department