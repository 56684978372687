import { Col, Input, Row, Select,Form } from 'antd'
import React, { useState, useEffect } from 'react'
const { Option } = Select;
const zoneOptions = [
    {
      value: 'ZH1',
    },
    {
      value: 'ZH16',
    },
    {
      value: 'ZH17',
    },
    {
      value: 'ZH18',
    },
    {
      value: 'ZH19',
    },
    {
      value: 'ZH22',
    },
    {
      value: 'ZH24',
    },
    {
      value: 'ZH26',
    },
    {
      value: 'ZH28',
    },
    {
      value: 'ZH29',
    },
    {
      value: 'ZH30',
    },
    {
      value: 'ZH32',
    },
    {
      value: 'ZH33',
    },
    {
      value: 'ZH5',
    },
  ];
const OffersFilter = ({ filter, setFilter, setPage, couponCategoryList }) => {
    const BtlOffersId = [
        4, 5
    ];
    const [values, setValues] = useState({
        IsActive: "",
        TestCode: "",
        CouponCategoryId: "",
        Zone:""
    });
    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value });
    }
    const handleFilter = () => {
        setFilter({ ...filter, ...values });
        setPage(1);
    }
    const handleFilterReset = () => {
        setFilter({});
        setValues({
            ...values,
            IsActive: "",
            TestCode: "",
            CouponCategoryId: "",
            Zone:""
        })
        setPage(1);
    }


    return (
        <div className='table_filter'>
            <Row gutter={[12, 24]}>
                <Col md={5} sm={5} xs={24}>
                    <Select
                        className='text-capitalize w-100'
                        placeholder='Select Category'
                        autoComplete="new-off"
                        onChange={(value) => handleChange("CouponCategoryId", value)}
                        value={values?.CouponCategoryId || undefined}
                    >
                        <Option className='text-capitalize'>Select Category</Option>

                        {couponCategoryList?.length > 0 && couponCategoryList?.map((item, idx) =>
                            (BtlOffersId.includes(item.Id)) ?
                                <Option key={idx} className='text-capitalize' value={item.Id}>{item.Name}</Option>
                                : ''
                        )}
                    </Select>
                </Col>
                <Col md={4} sm={4} xs={24}>
                    <Input
                        type="text"
                        autoComplete="new-off"
                        className='form-control'
                        onChange={(e) => handleChange("TestCode", e.target.value)}
                        placeholder='Test Code'
                        value={values?.TestCode}
                    />
                </Col>
                <Col md={3} sm={3} xs={24}>
                <Form.Item
                                >
                                    <Select
                                        showSearch
                                        placeholder="Zone"
                                        name="Zone"
                                        onChange={(value) => handleChange("Zone", value)}
                                        value={values?.Zone || undefined}
                                    >
                                        {zoneOptions?.length > 0 && zoneOptions?.map((item, idx) =>
                                            <Option key={idx} className='text-capitalize' value={item.value}>{item.value}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                </Col>
                <Col md={4} sm={12} xs={24}>
                    <Select
                        className='text-capitalize w-100'
                        placeholder='Select Status'
                        autoComplete="new-off"
                        onChange={(value) => handleChange("IsActive", value)}
                        value={values?.IsActive}
                    >
                        <Option className='text-capitalize' value="">All</Option>
                        <Option className='text-capitalize' value={"0"}>Inactive</Option>
                        <Option className='text-capitalize' value={"1"}>Active</Option>
                    </Select>
                </Col>
                <Col md={6} sm={12} xs={24}>
                    <div className='flex gap10'>
                        <button className='filter-button' onClick={() => handleFilter()}>Filter</button>
                        <button className='clear-button' onClick={() => handleFilterReset()}>Reset</button>
                    </div>
                </Col>
            </Row>
        </div >
    )
}

export default OffersFilter