import { notification } from "antd";
import Api from "../../api";
import API_URL from "../../api/url";
import { setBannerDetail, setBannerList } from "../Slices/bannerSlice";

export const fetchBannerListApi = (data) => async (dispatch) => {
    try {
        const res = await Api.get(API_URL.BANNER_LIST, data);
        dispatch(setBannerList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};

export const fetchBannerDetailApi = (id) => async (dispatch) => {
    try {
        const res = await Api.post(`${API_URL.BANNER_DETAILS}/${id}`);
        dispatch(setBannerDetail(res.data.Data));
        return res;
    } catch (error) {
        return error
    }
};
export const addBannerApi = (data, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.BANNER_ADD, data);
        navigate(-1);
        notification["success"]({
            message: "Banner added successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const BannerDetailUpdateApi = (data, id, navigate) => async (dispatch) => {
    try {
        const res = await Api.put(`${API_URL.BANNER_UPDATE}/${id}`, data);
        navigate(-1);
        notification["success"]({
            message: "Banner updated successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const BannerImageAddApi = (data) => async (dispatch) => {
    try {
        const res = await Api.post(`${API_URL.BANNER_IMG_ADD}`, data);
        notification["success"]({
            message: "Image added successfully"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
