import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userList: [],
    totalUser: "",
    userDetail: {},
    roleList: [],
    totalRole: "",
    roleDetail: {},
    filterData: {},
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserList: (state, action) => {
            state.userList = action.payload.list;
            state.totalUser = action.payload.total;
        },
        setUserDetail: (state, action) => {
            state.userDetail = action.payload;
        },
        setRoleList: (state, action) => {
            state.roleList = action.payload.list;
            state.totalRole = action.payload.total;
        },
        setRoleDetail: (state, action) => {
            state.roleDetail = action.payload;
        },
        setFilterData: (state, action) => {
            state.filterData = action.payload;
        },
    },
})

export const { setUserList, setUserDetail, setRoleList, setRoleDetail, setFilterData } = userSlice.actions

export default userSlice.reducer