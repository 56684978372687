import React, { useEffect, useState } from 'react'
import { Col, Form, Input, notification, Row, Select } from 'antd'
import { useNavigate, useParams } from 'react-router-dom';
import CustomEditor from '../../../component/CustomEditor/CustomEditor';
import { useDispatch, useSelector } from 'react-redux';
import { JobApplicationsServices } from '../../../redux/Services';
import { useMutation } from 'react-query';
import { GetLoggedInUser } from '../../../utils';
import URL from "../../../api/url";
import HeadingBack from '../../../component/HeadingBack';
import { usePermission } from '../../../hooks/usePermission';
const { Option } = Select;

const EditJobApplication = () => {
    const permission = usePermission("Job Applications", "Edit");
    const { userId } = useParams();
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [jpbAppData, setjpbAppData] = useState({});
    const JobApplicationData = useSelector(state => state.jobAppliction.JobApplicationsDetails || {});
    // const faqCategoryList = useSelector(state => state.faq.faqCategoryList || []);
    const { mutate: getJobApplicationById } = useMutation((data) => dispatch(JobApplicationsServices.fetchJobApplicationsDetailsApi(data, userId)));
    const { mutate: updateJobApplication } = useMutation((data) => dispatch(JobApplicationsServices.updateJobApplicationApi(data, userId, navigate)));

    // console.log('JobApplicationsId',JobApplicationData);
    // // console.log(getJobApplicationById());
    useEffect(() => {
        getJobApplicationById(userId);
        // if (fetchJobApplicationById) {
        //     fetchFaqById({});
        // }
        return () => { }
    }, [])

    useEffect(() => {
        if (JobApplicationData && Object.keys(JobApplicationData).length > 0 && Object.keys(JobApplicationData.list)?.length > 0) {
            const jpbAppData = JobApplicationData.list[0];
            setjpbAppData(jpbAppData);
            form.setFieldValue('Id', jpbAppData?.Id);
            form.setFieldValue('FirstName', jpbAppData?.FirstName);
            form.setFieldValue('LastName', jpbAppData?.LastName);
            form.setFieldValue('Email', jpbAppData?.Email);
            form.setFieldValue('Mobile', jpbAppData?.Mobile);
            form.setFieldValue('State', jpbAppData?.State);
            form.setFieldValue('City', jpbAppData?.City);
            form.setFieldValue('WorkStatus', jpbAppData?.WorkStatus);
            form.setFieldValue('ApplicationStatus', jpbAppData?.ApplicationStatus);
            form.setFieldValue('Experience', jpbAppData?.Experience);
            form.setFieldValue('CurrentCompany', jpbAppData?.CurrentCompany);
            form.setFieldValue('JobName', jpbAppData?.JobName);
            form.setFieldValue('CurrentSalary', jpbAppData?.CurrentSalary);
            form.setFieldValue('ExpectedSalary', jpbAppData?.ExpectedSalary);
            form.setFieldValue('PreferJobLocation', jpbAppData?.PreferJobLocation);
            form.setFieldValue('CurrentRole', jpbAppData?.CurrentRole);
            form.setFieldValue('JobId', jpbAppData?.JobId);
            form.setFieldValue('Remarks', jpbAppData?.Remarks);
        }
    }, [JobApplicationData])

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            if (updateJobApplication) {
                let _data = { ...values };
                _data.id = updateJobApplication?.id;
                updateJobApplication(_data);
            }
        })
    }

    return (
        <>
            <HeadingBack title={"Job Application Edit"} subHeading="" />
            <div className='p-20'>
                < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                >
                    <Row gutter={[20, 0]}>

                        <Col lg={12} md={16} sm={12} xs={24}>
                            <Form.Item
                                label="First Name"
                                name="FirstName"
                                rules={[{ required: false, message: 'First Name is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='First Name' readOnly />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Last Name"
                                name="LastName"
                                rules={[{ required: false, message: 'Last Name is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Last Name' readOnly />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Email"
                                name="Email"
                                rules={[{ required: false, message: 'Eamil is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Email' readOnly />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Mobile"
                                name="Mobile"
                                rules={[{ required: false, message: 'Mobile is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Mobile Number' readOnly />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="State"
                                name="State"
                                rules={[{ required: false, message: 'State is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='State' readOnly />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="City"
                                name="City"
                                rules={[{ required: false, message: 'City is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='City' readOnly />
                            </Form.Item>
                        </Col >
                        
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Work Status"
                                name="WorkStatus"
                                rules={[{ required: false, message: 'Work Status is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Work Status' readOnly />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Experience (Years)"
                                name="Experience"
                                rules={[{ required: false, message: 'City is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Experience' readOnly />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Current Company"
                                name="CurrentCompany"
                                rules={[{ required: false, message: 'City is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Current Company' readOnly />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Current Role"
                                name="CurrentRole"
                                rules={[{ required: false, message: '' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Current Role' readOnly />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Current Salary (Monthly)"
                                name="CurrentSalary"
                                rules={[{ required: false, message: 'Current Salary is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Current Salary' readOnly />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Expected Salary (Monthly)"
                                name="ExpectedSalary"
                                rules={[{ required: false, message: 'Expected Salary is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Expected Salary' readOnly />
                            </Form.Item>
                        </Col >
                       
                       
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="LinkedIn Link"
                                name="LinkedLink"
                                rules={[{ required: false, message: '' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Linked Link (Optional)' readOnly />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Preferred Job Location"
                                name="PreferJobLocation"
                                rules={[{ required: false, message: '' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Preferred Job Location' readOnly />
                            </Form.Item>
                        </Col >                      
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Job Role"
                                name="JobName"
                                rules={[{ required: false, message: '' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Job Role' readOnly />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <> <label class="ant-form-item-required appli_view_label" title="Profile Document">CV Document</label>
                         <div className="appli_view">
                                {jpbAppData?.ProfileDocumentName ? <a target={"_blank"} style={{ textDecoration: "underline" }} href={URL.DOCUMENTPATH + jpbAppData?.ProfileDocumentName}>View</a> : 'NA'}
                                </div>
                                </>
                        </Col >
                          
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Application Status"
                                name="ApplicationStatus"
                                rules={[{ required: true, message: 'Application Status is required' }]}
                            >
                              <Select
                        className='text-capitalize w-100'
                        placeholder='Select Application Status'
                        autoComplete="new-off"
                        // onChange={(value) => handleChange("ApplicationStatus", value)}
                        // value={values?.ApplicationStatus}
                    >
                        <Option className='text-capitalize' value="" Selected>Select Application Status</Option>
                        <Option className='text-capitalize' value="0">Pending</Option>
                        <Option className='text-capitalize' value="1">In Review</Option>
                        <Option className='text-capitalize' value="2">Accepted</Option>
                        <Option className='text-capitalize' value="3">Rejected</Option>
                    </Select>
                                {/* <Input type="text" autoComplete="new-off" className='form-control' placeholder='Application Status' /> */}
                            </Form.Item>
                        </Col >
                        <Col lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Remarks"
                                name="Remarks"
                                rules={[{ required: false, message: 'Remarks' }]}
                            >
                                <CustomEditor value={form.getFieldValue("Remarks")} onEditorChange={(value) => form.setFieldsValue({ Remarks: value })} />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label=""
                                name="Id"
                                rules={[{ required: false, message: '' }]}
                            >
                                <Input type="hidden" autoComplete="new-off" className='form-control' placeholder='Job Id' />
                            </Form.Item>
                        </Col >
                    </Row >
                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit}>
                            Update
                        </button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default EditJobApplication