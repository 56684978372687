import React from 'react'
import { Col, Form, Input, Row } from 'antd'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { UserServices } from '../../../redux/Services';
import { GetLoggedInUser } from '../../../utils';
import HeadingBack from '../../../component/HeadingBack';
import { usePermission } from '../../../hooks/usePermission';

const AddRoles = () => {
    const permission = usePermission("Roles", "Add");
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { mutate: addRole } = useMutation((data) => dispatch(UserServices.addRolesApi(data, navigate)));
    const handleSubmit = () => {
        form.validateFields().then((values) => {
            let _data = { ...values };
            _data.UserId = LOG_IN_USER?.id;
            addRole(_data)
        })
    }
    return (
        <>
            <HeadingBack title={"Role Add"} subHeading="" />
            <div className='p-20'>
                <Row>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        < Form
                            form={form}
                            name="basic"
                            layout={"vertical"}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Role"
                                name="Role"
                                rules={[{ required: true, message: 'Role is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Role' />
                            </Form.Item>
                            <Form.Item
                                label="Additional Field"
                                name="AdditionalInfo"
                            >
                                <Input.TextArea autoComplete="new-off" className='form-control' placeholder='Enter Text' rows={4} maxLength={100} />
                            </Form.Item>
                            <div className='text-center'>
                                <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit}>
                                    Submit
                                </button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default AddRoles