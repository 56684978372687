import { Col, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import HeadingBack from '../../../component/HeadingBack';
import { UserServices } from '../../../redux/Services';
import { GetLoggedInUser } from '../../../utils';
import { usePermission } from '../../../hooks/usePermission';

const UserPermission = () => {
    const permission = usePermission("Roles", "Edit");
    const { roleId } = useParams();
    let LOG_IN_USER = GetLoggedInUser();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [permissionsValue, setPermissionsValue] = useState([]);
    const ModuleData = useSelector(state => state.user.filterData?.ModuleName || {});
    const roleData = useSelector(state => state.user.roleDetail || {});
    const { mutate: fetchRoleById, isLoading } = useMutation((data) => dispatch(UserServices.fetchRolesByIdApi(data, roleId)));
    const { mutate: updatePermission } = useMutation((_data) => dispatch(UserServices.updatePermissionApi(_data, roleId, navigate)));

    useEffect(() => {
        fetchRoleById({});
        return () => { }
    }, [roleId])

    useEffect(() => {
        if (roleData?.Permission) {
            setPermissionsValue(JSON.parse(roleData?.Permission || "[]"));
        }
    }, [roleData])

    const handleUpdatePermission = () => {
        let payload = {
            UserId: LOG_IN_USER?.id,
            Permissions: permissionsValue
        }
        updatePermission(payload)
    }

    const handleSetPermission = (e) => {
        let moduleName = (e.target.name);
        let value = e.target.value;
        let arr = [...permissionsValue];
        const index = arr.findIndex(object => {
            return object.module === moduleName;
        });
        if (e.target.checked) {
            if (index > -1) {
                arr[index].permission = value;
            } else {
                arr.push({ module: moduleName, permission: value });
            }
        } else {
            if (index > -1) {
                arr[index].permission = "";
            }
        }
        setPermissionsValue(arr);
    }
    const getCheckedPermission = (module, value) => {
        let _obj = permissionsValue && permissionsValue?.length > 0 && permissionsValue?.find(ele => ele?.module == module && ele?.permission == value);
        return _obj ? true : false;
    }
    const columns = [
        {
            title: 'Module Name',
            dataIndex: 'ModuleName',
            key: 'ModuleName',
            width: '250px',
            render: (value, item, index) => (
                < span >{item}</span >
            )
        },
        {
            title: 'View',
            dataIndex: 'View',
            key: 'View',
            render: (value, item, index) => (
                <div>
                    <input className="largeCheckbox" type="checkbox" name={item} checked={getCheckedPermission(item, 1)} value={1} onChange={handleSetPermission} />
                </div>
            )
        },
        {
            title: 'View | Add',
            dataIndex: 'ViewAdd',
            key: 'ViewAdd',
            render: (value, item, index) => (
                <div>
                    <input className="largeCheckbox" type="checkbox" name={item} checked={getCheckedPermission(item, 2)} value={2} onChange={handleSetPermission} />
                </div>
            )
        },
        {
            title: 'View | Edit',
            dataIndex: 'ViewEdit',
            key: 'ViewEdit',
            render: (value, item, index) => (
                <div>
                    <input className="largeCheckbox" type="checkbox" name={item} checked={getCheckedPermission(item, 3)} value={3} onChange={handleSetPermission} />
                </div>
            )
        },
        {
            title: 'View | Edit | Add',
            dataIndex: 'ViewEditAdd',
            key: 'ViewEditAdd',
            render: (value, item, index) => (
                <div>
                    <input className="largeCheckbox" type="checkbox" name={item} checked={getCheckedPermission(item, 4)} value={4} onChange={handleSetPermission} />
                </div>
            )
        },
        {
            title: 'View | Edit | Add | Delete',
            dataIndex: 'ViewEditAddDelete',
            key: 'ViewEditAddDelete',
            render: (value, item, index) => (
                <div>
                    <input className="largeCheckbox" type="checkbox" name={item} checked={getCheckedPermission(item, 5)} value={5} onChange={handleSetPermission} />
                </div>
            )
        },
    ];

    return (
        <div>
            <div className='flex'>
                <div>
                    <HeadingBack title={"ROLE : Customer Care support"} subHeading={""} />
                </div>
            </div>
            <div className='mt-10'>
                <div className='permissionBox'>
                    <Table
                        className={isLoading ? 'table antd_table no_data_found_hide' : 'table antd_table'}
                        columns={columns}
                        dataSource={Object.values(ModuleData)?.length > 0 ? Object.values(ModuleData) : []}
                        pagination={false}
                    />
                </div>
                <div className='text-center mt-20'>
                    <button type="button" style={{ maxWidth: "120px" }} className='gradient-button' onClick={() => handleUpdatePermission()}>Update</button>
                </div>
            </div>
        </div>
    )
}

export default UserPermission