import { Col, Row, Table, Tooltip, Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate,useParams } from 'react-router-dom';
import HeadingBack from '../../component/HeadingBack';
import SvgIcon from '../../const/SvgIcon';
import { usePermission } from '../../hooks/usePermission';
import { GetLoggedInUser } from '../../utils';
import OffersFilter from './TeamComponent/TeamFilter';
import { TeamServices } from '../../redux/Services';
import moment from 'moment';
const TeamList = () => {
    const permission = usePermission("Team Management", "List");
    let LOG_IN_USER = GetLoggedInUser();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const TeamList = useSelector(state => state.team.TeamMemberList || []);
    const totalTeamList = useSelector(state => state.team.totalTeamMemberList || "");
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState({});
    const [modal2Open, setModal2Open] = useState(false);
    const { mutate: getTeamList, isLoading } = useMutation((data) => dispatch(TeamServices.fetchTeamMemberApi(data)));
    // const { mutate: updatBtlStatusApi } = useMutation(({ data, id }) => dispatch(TeamServices.updatBtlStatusApi(data, id)));
    const [raw, setRaw] = useState({});
    const { d } = useParams();
    useEffect(() => {
        getTeamList(filter)
        return () => { }
    }, [filter])
    const UserId = LOG_IN_USER?.id
    // console.log(UserId)
    // const handleUpdateStatus = (status, id) => {
    //     let data = { Status: status ? 0 : 1, id: id,UserId:UserId}
    //     updatBtlStatusApi({ data, id }, {
    //         onSuccess: (res) => {
    //             if (res.status === 200) {
    //                 getTeamList(filter);
    //             }
    //         }
    //     });
    // }
    const Category = {
        "1": 'Board of Directors',
        "2": 'Senior Management',
    }
    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
            className: 'table_first_col',
            render: (value, item, index) => (page - 1) * 10 + index + 1
            
        },
        {
            title: 'Category',
            dataIndex: 'Category',
            key: 'Category',
            className: 'text-capitalize btlCate',
            render: (value, item, index) => {
                return (
                    <span>{Category[item?.Category]}</span>
                )
            }
        },
        {
            title: 'Name',
            dataIndex: 'Code',
            key: 'Code',
            className: 'table_action_col',
            render: (value, item, index) => {
                return (
                    <span>{item?.Code ? item.Code : item.Name}</span>
                )
            }
        },
        {
            title: 'Image',
            dataIndex: 'Banner',
            key: 'ReferenceId',
            className: 'table_status_col',
            render: (value, item, index) => <span>{item?.DisplayPhoto && <Button className="btl_img_class" type="primary" onClick={() => { setRaw(item?.DisplayPhoto || "{}"); setModal2Open(true) }}><img className="dire_img" src={item?.DisplayPhoto} crossorigin="anonymous" /></Button>}</span>
        },
      

        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            className: 'table_status_col',
            defaultSortOrder: 'descend',
            // sorter: (a, b) => a.Status - b.Status,
            render: (value, item, index) => {
                return (
                    // <Tooltip title="Change Offer Status">
                    <span className={item?.Status ? "status_active" : "status_inactive"} >{item?.Status ? "Active" : "Inactive"}</span>
            //    </Tooltip>
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            className: 'table_action_col',
            render: (value, item, index) => {
                return (
                    <Tooltip title="Edit">
                        <span className='cursor-pointer' onClick={() => navigate(`/our-team/edit/${item?.Id}`)}><SvgIcon.Edit className="scale-icon edit-icon" /></span>
                    </Tooltip>
                )
            }
        },
    ];
    return (
        <div>
            <Row gutter={[20, 20]} align={"middle"}>
                <Col lg={3} md={3} sm={24} xs={24}>
                    <HeadingBack title={"Our Team"} subHeading={`Total ${totalTeamList || 0} `} />
                </Col>
                <Col lg={17} md={17} sm={24} xs={24}>
                    <OffersFilter filter={filter} setFilter={setFilter} setPage={setPage}  />
                </Col>
                <Col lg={2} md={24} sm={24} xs={24}>
                    <div className='flex justify-content-end align-items-center'>
                        <Tooltip title="Add"><span className='jop_btn' onClick={() => navigate("/our-team/add")}>Add<SvgIcon.Add width={40} height={40} className="cursor-pointer" /></span></Tooltip>
                    </div>
                </Col>
            </Row>
            <div className='tableResponsive mt-10'>
                <Table
                    rowKey='Id'
                    className={isLoading ? 'table antd_table no_data_found_hide' : 'table antd_table'}
                    bordered
                    columns={columns}
                    dataSource={Array.isArray(TeamList) && TeamList?.length > 0 ? TeamList : []}
                    pagination={{
                        current: page,
                        pageSize: 10,
                        onChange(current, pageSize) {
                            setPage(current);
                        },
                        hideOnSinglePage: true,
                        /* total: totalFaq,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        responsive: true */
                    }}
                />
            </div>
            <Modal
                className='btl_modal'
                centered
                open={modal2Open}
                onOk={() => setModal2Open(false)}
                onCancel={() => setModal2Open(false)}
                footer={null}
            >
                <img src={(raw)} crossOrigin='anymouse' />
            </Modal>
        </div>
    )
}

export default TeamList