import React, { useEffect } from 'react'
import { Form, Input, Select, Row, Col } from 'antd'
import { GetLoggedInUser } from '../../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { LeadPageServices } from '../../../redux/Services';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import HeadingBack from '../../../component/HeadingBack';
import { usePermission } from '../../../hooks/usePermission';
const { Option } = Select;

const EditManageZone = () => {
    const permission = usePermission("Zone Management", "Edit");
    let LOG_IN_USER = GetLoggedInUser();
    const { zoneId } = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const zoneListData = useSelector(state => state.lead.zoneListByIdDetail || {});
    const { mutate: fetchZoneListById } = useMutation((data) => dispatch(LeadPageServices.fetchZoneListByIdApi(data, zoneId)));
    const { mutate: updateZone } = useMutation((data) => dispatch(LeadPageServices.updateZoneApi(data, zoneId, navigate)));
    useEffect(() => {
        if (zoneId) {
            fetchZoneListById({});
        }
        return () => { }
    }, [zoneId])

    useEffect(() => {
        if (zoneListData && Object.keys(zoneListData)?.length > 0) {
            form.setFieldValue('Zone', zoneListData?.Name);
            form.setFieldValue('Slug', zoneListData?.Slug);
            form.setFieldValue('Status', zoneListData?.Status);
        }
    }, [zoneListData])

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            if (zoneId) {
                let _data = { ...values };
                _data.UserId = LOG_IN_USER?.id;
                updateZone(_data)
            }
        })
    }
    return (
        <>
         <HeadingBack title={"Zone/Region Edit"} subHeading="" />
            <div className='p-20'>
            <Row>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        < Form
                            form={form}
                            name="basic"
                            layout={"vertical"}
                            autoComplete="off"
                            initialValues={{ Status: "1" }}
                        >
                            <Row gutter={[24, 0]}>
                            <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Zone/Region"
                                name="Zone"
                                rules={[{ required: true, message: 'Zone is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Zone/Region' />
                            </Form.Item>
                            </Col>
                            <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                    name="Status"
                                >
                                    <Option className='text-capitalize' value={0}>Inactive</Option>
                                    <Option className='text-capitalize' value={1}>Active</Option>
                                </Select>
                            </Form.Item>
                            </Col>
                            </Row>
                            
                            <div className='text-center'>
                                <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit}>
                                Update
                                </button>
                            </div>
                        </Form >
                    </Col>
                </Row>
            </div >
        </>
    )
}

export default EditManageZone