import * as moment from 'moment';
import jwt_decode from "jwt-decode";
import axios from 'axios';

export function SetToken(token) {
    window.localStorage.setItem("lpl_admin:token", token || "")
}

export function GetToken() {
    let token = window.localStorage.getItem("lpl_admin:token") || ""
    return token
}
export function DeleteToken() {
    window.localStorage.removeItem("lpl_admin:token")
}
export function GetLoggedInUser() {
    let token = GetToken();
    if (token) {
        const user = jwt_decode(token);
        return user
    } else {
        return {}
    }
}
export function isSuperAdmin() {
    let LODDED_USER = GetLoggedInUser();
    if (LODDED_USER?.role_id == 1) {
        return true
    } else {
        return false
    }
}

export function DateFormat(date) {
    return date && moment(date).format("MMM DD YYYY") || "";
}
export function getDatesInRange(first, second) {
    const date = new Date(first?.getTime());
    const dates = [];
    while (date <= second) {
        dates.push(moment(date).format("YYYY-MM-DD"));
        date.setDate(date.getDate() + 1);
    }
    return dates
}
export function DayFrom(date) {
    if (date) {
        const firstDate = new Date(date);
        var diffDays = moment().diff(firstDate, 'days');
        return diffDays
    } else {
        return ""
    }
}
export function FromNow(date) {
    return date && moment(date).fromNow() || "";
}
export function onlyMobileInput() {
    let mobile = document.getElementById("only_mobile");
    let mobile1 = document.getElementById("only_mobile1");
    let mobile2 = document.getElementById("only_mobile2");
    let mobile3 = document.getElementById("only_mobile3");
    if (mobile) {
        mobile.addEventListener("input", function () {
            if (/\D/g.test(this.value)) {
                this.value = this.value.replace(/\D/g, "");
            }
        })
    }
    if (mobile1) {
        mobile1.addEventListener("input", function () {
            if (/\D/g.test(this.value)) {
                this.value = this.value.replace(/\D/g, "");
            }
        })
    }
    if (mobile2) {
        mobile2.addEventListener("input", function () {
            if (/\D/g.test(this.value)) {
                this.value = this.value.replace(/\D/g, "");
            }
        })
    }
    if (mobile3) {
        mobile3.addEventListener("input", function () {
            if (/\D/g.test(this.value)) {
                this.value = this.value.replace(/\D/g, "");
            }
        })
    }
}

export function isNegative(num) {
    if (Math.sign(num) === -1) {
        return true;
    }
    return false;
}
export function getCurrentYear() {
    let currentYear = moment(new Date()).format("YYYY");
    return currentYear;
}
export function getDaysByMonth() {
    var daysArr = [];
    var monthDate = moment().startOf('month');
    for (let i = 0; i < monthDate.daysInMonth(); i++) {
        let newDay = monthDate.clone().add(i, 'days');
        daysArr.push({ date: newDay.format('D'), month: newDay.format('MMM'), value: newDay.format('DD') });
    }
    return daysArr
};
export function ShowMenu(Module) {
    let LOGGED_IN_USER = GetLoggedInUser();
    if (LOGGED_IN_USER?.role_id == 1) {
        return ''
    } else {
        let permission = JSON.parse(LOGGED_IN_USER?.permission || "[]");
        let obj = permission.find(ele => Array.isArray(Module) && Module.includes(ele.module));
        console.log(obj, Module)
        if (obj?.permission && obj?.permission >= 1) {
            return ''
        } else {
            return 'none'
        }
    }
}
export function isJSON(str) {
    try {
        return (JSON.parse(str) && !!str);
    } catch (e) {
        return false;
    }
}
export const getNameValue = (list, id) => {
    let _val = list && Array.isArray(list) && list?.length > 0 && list.find(ele => ele.Id == id);
    return _val?.Category || "";
}
export const getFilterTableData = (baseData, search) => {
    return baseData?.filter(o =>
        Object.keys(o).some(k => {
            if (k == "SubCategory1" || k == "SubCategory2" || k == "SubCategory3") {
                return String(o[k]?.Category)
                    .toLowerCase()
                    .includes(search?.toLowerCase())
            } else {
                return String(o[k])
                    .toLowerCase()
                    .includes(search?.toLowerCase())
            }
        }
        )
    );
}
export function getFileDir(filename) {
    let imgType = ["png", "jpg", "jpeg"];
    var ext = filename.split('.').pop();
    if (imgType.includes(ext)) {
        return "images"
    }
    else if (ext == "pdf") {
        return "pdf"
    } else {
        return "audio"
    }
}
export const JSONParse = (json = "", object = false) => {
    try {
        return JSON.parse(json || (object ? "{}" : "[]"));
    } catch (error) {
        return object ? {} : []
    }
}
export function getBusinessProfession(value = null) {
    const BusinessProfession = {
        "1": 'Technician',
        "2": 'Phlebotomist',
        "3": 'Doctor',
        "4": 'Businessman',
        "5": 'Other',
    }
    if (value) {
        if (typeof BusinessProfession[value] != undefined) {
            return BusinessProfession[value];
        } else {
            return 'NONE';
        }
    } else {
        return BusinessProfession;
    }
}
const getExt = (file)=>  file?.split('.').pop() || "";
export const downloadFile = (file, name = "file") => {
    let ext = getExt(file);
    axios({
      url: file,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${name}_${new Date().getTime()}.${ext}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => console.log(error));
  };