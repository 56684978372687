import { notification } from "antd";
import Api from "../../api";
import API_URL from "../../api/url";
import { setCouponCategoryList, setCouponCategoryDetail, setCouponList,setCouponDetail,setTestCodeList,setBtlOffersList,setBtlCustomerList} from "../Slices/couponNewSlice";

export const fetchCouponApi = (data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.COUPON_LIST, data);
        dispatch(setCouponList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const addCouponApi = (data, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.COUPON_ADD, data);
        navigate("/coupons");
        notification["success"]({
            message: "Coupon added successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};

export const updateCouponApi = (data, id, navigate) => async (dispatch) => {
    try {
        const res = await Api.put(`${API_URL.COUPON_UPDATE}/${id}`, data);
        navigate("/coupons");
        notification["success"]({
            message: "Coupon updated successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const fetchCouponByIdApi = (data, id) => async (dispatch) => {
    try {
        const res = await Api.post(`${API_URL.COUPON_DETAIL}/${id}`);
        dispatch(setCouponDetail(res.data.Data));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchCouponCategoryApi = (data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.COUPON_CATEGORY, data);
        dispatch(setCouponCategoryList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const addCouponCategoryApi = (data, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.COUPON_CATEGORY_ADD, data);
        navigate("/coupon-category");
        notification["success"]({
            message: "Coupon Category created successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const updateCouponCategoryApi = (data, id, navigate) => async (dispatch) => {
    try {
        const res = await Api.put(`${API_URL.COUPON_CATEGORY_UPDATE}/${id}`, data);
        navigate("/coupon-category");
        notification["success"]({
            message: "Coupon Category updated successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const fetchCouponCategoryByIdApi = (data, id) => async (dispatch) => {
    try {
        const res = await Api.post(`${API_URL.COUPON_CATEGORY_DETAIL}/${id}`);
        dispatch(setCouponCategoryDetail(res.data.Data));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchBtlOffersApi = (data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.BTL_OFFERS, data);
        dispatch(setBtlOffersList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const addBtlOfferApi = (data, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.COUPON_ADD, data);
        navigate("/btl");
        notification["success"]({
            message: "Offer added successfully"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const updateBtlOfferApi = (data, id, navigate) => async (dispatch) => {
    try {
        const res = await Api.put(`${API_URL.COUPON_UPDATE}/${id}`, data);
        navigate("/btl");
        notification["success"]({
            message: "Offer updated successfully"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const fetchTestCode = (data) => async (dispatch) => {
    try {
        const res = await Api.get(API_URL. BTL_TESTCODE, data);
        dispatch(setTestCodeList({ list: res.data.Data, total: res.data.TotalRecord}));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchBtlCustomerList = (data,limit, offset) => async (dispatch) => {
    try {
        data.Limit = limit;
        data.Offset = offset;   
        const res = await Api.post(API_URL.BTL_CUSTOMERS, data);
        dispatch(setBtlCustomerList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const updatBtlStatusApi = (data, id) => async (dispatch) => {
    try {
        const res = await Api.put(`${API_URL.BTL_OFFERS_STATUS_UPDATE}/${id}`, data);
        notification["success"]({
            message: "Offer Status updated successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
