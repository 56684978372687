import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row, Select, Grid } from "antd";
import CustomEditor from "../../component/CustomEditor/CustomEditor";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { GetLoggedInUser } from "../../utils";
import { useNavigate } from "react-router-dom";
import HeadingBack from "../../component/HeadingBack";
import FileUpload from "./FileUpload";
import { BannerServices } from "../../redux/Services";
import { ReportDownloadServices } from "../../redux/Services";
import { usePermission } from "../../hooks/usePermission";
import { AutoComplete } from "antd";

const { Option } = Select;
const AddReport = () => {
  const permission = usePermission("Manage Download Report", "Add");
  var newOptions = [];

  const [file, setFile] = useState("");
  const [fileBanner, setfileBanner] = useState("");
  const [codeValue, setCodeValue] = useState("");
  let LOG_IN_USER = GetLoggedInUser();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mutate: addManageDownloadReport } = useMutation((data) =>
    dispatch(ReportDownloadServices.addManageDownloadReportApi(data, navigate))
  );
  const { mutate: BannerImageAdd } = useMutation((data) =>
    dispatch(BannerServices.BannerImageAddApi(data))
  );
  const handleUpload = (file) => {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("dir_location", "download-report");
    BannerImageAdd(formData, {
      onSuccess: (res) => {
        var fileName = res.data?.data?.key;
        setFile(fileName);
      },
    });
  };
  const handleUploadBanner = (file) => {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("dir_location", "download-report");
    BannerImageAdd(formData, {
      onSuccess: (res) => {
        var fileName = res.data?.data?.key;
        setfileBanner(fileName);
      },
    });
  };
  useEffect(() => {
    form.setFieldsValue({
      Logo: file,
      Banner: fileBanner,
    });
  }, [file, fileBanner]);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      let _data = { ...values };
      _data.UserId = LOG_IN_USER?.id;

      addManageDownloadReport(_data);
    });
  };

  // console.log(zoneOptions)

  return (
    <>
      <HeadingBack title={"Add Download Report"} subHeading="" />
      <div className="p-20">
        <Form
          form={form}
          name="basic"
          layout={"vertical"}
          autoComplete="off"
          initialValues={{ Status: "1" }}
        >
          <Row gutter={[24, 0]}>
            <Col lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label="Name"
                name="Name"
                rules={[{ required: true, message: "Name is required" }]}
              >
                <Input
                  autoComplete="new-off"
                  className="form-control"
                  placeholder="Name"
                />
              </Form.Item>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label="Code"
                name="Code"
                rules={[
                  {
                    required: true,
                    message: "Code is required",
                  },
                  {
                    pattern: /^[0-9a-zA-Z\-]+$/,
                    message: 'Code can only contain hyphen and integer characters',
                  },
                ]}
              >
                <Input
                  autoComplete="new-off"
                  className="form-control"
                  placeholder="Code"
                  onChange={(e) => {
                    form.setFieldsValue({ Code: e.target.value });
                    setCodeValue(e.target.value);
                  }}
                />
              </Form.Item>
              <div className="logo-hyperlink">
                <p>
                  <strong>Logo Hyperlink:</strong> https://www.lalpathlabs.com/our-subsidiaries/{codeValue}
                </p>
              </div>
            </Col>

            <Col lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label="Logo"
                name="Logo"
                rules={[{ required: true, message: "Logo is required" }]}
              >
                <FileUpload
                  file={file}
                  setFile={setFile}
                  handleUpload={handleUpload}
                />
                <div className="recom-t">
                  <p>Recommended Size:200 × 65 px</p>
                </div>
              </Form.Item>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label="Banner"
                name="Banner"
                rules={[{ required: true, message: "Banner is required" }]}
              >
                <FileUpload
                  file={fileBanner}
                  setFile={setfileBanner}
                  handleUpload={handleUploadBanner}
                />
                <div className="recom-t">
                  <p>Recommended Size:1068 × 600 px</p>
                </div>
              </Form.Item>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label="Status"
                name="Status"
                rules={[{ required: true, message: "Status is required" }]}
              >
                <Select
                  className="from-control text-capitalize"
                  placeholder="Select Status"
                  autoComplete="new-off"
                  name="Status"
                >
                  <Option className="text-capitalize" value={"0"}>
                    Inactive
                  </Option>
                  <Option className="text-capitalize" value={"1"}>
                    Active
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            <Col lg={24} md={24} sm={24} xs={24}>
              <Form.Item label="Details" name="Details">
                <CustomEditor
                  value={form.getFieldValue("Details")}
                  onEditorChange={(value) =>
                    form.setFieldsValue({ Details: value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="text-center">
            <button
              key="submit"
              type="submit"
              className="gradient-button w-auto"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddReport;
