import Api from "../../api";
import API_URL from "../../api/url";
import { setFellowshipDetails, setFellowshipList } from "../Slices/FellowshipSlice";

export const fetchFellowshipListApi = (data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.FELOWSHIP_LIST, data);
        dispatch(setFellowshipList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};

export const fetchFellowshipDetailsApi = (id) => async (dispatch) => {
    try {
        var url = API_URL.FELOWSHIP_DETAILS + '/'+ id;
        const res = await Api.post(url);
        dispatch(setFellowshipDetails(res.data.Data));
        return res;
    } catch (error) {
        return error
    }
};

