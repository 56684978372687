import { Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeadingBack from '../../../component/HeadingBack';
import SvgIcon from '../../../const/SvgIcon';
import { usePermission } from '../../../hooks/usePermission';
import { UserServices } from '../../../redux/Services';

const Users = () => {
    const permission = usePermission("Users", "List");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const userList = useSelector(state => state.user.userList || []);
    const totalUser = useSelector(state => state.user.totalUser || "");
    const { mutate: getUserList, isLoading } = useMutation((data) => dispatch(UserServices.fetchUsersApi(data)));
    const { mutate: updateUserStatus } = useMutation(({ data, id }) => dispatch(UserServices.updateUserStatusApi(data, id)));

    useEffect(() => {
        getUserList({});
        return () => { }
    }, [])

    const handleUpdateStatus = (status, id) => {
        let data = { Status: status ? 0 : 1, userId: id }
        updateUserStatus({ data, id }, {
            onSuccess: (res) => {
                if (res.status === 200) {
                    getUserList();
                }
            }
        });
    }

    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
            className:'table_first_col',
            render: (value, item, index) => (page - 1) * 10 + index + 1
        },
        {
            title: 'Name',
            dataIndex: 'Name',
            key: 'Name',
            className: 'text-capitalize',
        },
        {
            title: 'Email',
            dataIndex: 'Email',
            key: 'Email',
            width: '250px'
        },
        {
            title: 'Mobile',
            dataIndex: 'Mobile',
            key: 'Mobile',
        },
        {
            title: 'Role',
            dataIndex: 'Role',
            key: 'Role',
            className: 'text-capitalize',
            render: (value, item, index) => {
                return (
                    <span>{item?.Role?.role}</span>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            className: 'table_status_col',
            render: (value, item, index) => {
                return (
                    <span className={item?.Status ? "status_active" : "status_inactive"} onClick={() => handleUpdateStatus(item?.Status, item?.UserId)}>{item?.Status ? "Active" : "Inactive"}</span>
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            className: 'table_action_col',
            render: (value, item, index) => {
                return (
                    <Tooltip title="Edit">
                        <span className='cursor-pointer' onClick={() => navigate(`/user/edit/${item?.UserId}`)}><SvgIcon.Edit className="scale-icon edit-icon" /></span>
                    </Tooltip>
                )
            }
        },
    ];
    return (
        <div>
            <div className='flex'>
                <div>
                    <HeadingBack title={"Users"} subHeading={`Total ${totalUser || 0} Users`} />
                </div>
                <div className='flex1 flex justify-content-end align-items-center'>
                    <Tooltip title="Add"><span className='jop_btn' onClick={() => navigate("/user/add")}>Add<SvgIcon.Add width={40} height={40}  className="cursor-pointer" /></span></Tooltip>
                </div>
            </div>
            <div className='tableResponsive mt-10'>
                <Table
                    rowKey='UserId'
                    className={isLoading ? 'table antd_table no_data_found_hide' : 'table antd_table'}
                    bordered
                    columns={columns}
                    dataSource={Array.isArray(userList) && userList?.length > 0 ? userList : []}
                    pagination={{
                        current: page,
                        pageSize: 10,
                        onChange(current, pageSize) {
                            setPage(current);
                        },
                        hideOnSinglePage: true
                    }}
                />
            </div>
        </div>
    )
}

export default Users