import React, { useEffect } from 'react'
import { Form, Input, Select, Row, Col } from 'antd'
import { GetLoggedInUser } from '../../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { CouponServices, FaqServices } from '../../../redux/Services';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import HeadingBack from '../../../component/HeadingBack';
import { usePermission } from '../../../hooks/usePermission';
const { Option } = Select;

const EditPromotionCategory = () => {
    const permission = usePermission("Coupon Management", "Edit");
    let LOG_IN_USER = GetLoggedInUser();
    const { couponCategoryId } = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const couponCategoryData = useSelector(state => state.coupon.CouponCategoryDetail || {});
    const { mutate: fetchCouponCategoryById } = useMutation((data) => dispatch(CouponServices.fetchCouponCategoryByIdApi(data, couponCategoryId)));
    const { mutate: updateCouponCategory } = useMutation((data) => dispatch(CouponServices.updateCouponCategoryApi(data, couponCategoryId, navigate)));
    useEffect(() => {
        if (couponCategoryId) {
            fetchCouponCategoryById({});
        }
        return () => { }
    }, [couponCategoryId])

    useEffect(() => {
        if (couponCategoryData && Object.keys(couponCategoryData)?.length > 0) {
            form.setFieldValue('Name', couponCategoryData?.Name);
            form.setFieldValue('Slug', couponCategoryData?.Slug);
            form.setFieldValue('Status', couponCategoryData?.Status);
        }
    }, [couponCategoryData])

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            if (couponCategoryId) {
                let _data = { ...values };
                _data.UserId = LOG_IN_USER?.id;
                updateCouponCategory(_data)
            }
        })
    }
    return (
        <>
         <HeadingBack title={"Promotions & Discounts Category Edit"} subHeading="" />
            <div className='p-20'>
            <Row>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        < Form
                            form={form}
                            name="basic"
                            layout={"vertical"}
                            autoComplete="off"
                            initialValues={{ Status: "1" }}
                        >
                            <Row gutter={[24, 0]}>
                            <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Category"
                                name="Name"
                                rules={[{ required: true, message: 'Category is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Category' />
                            </Form.Item>
                            </Col>
                            <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Slug"
                                name="Slug"
                                rules={[{ required: true, message: 'Slug is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Slug' />
                            </Form.Item>
                            </Col>
                            <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Type"
                                name="Type"
                                rules={[{ required: true, message: 'Type is required' }]}
                            >
                              <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Type'
                                    autoComplete="new-off"
                                    name="Select Type"
                                >
                                    <Option className='text-capitalize' value={"1"}>Website</Option>
                                    <Option className='text-capitalize' value={"2"}>Others</Option>
                                    <Option className='text-capitalize' value={"3"}>Both</Option>
                                </Select>
                            </Form.Item>
                            </Col>
                            <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                    name="Status"
                                >
                                    <Option className='text-capitalize' value={0}>Inactive</Option>
                                    <Option className='text-capitalize' value={1}>Active</Option>
                                </Select>
                            </Form.Item>
                            </Col>
                            </Row>
                            
                            <div className='text-center'>
                                <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit}>
                                Update
                                </button>
                            </div>
                        </Form >
                    </Col>
                </Row>
            </div >
        </>
    )
}

export default EditPromotionCategory