import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    SeoList: [],
    TotalRecord: '',
    SeoDetails: {},
  
}

export const SeoSlice = createSlice({
    name: 'seo',
    initialState,
    reducers: {
        setSeoList: (state, action) => {
            state.SeoList = action.payload.list;
          state.TotalRecord = action.payload.total;
        },
      
        setSeoDetails: (state, action) => {
            state.SeoDetails = action.payload;
        },
    },
})

export const { setSeoList, setSeoDetails} = SeoSlice.actions

export default SeoSlice.reducer