import { Col, Input, Row, Select } from 'antd'
import React, { useState, useEffect } from 'react'
import moment from 'moment';
const { Option } = Select;

const TicketFilter = ({ filter, setFilter, setPage, setOffset }) => {
    const [values, setValues] = useState({
        Subject: "",
        FromDate: "",
        ToDate: "",
        IsActive: "",
    });

    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value });
    }
    const handleFilter = () => {
        setFilter({ ...filter, ...values });
        setPage(1);
        setOffset(0);
    }
    const handleFilterReset = () => {
        setFilter({});
        setValues({
            ...values,
            Subject: "",
            FromDate: "",
            ToDate: "",
            IsActive: "",
        })
        setPage(1);
    }

    return (

        <div className='table_filter'>
            <Row gutter={[24, 20]} className="ml-10">
                <Col md={6} sm={4} xs={24}>
                    <strong> <p className='mb-2'>Subject</p></strong>
                    <Input
                        type="text"
                        autoComplete="new-off"
                        className='form-control'
                        onChange={(e) => handleChange("Subject", e.target.value)}
                        placeholder='Subject'
                        value={values?.Subject}
                    />
                </Col>
                <Col md={5} sm={12} xs={24}>

                    <strong>  <p className='mb-2'>From Date</p></strong>
                    <Input

                        type="date"
                        id="fromdate"
                        autoComplete="new-off"
                        className='form-control'
                        onChange={(e) => handleChange("FromDate", e.target.value)}
                        max={moment().format("YYYY-MM-DD")}
                        placeholder='From Date'
                        value={values?.FromDate}
                    />

                </Col>

                <Col md={5} sm={12} xs={24}>
                    <strong><p className='mb-2'>To Date</p></strong>
                    <Input
                        type="date"
                        id="todate"
                        autoComplete="todate"
                        className='form-control'
                        onChange={(e) => handleChange("ToDate", e.target.value)}
                        min={values?.FromDate}
                        placeholder='To Date'
                        value={values?.ToDate}
                    />
                </Col>

                <Col md={4} sm={12} xs={24}>
                    <strong><p className='mb-2'>Status</p></strong>
                    <Select
                        className='text-capitalize w-100'
                        placeholder='Select Status'
                        autoComplete="new-off"
                        onChange={(value) => handleChange("IsActive", value)}
                        value={values?.IsActive}
                    >
                        <Option className='text-capitalize' value="">All</Option>
                        <Option className='text-capitalize' value={"1"}>New</Option>
                        <Option className='text-capitalize' value={"2"}>InProgress</Option>
                        <Option className='text-capitalize' value={"3"}>InTesting</Option>
                        <Option className='text-capitalize' value={"4"}>Completed</Option>
                        <Option className='text-capitalize' value={"5"}>Parked</Option>
                        <Option className='text-capitalize' value={"6"}>Revoke</Option>
                        <Option className='text-capitalize' value={"7"}>Re Open</Option>
                    </Select>
                </Col>
                <Col md={3} sm={12} xs={24}>
                    <div className='flex gap10 mt-20'>
                        <button className='filter-button' onClick={() => handleFilter()}>Filter</button>
                        <button className='clear-button' onClick={() => handleFilterReset()}>Reset</button>
                    </div>
                </Col>
            </Row>
        </div >
    )
}

export default TicketFilter