import { Upload } from 'antd';
import {
    FileExcelTwoTone,
    FilePdfTwoTone,
    FileWordTwoTone,
    LoadingOutlined,
    PictureTwoTone,
    PlusOutlined,
    PaperClipOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react'
import API_URL from '../../api/url';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { BannerServices } from '../../redux/Services';
import { getFileDir } from '../../utils';

const AttachmentUpload = ({ file, setFile, handleChange, accept }) => {
    const dispatch = useDispatch();
    const [fileList, setFileList] = useState([]);

    const { mutate: BannerImageAdd, isLoading } = useMutation((data) => dispatch(BannerServices.BannerImageAddApi(data)));
    const onPreview = async (file) => {
        let src = file.url;
        window.open(src, "_blank");
    };
    const onRemove = () => {
        setFile("");
        setFileList([]);
    }
    useEffect(() => {
        if (file) {
            setFileList([{
                crossOrigin: "anonymous",
                uid: '-1',
                name: "attachment",
                status: 'done',
                url: API_URL.S3_BANNER_BASE + file,
                thumbUrl: API_URL.S3_BANNER_BASE + file,
                type: file.split('.').pop()
            }])
        }
    }, [file])

    const handleUpload = (file) => {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("dir_location", `investor/${getFileDir(file?.name)}`);
        BannerImageAdd(formData, {
            onSuccess: (res) => {
                var fileName = res.data?.data?.key || res.data?.data?.Key;
                handleChange(fileName);
            },
        })
    }
    const HandleIconRender = (file, listType) => {
        const fileSufIconList = [
            {
                type: <FilePdfTwoTone />,
                suf: ['pdf'],
            },
            {
                type: <FileExcelTwoTone />,
                suf: ['xlsx', 'xls', 'csv'],
            },
            {
                type: <FileWordTwoTone />,
                suf: ['doc', '.docx'],
            },
            {
                type: <PictureTwoTone />,
                suf: ['webp', 'svg', 'png', 'gif', 'jpg', 'jpeg', 'jfif', 'bmp', 'dpg'],
            },
        ];
        let icon = <PaperClipOutlined />;
        if (isLoading) {
            icon = <LoadingOutlined />;
        } else {
            if (listType === 'picture' || listType === 'picture-card') {
                if (listType === 'picture-card' && isLoading) {
                    icon = <LoadingOutlined />;
                } else {
                    fileSufIconList.forEach((item) => {
                        if (item.suf.includes(file?.type)) {
                            icon = item.type;
                        } else {
                            <PaperClipOutlined />
                        }
                    });
                }
            } else {
                icon = <PaperClipOutlined />
            }
        }
        return icon;
    }
    return (
        <Upload
            className='upload_img'
            accept={accept}
            multiple={false}
            listType="picture-card"
            onPreview={onPreview}
            onRemove={onRemove}
            customRequest={({ file }) => {
                handleUpload(file)
            }}
            iconRender={HandleIconRender}
            fileList={fileList}
        >
            {isLoading ? <LoadingOutlined /> : fileList.length < 1 && '+ Upload'}
        </Upload>
    )
}

export default AttachmentUpload