import { configureStore } from '@reduxjs/toolkit'
import commonSlice from './Slices/commonSlice'
import dashboardSlice from './Slices/dashboardSlice'
import faqSlice from './Slices/faqSlice'
import userSlice from './Slices/userSlice'
import jobApplicationsSlice from './Slices/jobApplicationsSlice'
import departmentSlice from './Slices/departmentSlice'
import TestdepartmentSlice from './Slices/TestdepartmentSlice'
import jobAddSlice from './Slices/jobPostSlice'
import SeoSlice from './Slices/SeoSlice'
import InvestorSlice from './Slices/InvestorSlice'
import TicketSlice from './Slices/TicketSlice'
import InvestorItemSlice from './Slices/InvesotroItemSlice'
import bannerSlice from './Slices/bannerSlice'
import couponSlice from './Slices/couponNewSlice'
import WhatsNewbannerSlice from './Slices/whatsNewSlice'
import teamSlice from './Slices/teamSlice'
import BlogAttachmentSlice from './Slices/blogAttachmentSlice'
import DownloadReportSlice from './Slices/ReportDownloadSlice'
import fellowshipSlice from './Slices/FellowshipSlice'
import smsSlice from './Slices/SmsSlice'
import leadSlice from './Slices/leadPageSlice'

const store = configureStore({
    reducer: {
        common: commonSlice,
        user: userSlice,
        faq: faqSlice,
        dashboard: dashboardSlice,
        jobAppliction: jobApplicationsSlice,
        department: departmentSlice,
        banner: bannerSlice,
        Testdepartment: TestdepartmentSlice,
        jobPost: jobAddSlice,
        seo: SeoSlice,
        invesotorCategory: InvestorSlice,
        Ticket: TicketSlice,
        investoritem: InvestorItemSlice,
        coupon: couponSlice,
        whatsNewBanner: WhatsNewbannerSlice,
        team: teamSlice,
        BlogAttachment: BlogAttachmentSlice,
        DownloadReport: DownloadReportSlice,
        FellowShip:fellowshipSlice,
        sms:smsSlice,
        lead:leadSlice,
    },
})
export default store