import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    FellowshipList: [],
    TotalFellowship: '',
    FellowshipDetails: {},
}

export const fellowshipSlice = createSlice({
    name: 'fellowship',
    initialState,
    reducers: {
        setFellowshipList: (state, action) => {
            state.FellowshipList = action.payload.list;
          state.TotalFellowship = action.payload.total;
        },
      
        setFellowshipDetails: (state, action) => {
            state.FellowshipDetails = action.payload;
        },
    },
})

export const { setFellowshipList, setFellowshipDetails} = fellowshipSlice.actions

export default fellowshipSlice.reducer