import { Table,Col, Row, Tooltip } from 'antd';
import React, { useEffect } from 'react'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeadingBack from '../../../component/HeadingBack';
import SvgIcon from '../../../const/SvgIcon';
import { LeadPageServices } from '../../../redux/Services';
import { usePermission } from '../../../hooks/usePermission';

const LeadManageZone = () => {
    const permission = usePermission("Zone Management", "List");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const zoneList = useSelector(state => state.lead.zoneList || []);
    const totalzoneList = useSelector(state => state.lead.totalzoneList || "");
    const { mutate: fetchZoneList, isLoading } = useMutation((data) => dispatch(LeadPageServices.fetchZoneListApi(data)));

    useEffect(() => {
        fetchZoneList({});
        return () => { }
    }, [])
    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
            className:'table_first_col',
            render: (value, item, index) => ++index
        },
        {
            title: 'Zone/Region',
            dataIndex: 'Name',
            key: 'Name',
            className: 'text-capitalize',
            width: 200,
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            className: 'table_status_col',
            render: (value, item, index) => {
                return (
                    <span className={item?.Status ? "status_active" : "status_inactive"}>{item?.Status ? "Active" : "Inactive"}</span>
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            className: 'table_action_col',
            render: (value, item, index) => {
                return (
                    <Tooltip title="Edit">
                        <span className='cursor-pointer' onClick={() => navigate(`/manage-zone/edit/${item?.Id}`)}><SvgIcon.Edit className="scale-icon edit-icon" /></span>
                    </Tooltip>
                )
            }
        }
    ];
    return (
        <div>
            <div className='flex'>
            <div className='w-100'>
                    <HeadingBack title={"Zone/Region"} subHeading={`Total ${zoneList.length || 0} Zone`} />
                </div>
                <Col lg={2} md={24} sm={24} xs={24}>
                <div className='flex justify-content-end align-items-center'>
                    <Tooltip title="Add"><span className='jop_btn' onClick={() => navigate("/manage-zone/add")}>Add<SvgIcon.Add width={40} height={40} className="cursor-pointer" /></span></Tooltip>
                </div>
                </Col>
            </div>
            <div className='tableResponsive mt-10'>
                <Table
                    rowKey='Id'
                    className={isLoading ? 'table antd_table no_data_found_hide' : 'table antd_table'}
                    bordered
                    columns={columns}
                    dataSource={Array.isArray(zoneList) && zoneList?.length > 0 ? zoneList : []}
                    pagination={false}
                />
            </div>
        </div>
    )
}

export default LeadManageZone