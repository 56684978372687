import React from 'react'

const SidebarFooter = () => {
    return (
        <div className='siderbar_footer'>
           © {new Date().getFullYear()}
            {" "}
            <a
                href="https://www.lalpathlabs.com"
                target="_blank"
                rel="noreferrer"
            >
                lalpathlabs.com
            </a>
            <br />
            Designed & Developed by
            <br />
            <a
                href="https://abym.in"
                target="_blank"
                rel="noreferrer"
            >AbyM Technology. <br></br>---v1.20.5.1
            </a>
        </div>
    )
}

export default SidebarFooter