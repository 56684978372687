import React, { useEffect, useState } from 'react'
import { Col, Form, Input, Row, Select } from 'antd'
import { useNavigate, useParams } from 'react-router-dom';
// import CustomEditor from '../../../component/CustomEditor/CustomEditor';
import { useDispatch, useSelector } from 'react-redux';
// import { SeoServices } from '../../../redux/Services';
// import { FaqServices } from '../../../redux/Services';
import { InvestorCategoriesServices } from '../../../redux/Services';
import { useMutation } from 'react-query';
import { GetLoggedInUser } from '../../../utils';
import HeadingBack from '../../../component/HeadingBack';
import { GetToken } from "../../../utils";
import BASEURL from "../../../api/url";
import axios from "axios";
import { usePermission } from '../../../hooks/usePermission';
const { Option } = Select;
const EditInvestorSubCategory = () => {
    const permission = usePermission("Seo Pages", "Edit");
    const { Id } = useParams();
    const queryParams = new URLSearchParams(window.location.search);
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [categoryH1Data, setCategoryH1Data] = useState([]);
    const [categoryH2Data, setCategoryH2Data] = useState([]);
    const [categoryH3Data, setCategoryH3Data] = useState([]);
    const [ParentId, setParentId] = useState(0);
    const [type, setType] = useState("H1");
    const navigate = useNavigate();
    const InvestorCategoryDetails = useSelector(state => state.invesotorCategory.InvestorCategoryDetails?.list || {});
    const InvestorCategoryData = useSelector(state => state.invesotorCategory.InvestorCategoryDetails || {});
    const { mutate: fetchInviestroCategoryById } = useMutation((data) => dispatch(InvestorCategoriesServices.fetchInvestorCategroyDetailsApi(Id)));
    const InvestorCategoryList = useSelector(state => state.invesotorCategory.InvestorCategoryList || []);
    const { mutate: InvestorCategoryLists } = useMutation((data) => dispatch(InvestorCategoriesServices.fetchInvestorListApi(data)));
    const { mutate: updateInvestorCategory, isLoading: addLoading } = useMutation((data) => dispatch(InvestorCategoriesServices.InvestorSubCategoryUpdateApi(data, Id, queryParams, navigate)));

    useEffect(() => {
        fetchInviestroCategoryById(Id);
        return () => { }
    }, [])
    // console.log(InvestorCategoryData)
    useEffect(() => {
        InvestorCategoryLists({ IsActive: "1" });
        return () => { }
    }, [])

    useEffect(() => {
        if (InvestorCategoryDetails && Object.keys(InvestorCategoryDetails)?.length > 0) {
            var category = "";
            var category_h1 = "";
            var category_h2 = "";
            var category_h3 = "";
            if (InvestorCategoryDetails?.ParentCategory?.Id) {
                category = InvestorCategoryDetails?.ParentCategory?.Id;
                category_h1 = InvestorCategoryDetails?.Id;
                category_h2 = "";
                category_h3 = "";
            }
            if (InvestorCategoryDetails?.ParentCategory?.ParentCategory?.Id) {
                category = InvestorCategoryDetails?.ParentCategory?.ParentCategory?.Id;
                category_h1 = InvestorCategoryDetails?.ParentCategory?.Id;
                category_h2 = InvestorCategoryDetails?.Id;
                category_h3 = "";
            }
            if (InvestorCategoryDetails?.ParentCategory?.ParentCategory?.ParentCategory?.Id) {
                category = InvestorCategoryDetails?.ParentCategory?.ParentCategory?.ParentCategory?.Id;
                category_h1 = InvestorCategoryDetails?.ParentCategory?.ParentCategory?.Id;
                category_h2 = InvestorCategoryDetails?.ParentCategory?.Id;
                category_h3 = InvestorCategoryDetails?.Id;
            }
            if (category_h2) {
                setType("H2");
                getSubcategories(category, 'CategoryH1');
            }
            if (category_h3) {
                setType("H3");
                getSubcategories(category_h1, 'CategoryH2');
            }
            form.setFieldValue('category_h2_id', category_h2);
            form.setFieldValue('category_h1_id', category_h1);
            form.setFieldValue('category_id', category);
            form.setFieldValue('ParentId', InvestorCategoryData?.list.ParentId);
            form.setFieldValue('Category', InvestorCategoryData?.list.Category);
            form.setFieldValue('Type', InvestorCategoryData?.list.Type);
            form.setFieldValue('Slug', InvestorCategoryData?.list.Slug);
            form.setFieldValue('Status', InvestorCategoryData?.list.Status);
            setParentId(InvestorCategoryDetails?.ParentId);
        }
    }, [InvestorCategoryDetails])

    /*  useEffect(() => {
         if (InvestorCategoryData && Object.keys(InvestorCategoryData)?.length > 0) {
             var category_id = 0;
             var category_h1_id = 0;
             var category_h2_id = 0;
             if (InvestorCategoryData?.list.ParentCategory) {
                 var category_id = InvestorCategoryData?.list.ParentCategory.Id;
                 if (InvestorCategoryData?.list.ParentCategory?.ParentCategory) {
                     var category_id = InvestorCategoryData?.list?.ParentCategory?.ParentCategory.Id;
                     if (InvestorCategoryData?.list.ParentCategory?.ParentCategory?.ParentCategory) {
                         var category_id = InvestorCategoryData?.list?.ParentCategory?.ParentCategory?.ParentCategory.Id;
                         if (InvestorCategoryData?.list.ParentCategory?.ParentCategory?.ParentCategory?.ParentCategory) {
                             var category_id = InvestorCategoryData?.list?.ParentCategory?.ParentCategory?.ParentCategory?.ParentCategory.Id;
                         }
                     }
 
                 }
             }
             if (InvestorCategoryData?.list.ParentCategory && category_id != InvestorCategoryData?.list.ParentCategory.Id) {
                 var category_h1_id = InvestorCategoryData?.list.ParentCategory.Id;
                 if (InvestorCategoryData?.list?.ParentCategory?.ParentCategory && category_id != InvestorCategoryData?.list.ParentCategory?.ParentCategory.Id) {
                     var category_h1_id = InvestorCategoryData?.list?.ParentCategory?.ParentCategory.Id;
                     if (InvestorCategoryData?.list?.ParentCategory?.ParentCategory?.ParentCategory && category_id != InvestorCategoryData?.list.ParentCategory?.ParentCategory?.ParentCategory.Id) {
                         console.log(3);
                         var category_h1_id = InvestorCategoryData?.list?.ParentCategory?.ParentCategory?.ParentCategory.Id;
                     }
                 }
             }
             if (InvestorCategoryData?.list.ParentCategory && category_h1_id != InvestorCategoryData?.list.ParentCategory.Id) {
                 var category_h2_id = InvestorCategoryData?.list.ParentCategory.Id;
                 if (InvestorCategoryData?.list?.ParentCategory?.ParentCategory && category_h1_id != InvestorCategoryData?.list.ParentCategory?.ParentCategory.Id) {
                     var category_h2_id = InvestorCategoryData?.list?.ParentCategory?.ParentCategory.Id;
                 }
             }
             if (category_id > 0) {
                 getSubcategories(category_id, 'CategoryH1');
             }
             if (category_h1_id > 0) {
                 getSubcategories(category_h1_id, 'CategoryH2');
             }
             form.setFieldValue('category_h2_id', category_h2_id);
             form.setFieldValue('category_h1_id', category_h1_id);
             form.setFieldValue('category_id', category_id);
             form.setFieldValue('ParentId', InvestorCategoryData?.list.ParentId);
             form.setFieldValue('Category', InvestorCategoryData?.list.Category);
             form.setFieldValue('Type', InvestorCategoryData?.list.Type);
             form.setFieldValue('Slug', InvestorCategoryData?.list.Slug);
             form.setFieldValue('Status', InvestorCategoryData?.list.Status);
             setParentId(InvestorCategoryData?.list.ParentId);
         }
     }, [InvestorCategoryData]) */

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            if (updateInvestorCategory) {
                let _data = { ...values };
                _data.UserId = LOG_IN_USER?.id;
                _data.ParentId = ParentId;
                updateInvestorCategory(_data);
            }
        })
    }
    const getSubcategories = (parentId, type, manual = false) => {
        const payload = {
            ParentId: parentId,
            /* IsActive: 1, */
        }
        axios({
            method: 'post',
            url: BASEURL.BASEURL + 'api/investor/category/list',
            data: payload, // you are sending body instead
            headers: {
                'token': GetToken(),
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            if (type == 'CategoryH1') {
                if (manual == true) {
                    form.setFieldValue('category_h1_id', "");
                    form.setFieldValue('category_h2_id', "");
                    form.setFieldValue('category_h3_id', "");
                    setCategoryH2Data([]);
                    setCategoryH3Data([]);
                }
                setCategoryH1Data(response.data.Data);
            }
            if (type == 'CategoryH2') {
                if (manual == true) {
                    form.setFieldValue('category_h2_id', "");
                    form.setFieldValue('category_h3_id', "");
                    setCategoryH3Data([]);
                }
                setCategoryH2Data(response.data.Data);
            }
            if (type == 'CategoryH3') {
                if (manual == true) {
                    form.setFieldValue('category_h3_id', "");
                }
                setCategoryH3Data(response.data.Data);
            }
        })
            .catch((error) => {
                alert(error)
            })
    }
    return (
        <>
            <HeadingBack title={"Investor Sub Category Edit"} subHeading="" />
            <div className='p-20'>
                < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                >
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Category"
                                name="category_id"
                                rules={[{ required: true, message: 'Category is required' }]}
                                disabled={true}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Category'
                                    autoComplete="new-off"
                                    name="name"
                                    onChange={(e) => getSubcategories(e, 'CategoryH1', true)}
                                    disabled={true}
                                >
                                    <Option className='text-capitalize' value={""}>Select Category</Option>
                                    {InvestorCategoryList?.length > 0 && InvestorCategoryList?.map((item, idx) =>
                                        <Option key={idx} className='text-capitalize' value={item.Id}>{item.Category}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col >
                        {(categoryH1Data && categoryH1Data.length > 0) ? <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Sub Category (H1)"
                                name="category_h1_id"
                                rules={[{ required: true, message: 'Category is required' }]}
                                disabled={true}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Category'
                                    autoComplete="new-off"
                                    name="name"
                                    onChange={(e) => getSubcategories(e, 'CategoryH2', true)}
                                    disabled={true}
                                >
                                    <Option className='text-capitalize' value={""}>Select Category</Option>
                                    {categoryH1Data?.length > 0 && categoryH1Data?.map((item, idx) =>
                                        <Option key={idx} className='text-capitalize' value={item.Id}>{item.Category}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col > : ''}
                        {(categoryH2Data && categoryH2Data.length > 0) ? <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Sub Category (H2)"
                                name="category_h2_id"
                                rules={[{ required: true, message: 'Category is required' }]}
                                disabled={true}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Category'
                                    autoComplete="new-off"
                                    name="category_h2_id"
                                    onChange={(e) => getSubcategories(e, 'CategoryH3', true)}
                                    disabled={true}
                                >
                                    <Option className='text-capitalize' value={""}>Select Category</Option>
                                    {categoryH2Data?.length > 0 && categoryH2Data?.map((item, idx) =>
                                        <Option key={idx} className='text-capitalize' value={item.Id}>{item.Category}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col > : ''}
                        {(categoryH3Data && categoryH3Data.length > 0) ? <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Sub Category (H3)"
                                name="category_h3_id"
                                rules={[{ required: true, message: 'Category is required' }]}
                                disabled={true}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Category'
                                    autoComplete="new-off"
                                    name="category_h3_id"
                                    disabled={true}
                                >
                                    <Option className='text-capitalize' value={""}>Select Category</Option>
                                    {categoryH3Data?.length > 0 && categoryH3Data?.map((item, idx) =>
                                        <Option key={idx} className='text-capitalize' value={item.Id}>{item.Category}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col > : ''}
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label={`Sub Category (${type || ""})`}
                                name="Category"
                                rules={[{ required: true, message: 'Sub Category is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder={`Sub Category (${type || ""})`} />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Type"
                                name="Type"
                                rules={[{ required: true, message: 'Type is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Type'
                                    autoComplete="new-off"
                                    name="Type"
                                >
                                    <Option className='text-capitalize' value={1}>List</Option>
                                    <Option className='text-capitalize' value={2}>Accordion</Option>
                                    <Option className='text-capitalize' value={3}>Box</Option>
                                    <Option className='text-capitalize' value={4}>Textarea</Option>
                                </Select>
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                name="Slug"
                                label={`Sub Category Slug (${type || ""})`}
                                rules={[{ required: true, message: 'Slug is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder={`Sub Category Slug (${type || ""})`} />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                    name="Status"
                                >
                                    <Option className='text-capitalize' value={0}>Inactive</Option>
                                    <Option className='text-capitalize' value={1}>Active</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>
                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit} disabled={addLoading}>
                            Update
                        </button>
                    </div>
                </Form >
            </div>
        </>
    )
}

export default EditInvestorSubCategory