import { createSlice } from '@reduxjs/toolkit'

const initialState = { 
    TicketList: [],
    uploadedAttchment: {},
    TotalRecord: '',
    TicketDetails: {},
}

export const TicketSlice = createSlice({
    name: 'Ticket',
    initialState,
    reducers: {
      
        setAddattachmentApi: (state, action) => {
            state.uploadedAttchment = action.payload;
        }, 
        setTicketList: (state, action) => {
            state.TicketList = action.payload.list;
          state.TotalRecord = action.payload.total;
        },
        setTicketDetails: (state, action) => {
            state.TicketDetails = action.payload;
        },
    },
})

export const {  setAddattachmentApi, setTicketList,setTicketDetails} = TicketSlice.actions

export default TicketSlice.reducer