import { Col, Row } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'

const Boxes = () => {
    const navigate = useNavigate();
    const totalUsers = useSelector(state => state.dashboard.totalUsers || "");
    const totalFaqs = useSelector(state => state.dashboard.totalFaqs || "");
    const TotalJobsAdd = useSelector(state => state.dashboard.TotalJobs || "");
    const totalJobApp = useSelector(state => state.jobAppliction.totalJobApp || 0); 
    console.log(totalJobApp);
    return (
        <Row gutter={[0, 20]}>
            <Col span={24}>
                <div className='flex1 p-10 dashboardBox cursor-pointer' onClick={() => navigate("/faqs")}>
                    <div className='flex justify-content-between align-item-center'>
                        <div className='relative boxHeadingTotal fw-600'>Total FAQs</div>
                    </div>
                    <div className='flex justify-content-end align-item-center'>
                        <div className='priceText'>{totalFaqs || 0}</div>
                    </div>
                </div>
            </Col>
            <Col span={24}>
                <div className='flex1 p-10 dashboardBox cursor-pointer' onClick={() => navigate("/users")}>
                    <div className='flex justify-content-between align-item-center'>
                        <div className='relative boxHeadingRisk fw-600'>Total Users</div>
                    </div>
                    <div className='flex justify-content-end align-item-center'>
                        <div className='priceText color-risk'>{totalUsers || 0}</div>
                    </div>
                </div>
            </Col>
            <Col span={24}>
                <div className='flex1 p-10 dashboardBox cursor-pointer' onClick={() => navigate("/job-applications")}>
                    <div className='flex justify-content-between align-item-center'>
                        <div className='relative boxHeadingTotal fw-600'>Total Job Applications</div>
                    </div>
                    <div className='flex justify-content-end align-item-center'>
                        <div className='priceText'>{totalJobApp || 0}</div>
                    </div>
                </div>
            </Col>
            <Col span={24}>
                <div className='flex1 p-10 dashboardBox cursor-pointer' onClick={() => navigate("/jobs")}>
                    <div className='flex justify-content-between align-item-center'>
                        <div className='relative boxHeadingRisk fw-600'>Total Job Posts</div>
                    </div>
                    <div className='flex justify-content-end align-item-center'>
                        <div className='priceText color-risk'>{TotalJobsAdd || 0}</div>
                    </div>
                </div>
            </Col>



        </Row>
    )
}

export default Boxes