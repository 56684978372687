import React, { useEffect } from 'react'
import { Form, Input, Select, Row, Col } from 'antd'
import { GetLoggedInUser } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { DepartmentServices } from '../../redux/Services';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import HeadingBack from '../../component/HeadingBack';
import { usePermission } from '../../hooks/usePermission';
const { Option } = Select;
const EditDepartment = () => {
    const permission = usePermission("Department Management", "Edit");
    let LOG_IN_USER = GetLoggedInUser();
    const { departmentId } = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const departmentData = useSelector(state => state.department.departmentDetail || {});
    const { mutate: fetchDepartmentDetail } = useMutation((data) => dispatch(DepartmentServices.fetchDepartmentDetailApi(data, departmentId)));
    const { mutate: updateDeepartment, isLoading } = useMutation((data) => dispatch(DepartmentServices.DeepartmentDetailUpdateApi(data, departmentId, navigate)));
    useEffect(() => {
        if (departmentId) {
            fetchDepartmentDetail({});
        }
        return () => { }
    }, [departmentId])

    useEffect(() => {
        if (departmentData && Object.keys(departmentData)?.length > 0) {
            form.setFieldValue('DepartmentName', departmentData?.DepartmentName);
            form.setFieldValue('Status', departmentData?.Status);
        }
    }, [departmentData])

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            if (departmentId) {
                let _data = { ...values };
                _data.UserId = LOG_IN_USER?.id;
                updateDeepartment(_data)
            }
        })
    }
    console.log(isLoading,"lll")
    return (
        <>
            <HeadingBack title={"Department Edit"} subHeading="" />
            <div className='p-20'>
                <Row>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        < Form
                            form={form}
                            name="basic"
                            layout={"vertical"}
                            autoComplete="off"

                        >
                            <Form.Item
                                label="Department Name"
                                name="DepartmentName"
                                rules={[{ required: true, message: 'Department Name is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Department Name' />
                            </Form.Item>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                    name="Status"
                                >
                                    <Option className='text-capitalize' value={0}>Inactive</Option>
                                    <Option className='text-capitalize' value={1}>Active</Option>
                                </Select>
                            </Form.Item>
                            <div className='text-center'>
                                <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit} disabled={isLoading}>
                                    Update
                                </button>
                            </div>
                        </Form >
                    </Col>
                </Row>
            </div >
        </>
    )
}

export default EditDepartment