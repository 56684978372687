import { notification } from "antd";
import Api from "../../api";
import API_URL from "../../api/url";
import { setInvestorCategoryList,setInvestorCategoryList2,setInvestorCategoryList3,setInvestorCategoryList4,setInvestorList,setInvestorListFilter,setInvestorCategroyDetails,setInvestorCategoryList5, setInvestorCategoryList10, setInvestorCategoryList9 } from "../Slices/InvestorSlice"

export const getInvestorCategoryList = async(data) => {
    try {
        const res = await Api.post(API_URL.INVESTOR_CATEGORY_LIST, data);
        return res;
    } catch (error) {
        return error
    }
};
export const fetchInvestorCategoryListApi2 = (data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.INVESTOR_CATEGORY_LIST, data);
        dispatch(setInvestorCategoryList2({ list: res.data.Data }));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchInvestorCategoryListApi3 = (data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.INVESTOR_CATEGORY_LIST, data);
        dispatch(setInvestorCategoryList3({ list: res.data.Data }));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchInvestorCategoryListApi9 = (data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.INVESTOR_CATEGORY_LIST, data);
        dispatch(setInvestorCategoryList9({ list: res.data.Data }));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchInvestorCategoryListApi10 = (data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.INVESTOR_CATEGORY_LIST, data);
        dispatch(setInvestorCategoryList10({ list: res.data.Data }));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchInvestorCategoryListApi6 = (data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.INVESTOR_CATEGORY_LIST, data);
        dispatch(setInvestorCategoryList3({ list: res.data.Data }));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchInvestorCategoryListApi5 = (data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.INVESTOR_CATEGORY_LIST, data);
        dispatch(setInvestorCategoryList5({ list: res.data.Data }));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchInvestorCategoryListApi4 = (data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.INVESTOR_CATEGORY_LIST, data);
        dispatch(setInvestorCategoryList4({ list: res.data.Data }));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchInvestorCategoryListApi = (data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.INVESTOR_CATEGORY_LIST, data);
        dispatch(setInvestorCategoryList({ list: res.data.Data }));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchInvestorListApi = (data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.INVESTOR_CATEGORY_LIST, data);
        dispatch(setInvestorList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};

export const fetchInvestorListDataApi = (limit,offset,data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.INVESTOR_CATEGORY_LIST, data);
        dispatch(setInvestorList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchInvestorListDataApiFilter = (limit,offset,data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.INVESTOR_CATEGORY_LIST, data);
        dispatch(setInvestorListFilter({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};

export const fetchInvestorCategroyDetailsApi = (id) => async (dispatch) => {
    try {
        var url = API_URL.INVESTOR_CATEGORY_DETAILS + '/'+ id;
        const res = await Api.post(url);
        dispatch(setInvestorCategroyDetails({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const InvestorCategoryUpdateApi = (data, id, navigate) => async (dispatch) => {
    try {
        const res = await Api.put(`${API_URL.INVESTOR_CATEGORY_UPDATE}/${id}`, data);
        navigate("/investor-category");
        notification["success"]({
            message: "Investor Category updated successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const addInvesetorCategoryApi = (data, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.INVESTOR_CATEGORY_ADD, data);
        navigate("/investor-category");
        notification["success"]({
            message: "Investor Category add successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const addInvesetorSubCategoryApi = (data, queryString, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.INVESTOR_CATEGORY_ADD, data);
        navigate(`/investor-subcategory?${queryString}`);
        notification["success"]({
            message: "Investor Sub Category added successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const InvestorSubCategoryUpdateApi = (data, id, queryString, navigate) => async (dispatch) => {
    try {
        const res = await Api.put(`${API_URL.INVESTOR_CATEGORY_UPDATE}/${id}`, data);
        navigate(`/investor-subcategory?${queryString}`);
        notification["success"]({
            message: "Investor Sub Category updated successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};

