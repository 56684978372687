import React, { useEffect, useState } from 'react'
import { Col, Form, Input, Row, Select, Grid } from 'antd'
import CustomEditor from '../../component/CustomEditor/CustomEditor';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { GetLoggedInUser } from '../../utils';
import { useNavigate } from 'react-router-dom';
import HeadingBack from '../../component/HeadingBack';
import FileUpload from './FileUpload';
import { BannerServices } from '../../redux/Services';
import { TeamServices } from '../../redux/Services';
import { usePermission } from '../../hooks/usePermission';
import { AutoComplete } from 'antd';

const { Option } = Select;
const AddTeam = () => {
    const permission = usePermission("Team Management", "Add");
    var newOptions = [];

    const [file, setFile] = useState("");
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { mutate: addTeamMember } = useMutation((data) => dispatch(TeamServices.addTeamApi(data, navigate)));
    const { mutate: BannerImageAdd } = useMutation((data) => dispatch(BannerServices.BannerImageAddApi(data)));
    const handleUpload = (file) => {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("dir_location", "team-members");
        BannerImageAdd(formData, {
            onSuccess: (res) => {
                var fileName = res.data?.data?.key;
                setFile(fileName)
            },
        })
    }
    useEffect(() => {
        form.setFieldsValue({
            DisplayPhoto: file,
        })
    }, [file])


    const handleSubmit = () => {
        form.validateFields().then((values) => {
            let _data = { ...values };
            _data.UserId = LOG_IN_USER?.id;
            if (values.Name) {
              } else {
                _data.Name = "BTL Banners";
              }
              addTeamMember(_data)
    
        })
    }

// console.log(zoneOptions)

    return (
        <>
            <HeadingBack title={"Add Team Member"} subHeading="" />
            <div className='p-20'>

                < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                    initialValues={{ Status: "1" }}
                >
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Category"
                                name="Category"
                                initialValues={{ id: "4" }}
                                rules={[{ required: true, message: 'Category is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Category'
                                    autoComplete="new-off"
                                    name="Category"
                                >
                                    <Option className='text-capitalize'>Select Category</Option>
                                    <Option className='text-capitalize'value='1'>Board of Directors</Option>
                                    <Option className='text-capitalize' value='2'>Senior Management</Option>

                                </Select>
          

                            </Form.Item>
                            
                        </Col >
                            <Col lg={12} md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Name"
                                    name="Name"
                                    rules={[{ required: true, message: 'Name is required' }]}
                                >
                                    <Input autoComplete="new-off" className='form-control' placeholder='Name' />
                                </Form.Item>
                            </Col >
                            <Col lg={12} md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Designation"
                                    name="Designation"
                                    rules={[{ required: true, message: 'Designation is required' }]}
                                >
                                    <Input autoComplete="new-off" className='form-control' placeholder='Designation' />
                                </Form.Item>
                            </Col >
                            <Col lg={12} md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Order By"
                                    name="OrderBy"
                                    rules={[{ required: true, message: 'Ordery By is required' }]}
                                >
                                    <Input type ="number" autoComplete="new-off" className='form-control' placeholder='Ordery By' />
                                </Form.Item>
                            </Col >

                            <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                    name="Status"
                                >
                                    <Option className='text-capitalize' value={"0"}>Inactive</Option>
                                    <Option className='text-capitalize' value={"1"}>Active</Option>
                                </Select>
                            </Form.Item>
                        </Col> 
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Image"
                                name="DisplayPhoto"
                                rules={[{ required: true, message: 'Image is required' }]}
                            >
                                <FileUpload file={file} setFile={setFile} handleUpload={handleUpload} />
                                <div className='recom-t'>
                                    <p>Recommended Size:308 × 265</p>
                                </div>
                            </Form.Item>
                        </Col >
                      
                        
                        <Col lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Team Member Profile"
                                name="AboutProfile"
                                rules={[{ required: true, message: 'Team Member Profile is required' }]}
                            >
                                <CustomEditor value={form.getFieldValue("AboutProfile")} onEditorChange={(value) => form.setFieldsValue({ AboutProfile: value })} />
                            </Form.Item>
                       
                        </Col>
                        
                    </Row >
                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit}>
                            Submit
                        </button>
                    </div>
                </Form>
            </div >
        </>
    )
}

export default AddTeam