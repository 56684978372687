import React from 'react'
const LeftSection = () => {
    return (
        <div className='bg-auth bg-login-img'>
            <h1>USING DATA TO YOUR ADVANTAGE</h1>
            <h3>ORGANIZATION WHICH USE ADVANCE ANALYTICS
                SEE A 60% HIGHER INCREASE IN YEAR-ON-YEAR
                REVENUE THAN THOSE THAT DON'T</h3>
            <div>
            </div>
        </div>
    )
}

export default LeftSection