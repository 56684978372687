import React from 'react'
import { Col, Form, Input, Row, Select } from 'antd'
import CustomEditor from '../../../component/CustomEditor/CustomEditor';
import { GetLoggedInUser, onlyMobileInput } from '../../../utils';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { JobPostServices } from '../../../redux/Services';
import HeadingBack from '../../../component/HeadingBack';
import { usePermission } from '../../../hooks/usePermission';

const { Option } = Select;

const AddJob = () => {
    const permission = usePermission("Job Posts", "Add"); 
    onlyMobileInput();
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { mutate: addJobs, isLoading: addLoading } = useMutation((data) => dispatch(JobPostServices.addJobsApi(data, navigate)));
// console.log(addLoading)
    const handleSubmit = () => {
        form.validateFields().then((values) => {
            let _data = { ...values };
            _data.UserId = LOG_IN_USER?.id;
            addJobs(_data)
        })
    }
    // const handleProfileUpload = (e) => {
    //     let file = e.target.files[0];
    //     /* form.setFieldValue('ProfilePhoto', file); */
    // }
    return (
        <>
            <HeadingBack title={"Job Add"} subHeading="" />
            <div className='p-20'>
                < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                >
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Profile"
                                name="JobTitle"
                                rules={[{ required: true, message: 'Profile is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Profile' />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Experience (Years)"
                                name="Experience"
                                rules={[{ required: true, message: 'Experience is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Experience' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Location"
                                name="Location"
                                rules={[{ required: true, message: 'Location is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Location' />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Vacancy"
                                name="JobVacancy"
                                rules={[{ required: true, message: 'Vacancy is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Vacancy' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Salary (Monthly)"
                                name="JobSalary"
                                rules={[{ required: true, message: 'Salary is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Salary' />
                            </Form.Item>
                        </Col >
                        <Col span={24}>
                            <Form.Item
                                label="Job Highlights"
                                name="JobDetails"
                                rules={[{ required: true, message: 'Job Highlights is required' }]}
                            >
                                <CustomEditor value={form.getFieldValue("JobDetails ")} onEditorChange={(value) => form.setFieldsValue({ JobDetails: value })} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Purpose"
                                name="JobPurpose"
                                rules={[{ required: true, message: 'Purpose is required' }]}
                            >
                                <CustomEditor value={form.getFieldValue("JobPurpose")} onEditorChange={(value) => form.setFieldsValue({ JobPurpose : value })} />
                            </Form.Item>
                        </Col>  
                        <Col span={24}>
                            <Form.Item
                                label="Roles and Responsibilities"
                                name="Responsibility"
                                rules={[{ required: true, message: 'Roles and Responsibilities is required' }]}
                            >
                                <CustomEditor value={form.getFieldValue("Responsibility")} onEditorChange={(value) => form.setFieldsValue({ Responsibility: value })} />
                           
                            </Form.Item>
                        </Col> 
                        <Col span={24}>
                            <Form.Item
                                label="Required Candidate Profile"
                                name="JobDisclosed"
                                rules={[{ required: true, message: 'Required Candidate Profile is required' }]}
                            >
                                <CustomEditor value={form.getFieldValue("JobDisclosed")} onEditorChange={(value) => form.setFieldsValue({ JobDisclosed: value })} />
                            </Form.Item>
                            
                        </Col>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                    name="Status"
                                >
                                    <Option className='text-capitalize' value={"0"}>Inactive</Option>
                                    <Option className='text-capitalize' value={"1"}>Active</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label=" "
                                name="JobTitle "
                                rules={[{ required: false, message: 'Profile is required' }]}
                            >
                                <Input type ="hidden" autoComplete="new-off" className='form-control' placeholder='Profile' />
                            </Form.Item>
                        </Col >
                    </Row>
                
                
                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit} disabled={addLoading}>
                            Submit
                        </button>
                    </div>
                </Form >
            </div>
        </>
    )
}

export default AddJob