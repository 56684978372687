import React, { useEffect, useState } from "react";
import {
  DashboardOutlined,
  UsergroupAddOutlined,
  QuestionCircleOutlined,
  QuestionOutlined,
  SettingOutlined,
  UserOutlined,
  FileOutlined,
  ApartmentOutlined,
  FileSearchOutlined,
  FileImageOutlined,
  TeamOutlined,
  MessageOutlined,
  DiffOutlined,
  GoldOutlined,
  FilePptOutlined
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import SidebarFooter from "./SidebarFooter";
import { useNavigate } from "react-router-dom";
import Logo from "./Logo";
import { useBasePath } from "../../hooks/useBasePath";
import SvgIcon from "../../const/SvgIcon";
import { ShowMenu } from "../../utils";
const { Sider } = Layout;

const Sidebar = ({ collapsed }) => {
  const navigate = useNavigate();
  const { openKey, selectKey } = useBasePath();

  return (
    <Sider
      className="layout_sidebar"
      collapsible
      collapsed={collapsed}
      width={240}
      trigger={!collapsed ? <SidebarFooter /> : null}
    >
      <Logo collapsed={collapsed} />
      <div className="sidebar_menus">
        <Menu
          mode="inline"
          selectedKeys={selectKey}
          defaultOpenKeys={openKey}
          style={{ background: "#fecc4e" }}
          items={[
            {
              key: "dashboard",
              icon: <DashboardOutlined />,
              label: "Dashboard Management",
              onClick: () => {
                navigate("/");
              },
            },
            // {
            //     key: 'banner-management',
            //     icon: <QuestionCircleOutlined />,
            //     label: 'Banner Management',
            //    /*  style: { display: ShowMenu(["Faqs", 'Faq Category']) }, */
            //     children: [
            //         {
            //             key: 'home-banner',
            //             label: 'Home Page',
            //             icon: <QuestionCircleOutlined />,
            //             /* style: { display: ShowMenu(["Faq Category"]) }, */
            //             onClick: () => {
            //                 navigate("/page-banner")
            //             },
            //         }
            //     ]
            // },
            {
              key: "promotions-management",
              icon: <SvgIcon.promotionIcon />,
              label: "Promotions & Discounts",
              style: { display: ShowMenu(["Coupon Management"]) },
              children: [
                {
                  key: "Promotions&DiscountCategory",
                  label: "Categories",
                  icon: <SvgIcon.categoryIcon />,
                  style: { display: ShowMenu(["Coupon Management"]) },
                  onClick: () => {
                    navigate("/coupon-category");
                  },
                },

                {
                  key: "Promotions-Item",
                  label: "Offers",
                  icon: <SvgIcon.offersIcon />,
                  style: { display: ShowMenu(["Coupon Management"]) },
                  onClick: () => {
                    navigate("/coupons");
                  },
                },
              ],
            },
            {
              key: "banner-management",
              icon: <FileImageOutlined />,
              label: "Banner Management",
              style: { display: ShowMenu(["Banner Management"]) },
              onClick: () => {
                navigate("/page-banner");
              },
            },
            {
              key: "whats-new",
              icon: <FileImageOutlined />,
              label: "What's New",
              style: { display: ShowMenu(["Whats New Management"]) },
              onClick: () => {
                navigate("/whats-new");
              },
            },
            {
              key: "testmanagement",
              icon: <FileSearchOutlined />,
              label: "Test Management",
              style: { display: ShowMenu(["Test Management"]) },
              onClick: () => {
                navigate("/test-management");
              },
            },
            {
              key: "lead-management",
              icon: <FilePptOutlined />,
              label: "Lead Page Management",
              style: { display: ShowMenu(["Lead Page Management"]) },
              children: [
                {
                  key: "lead-zone-management",
                  label: "Zone/Region",
                  icon: <GoldOutlined />,
                  style: { display: ShowMenu(["Zone Management"]) },
                  onClick: () => {
                    navigate("/manage-zone");
                  },
                },

                {
                  key: "lead-page-management",
                  label: "Manage Pages",
                  icon: <DiffOutlined />,
                  style: { display: ShowMenu(["Lead Page Management"]) },
                  onClick: () => {
                    navigate("/manage-page");
                  },
                },
              ],
            },
            {
              key: "department",
              icon: <ApartmentOutlined />,
              label: "Department Management",
              style: { display: ShowMenu(["Department Management"]) },
              onClick: () => {
                navigate("/department");
              },
            },
            {
              key: "faq-management",
              icon: <QuestionCircleOutlined />,
              label: "FAQs Management",
              style: { display: ShowMenu(["Faqs", "Faq Category"]) },
              children: [
                {
                  key: "faq-category",
                  label: "FAQ-Category",
                  icon: <QuestionCircleOutlined />,
                  style: { display: ShowMenu(["Faq Category"]) },
                  onClick: () => {
                    navigate("/faq-category");
                  },
                },

                {
                  key: "faqs",
                  label: "FAQs",
                  icon: <QuestionOutlined />,
                  style: { display: ShowMenu(["Faqs"]) },
                  onClick: () => {
                    navigate("/faqs");
                  },
                },
              ],
            },
            {
              key: "investor-management",
              icon: <QuestionCircleOutlined />,
              label: "Investor Management",
              style: {
                display: ShowMenu([
                  "investor Category",
                  "Financials Pages",
                  "investor Pages",
                ]),
              },
              children: [
                {
                  key: "investor Category",
                  label: "Categories",
                  icon: <QuestionCircleOutlined />,
                  style: { display: ShowMenu(["investor Categorys"]) },
                  onClick: () => {
                    navigate("/investor-category");
                  },
                },

                {
                  key: "financials",
                  label: "Financials",
                  icon: <QuestionCircleOutlined />,
                  style: { display: ShowMenu(["Financials Pages"]) },
                  onClick: () => {
                    navigate("/investor-financials");
                  },
                },
                {
                  key: "investor",
                  label: "Investors Information",
                  icon: <QuestionCircleOutlined />,
                  style: { display: ShowMenu(["investor Pages"]) },
                  onClick: () => {
                    navigate("/investor-information");
                  },
                },
                {
                  key: "corporate-governance",
                  label: "Corporate Governance",
                  icon: <QuestionCircleOutlined />,
                  style: { display: ShowMenu(["investor Pages"]) },
                  onClick: () => {
                    navigate("/investor-corporate-governance");
                  },
                },
              ],
            },
            {
              key: "team-management",
              icon: <TeamOutlined />,
              label: "Team Management",
              style: { display: ShowMenu(["Team Management"]) },
              onClick: () => {
                navigate("/our-team");
              },
            },
            {
              key: "sms-partners",
              icon: <MessageOutlined />,
              label: "SMS Partners",
              style: { display: ShowMenu(["SMS Partners"]) },
              onClick: () => {
                navigate("/sms-partners");
              },
            },
            {
              key: "business-partnership",
              icon: <QuestionCircleOutlined />,
              label: "Business Partnership",
              style: { display: ShowMenu(["Become a Partner", "Corporate Tie Up"]) },
              children: [
                {
                  key: "business-partner",
                  icon: <TeamOutlined />,
                  label: "Become a Partner",
                  style: { display: ShowMenu(["Become a Partner"]) },
                  onClick: () => {
                    navigate("/business-partner");
                  },
                },

                {
                  key: "corporate-tie-up",
                  label: "Corporate Tie Up",
                  icon: <QuestionCircleOutlined />,
                  style: { display: ShowMenu(["Corporate Tie Up"]) },
                  onClick: () => {
                    navigate("/corporate-tie-up");
                  },
                },
              ],
            },
            // {
            //   key: "home-collection",
            //   icon: <TeamOutlined />,
            //   label: "Home Collection",
            //   style: { display: ShowMenu(["Home Collection"]) },
            //   onClick: () => {
            //     navigate("/home-collection");
            //   },
            // },
            {
              key: "fellowship-program",
              icon: <TeamOutlined />,
              label: "Fellowship Program",
              style: { display: ShowMenu(["Fellowship Program"]) },
              onClick: () => {
                navigate("/fellowship-program");
              },
            },
            {
              key: "seo-management",
              icon: <SvgIcon.seoManagementIcon />,
              label: "SEO Management",
              style: { display: ShowMenu(["seo", "Seo Pages"]) },
              children: [
                {
                  key: "seo",
                  label: "SEO",
                  icon: <QuestionCircleOutlined />,
                  style: { display: ShowMenu(["Seo Pages"]) },
                  onClick: () => {
                    navigate("/seo-management");
                  },
                },
              ],
            },
            {
              key: "manage-download-report",
              icon: <FileOutlined />,
              label: "Manage Download Report",
              style: { display: ShowMenu(["Manage Download Report"]) },
              onClick: () => {
                navigate("/manage-download-report");
              },
            },
            {
              key: "blog-attachment",
              icon: <FileOutlined />,
              label: "Blog Attachment",
              style: { display: ShowMenu(["Blog Attachment"]) },
              onClick: () => {
                navigate("/blog-management");
              },
            },
            {
              key: "ticket-management",
              icon: <FileOutlined />,
              label: "Ticket Management",
              style: { display: ShowMenu(["Ticket Management"]) },
              onClick: () => {
                navigate("/ticket-management");
              },
            },
            {
              key: "hr-management",
              icon: <SvgIcon.HrIcon />,
              label: "HR Management",
              style: { display: ShowMenu(["Job Applications", "Job Posts"]) },
              children: [
                {
                  key: "job-applications",
                  label: "Job Applications",
                  icon: <FileOutlined />,
                  style: { display: ShowMenu(["Job Applications"]) },
                  onClick: () => {
                    navigate("job-applications");
                  },
                },
                {
                  key: "job-post",
                  label: "Job Posts",
                  icon: <FileOutlined />,
                  style: { display: ShowMenu(["Job Posts"]) },
                  onClick: () => {
                    navigate("jobs");
                  },
                },
              ],
            },
            {
              key: "btl-management",
              icon: <SvgIcon.btlManageIcon />,
              label: "BTL/Non-BTL Management ",
              className: "btManagement",
              style: { display: ShowMenu(["BTL Management"]) },
              children: [
                {
                  key: "btl",
                  label: "Offers",
                  icon: <SvgIcon.promotionIcon />,
                  style: { display: ShowMenu(["BTL Management"]) },
                  onClick: () => {
                    navigate("btl");
                  },
                },
                {
                  key: "btl-customers",
                  label: "Customers",
                  icon: <SvgIcon.customerIcon />,
                  style: { display: ShowMenu(["BTL Management"]) },
                  onClick: () => {
                    navigate("btl-customers");
                  },
                },
              ],
            },
            //     {
            //         key: 'btl',
            //         icon: <SvgIcon.btlManageIcon />,
            //         label: 'BTL/Non BTL Management',
            //         style: { display: ShowMenu(["btl", "btlCustomer"]) },
            //         children: [
            //     {
            //         key: 'btl',
            //         icon: <SvgIcon.promotionIcon />,
            //         label: 'Offers',
            //         style: { display: ShowMenu(["btl"]) },
            //         onClick: () => {
            //             navigate("/btl")
            //         },
            //     },
            //     {
            //         key: 'btlCustomer',
            //         icon: <SvgIcon.customerIcon />,
            //         label: 'Customers',
            //         style: { display: ShowMenu(["btlCustomer"]) },

            //         onClick: () => {
            //             navigate("/btl-customers")
            //         },
            //     },
            // ]
            // },
            {
              key: "user-management",
              icon: <UsergroupAddOutlined />,
              label: "Users Management",
              style: { display: ShowMenu(["Users", "Roles"]) },
              children: [
                {
                  key: "users",
                  label: "Users",
                  icon: <UserOutlined />,
                  style: { display: ShowMenu(["Users"]) },
                  onClick: () => {
                    navigate("/users");
                  },
                },
                {
                  key: "roles",
                  label: "Roles",
                  icon: <UserOutlined />,
                  style: { display: ShowMenu(["Roles"]) },
                  onClick: () => {
                    navigate("/roles");
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </Sider>
  );
};

export default Sidebar;
