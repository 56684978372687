import { Col, Form, Input, Row } from 'antd';
import React, { useEffect } from 'react'
import HeadingBack from '../../component/HeadingBack';
import { onlyMobileInput } from '../../utils';

const Settings = () => {
    onlyMobileInput();
    const [form] = Form.useForm();
    useEffect(() => {
        onlyMobileInput();
    })
    const handleSubmit = () => {

    }
    return (
        <>
            <HeadingBack title={"Settings"} subHeading="" />
            <div className='p-20'>
                < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                >
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Registerd Office Address"
                                name="RegisteredOffice"
                                rules={[{ required: true, message: 'Registerd Office Address is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Registerd Office Address' />
                            </Form.Item>
                            <Form.Item
                                name="RegisteredOfficeState"
                                rules={[{ required: true, message: 'State is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='State' />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Office Address"
                                name="Office"
                                rules={[{ required: true, message: 'Office Address is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Office Address' />
                            </Form.Item>
                            <Form.Item
                                name="OfficeState"
                                rules={[{ required: true, message: 'State is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='State' />
                            </Form.Item>
                        </Col >
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Phone Number"
                                name="PhoneNumber"
                                rules={[
                                    { required: true, message: 'Phone Number is required' },
                                    { min: 11, message: "Enter min 11 Number" }
                                ]}
                            >
                                <Input type="text" maxLength={11} id="only_mobile" autoComplete="new-off" className='form-control' placeholder='Phone Number' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Email Address"
                                name="EmailAddress"
                                rules={[
                                    { required: true, message: 'Email Address is required' },
                                    { type: 'email', message: 'Email Address is Invalid' }
                                ]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Email Address' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="WhatsApp No"
                                name="WhatsAppNo"
                                rules={[
                                    { required: true, message: 'WhatsApp No is required' },
                                    { min: 11, message: "Enter min 11 Number" }
                                ]}
                            >
                                <Input type="text" maxLength={11} id="only_mobile1" autoComplete="new-off" className='form-control' placeholder='WhatsApp No' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Customer Care"
                                name="CustomerCare"
                                rules={[
                                    { required: true, message: 'Customer Care is required' },
                                    { min: 11, message: "Enter min 11 Number" }
                                ]}
                            >
                                <Input type="text" maxLength={11} id="only_mobile2" autoComplete="new-off" className='form-control' placeholder='Customer Care' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="GST No"
                                name="GSTNo"
                                rules={[
                                    { required: true, message: 'GST No is required' },
                                    { min: 15, message: "Enter min 15 Number" }
                                ]}
                            >
                                <Input maxLength={15} autoComplete="new-off" className='form-control' placeholder='GST No' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit}>
                            Submit
                        </button>
                    </div>
                </Form >
            </div>
        </>
    )
}

export default Settings