import axios from "axios";
import { GetToken } from "../utils";
import URL from "./url";

const Api = axios.create({
  baseURL: URL.API_BASEURL
})
Api.interceptors.request.use((request) => {
  if(!request?.headers?.["token"]) {
    request.headers["token"] = GetToken() ? `${GetToken()}` : ""
  }
  return request
})
Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {

    /* if (error?.response?.status === 401) {
    } else if (error?.response?.status === 403) {
      toast.error("Access Forbidden !");
    } else if (error?.response?.status === 500) {
      toast.error("Internal Server Error !");
    } */
    return Promise.reject(error);
  }
);
export default Api;
