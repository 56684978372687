import { Col, Row } from 'antd'
import React, { useEffect } from 'react'
import HeadingBack from '../../component/HeadingBack'
import { useParams } from 'react-router-dom';
import { usePermission } from '../../hooks/usePermission';
import { FellowshipServices } from '../../redux/Services';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DownLoadDocLink from './DownLoadDocLink';
import { JSONParse } from '../../utils';

const FellowshipDetails = () => {
    const permission = usePermission("Fellowship Program", "List");
    const { Id } = useParams();
    const dispatch = useDispatch();
    const FellowshipDetails = useSelector(state => state.FellowShip.FellowshipDetails || []);
    const { mutate: getFellowshipDetails, isLoading } = useMutation(() => dispatch(FellowshipServices.fetchFellowshipDetailsApi(Id)));

    useEffect(() => {
        getFellowshipDetails();
        return () => { }
    }, [Id])

    const ViewBox = ({ label, value }) => (
        <div className='form-group'>
            {label && <label>{label}</label>}
            <div className='form-control show_input'>{value}</div>
        </div>
    )
    const ViewBoxLink = ({ label, value, downloadName }) => (
        <div className='form-group'>
            {label && <label>{label}</label>}
            <div className='form-control show_input text-center'>
            <DownLoadDocLink file={value} downloadName={downloadName}/> 
            </div>
        </div>
    )
    const ViewBoxArray = ({ label, value = [] }) => (
        <div className='form-group'>
            {label && <label>{label}</label>}
            <div className='form-control show_input'>
                {value?.length > 0 && value?.map((item) => (
                    <div key={item?.item_id}>{item?.item_text}</div>
                ))}
            </div>
        </div>
    )
    function getApplyFor(applyCourse) {
        let applyFor = JSONParse(applyCourse);
        return applyFor
    }
    return (
        <>
            <div className='position-relative part-filter'>
                <HeadingBack title={"Fellowship Program Details"} subHeading="" />
            </div>
            <div className='p-20'>
                <Row gutter={16}>
                    <Col span={24} className='mb-5 fs-16'><strong>Personal Details</strong></Col>
                    <Col lg={12} md={24} sm={24} xs={24} className='text-capitalize'>
                        <ViewBox label="Name" value={FellowshipDetails?.FirstName} />
                    </Col>
                    {/* <Col lg={12} md={24} sm={24} xs={24} className='text-capitalize'>
                        <ViewBox label="Last Name" value={FellowshipDetails?.LastName} />
                    </Col> */}
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <ViewBox label="Mobile Number" value={FellowshipDetails?.MobileNo} />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <ViewBox label="Email Id" value={FellowshipDetails?.EmailId} />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} className='text-capitalize'>
                        <ViewBox label="Gender" value={FellowshipDetails?.Gender} />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <ViewBox label="Current Address" value={FellowshipDetails?.CurrentAddress} />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} className='text-capitalize'>
                        <ViewBox label="Country" value={FellowshipDetails?.Country} />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} className='text-capitalize'>
                        <ViewBox label="State" value={FellowshipDetails?.State} />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} className='text-capitalize'>
                        <ViewBox label="City" value={FellowshipDetails?.City} />
                    </Col>
                    <Col span={24} className='mb-5 fs-16'><strong>Education</strong></Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <ViewBox label="Highest Education" value={FellowshipDetails?.HighestEducation} />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <ViewBox label="College/Institute" value={FellowshipDetails?.InstituteName} />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <ViewBox label="Year of Passing" value={FellowshipDetails?.PassingYear} />
                    </Col>
                    <Col span={24} className='mb-5 fs-16'><strong>Experience</strong></Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <ViewBox label="Current Organisation" value={FellowshipDetails?.CurrentOrganisation} />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <ViewBox label="Designation" value={FellowshipDetails?.CurrentDesignation} />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <ViewBox label="Experience post DNB/MD" value={FellowshipDetails?.Experience} />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <ViewBox label="Published article/journals/presentations Topic" value={FellowshipDetails?.Topic} />
                    </Col>
                    <Col span={24} className='mb-5 fs-16'><strong> Course Selection</strong></Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <ViewBoxArray label="Course Applying For" value={getApplyFor(FellowshipDetails?.ApplyingCourses)} />
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={24}>
                        <ViewBoxLink label="Upload CV" value={FellowshipDetails?.UploadedCV} downloadName="UploadCV"/>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={24}>
                        <ViewBoxLink label="Statement of Purpose" value={FellowshipDetails?.UploadedStatement} downloadName="UploadedStatement"/>
                    </Col>
                    <Col span={24} className='mb-5 fs-16'><strong>Others</strong></Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <ViewBox label="Program" value={FellowshipDetails?.Program} />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <ViewBox label="Applied Date" value={FellowshipDetails.CreatedAt ? moment(FellowshipDetails.CreatedAt).format('DD-MM-YYYY H:mm:ss') : ""} />
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default FellowshipDetails