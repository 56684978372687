import { Table,Col, Row, Tooltip } from 'antd';
import React, { useEffect } from 'react'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeadingBack from '../../../component/HeadingBack';
import SvgIcon from '../../../const/SvgIcon';
import { CouponServices } from '../../../redux/Services';
import { usePermission } from '../../../hooks/usePermission';

const PromotionCategory = () => {
    const permission = usePermission("Coupon Management", "List");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const couponCategoryList = useSelector(state => state.coupon.couponCategoryList || []);
    const totalcouponCategoryList = useSelector(state => state.coupon.totalcouponCategoryList || "");
    const { mutate: fetchCouponCategory, isLoading } = useMutation((data) => dispatch(CouponServices.fetchCouponCategoryApi(data)));

    useEffect(() => {
        fetchCouponCategory({});
        return () => { }
    }, [])
// console.log(couponCategoryList)
    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
            className:'table_first_col',
            render: (value, item, index) => ++index
        },
        {
            title: 'Category',
            dataIndex: 'Name',
            key: 'Name',
            className: 'text-capitalize'
        },
         {
            title: 'Slug',
            dataIndex: 'Slug',
            key: 'Category',
            className: 'text-capitalize'
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            className: 'table_status_col',
            render: (value, item, index) => {
                return (
                    <span className={item?.Status ? "status_active" : "status_inactive"}>{item?.Status ? "Active" : "Inactive"}</span>
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            className: 'table_action_col',
            render: (value, item, index) => {
                return (
                    <Tooltip title="Edit">
                        <span className='cursor-pointer' onClick={() => navigate(`/coupon-category/edit/${item?.Id}`)}><SvgIcon.Edit className="scale-icon edit-icon" /></span>
                    </Tooltip>
                )
            }
        }
    ];
    return (
        <div>
            <div className='flex'>
            <div className='w-100'>
                    <HeadingBack title={"Promotions & Discounts Category"} subHeading={`Total ${totalcouponCategoryList || 0} Category`} />
                </div>
                <Col lg={2} md={24} sm={24} xs={24}>
                <div className='flex justify-content-end align-items-center'>
                    <Tooltip title="Add"><span className='jop_btn' onClick={() => navigate("/coupon-category/add")}>Add<SvgIcon.Add width={40} height={40} className="cursor-pointer" /></span></Tooltip>
                </div>
                </Col>
            </div>
            <div className='tableResponsive mt-10'>
                <Table
                    rowKey='Id'
                    className={isLoading ? 'table antd_table no_data_found_hide' : 'table antd_table'}
                    bordered
                    columns={columns}
                    dataSource={Array.isArray(couponCategoryList) && couponCategoryList?.length > 0 ? couponCategoryList : []}
                    pagination={false}
                />
                {/* <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th>Category</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {faqCategoryList && faqCategoryList?.length > 0 && faqCategoryList?.map((item, idx) => (
                            <tr key={idx}>
                                <td>{++idx}</td>
                                <td className='text-capitalize'>{item?.Category}</td>
                                <td ><span className={item?.Status ? "status_active" : "status_inactive"}>{item?.Status ? "Active" : "Inactive"}</span></td>
                                <td><span className='cursor-pointer' onClick={() => navigate(`/faq-category/edit/${item?.Id}`)}><SvgIcon.Pencil className="scale-icon" /></span></td>
                            </tr>
                        ))
                        }
                    </tbody>
                </table> */}
            </div>
        </div>
    )
}

export default PromotionCategory