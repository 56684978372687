import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    jobApplicationList: [],
    totalJobApp: 0,
    JobApplicationsDetails: [],
    StateCityList: [],
    jobApplicationsAllList: [],
    JobRoleList: [],
    PartnerList: [],
    totalPartnerList: 0,
    HomeCollectionList: [],
    totalHomeCollectionList: 0,
    CorporateTieUpList: [],
    totalCorporateTieUpList: 0,
}

export const jobApplicationsSlice = createSlice({
    name: 'jobApplication',
    initialState,
    reducers: {
        setJobApplicationsList: (state, action) => {
            state.jobApplicationList = action.payload.list;
            state.totalJobApp = action.payload.total;
        },
        setjobApplicationsAllList: (state, action) => {
            state.jobApplicationsAllList = action.payload.list;
            state.totalJobApp = action.payload.total;
        },
        setStateCityList: (state, action) => {
            state.StateCityList = action.payload.list;
        },
        setJobApplicationsDetailsApi: (state, action) => {
            state.JobApplicationsDetails = action.payload;
            state.totalJobApp = action.payload.total;
        },

        setJobRoleList: (state, action) => {
            state.JobRoleList = action.payload.list;
        },
        setBecomePartnerList: (state, action) => {
            state.PartnerList = action.payload.list;
            state.totalPartnerList = action.payload.total;
        },
        setHomeCollectionList: (state, action) => {
            state.HomeCollectionList = action.payload.list;
            state.totalHomeCollectionList = action.payload.total;
        },
        setCorporateTieUpList: (state, action) => {
            state.CorporateTieUpList = action.payload.list;
            state.totalCorporateTieUpList = action.payload.total;
        },
    },
})

export const { setJobApplicationsList, setJobApplicationsDetailsApi, setStateCityList, setjobApplicationsAllList, setJobRoleList,setBecomePartnerList,setHomeCollectionList, setCorporateTieUpList } = jobApplicationsSlice.actions

export default jobApplicationsSlice.reducer