import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    InvestorItemList: [],
    TotalRecord: '',
    InvestorItemDetails: {},
    investorInfo: {}
}

export const InvestorItemSlice = createSlice({
    name: 'investoritem',
    initialState,
    reducers: {
        setItemList: (state, action) => {
            state.InvestorItemList = action.payload.list;
          state.TotalRecord = action.payload.total;
        },
      
        setInvestorItemDetails: (state, action) => {
            state.InvestorItemDetails = action.payload;
        },
        setInvesoterInfo : (state, action) => {
            state.investorInfo = action.payload;
        }
    },
})

export const { setItemList, setInvestorItemDetails,setInvesoterInfo} = InvestorItemSlice.actions

export default InvestorItemSlice.reducer