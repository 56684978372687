import React from 'react'
import { Form, Input, Select, Row, Col } from 'antd'
import { GetLoggedInUser } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { LeadPageServices } from '../../../redux/Services';
import HeadingBack from '../../../component/HeadingBack';
import { usePermission } from '../../../hooks/usePermission';
const { Option } = Select;

const AddManageZone = () => {
    const permission = usePermission("Zone Management", "Add");
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { mutate: addZone } = useMutation((data) => dispatch(LeadPageServices.addZoneApi(data, navigate)));
   
    const handleSubmit = () => {
        form.validateFields().then((values) => {
            let _data = { ...values };
            _data.UserId = LOG_IN_USER?.id;
            addZone(_data)
        })
    }
    
    return (
        <>
            <HeadingBack title={"Zone/Region Add"} subHeading="" />
            <div className='p-20'>
                <Row>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        < Form
                            form={form}
                            name="basic"
                            layout={"vertical"}
                            autoComplete="off"
                            initialValues={{ Status: "1" }}
                        >
                            <Row gutter={[24, 0]}>
                            <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Zone/Region"
                                name="Zone"
                                rules={[{ required: true, message: 'Zone is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Zone/Region' />
                            </Form.Item>
                            </Col>
                            <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                    name="Status"
                                >
                                    <Option className='text-capitalize' value={"0"}>Inactive</Option>
                                    <Option className='text-capitalize' value={"1"}>Active</Option>
                                </Select>
                            </Form.Item>
                            </Col>
                            </Row>
                          
                            <div className='text-center'>
                                <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit}>
                                    Submit
                                </button>
                            </div>
                        </Form >
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default AddManageZone