import { Empty, Table, Tooltip } from 'antd';
import React, { useEffect } from 'react'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import HeadingBack from '../../../component/HeadingBack';
import SvgIcon from '../../../const/SvgIcon';
import { UserServices } from '../../../redux/Services';
import { usePermission } from '../../../hooks/usePermission';

const UserRoles = () => {
    const permission = usePermission("Roles", "List");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const roleList = useSelector(state => state.user.roleList || []);
    const totalRole = useSelector(state => state.user.totalRole || "");
    const { mutate: getRolesList, isLoading } = useMutation((data) => dispatch(UserServices.fetchRolesApi(data)));
    useEffect(() => {
        getRolesList({});
        return () => { }
    }, [])
    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
            className:'table_first_col',
            render: (value, item, index) => ++index
        },
        {
            title: 'Role Name',
            dataIndex: 'Role',
            key: 'Role',
            className: 'text-capitalize',
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            className: 'table_action_col',
            render: (value, item, index) => {
                return (
                    <>
                        <Tooltip title="Edit">
                            <span className='cursor-pointer' onClick={() => navigate(`/role/edit/${item?.RoleId}`)}><SvgIcon.Edit className="scale-icon edit-icon" /></span>
                        </Tooltip>
                        <Tooltip title="View Permissions">
                            <Link className='ml-10' to={`/role/user-permission/${item?.RoleId}`}><SvgIcon.PermissionIcon className="scale-icon edit-icon" /></Link>
                        </Tooltip>
                    </>
                )
            }
        },
    ];
    return (
        <div>
            <div className='flex'>
                <div>
                    <HeadingBack title={"Roles"} subHeading={`Total ${totalRole || 0} Roles`} />
                </div>
                <div className='flex1 flex justify-content-end align-items-center'>
                    <Tooltip title="Add"><span className='jop_btn' onClick={() => navigate("/role/add")}>Add<SvgIcon.Add width={40} height={40} className="cursor-pointer" /></span></Tooltip>
                </div>
            </div>
            <div className='tableResponsive mt-10'>
                <Table
                    rowKey='RoleId'
                    className={isLoading ? 'table antd_table no_data_found_hide' : 'table antd_table'}
                    bordered
                    columns={columns}
                    dataSource={Array.isArray(roleList) && roleList?.length > 0 ? roleList : []}
                    pagination={false}
                />
            </div>
        </div>
    )
}

export default UserRoles
