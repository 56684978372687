import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Row, Select } from 'antd'
import { useNavigate, useParams } from 'react-router-dom';
import CustomEditor from '../../component/CustomEditor/CustomEditor';
import { useDispatch, useSelector } from 'react-redux';
import { SeoServices } from '../../redux/Services';
import { useMutation } from 'react-query';
import { GetLoggedInUser, JSONParse } from '../../utils';
import HeadingBack from '../../component/HeadingBack';
import { usePermission } from '../../hooks/usePermission';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';

const { Option } = Select;
const EditSeo = () => {
    const permission = usePermission("Seo Pages", "Edit");
    const { seoId } = useParams();
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [count, setCount] = React.useState(0);
    const [count1, setCount1] = React.useState(0);
    const [count2, setCount2] = React.useState(0);
    const seodetails = useSelector(state => state.seo.SeoDetails);
    const { mutate: SeoDetail } = useMutation((data) => dispatch(SeoServices.fetchSeoDetailsApi(data, seoId)));
    const { mutate: SeoDetailUpdateApi, isLoading: addLoading } = useMutation((data) => dispatch(SeoServices.SeoDetailUpdateApi(data, seoId, navigate)));
    useEffect(() => {
        SeoDetail(seoId);
        return () => { }
    }, [])
    useEffect(() => {
        if (seodetails && Object.keys(seodetails).length > 0 && Object.keys(seodetails.list)?.length > 0) {
            const seoData = seodetails.list;
            let tags= JSONParse(seoData?.PageSchemaTags);
            form.setFieldValue('Id', seoData?.Id);
            form.setFieldValue('PageName', seoData?.PageName);
            form.setFieldValue('Slug', seoData?.Slug);
            form.setFieldValue('SeoTitle', seoData?.SeoTitle);
            form.setFieldValue('Breadcrumb', seoData?.Breadcrumb);
            form.setFieldValue('SeoDescription', seoData?.SeoDescription);
            form.setFieldValue('SeoKeywords', seoData?.SeoKeywords);
            form.setFieldValue('Status', seoData?.Status);
            form.setFieldValue('CanonicalUrl', seoData?.CanonicalUrl);
            form.setFieldValue('PageContent', seoData?.PageContent);
            setCount1(seoData?.SeoTitle?.length || 0)
            setCount(seoData?.SeoDescription?.length || 0)
            setCount2(seoData?.SeoKeywords?.length || 0)
            form.setFieldValue('PageSchemaTags', tags?.length > 0 ? tags : [undefined]);
        }
    }, [seodetails])


    const handleSubmit = () => {
        form.validateFields().then((values) => {
            if (SeoDetailUpdateApi) {
                let _data = { ...values };
                _data.UserId = LOG_IN_USER?.id;
                _data.PageSchemaTags = JSON.stringify(values?.PageSchemaTags);
                SeoDetailUpdateApi(_data);
            }
        })
    }

    return (
        <>
            <HeadingBack title={"SEO Edit"} subHeading="" />
            <div className='p-20'>
                < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                >
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Reference Name"
                                name="PageName"
                                rules={[{ required: true, message: 'Reference Name is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Reference' />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Slug"
                                name="Slug"
                                rules={[{ required: true, message: 'Slug is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Slug' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Seo Title"
                                name="SeoTitle"
                                rules={[{ required: true, message: 'Seo Title is required' }]}
                                value={count1}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Seo Title' onChange={(e) => setCount1((e).target.value.length)} />
                            </Form.Item>
                            <p className='char_count mb-0 mt-8
                                '><strong>{count1}</strong> Characters</p>
                        </Col >
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Breadcrumb"
                                name="Breadcrumb"
                                rules={[{ required: true, message: 'Breadcrumb is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Breadcrumb' />
                            </Form.Item>
                        </Col >
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label="Seo Description"
                                name="SeoDescription"
                                rules={[{ required: true, message: 'Seo Description is required' }]}
                            >
                                {/* <CustomEditor value={form.getFieldValue("SeoDescription ")} onEditorChange={(value) => form.setFieldsValue({ SeoDescription: value })} /> */}
                                <textarea className='form-control' onChange={e => setCount(e.target.value.length)} onEditorChange={(value) => form.setFieldsValue({ SeoDescription: value })} />

                            </Form.Item>
                            <p className='char_count mb-0'><strong>{count}</strong> Characters</p>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Seo Keywords"
                                name="SeoKeywords"
                                rules={[{ required: true, message: 'Seo Keywords is required' }]}
                            >
                                {/* <CustomEditor value={form.getFieldValue("SeoKeywords")} onEditorChange={(value) => form.setFieldsValue({ SeoKeywords: value })} /> */}
                                <textarea className='form-control' onChange={e => setCount2(e.target.value.length)} value={form.getFieldValue("SeoKeywords ")} onEditorChange={(value) => form.setFieldsValue({ SeoKeywords: value })} placeholder="Seo Keywords" />
                            </Form.Item>
                            <p className='char_count mb-0'><strong>{count2}</strong> Characters</p>
                        </Col>
                        <Col lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Page Content (Above Footer) "
                                name="PageContent"
                           
                            >
                                <CustomEditor value={form.getFieldValue("PageContent")} onEditorChange={(value) => form.setFieldsValue({ PageContent: value })} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <div className='frameSet'>
                            <Form.List
                                name="PageSchemaTags"
                                label="Manage Schema Tag"
                            >
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Row align={"top"} justify={"center"} gutter={[16,16]}>
                                                <Col span={21}>
                                                    <Form.Item
                                                        label={index === 0 ? "Manage Schema Tag" : ""}
                                                        {...field}
                                                    >
                                                        <TextArea className='form-control' placeholder="Schema Tag" rows="5" cols="50" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={3} className='text-center'>
                                                    {fields.length > 1 ? (
                                                        <MinusCircleOutlined
                                                            style={{ fontSize: "30px", color: "red" }}
                                                            className="dynamic-delete-button delete_field"
                                                            onClick={() => remove(field.name)}
                                                        />
                                                    ) : null}
                                                </Col>
                                            </Row>
                                        ))}
                                        <div className='text-center'>
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    style={{
                                                        width: '20%',
                                                    }}
                                                    icon={<PlusOutlined />}
                                                >
                                                    Add field
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    </>
                                )}
                            </Form.List>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Canonical URL"
                                name="CanonicalUrl"
                                rules={[{ required: true, message: 'Canonical URL is required' }]}
                            >
                                <Input type="url" autoComplete="new-off" className='form-control' placeholder='Canonical URL' />

                            </Form.Item>
                        </Col >
                        <Col lg={12} md={12} sm={24} xs={24}>

                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                    name="Status"
                                >
                                    <Option className='text-capitalize' value={0}>Inactive</Option>
                                    <Option className='text-capitalize' value={1}>Active</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit} disabled={addLoading}>
                            Update
                        </button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default EditSeo