import Api from "../../api";
import API_URL from "../../api/url";
import { setCityList } from "../Slices/commonSlice";

export const fetchCitiesApi = (data) => async (dispatch) => {
    try {
        const res = await Api.get(API_URL.CITY_LIST);
        dispatch(setCityList(res.data?.Data));
        return res;
    } catch (error) {
        return error
    }
};