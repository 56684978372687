import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    bannerList: [],
    TotalRecord: '',
    bannerDetail: {},
  
}

export const bannerSlice = createSlice({
    name: 'banner',
    initialState,
    reducers: {
        setBannerList: (state, action) => {
            state.bannerList = action.payload.list;
          state.TotalRecord = action.payload.total;
        },
      
        setBannerDetail: (state, action) => {
            state.bannerDetail = action.payload;
        },
    },
})

export const { setBannerList, setBannerDetail} = bannerSlice.actions

export default bannerSlice.reducer