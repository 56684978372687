import { Col, Row, Table, Tooltip, message } from 'antd';
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeadingBack from '../../component/HeadingBack';
import { usePermission } from '../../hooks/usePermission';
import { CouponServices } from '../../redux/Services';
import SearchFilter from './SearchFilter';
import { FileExcelFilled } from '@ant-design/icons';
import { getFilterTableData } from '../../utils';
import URL from "../../api/url";
import { ExportToExcel } from '../../../src/component/ExportToExcel'
import axios from 'axios';
import { GetToken } from "../../utils";
import moment from 'moment';
import { CSVLink } from "react-csv"
const BtlCustomer = () => {
    const permission = usePermission("BTL Management", "List");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [filter, setFilter] = useState({});
    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0);
    const [filterData, setFilterdData] = useState([]);
    const BtlCustomerList = useSelector(state => state.coupon.BtlCustomer || []);
    const totalBtl = useSelector(state => state.coupon.totalBtlCustomerList || "");
    const couponCategoryList = useSelector(state => state.coupon.couponCategoryList || []);
    const [page, setPage] = useState(1);
    const { mutate: getBtlCustomerList, isLoading } = useMutation((data) => dispatch(CouponServices.fetchBtlCustomerList(data, limit, offset,)));
    const { mutate: fetchCouponCategory } = useMutation((data) => dispatch(CouponServices.fetchCouponCategoryApi(data)));
    const fileName = "Btl Customer List";
    useEffect(() => {
        getBtlCustomerList(filter);
        return () => { }
    }, [filter, offset, limit])

    useEffect(() => {
        fetchCouponCategory({ isActive: "1" });
        return () => { }
    }, [])
    useEffect(() => {

        setFilterdData(BtlCustomerList);
    }, [BtlCustomerList])
    const [data, setData] = React.useState([])
    useEffect(() => {
        if (data && data.length > 0) {
            ExportToExcel(data, fileName);
        }
        return () => { }
    }, [data]);
    const downloadExcelData = async () => {

        filter.setOffset = 0;
        filter.Limit = 10000;
        axios.post(URL.API_BASEURL + '/' + URL.BTL_CUSTOMERS_LIST, filter,
            {
                headers: {
                    'token': GetToken()
                }
            }).then(async getData => {
                var exportData = getData.data.Data;
                var expoDataArr = [];
                await exportData.map((val, index) => {
                    val.Created = moment(val.Created).format('DD-MM-YYYY H:mm:ss')
                    expoDataArr.push(val)
                })
                setData(expoDataArr)
            })
    }
    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
            className: 'table_first_col',
            render: (value, item, index) => (page - 1) * 20 + index + 1
        },
        {
            title: 'Name',
            dataIndex: 'Name',
            key: 'Name',
            className: 'btlName',

        },
        // {
        //     title: 'Mobile Number',
        //     dataIndex: 'MobileNo',
        //     key: 'MobileNo',
        //     className: 'table_status_col',

        // },
        {
            title: 'Category',
            dataIndex: 'isBTL',
            key: 'isBTL',
            className: 'table_status_col',
            render: (value, item, index) => {
                return (
                    <span>{item?.isBTL2 ? "BTL" : "Non-BTL"}</span>
                )
            }
        },
        {
            title: 'Invoice Number',
            dataIndex: 'InvoiceCode',
            key: 'Invoice Number',
            className: 'table_action_col',

        },
        {
            title: 'Zone',
            dataIndex: 'Zone',
            key: 'Zone',
            className: 'table_action_col',

        },

        {
            title: 'Latitude',
            dataIndex: 'Lat',
            key: 'Lat',
            className: 'table_action_col',
            render: (value, item, index) => {
                return (
                    <span>{item.Lat}</span>
                )
            }
        },
        {
            title: 'Longitude',
            dataIndex: 'Lng',
            key: 'Lng',
            className: 'table_action_col',
            render: (value, item, index) => {
                return (
                    <span>{item.Lng}</span>
                )
            }
        },
        // {
        //     title: 'Created Date',
        //     dataIndex: 'CreatedAt',
        //     key: 'CreatedAt',
        //     render: (value, item, index) => {
        //         return (
        //           <>{moment(item.CreatedAt).format('DD-MM-YYYY H:mm:ss')}</>
        //         )
        //     }
        // },
        {
            title: 'Last Checked',
            dataIndex: 'LastChecked',
            key: 'LastChecked',
            render: (value, item, index) => {
                return (
                    <>{moment(item.CreatedAt).format('DD-MM-YYYY H:mm:ss')}</>
                )
            }
        },

    ];
    return (
        <div>
            <Col lg={4} md={4} sm={24} xs={24}>
                <HeadingBack title={"Customers"} subHeading={`Total  ${totalBtl || 0} Customers`} />
            </Col>
            <Row gutter={[20, 20]} align={"middle"} className='btl-filter'>

                <SearchFilter filter={filter} setFilter={setFilter} couponCategoryList={couponCategoryList} limit={setLimit} setPage={setPage} setOffset={setOffset} />
                {/* <CSVLink
              filename={"Customers data.csv"}
              data={filterData}
              className="ex-export2"
              onClick={()=>{
                message.success("The file is downloading")
              }}
            >
                 <Tooltip placement="top" title={"Export Customer Data"} arrow={false}>
            <FileExcelFilled />
            </Tooltip>
            </CSVLink>  */}
                <a className="ex-export2" apiData={data} fileName={fileName} onClick={(e) => downloadExcelData()} ><FileExcelFilled /></a>
            </Row>
            <div className='tableResponsive mt-10'>
                <Table
                    rowKey='Id'
                    className={isLoading ? 'table antd_table no_data_found_hide' : 'table antd_table'}
                    bordered
                    columns={columns}
                    dataSource={Array.isArray(filterData) && filterData?.length > 0 ? filterData : []}
                    pagination={{
                        current: page,
                        pageSize: limit,
                        total: totalBtl,
                        onChange(current, pageSize) {
                            setPage(current);
                            setOffset(current == 1 ? 0 : ((limit * current) - 20));
                        },
                        hideOnSinglePage: true
                    }}
                />
            </div>
        </div>
    )
}

export default BtlCustomer