

import { Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeadingBack from '../../../component/HeadingBack';
import SvgIcon from '../../../const/SvgIcon';
import { InvestorCategoriesServices } from '../../../redux/Services';
import InvestorSubCategoryFilter from './InvestorSubFilter';
import { usePermission } from '../../../hooks/usePermission';
import { EyeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
const Type = {
    "1": 'List',
    "2": 'Accordion',
    "3": 'Box',
    "4": "Textarea"
}
const InvestorSubCategory = () => {
    const permission = usePermission("Seo Pages", "List");
    const navigate = useNavigate();
    const [filter, setFilter] = useState({});
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [seq, setSeq] = useState(0);
    const [columns, setColumns] = useState([]);
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [FilterInvesotorCategoryList, setInvestorCategoryList] = useState({});
    const InvestorCategoryList = useSelector(state => state.invesotorCategory.InvestorCategoryListDrop || []);
    const InvestorCategoryLists = useSelector(state => state.invesotorCategory.InvestorCategoryList || []);
    const InvestorCategoryListFilterArr = useSelector(state => state.invesotorCategory.InvestorCategoryListFilter || []);
    const totalSubCategory = useSelector(state => state.invesotorCategory.TotalRecord || 0);
    const { mutate: InvestorSubCategoryListFilter } = useMutation((data) => dispatch(InvestorCategoriesServices.fetchInvestorListDataApiFilter(limit, offset, data)));
    const { mutate: InvestorSubCategoryList, isLoading } = useMutation((data) => dispatch(InvestorCategoriesServices.fetchInvestorListDataApi(limit, offset, data)));
    const { mutate: InvestorSubCategoryListdrop } = useMutation((data) => dispatch(InvestorCategoriesServices.fetchInvestorCategoryListApi(data)));

    var currentUrl = window.location.href;
    const queryParams = new URLSearchParams(window.location.search);
    var queryString = queryParams.toString();
    var currentBreadcrumb = queryParams.get('CategoryName');
    var currentBreadcrumb2 = queryParams.get('CategoryName');
    const ParentId = queryParams.get('ParentCategory');
    const CategoryId = queryParams.get('CategoryId');
    const CategoryIdH1 = queryParams.get('CategoryIdH1');
    const CategoryName = queryParams.get('CategoryName');
    const CategoryIdH2 = queryParams.get('CategoryIdH2');
    const CategoryIdH3 = queryParams.get('CategoryIdH3');
    var Heading1 = "Category";
    var Heading2 = "Sub Category (H1)";
    const ParentCategory = localStorage.getItem('Category');
    const CategoryH1 = localStorage.getItem('CategoryH1');
    const CategoryH2 = localStorage.getItem('CategoryH2');
    useEffect(() => {
        // if (CategoryIdH1) {
        //     if (CategoryH2) {
        //         setLocalSync('CategoryH2', '');
        //     }
        // }
        // if (CategoryId) {
        //     if (CategoryH1) {
        //         setLocalSync('CategoryH1', '');
        //         if (CategoryH2) {
        //             setLocalSync('CategoryH2', '');
        //         }
        //     }
        // }
        return () => { }
    }, [ParentId, CategoryId]);

    useEffect(() => {
        InvestorSubCategoryList((ParentId ? { ParentId, CategoryId } : { IsSubCategory: "", ParentId: "", ParentIds: "1,2,3" }))
        return () => { }
    }, [ParentId, CategoryId])

    useEffect(() => {
        // console.log('filter',filter);
        if (Object.keys(filter).length > 0) {
            InvestorSubCategoryList(filter);
        }
        return () => { }
    }, [filter])

    useEffect(() => {
        InvestorSubCategoryListdrop({ IsActive: "1" }, FilterInvesotorCategoryList)
        return () => { }
    }, [FilterInvesotorCategoryList])

    useEffect(() => {
        if (InvestorCategoryListFilterArr.length > 0)
            InvestorCategoryList = InvestorCategoryListFilterArr;
        return () => { }
    }, [InvestorCategoryListFilterArr]);

    const setLocalSync = (category, categoryName) => {
        localStorage.setItem(category, categoryName);
    }

    if ((ParentCategory && CategoryH1) && !CategoryH2) {
        Heading2 = 'Sub Category (H2)';
    }

    if (ParentCategory && CategoryH1 && CategoryH2) {
        Heading2 = 'Sub Category (H3)';
    }

    const handleAddColumn = (columns) => {
        if (CategoryH1) {
            const newColumn = {
                sequence: '3',
                title: 'Sub Category (H1)',
                dataIndex: 'Category',
                key: 'CategoryH2',
                className: 'text-capitalize',
                render: (value, item, index) => {
                    {
                        return ((() => {

                            return (
                                <span /* className='cursor-pointer' onClick={() => {
                                    setLocalSync('CategoryH1', CategoryH1);
                                }} */ >
                                    {CategoryH1}
                                </span>
                            )
                        })()
                        )
                    }
                }
            };
            var columns = [...columns, newColumn];
            columns = columns.sort((a, b) => a['sequence'] - b['sequence']);
            setColumns(columns);
        }
        if (CategoryH2) {
            const newColumn = {
                sequence: '4',
                title: 'Sub Category (H2)',
                dataIndex: 'Category',
                key: 'CategoryH2',
                className: 'text-capitalize',
                render: (value, item, index) => {
                    {
                        return ((() => {
                            return (
                                <span  /* onClick={() => {
                                    setLocalSync('CategoryH1', CategoryH2);
                                }} */ >
                                    {CategoryH2}
                                </span>
                            )
                        })()
                        )
                    }
                }
            };
            var columns = [...columns, newColumn];
            columns = columns.sort((a, b) => a['sequence'] - b['sequence']);
            setColumns(columns);
        }
    }

    useEffect(() => {
        handleAddColumn(columns);
        return () => { }
    }, [InvestorCategoryLists]);



    useEffect(() => {
        setColumns([
            {
                sequence: '1',
                title: 'S.No.',
                dataIndex: 'sno',
                key: 'sno',
                render: (value, item, index) => (page - 1) * 10 + index + 1
            },

            {
                sequence: '2',
                title: Heading1,
                dataIndex: 'Category',
                key: 'Category',
                className: 'text-capitalize',
                render: (value, item, index) => {
                    {
                        return ((() => {
                            if (ParentCategory) {
                                return (
                                    <span /*  onClick={() => {
                                        setLocalSync('Category', ParentCategory);
                                    }} */ >
                                        {ParentCategory}
                                    </span>
                                )
                            } else {
                                return (
                                    <Tooltip title="View Sub Category">
                                        <span className='cursor-pointer' onClick={() => {
                                            setLocalSync('Category', item?.ParentCategory?.Category);
                                        }} >
                                            {/* <a href={`ParentCategory=${item?.ParentCategory?.Id}`}> */}
                                            {item?.ParentCategory?.Category}
                                            {/* </a> */}
                                        </span>
                                    </Tooltip>

                                )
                            }
                        })()
                        )
                    }
                }
            },


            {

                sequence: '5',
                title: Heading2,
                dataIndex: 'Category',
                key: 'PageName',
                className: 'text-capitalize',
                render: (value, item, index) => {
                    return (
                        <>
                            {(() => {
                                if (CategoryId && !CategoryIdH1 && !CategoryIdH2) {
                                    return (
                                        <Tooltip title="View Sub Category">
                                            <span className='cursor-pointer'>
                                                <a onClick={() => {
                                                    setLocalSync('CategoryH1', item?.Category);
                                                    setLocalSync('CategoryH1Url', currentUrl);
                                                }} href={`investor-subcategory?ParentCategory=${item?.Id}&CategoryId=${item?.Id}&CategoryIdH1=1&CategoryName=${item?.Category}`}>
                                                    {item?.Category}
                                                </a>
                                            </span>
                                        </Tooltip>
                                    )
                                } else if (CategoryId && CategoryIdH1 && !CategoryIdH2) {
                                    return (
                                        <Tooltip title="View Sub Category">
                                            <span className='cursor-pointer'>
                                                <a onClick={() => {
                                                    setLocalSync('CategoryH2', item?.Category);
                                                    setLocalSync('CategoryH2Url', currentUrl);
                                                }} href={`investor-subcategory?ParentCategory=${item?.Id}&CategoryId=${item?.Id}&CategoryIdH2=1&CategoryName=${item?.Category}`}>
                                                    {item?.Category}
                                                </a>
                                            </span>
                                        </Tooltip>
                                    )
                                } else if (CategoryId && !CategoryIdH1 && CategoryIdH2) {
                                    return <span> {item?.Category}</span>

                                } else {
                                    <Tooltip title="View Sub Category">
                                        <span className='cursor-pointer'>
                                            <a onClick={() => {
                                                setLocalSync('Category', item?.Category);
                                            }} href={`investor-subcategory?ParentCategory=${item?.Id}&CategoryId=${item?.Id}&CategoryName=${item?.Category}`}>
                                                {item?.Category}
                                            </a>
                                        </span>
                                    </Tooltip>
                                }
                            })()}
                        </>

                    )
                }

            },
            {
                sequence: '5',
                title: 'Type',
                dataIndex: 'Type',
                key: 'Type',
                className: 'text-capitalize',
                render: (value, item, index) => {
                    return (
                        <span>{item?.Type ? Type[item?.Type] : ""}</span>
                    )
                }
            },

            {
                sequence: '6',
                title: 'Status',
                dataIndex: 'Status',
                key: 'Status',
                render: (value, item, index) => {
                    return (
                        <div><span className={item?.Status ? "status_active" : "status_inactive"}>{item?.Status ? "Active" : "Inactive"}</span>
                        </div>
                    )
                }
            },
            {
                sequence: '7',
                title: 'Actions',
                dataIndex: 'action',
                key: 'action',
                render: (value, item, index) => {
                    return (
                        <>
                            <Tooltip title="Edit">
                                <span className='cursor-pointer' onClick={() => navigate(`/investor-subcategory/edit/${item?.Id}?${queryString}`)}>
                                    <SvgIcon.Edit className="scale-icon edit-icon" />
                                </span>
                            </Tooltip>

                        </>
                    )
                }
            },
        ]);
        return () => { }
    }, []);

    return (
        <div>
            <div className='flex'>
                <HeadingBack title={"Investor Sub Category"} subHeading={`Total ${totalSubCategory || 0} Sub Category`} />
                <div className='flex job-post-add'>
                    <Tooltip title="Add">
                        <span onClick={() => navigate("/investor-subcategory/add?" + queryString)} className='jop_btn'>Add<SvgIcon.Add width={40} height={40} className="cursor-pointer" /></span>
                    </Tooltip>
                </div>
            </div>
            <div className='tableResponsive mt-10'>
                <Table
                    rowKey='UsrId'
                    className={isLoading ? 'table antd_table no_data_found_hide' : 'table antd_table'}
                    bordered
                    columns={columns}
                    dataSource={Array.isArray(InvestorCategoryLists) && InvestorCategoryLists?.length > 0 ? InvestorCategoryLists : []}
                    pagination={{
                        current: page,
                        pageSize: limit,
                        total: totalSubCategory,
                        onChange(current, pageSize) {
                            setPage(current);
                            setOffset(current == 1 ? 0 : (limit * current));
                        },
                        hideOnSinglePage: true
                    }}

                />
            </div>
        </div>
    )
}

export default InvestorSubCategory