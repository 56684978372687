import React, { useEffect } from 'react'
import { Form, Input, Select, Row, Col } from 'antd'
import { GetLoggedInUser } from '../../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { FaqServices } from '../../../redux/Services';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import HeadingBack from '../../../component/HeadingBack';
import { usePermission } from '../../../hooks/usePermission';
const { Option } = Select;

const EditFaqCategory = () => {
    const permission = usePermission("Faq Category", "Edit");
    let LOG_IN_USER = GetLoggedInUser();
    const { faqCategoryId } = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const faqCategoryData = useSelector(state => state.faq.faqCategoryDetail || {});
    const { mutate: fetchFaqCategoryById } = useMutation((data) => dispatch(FaqServices.fetchFaqCategoryByIdApi(data, faqCategoryId)));
    const { mutate: updateFaqCategory } = useMutation((data) => dispatch(FaqServices.updateFaqCategoryApi(data, faqCategoryId, navigate)));
    useEffect(() => {
        if (faqCategoryId) {
            fetchFaqCategoryById({});
        }
        return () => { }
    }, [faqCategoryId])

    useEffect(() => {
        if (faqCategoryData && Object.keys(faqCategoryData)?.length > 0) {
            form.setFieldValue('Category', faqCategoryData?.Category);
            form.setFieldValue('Status', faqCategoryData?.Status);
        }
    }, [faqCategoryData])

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            if (faqCategoryId) {
                let _data = { ...values };
                _data.UserId = LOG_IN_USER?.id;
                updateFaqCategory(_data)
            }
        })
    }
    return (
        <>
            <HeadingBack title={"Faq Category Edit"} subHeading="" />
            <div className='p-20'>
                <Row>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        < Form
                            form={form}
                            name="basic"
                            layout={"vertical"}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Category"
                                name="Category"
                                rules={[{ required: true, message: 'Category is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Category' />
                            </Form.Item>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                    name="Status"
                                >
                                    <Option className='text-capitalize' value={0}>Inactive</Option>
                                    <Option className='text-capitalize' value={1}>Active</Option>
                                </Select>
                            </Form.Item>
                            <div className='text-center'>
                                <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit}>
                                    Update
                                </button>
                            </div>
                        </Form >
                    </Col>
                </Row>
            </div >
        </>
    )
}

export default EditFaqCategory