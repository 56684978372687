import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    DownloadReportList: [],
    totalDownloadReportList: [],
    DownloadReportDetail: {},
}

export const DownloadReportSlice = createSlice({
    name: 'DownloadReport',
    initialState,
    reducers: {
      
        setDownloadReportList: (state, action) => {
            state.DownloadReportList = action.payload.list;
            state.totalDownloadReportList = action.payload.total;
        },
        setDownloadReportDetail: (state, action) => {
            state.DownloadReportDetail = action.payload;
        },
    },
})

export const { setDownloadReportList, setDownloadReportDetail} = DownloadReportSlice.actions

export default DownloadReportSlice.reducer