import React, { useEffect, useState } from 'react'
import { JsonEditor as Editor } from "jsoneditor-react";
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { InvestorItemServices } from '../../redux/Services';
import "jsoneditor-react/es/editor.min.css";

const JsonEditor = () => {
    const dispatch = useDispatch();
    const [info, setInfo] = useState();
    const { mutate: fetchInvestorInformationApi } = useMutation((data) => dispatch(InvestorItemServices.fetchInvestorInformation(data)));
    const { mutate: updateInvestorInfoJsonApi } = useMutation((data) => dispatch(InvestorItemServices.updateInvestorInfoJson(data)));

    const geInfo = () => {
        fetchInvestorInformationApi({}, {
            onSuccess: (res) => {
                setInfo(res.data?.Data);
            }
        });
    }

    useEffect(() => {
        geInfo();
    }, [])
    
    const handleSubmit = () => {
        updateInvestorInfoJsonApi({jsonData:JSON.stringify({Data:info})}, {
            onSuccess: (res) => {
                geInfo();
            }
        });
    }
    return (
        <div>
            {info &&
                <Editor value={info} onChange={(obj) => setInfo(obj)} />
            }
            <div className='text-center'>
                <button type="button" className='gradient-button w-auto' onClick={handleSubmit} disabled={!info}>
                    Update
                </button>
            </div>
        </div>
    )
}

export default JsonEditor