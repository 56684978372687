import { current } from '@reduxjs/toolkit';
import { Col, Input, Row, Select,DatePicker } from 'antd'
import React, { useState, useEffect } from 'react'
import moment from 'moment';
const { Option } = Select;
const SearchFilter = ({ filter, setFilter, setPage, setOffset, couponCategoryList, limit }) => {
    const [values, setValues] = useState({
        InvoiceNumber: "",
        CategoryId: "",
        FromDate: "",
        ToDate: ""
    });
   
    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value});
    }
    const handleFilter = () => {
        setFilter({ ...filter, ...values });
        setPage(1);
     
    }
    const handleFilterReset = () => {
        setFilter({});
        // limit(20);
        setValues({
            ...values,
            InvoiceNumber: "",
            CategoryId: "",
            FromDate: "",
            ToDate:"",
         
        })
        setPage(1);
    }
    
    const BtlOffersId = [
        4, 5
    ];
  
    return (
        <div className='table_filter'>
            <Row gutter={[24, 20]}>               
            <Col md={5} sm={5} xs={24}>
            <strong><p className='mb-2'>Select Category</p></strong>
                    <Select
                        className='text-capitalize w-100'
                        placeholder='Select Category'
                        autoComplete="new-off"
                        onChange={(value) => handleChange("CategoryId", value)}
                        value={values?.CategoryId || undefined}
                    >
                        <Option className='text-capitalize'>Select Category</Option>

                        {couponCategoryList?.length > 0 && couponCategoryList?.map((item, idx) =>
                            (BtlOffersId.includes(item.Id)) ?
                                <Option key={idx} className='text-capitalize' value={item.Id}>{item.Name}</Option>
                                : ''
                        )}
                    </Select>
                </Col>
                <Col md={5} sm={5} xs={24}>
                <strong><p className='mb-2'>Invoice Number</p></strong>
                    <Input
                        type="text"
                        autoComplete="new-off"
                        className='form-control'
                        onChange={(e) => handleChange("InvoiceNumber", e.target.value)}
                        placeholder='Invoice Number'
                        value={values?.InvoiceNumber}
                    />
                </Col>
                <Col md={5} sm={12} xs={24}>
                <strong><p className='mb-2'>From Date</p></strong>
                    <Input
                        type="date"
                        id="date"
                        autoComplete="new-off"
                        className='form-control'
                        min="2023-01-01" 
                        // id="date"
                        // max="3000-04-20"
                        onChange={(e) => handleChange("FromDate", e.target.value)}
                     
                        placeholder='From Date'
                        value={values?.FromDate}
                    />
                     {/* <DatePicker 
                    onChange={(e) => handleChange("FromDate", e.target.value)}
                     
                     /> */}
                       {/* <DatePicker onChange={(e) => handleChange("FromDate", e.target.value)}/> */}
                </Col>
                <Col md={5} sm={12} xs={24}>
                <strong><p className='mb-2'>To Date</p></strong>
                    <Input
                        type="date"
                        id="todate"
                        autoComplete="todate"
                        className='form-control'
                        onChange={(e) => handleChange("ToDate", e.target.value)}
                        min={values?.FromDate?values?.FromDate :"2023-01-01"}
                        placeholder='To Date'
                        value={values?.ToDate}
                    />
                </Col>
                <Col md={4} sm={12} xs={24}>
                    <div className='flex gap10 mt-20'>
                        <button className='filter-button' onClick={() => handleFilter()}>Filter</button>
                        <button className='clear-button' onClick={() => handleFilterReset()}>Reset</button>
                    </div>
                </Col>
            </Row>
        </div >
    )
}

export default SearchFilter

// import { Col, Input, Row } from 'antd'
// import React from 'react'

// const SearchFilter = ({onChange}) => {
//     return (
//         <>
//         <Row gutter={[12, 24]} className="ml-10">
//             <Col /* md={6} sm={12} xs={24} */>
//                 {/* <strong><p className='mb-2 f-14'>Search</p></strong> */}
//                 <Input
//                 style={{width: "250px"}}
//                     type="text"
//                     autoComplete="new-off"
//                     className='form-control'
//                     onChange={(e)=> onChange(e.target.value)}
//                     placeholder='Search'
//                 />
//            </Col>
//         </Row>
//         </>
//     )
// }

// export default SearchFilter
