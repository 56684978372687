import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    noPermissionMessage: false,
    notification: [],
    totalNotification: 0,
    cityList: []
}

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setNotification: (state, action) => {
            state.totalNotification = action.payload.totalNotification
        },
        setNoPermission: (state, action) => {
            state.noPermissionMessage = action.payload;
        },
        setCityList: (state, action) => {
            state.cityList = action.payload;
        },
    },
})

export const { setNotification, setNoPermission,setCityList } = commonSlice.actions

export default commonSlice.reducer