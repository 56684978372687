import { Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeadingBack from '../../../component/HeadingBack';
import SvgIcon from '../../../const/SvgIcon';
import { JobApplicationsServices } from '../../../redux/Services';
import JobAppFilter from './jobFilter';
import URL from "../../../api/url";
import { usePermission } from '../../../hooks/usePermission';
import { ExportToExcel } from '../../../component/ExportToExcel';
import { JobPostServices } from '../../../redux/Services';
import { FileExcelFilled } from '@ant-design/icons';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import moment from 'moment';
import axios from 'axios';
import { GetToken } from "../../../utils";
const Application = {
    "0": 'Pending',
    "1": 'In Review',
    "2": 'Accepted',
    "3": 'Rejected',
}

const JobApplications = () => {

    const permission = usePermission("Job Applications", "List");
    const navigate = useNavigate();
    const [filter, setFilter] = useState({});
    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0);
    const [isFilter, setisFilter] = useState(0);
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const jobApplicationsList = useSelector(state => state.jobAppliction.jobApplicationList || []);
    const StateCityList = useSelector(state => state.jobAppliction.StateCityList || []);
    const jobsPostList = useSelector(state => state.jobPost.jobPostList || []);
    const totalJobApp = useSelector(state => state.jobAppliction.totalJobApp || 0);
    const { mutate: getStateCityList } = useMutation((data) => dispatch(JobApplicationsServices.fetchStateCityListApi(limit, offset, data)));
    const { mutate: getJobApplicationsList, isLoading } = useMutation((data) => dispatch(JobApplicationsServices.fetchJobApplicationsListApi(limit, offset, data)));
    const { mutate: getJobAddList } = useMutation((data) => dispatch(JobPostServices.fetchJobAddListApi(limit, offset, data)));
    const fileName = "Application report";
    useEffect(() => {
        getJobApplicationsList(filter);
        return () => { }
    }, [filter, offset, limit])
    useEffect(() => {
        getJobAddList({ IsActive: "1" });
        return () => { }
    }, [])


    useEffect(() => {
        getStateCityList(filter);
        return () => { }
    }, [])
    const [data, setData] = React.useState([])

    const handleLinkdin = (e, url) => {
        window.location.href = url;

    }
    useEffect(() => {
        if (data && data.length > 0) {
            ExportToExcel(data, fileName);
        }
        return () => { }
    }, [data]);
    const downloadExcelData = async () => {

        filter.setOffset = 0;
        filter.Limit = 10000;
        axios.post(URL.API_BASEURL + '/' + URL.JOBAPPLICATIONS_LIST, filter,
            {
                headers: {
                    'token': GetToken()
                }
            }).then(getData => {

                setData(getData.data.Data)
            })
    }
    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
            className:'table_first_col',
            render: (value, item, index) => (page - 1) * 20 + index + 1
        },

        {
            title: 'First Name',
            dataIndex: 'FirstName',
            key: 'FirstName',
            className: 'text-capitalize',
        },
        {
            title: 'Last Name',
            dataIndex: 'LastName',
            key: 'Name',
            className: 'text-capitalize',
        },
        {
            title: 'Email',
            dataIndex: 'Email',
            key: 'Email',
            width: '250px'
        },
        {
            title: 'Mobile',
            dataIndex: 'Mobile',
            key: 'Mobile',
        },
        {
            title: 'State',
            dataIndex: 'State',
            key: 'State',
        },
        {
            title: 'City',
            dataIndex: 'City',
            key: 'City',
            render: (value, item, index) => {
                return (
                    <span>{(item.City) ? item.City : item.CandidateLocation}</span>
                )
            }
        },
        {
            title: 'Experience (Years)',
            dataIndex: 'Experience',
            key: 'Experience',
        },
        {
            title: 'Current Company',
            dataIndex: 'CurrentCompany',
            key: 'Current Company',
            render: (value, item, index) => {
                return (
                    <span>{item.CurrentCompany ? item.CurrentCompany : "NA"}</span>
                )
            }
        },
        {
            title: 'Current Salary',
            dataIndex: 'CurrentSalary',
            key: 'Current Salary',
            render: (value, item, index) => {
                return (
                    <span>{item.CurrentSalary ? item.CurrentSalary : "NA"}</span>
                )
            }
        },
        {
            title: 'Expected Salary',
            dataIndex: 'ExpectedSalary',
            key: 'Expected Salary',
            render: (value, item, index) => {
                return (
                    <span>{item.ExpectedSalary ? item.ExpectedSalary : "NA"}</span>
                )
            }
        },
        {
            title: 'LinkedIn Link',
            dataIndex: 'LinkedLink',
            key: 'LinkedLink',
            render: (value, item, index) => {
                return (
                    item.LinkedLink ? <a style={{ textDecoration: "underline" }} onClick={(e) => handleLinkdin(e, item.LinkedLink)}>View</a> : 'NA'
                )
            }
        },
        {
            title: 'CV Document',
            dataIndex: 'ProfileDocumentName',
            key: 'ProfileDocumentName',
            render: (value, item, index) => {
                return (
                    item.ProfileDocumentName ? <a style={{ textDecoration: "underline" }} href={URL.DOCUMENTPATH + item.ProfileDocumentName}>View</a> : 'NA'
                )
            }
        },
        {
            title: 'Preferred Job Location',
            dataIndex: 'PreferJobLocation',
            key: 'PreferJobLocation',
            render: (value,item,index) =>{
                return (
                    item.PreferJobLocation ? item.PreferJobLocation :"NA"
                )
            }
        },

        {
            title: 'Work Status',
            dataIndex: 'WorkStatus',
            key: 'WorkStatus',
            className: 'text-capitalize',
        },
        {
            title: 'Current Role',
            dataIndex: 'CurrentRole',
            key: 'CurrentRole',
            className: 'text-capitalize',
            render: (value,item,index) =>{
                return (
                    item.CurrentRole ? item.CurrentRole :"NA"
                )
            }
        },
        {
            title: 'Job Role',
            dataIndex: 'JobName',
            key: 'JobId',
            className: 'text-capitalize',
            render: (value,item,index) =>{
                return (
                    item.JobName ? item.JobName :"NA"
                )
            }
        },

        {
            title: 'Application Status',
            dataIndex: 'ApplicationStatus',
            key: 'Status',
            render: (value, item, index) => {
                return (
                    <span>{item?.ApplicationStatus ? Application[item?.ApplicationStatus] : ""}</span>
                )
            }
        },
        {
            title: 'Remarks',
            dataIndex: 'Remarks',
            key: 'Remarks',
            className: 'text-capitalize',
            render: (value, item, index) => {
                return (
                    <div
                        dangerouslySetInnerHTML={{ __html: item.Remarks ? item.Remarks : "NA"}}
                    />
                )
            }
        },
        {
            title: 'Created Date',
            dataIndex: 'CreatedDate',
            key: 'Createddate',
            render: (value, item, index) => {
                return (
                  <>{moment(item.CreatedDate).format('DD-MM-YYYY H:mm:ss')}</>
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            className: 'table_action_col',
            render: (value, item, index) => {
                return (
                    <Tooltip title="Edit">
                        <span className='cursor-pointer' onClick={() => navigate(`/job-applications/edit/${item?.Id}`)}>
                            <SvgIcon.Edit className="scale-icon edit-icon" />
                        </span>
                    </Tooltip>
                )
            }
        },
    ];
    return (
        <div>
            <div className='position-relative'>
                <div className='flex'>
                    <HeadingBack title={"Total Application"} subHeading={`Total ${totalJobApp || 0} Application`} />
                    <JobAppFilter filter={filter} setFilter={setFilter} setPage={setPage} setOffset={setOffset} StateCityList={StateCityList} limit={setLimit} isFilter={setisFilter} jobsPostList={jobsPostList} />

                </div>
                <div className="ex-export">
                    <a className="btn btn-exl" apiData={data} fileName={fileName} onClick={(e) => downloadExcelData()} ><FileExcelFilled /></a>
                </div>

            </div>
            <div className='tableResponsive mt-10'>
                <Table
                    rowKey='UsrId'
                    className={isLoading ? 'table antd_table no_data_found_hide' : 'table antd_table'}
                    bordered
                    columns={columns}
                    dataSource={Array.isArray(jobApplicationsList) && jobApplicationsList?.length > 0 ? jobApplicationsList : []}
                    pagination={{
                        current: page,
                        pageSize: limit,
                        total: totalJobApp,
                        onChange(current, pageSize) {
                            setPage(current);
                            setOffset(current == 1 ? 0 : ((limit * current) - 20));
                        },
                        hideOnSinglePage: true
                    }}

                />
            </div>
        </div>
    )
}

export default JobApplications