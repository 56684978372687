import React from 'react'
import { downloadFile } from '../../utils';

const DownLoadDocLink = ({ file, label = "View", downloadName }) => {
    return (
        <>
            {file ? <a style={{ textDecoration: "underline" }} onClick={(e) => { e.preventDefault(); downloadFile(file, downloadName); }} href="#">{label}</a> : 'NA'}
        </>
    )
}

export default DownLoadDocLink