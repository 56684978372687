import { current } from '@reduxjs/toolkit';
import { Col, Input, Row, Select, message, Tooltip } from 'antd'
import React, { useState, useEffect } from 'react'
import { FileExcelFilled } from '@ant-design/icons';
import { CSVLink } from "react-csv"
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getBusinessProfession } from "../../utils";

const { Option } = Select;
const PartnerFilter = ({ filter, setFilter, setPage, setOffset, limit, isFilter, }) => {
    const [values, setValues] = useState({
        FromDate: "",
        ToDate: "",

    });
    const [FilteredData, setFilteredData] = useState([]);
    const PartnerList = useSelector(state => state.jobAppliction.PartnerList || []);
    useEffect(() => {
        
        if (PartnerList && PartnerList.length > 0) {
            var data = [];
            PartnerList.map((value, index) => {
                var item = {
                    "ID": value?.ID,
                    "FristName": value?.FristName,
                    "LastName": value?.LastName,
                    "Mobile": value?.Mobile,
                    "EmailID": value?.EmailID,
                    "Address": value?.Address,
                    "Pincode": value?.Pincode,
                    "State": value?.State,
                    "City": value?.City,
                    "Ownership": value?.Ownership,
                    "Business_profession": value?.Business_profession ? getBusinessProfession(value?.Business_profession) : 'UA',
                    "IsOwnSpace": value?.IsOwnSpace,
                    "Association": value?.Association,
                    "Is_Relative_working": value?.Is_Relative_working,
                    "Is_Relative_Centers": value?.Is_Relative_Centers,
                    "Is_Relative_direct_competition": value?.Is_Relative_direct_competition,
                    "InsertDateTime": value?.InsertDateTime,
                    "Status": value?.Status
                } 

                data.push(item);
                // setFilteredDat([...item])
            })
            setFilteredData(data);
        }
        return () => { }
    }, [PartnerList])
    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value });
    }
    const handleFilter = () => {
        setFilter({ ...filter, ...values });
        setPage(1);
        setOffset(0);
        limit(100);
        isFilter(1);
    }
    const handleFilterReset = () => {
        setFilter({});
        limit(100);
        setValues({
            ...values,
            FromDate: "",
            ToDate: "",

        })
        setPage(1);
        isFilter(0);
    },
        headers = [
            { label: "First Name", key: "FristName" },
            { label: "Last Name", key: "LastName" },
            { label: "Email Id", key: "EmailID" },
            { label: "Mobile Number", key: "Mobile" },
            { label: "Profession", key: "Business_profession" },
            { label: "State", key: "State" },
            { label: "City", key: "City" },
            { label: "Pincode", key: "Pincode" },
            { label: "Created Date", key: "InsertDateTime" },
        ];
    return (
        <div className='table_filter w-80'>
            <Row gutter={[10, 20]} className="ml-10">
                <Col md={8} sm={12} xs={24}>
                    <strong>  <p className='mb-2'>From Date</p></strong>
                    <Input
                        type="date"
                        id="fromdate"
                        autoComplete="new-off"
                        className='form-control'
                        onChange={(e) => handleChange("FromDate", e.target.value)}
                        max={moment().format("YYYY-MM-DD")}
                        placeholder='From Date'
                        value={values?.FromDate}
                    />
                </Col>
                <Col md={8} sm={12} xs={24}>
                    <strong>  <p className='mb-2'>To Date</p></strong>
                    <Input
                        type="date"
                        id="todate"
                        autoComplete="todate"
                        className='form-control'
                        onChange={(e) => handleChange("ToDate", e.target.value)}
                        min={values?.FromDate}
                        placeholder='To Date'
                        value={values?.ToDate}
                    />
                </Col>
                <Col md={4} sm={12} xs={24}>
                    <div className='flex gap10 mt-20'>
                        <button className='filter-button' onClick={() => handleFilter()}>Filter</button>
                        <button className='clear-button' onClick={() => handleFilterReset()}>Reset</button>
                    </div>
                </Col>
                <Col md={4} sm={12} xs={24} className='mt-20'>
                    <CSVLink
                        filename={"Become a Partner.csv"}
                        data={FilteredData}
                        headers={headers}
                        className="ex-export2 part-export"
                        onClick={() => {
                            message.success("The file is downloading")
                        }}
                    >
                        <Tooltip placement="top" title={"A user can download only single page data once."} arrow={false}>
                            <FileExcelFilled />
                        </Tooltip>
                    </CSVLink>
                </Col>
            </Row>

        </div >
    )
}

export default PartnerFilter