import React from 'react'
import { Form, Input, Select, Row, Col } from 'antd'
import { GetLoggedInUser } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { DepartmentServices } from '../../redux/Services';
import HeadingBack from '../../component/HeadingBack';
import { usePermission } from '../../hooks/usePermission';
const { Option } = Select;

const AddDepartmentCategory = () => {
    const permission = usePermission("Department Management", "Add");
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { mutate: addDepartment } = useMutation((data) => dispatch(DepartmentServices.addDepartmentApi(data, navigate)));
    const handleSubmit = () => {
        form.validateFields().then((values) => {
            let _data = { ...values };
            _data.UserId = LOG_IN_USER?.id;
            addDepartment(_data)
        })
    }
    return (
        <>
            <HeadingBack title={"Department Add"} subHeading="" />
            <div className='p-20'>
                <Row>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        < Form
                            form={form}
                            name="basic"
                            layout={"vertical"}
                            autoComplete="off"
                            initialValues={{ Status: "1" }}
                        >
                            <Form.Item
                                label="Department Name"
                                name="DepartmentName"
                                rules={[{ required: true, message: 'Department Name is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Department Name' />
                            </Form.Item>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                    name="Status"
                                >
                                    <Option className='text-capitalize' value={"0"}>Inactive</Option>
                                    <Option className='text-capitalize' value={"1"}>Active</Option>
                                </Select>
                            </Form.Item>
                            <div className='text-center'>
                                <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit}>
                                    Submit
                                </button>
                            </div>
                        </Form >
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default AddDepartmentCategory