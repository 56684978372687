import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   zoneList: [],
    totalzoneList: [],
    zoneListByIdDetail: {},
    leadPageDetail: {},
    totalTeamMemberList: [],
}

export const leadSlice = createSlice({
    name: 'lead',
    initialState,
    reducers: {
        setZoneList: (state, action) => {
            state.zoneList = action.payload.list;
            state.totalzoneList = action.payload.total;
        },
        setZoneListByIdDetail: (state, action) => {
            state.zoneListByIdDetail = action.payload;
        },
        setLeadPageList: (state, action) => {
            state.leadPageList = action.payload.list;
            state.totalLeadPageList = action.payload.total;
        },
        setLeadPageDetail: (state, action) => {
            state.leadPageDetail = action.payload;
        },
        setTestCodeList: (state, action) => {
            state.TestCodeList = action.payload.list;
          state.TotalRecord = action.payload.total;
        },
    },
})

export const { setZoneList, setZoneListByIdDetail, setLeadPageList,setLeadPageDetail} = leadSlice.actions

export default leadSlice.reducer