import { Popconfirm, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeadingBack from '../../../component/HeadingBack';
import SvgIcon from '../../../const/SvgIcon';
import { InvestorCategoriesServices } from '../../../redux/Services';
import moment from 'moment';
import { usePermission } from '../../../hooks/usePermission';
import { EyeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';

const InvestorCategory = () => {

    var currentUrl = window.location.href;
    const permission = usePermission("Seo Pages", "List");
    const navigate = useNavigate();
    const [filter, setFilter] = useState({});
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const InvestorCategoryLists = useSelector(state => state.invesotorCategory.InvestorCategoryList || []);
    const totalaInvestorCategory = useSelector(state => state.invesotorCategory.TotalRecord || 0);
    const { mutate: InvestorCategoryList, isLoading } = useMutation((data) => dispatch(InvestorCategoriesServices.fetchInvestorListApi(limit, offset, data)));
    useEffect(() => {
        InvestorCategoryList(filter)
        localStorage.setItem('Category', '');
        localStorage.setItem('CategoryH1', '');
        localStorage.setItem('CategoryH2', '');
        localStorage.setItem('CategoryUrl', '');
        localStorage.setItem('CategoryH1Url', '');
        localStorage.setItem('CategoryH2Url', '');
        return () => { }

    }, [filter, offset])
    // console.log(InvestorCategoryList)
    const setLocalSync = (category, categoryName) => {
        localStorage.setItem(category, categoryName);
    }
    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
            render: (value, item, index) => (page - 1) * 10 + index + 1
        },

        {
            title: 'Category',
            dataIndex: 'Category',
            key: 'investor-management',
            className: 'text-capitalize',
            render: (value, item, index) => {
                return (
                    <Tooltip title="View Sub Category"> <span className='cursor-pointer c-bl' onClick={function (event) { navigate(`/investor-subcategory?ParentCategory=${item?.Id}&CategoryId=${item?.Id}&CategoryName=${item?.Category}`); setLocalSync('Category', item?.Category); setLocalSync('CategoryUrl', currentUrl); }} >{item?.Category}</span></Tooltip>
                )
            }
        },
        {
            title: 'Slug',
            dataIndex: 'Slug',
            key: 'investor-management'
        },

        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            render: (value, item, index) => {
                return (
                    <span className={item?.Status ? "status_active" : "status_inactive"}>{item?.Status ? "Active" : "Inactive"}</span>
                )
            }
        },
        /* {
            title: 'Sub Category',
            dataIndex: 'SubCategory',
            key: 'SubCategory',
            render: (value, item, index) => {
                return (
                    <Tooltip title="View Sub Category"> <span className='cursor-pointer' onClick={() => navigate(`/investor-subcategory?ParentCategory=${item?.Id}&CategoryId=${item?.Id} &CategoryName=${item?.Category}`)} ><EyeOutlined className="scale-icon faq-icon" /></span></Tooltip>
                )
            }
        }, */
        // {
        //     title: 'Actions',
        //     dataIndex: 'action',
        //     key: 'action',
        //     render: (value, item, index) => {
        //         return (
        //             <Tooltip title="Edit">
        //                 <span className='cursor-pointer' onClick={() => navigate(`/investor-category/edit/${item?.Id}`)}>
        //                     <SvgIcon.Edit className="scale-icon edit-icon" />
        //                 </span>
        //             </Tooltip>
        //         )
        //     }
        // },
    ];
    const handlePublish = () => {

    }
    return (
        <div>
            <div className='flex'>
                <HeadingBack title={"Investor Category"} subHeading={`Total ${totalaInvestorCategory || 0} Category`} />
                {/* <div className='flex job-post-add'>
                    <Tooltip title="Publish">
                        <Popconfirm
                            placement="bottomRight"
                            title={<div style={{fontWeight: 500, maxWidth: "300px" }}>
                                Do you want to publish all the information/ data from the Financials, Investor Information & Corporate Governance to the website?
                            </div>}
                            onConfirm={handlePublish}
                            okText="Yes"
                            cancelText="No"
                        >
                            <span className='jop_btn'>Publish</span>
                        </Popconfirm>
                    </Tooltip>
                </div> */}
            </div>
            <div className='tableResponsive mt-10'>
                <Table
                    rowKey='UsrId'
                    className={isLoading ? 'table antd_table no_data_found_hide' : 'table antd_table'}
                    bordered
                    columns={columns}
                    dataSource={Array.isArray(InvestorCategoryLists) && InvestorCategoryLists?.length > 0 ? InvestorCategoryLists : []}
                    pagination={{
                        current: page,
                        pageSize: limit,
                        total: totalaInvestorCategory,
                        onChange(current, pageSize) {
                            setPage(current);
                            setOffset(current == 1 ? 0 : (limit * current));
                        },
                        hideOnSinglePage: true
                    }}

                />
            </div>
        </div>
    )
}

export default InvestorCategory


