import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    InvestorCategoryListDrop: [],
    InvestorCategoryListDrop2: [],
    InvestorCategoryListDrop3: [],
    InvestorCategoryListDrop4: [],
    InvestorCategoryListDrop5: [],
    InvestorCategoryListDrop9: [],
    InvestorCategoryListDrop10: [],
    InvestorCategoryList: [],
    InvestorCategoryListFilter: [],
    TotalRecord: '',
    InvestorCategoryDetails: {},

}

export const InvestorListSlice = createSlice({
    name: 'invesotorCategory',
    initialState,
    reducers: {
        setInvestorCategoryList: (state, action) => {
            state.InvestorCategoryListDrop = action.payload.list;
        },
        setInvestorCategoryList2: (state, action) => {
            state.InvestorCategoryListDrop2 = action.payload.list;

        }, setInvestorCategoryList3: (state, action) => {
            state.InvestorCategoryListDrop3 = action.payload.list;
        },
         setInvestorCategoryList4: (state, action) => {
            state.InvestorCategoryListDrop4 = action.payload.list;
        },  
        setInvestorCategoryList5: (state, action) => {
            state.InvestorCategoryListDrop5 = action.payload.list;
        },
        setInvestorCategoryList9: (state, action) => {
            state.InvestorCategoryListDrop9 = action.payload.list;
        },
        setInvestorCategoryList10: (state, action) => {
            state.InvestorCategoryListDrop10 = action.payload.list;
        },
        setInvestorList: (state, action) => {
            state.InvestorCategoryList = action.payload.list;
            state.TotalRecord = action.payload.total;
        },
        setInvestorListFilter: (state, action) => {
            state.InvestorCategoryListFilter = action.payload.list;
            state.TotalRecord = action.payload.total;
        },


        setInvestorCategroyDetails: (state, action) => {
            state.InvestorCategoryDetails = action.payload;
        },
    },
})

export const {setInvestorCategoryList10, setInvestorCategoryList9, setInvestorCategoryList, setInvestorCategoryList2, setInvestorCategoryList3,setInvestorCategoryList4,InvestorCategoryListDrop5, setInvestorList, setInvestorCategroyDetails, setInvestorListFilter,setInvestorCategoryList5 } = InvestorListSlice.actions

export default InvestorListSlice.reducer