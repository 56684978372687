import { Col, Form, Input, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import CustomEditor from '../../../component/CustomEditor/CustomEditor';
import { GetLoggedInUser, onlyMobileInput } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { InvestorCategoriesServices } from '../../../redux/Services';
import HeadingBack from '../../../component/HeadingBack';
import { usePermission } from '../../../hooks/usePermission';

const { Option } = Select;
const AddInvestorSubCategory = () => {
    const permission = usePermission("Seo Pages", "Add");
    onlyMobileInput();
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [seq, setSeq] = useState("H1");
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    // const ParentCategory = queryParams.get('ParentCategory');
    const ParentCategory = localStorage.getItem('Category');
    const CategoryH1 = localStorage.getItem('CategoryH1');
    const CategoryH2 = localStorage.getItem('CategoryH2');
    const CategoryId = queryParams.get('CategoryId');
    const Category = queryParams.get('Category');
    const InvestorCategoryList = useSelector(state => state.invesotorCategory.InvestorCategoryList || []);
    const { mutate: InvestorCategoryLists } = useMutation((data) => dispatch(InvestorCategoriesServices.fetchInvestorListApi(data)));
    const { mutate: addInvesetorSubCategory, isLoading: addLoading } = useMutation((data) => dispatch(InvestorCategoriesServices.addInvesetorSubCategoryApi(data, queryParams, navigate)));
    useEffect(() => {
        InvestorCategoryLists({ IsActive: "1", "ParentId": CategoryId });
        if (CategoryH1) {
            setSeq('H2')
        }
        if (CategoryH2) {
            setSeq('H3')
        }
        return () => { }
    }, [])
    useEffect(() => {
        if (ParentCategory) {
            form.setFieldValue('InvestorCategoryList', ParentCategory)
        }
        return () => { }
    }, [ParentCategory])

    // console.log(ParentCategory)
    // console.log(CategoryId)

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            let _data = { ...values };
            _data.UserId = LOG_IN_USER?.id;
            if (CategoryId) {
                _data.ParentId = CategoryId;
            }
            addInvesetorSubCategory(_data)
        })
    }

    return (
        <>
            <div className='d-flex'>
                <HeadingBack title={"Investor Sub Category Add"} subHeading="" />
                <h2>
                    {(() => {
                        if (ParentCategory == 'Financials' || ParentCategory == 'Investors Information' || ParentCategory == 'Corporate Governance' || ParentCategory == 'Quarterly Result') {
                            return <><h2>{ParentCategory}</h2></>
                        }
                    })()}</h2>
            </div>
            <div className='p-20'>
                < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                >
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            {ParentCategory ?
                                <Form.Item
                                    label={ParentCategory ? "Category" : "Category"}
                                >
                                    <Input autoComplete="new-off" className='form-control' placeholder='Sub Category Name' value={ParentCategory} disabled={true} />
                                </Form.Item>
                                :
                                <Form.Item
                                    label={ParentCategory ? "Category" : "Category"}
                                    name="ParentId"
                                    rules={[{ required: false, message: 'Category is required' }]}
                                >

                                    <Select
                                        className='from-control text-capitalize'
                                        placeholder='Select Category'
                                        autoComplete="new-off"
                                        name="ParentId"
                                    >
                                        <Option className='text-capitalize' value={""}>Select Category</Option>
                                        {InvestorCategoryList?.length > 0 && InvestorCategoryList?.map((item, idx) =>
                                            <Option key={idx} className='text-capitalize' value={item.Id}>{item.Category}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            }
                        </Col >
                        {CategoryH1 ? <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Sub Category (H1)"
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Sub Category Name' value={CategoryH1} disabled={true} />
                            </Form.Item>
                        </Col > : ""}
                        {CategoryH2 ? <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Sub Category (H2)"
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Sub Category Name' value={CategoryH2} disabled={true} />
                            </Form.Item>
                        </Col > : ""}
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label={`Sub Category (${seq})`}
                                name="Category"
                                rules={[{ required: true, message: 'Sub Category is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder={`Sub Category (` + seq + `)`} />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Type"
                                name="Type"
                                rules={[{ required: true, message: 'Type is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Type'
                                    autoComplete="new-off"
                                    name="Type"
                                >
                                    <Option className='text-capitalize' value={"1"}>List</Option>
                                    <Option className='text-capitalize' value={"2"}>Accordion</Option>
                                    <Option className='text-capitalize' value={"3"}>Box</Option>
                                    <Option className='text-capitalize' value={"4"}>Textarea</Option>
                                </Select>
                            </Form.Item>
                        </Col >

                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label={`Sub Category Slug (${seq})`}
                                name="Slug"
                                rules={[{ required: true, message: 'Category slug is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder={`Sub Category Slug (${seq})`} />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                    name="Status"
                                >
                                    <Option className='text-capitalize' value={"0"}>Inactive</Option>
                                    <Option className='text-capitalize' value={"1"}>Active</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>
                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit} disabled={addLoading}>
                            Submit
                        </button>
                    </div>
                </Form >
            </div>
        </>
    )
}

export default AddInvestorSubCategory