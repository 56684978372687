import { notification } from "antd";
import Api from "../../api";
import API_URL from "../../api/url";
import {setWhatsNewBannerList,setWhatsNewBannerBannerDetail} from "../Slices/whatsNewSlice";

export const fetchWhatsBannerListApi = (data) => async (dispatch) => {
    try {
        const res = await Api.get(API_URL.WHATS_BANNER_LIST, data);
        dispatch(setWhatsNewBannerList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};

export const fetchWhatsBannerDetailApi = (id) => async (dispatch) => {
    try {
        const res = await Api.post(`${API_URL.WHATS_BANNER_DETAILS}/${id}`);
        dispatch(setWhatsNewBannerBannerDetail(res.data.Data));
        return res;
    } catch (error) {
        return error
    }
};
export const addWhatsNewApi = (data, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.WHATS_BANNER_ADD, data);
        navigate(-1);
        notification["success"]({
            message: "Banner added successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const WhatsBannerDetailUpdateApi = (data, id, navigate) => async (dispatch) => {
    try {
        const res = await Api.put(`${API_URL.WHATS_BANNER_UPDATE}/${id}`, data);
        navigate(-1);
        notification["success"]({
            message: "Banner updated successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const WhatsnewImageAddApi = (data) => async (dispatch) => {
    try {
        const res = await Api.post(`${API_URL.BANNER_IMG_ADD}`, data);
        notification["success"]({
            message: "Image added successfully"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
