import React from 'react'
import { Navigate, Route, Routes } from "react-router-dom";
import LayoutMain from '../Layout';
import Login from '../pages/Auth/Login';
import ForgotPassword from '../pages/Auth/ForgotPassword';
import Dashboard from '../pages/Dashboard';
import FaqCategory from '../pages/FaqManagement/FaqCategory';
import Faqs from '../pages/FaqManagement/Faq';
import AddFaq from '../pages/FaqManagement/Faq/AddFaq';
import EditFaq from '../pages/FaqManagement/Faq/EditFaq';
import AddFaqCategory from '../pages/FaqManagement/FaqCategory/AddFaqCategory';
import EditFaqCategory from '../pages/FaqManagement/FaqCategory/EditFaqCategory';
import Users from '../pages/UserManagement/Users';
import AddUser from '../pages/UserManagement/Users/AddUser';
import EditUser from '../pages/UserManagement/Users/EditUser';
import UserRoles from '../pages/UserManagement/UserRoles';
import AddRoles from '../pages/UserManagement/UserRoles/AddRoles';
import EditRoles from '../pages/UserManagement/UserRoles/EditRoles';
// import JobApplication from '../pages/JobManagement/JobApplication';
import { GetToken } from '../utils';
import Settings from '../pages/Settings';
import JobApplications from '../pages/JobManagement/JobAppliction';
import EditApplication from '../pages/JobManagement/JobAppliction/EditApplication';
import Department from '../pages/DepartmentManagement'
import AddDepartmentCategory from '../pages/DepartmentManagement/DepartmentAdd';
import EditDepartment from '../pages/DepartmentManagement/DepartmentEdit';
import TestManagement from '../pages/TestManagement';
import EditTestManagement from '../pages/TestManagement/EditTestManagement';
import AddTestManagement from '../pages/TestManagement/TestManagementAdd';
import UserPermission from '../pages/UserManagement/UserRoles/UserPermission';
import JobPost from '../pages/JobManagement/JobPost';
import AddJob from '../pages/JobManagement/JobPost/addJob';
import EditJob from '../pages/JobManagement/JobPost/EditJob';
import Seo from '../pages/SeoManagement/index';
import AddSeo from '../pages/SeoManagement/addSeo';
import EditSeo from '../pages/SeoManagement/EditSeo';
import AddTicket from '../pages/TicketManagement/addTicket';
import EditTicket from '../pages/TicketManagement/EditTicket';
import Ticket from '../pages/TicketManagement';
import InvestorCategory from '../pages/InvestorManagement/InvestorCategory'
import AddInvestorCategory from '../pages/InvestorManagement/InvestorCategory/addInvestorCategory'
import EditInvestorCategory from '../pages/InvestorManagement/InvestorCategory/EditInvestorCategory'
import InvestorSubCategory from '../pages/InvestorManagement/InvestorSubCategory'
import AddInvestorSubCategory from '../pages/InvestorManagement/InvestorSubCategory/addInvestorSubCategory'
import EditInvestorSubCategory from '../pages/InvestorManagement/InvestorSubCategory/EditInvestorSubCategory'
import InvestorFinancials from '../pages/InvestorManagement/InvestorFinancials'
import AddInvestorFinancials from '../pages/InvestorManagement/InvestorFinancials/addInvestorFinancials'
import EditInvestorFinancials from '../pages/InvestorManagement/InvestorFinancials/EditInvestorFinancial'
import InvestorInformation from '../pages/InvestorManagement/InvestorInformation'
import AddInvestorInformation from '../pages/InvestorManagement/InvestorInformation/addInvestorInformation'
import EditInvestorInformation from '../pages/InvestorManagement/InvestorInformation/EditInvestorInformation'
import CorporateGovernance from '../pages/InvestorManagement/CorporateGovernance'
import AddCorporateCorporateGovernance from '../pages/InvestorManagement/CorporateGovernance/addCorporatGovernance'
import EditCorporategovernance from '../pages/InvestorManagement/CorporateGovernance/EditCorporatGovernance'
import Banner from '../pages/BannerManagement';
import BannerAdd from '../pages/BannerManagement/BannerAdd';
import BannerEdit from '../pages/BannerManagement/BannerEdit';
import PromotionCategory from '../pages/PromotionsOffersManagement/PromotionsOffersCatgory';
import AddPromotionCategory from '../pages/PromotionsOffersManagement/PromotionsOffersCatgory/AddPromotionCategory';
import EditPromotionCategory from '../pages/PromotionsOffersManagement/PromotionsOffersCatgory/EditPromotionCategory';
import PromotionItem from '../pages/PromotionsOffersManagement/PromotionsOffers';
import AddOffers from '../pages/PromotionsOffersManagement/PromotionsOffers/AddOffers';
import EditOffers from '../pages/PromotionsOffersManagement/PromotionsOffers/EditOffers';
import JsonEditor from '../pages/JsonEditor';
import BtlItem from '../pages/BtlMangaement';
import AddBtl from '../pages/BtlMangaement/AddBtl';
import EditBtl from '../pages/BtlMangaement/EditBtl';
import BtlCustomer from '../pages/BtlCustomer'
import WhatsNew from '../pages/WhatsNew';
import WhatsBannerAdd from '../pages//WhatsNew/BannerAdd';
import WhatsBannerEdit from '../pages/WhatsNew/BannerEdit';
import TeamItem from '../pages/TeamManagement';
import AddTeam from '../pages/TeamManagement/AddTeam';
import EditTeam from '../pages/TeamManagement/EditTeam';
import BusinessPartner from '../pages/BusinessPartner';
import BlogAttachmentAdd from '../pages/BlogAttachment/BlogAttachmentAdd';
import BlogAttachment from '../pages/BlogAttachment/BlogAttachment';
import ManageDownloadReportList from '../pages/ManageDownloadReport';
import AddReport from '../pages/ManageDownloadReport/AddReport';
import EditReport from '../pages/ManageDownloadReport/EditReport';
import HomeCollection from '../pages/HomeCollection';
import FellowshipProgram from '../pages/FellowshipProgram';
import FellowshipDetails from '../pages/FellowshipProgram/FellowshipDetails';
import CorporateTieUp from '../pages/CorporateTieUp';
import SmsPartness from '../pages/SmsPartness/smsReport';
import LeadManageZone from '../pages/LeadManagement/ManageZone';
import AddManageZone from '../pages/LeadManagement/ManageZone/AddManageZone';
import EditManageZone from '../pages/LeadManagement/ManageZone/EditManageZone';
import ManagePageItem from '../pages/LeadManagement/ManagePage';
import AddManagePage from '../pages/LeadManagement/ManagePage/AddManagePage';
import EditManagePage from '../pages/LeadManagement/ManagePage/EditManagePage';
function RequireAuth({ children }) {
    const token = GetToken();
    return token ? children : <Navigate to="/login" replace />;
}
function AlreadyLogin({ children }) {
    const token = GetToken();
    return token ? <Navigate to="/" replace /> : children;
}

const PageRoutes = () => {
    return (
        <div className='main_wrapper'>
            <Routes>
                <Route path="/forgot-password" element={<AlreadyLogin> <ForgotPassword /></AlreadyLogin>} />
                <Route path="/login" element={<AlreadyLogin><Login /></AlreadyLogin>} />
                <Route element={<RequireAuth> <LayoutMain /></RequireAuth>}>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/faq-category" element={<FaqCategory />} />
                    <Route path="/faq-category/list" element={<FaqCategory />} />
                    <Route path="/faq-category/add" element={<AddFaqCategory />} />
                    <Route path="/faq-category/edit/:faqCategoryId" element={<EditFaqCategory />} />

                    <Route path="/faqs" element={<Faqs />} />
                    <Route path="/faq/list" element={<Faqs />} />
                    <Route path="/faq/add" element={<AddFaq />} />
                    <Route path="/faq/edit/:faqId" element={<EditFaq />} />

                    <Route path="/users" element={<Users />} />
                    <Route path="/user/list" element={<Users />} />
                    <Route path="/user/add" element={<AddUser />} />
                    <Route path="/user/edit/:userId" element={<EditUser />} />

                    <Route path="/roles" element={<UserRoles />} />
                    <Route path="/role/list" element={< UserRoles />} />
                    <Route path="/role/add" element={<AddRoles />} />
                    <Route path="/role/edit/:roleId" element={<EditRoles />} />
                    <Route path="/role/user-permission/:roleId" element={<UserPermission />} />
                    <Route path="/settings" element={<Settings />} />

                    <Route path="/job-applications" element={<JobApplications />} />
                    <Route path="/job-applications/edit/:userId" element={<EditApplication />} />

                    <Route path="/jobs" element={<JobPost />} />
                    <Route path="/jobs/add" element={<AddJob />} />
                    <Route path="/jobs/edit/:jobsId" element={<EditJob />} />

                    <Route path="/department" element={<Department />} />
                    <Route path="/department/add" element={<AddDepartmentCategory />} />
                    <Route path="/department/edit/:departmentId" element={<EditDepartment />} />

                    <Route path="/test-management" element={<TestManagement />} />
                    <Route path="/test-management/add" element={<AddTestManagement />} />
                    <Route path="/test-management/edit/:TestdepartmentId" element={<EditTestManagement />} />

                    <Route path="/seo-management" element={<Seo />} />
                    <Route path="/seo-management/add" element={<AddSeo />} />
                    <Route path="/seo-management/edit/:seoId" element={<EditSeo />} />

                    <Route path="/ticket-management" element={<Ticket />} />
                    <Route path="/ticket-management/add" element={<AddTicket />} />
                    <Route path="/ticket-management/edit/:Id" element={<EditTicket />} />


                    <Route path="/investor-category" element={<InvestorCategory />} />
                    <Route path="/investor-category/add" element={<AddInvestorCategory />} />
                    <Route path="/investor-category/edit/:Id" element={<EditInvestorCategory />} />

                    <Route path="/investor-subcategory" element={<InvestorSubCategory />} />
                    <Route path="/investor-subcategory/add" element={<AddInvestorSubCategory />} />
                    <Route path="/investor-subcategory/edit/:Id" element={<EditInvestorSubCategory />} />

                    <Route path="/investor-financials" element={<InvestorFinancials />} />
                    <Route path="/investor-financials/add" element={<AddInvestorFinancials />} />
                    <Route path="/investor-financials/edit/:itemid" element={<EditInvestorFinancials />} />

                    <Route path="/investor-information" element={<InvestorInformation />} />
                    <Route path="/investor-information/add" element={<AddInvestorInformation />} />
                    <Route path="/investor-information/edit/:InformationId" element={<EditInvestorInformation />} />

                    <Route path="/investor-corporate-governance" element={<CorporateGovernance />} />
                    <Route path="/investor-corporate-governance/add" element={<AddCorporateCorporateGovernance />} />
                    <Route path="/investor-corporate-governance/edit/:corporatGovernanceId" element={<EditCorporategovernance />} />

                    <Route path="/page-banner" element={<Banner />} />
                    <Route path="/page-banner/add" element={<BannerAdd />} />
                    <Route path="/page-banner/edit/:bannerId" element={<BannerEdit />} />

                    <Route path="/coupon-category" element={<PromotionCategory />} />
                    <Route path="/coupon-category/add" element={<AddPromotionCategory />} />
                    <Route path="/coupon-category/edit/:couponCategoryId" element={<EditPromotionCategory />} />

                    <Route path="/coupons" element={<PromotionItem />} />
                    <Route path="/coupons/add" element={<AddOffers />} />
                    <Route path="/coupons/edit/:CouponId" element={<EditOffers />} />
                    <Route path="/investor-editor" element={<JsonEditor />} />


                    <Route path="/btl" element={<BtlItem />} />
                    <Route path="/btl/add" element={<AddBtl />} />
                    <Route path="/btl/edit/:CouponId" element={<EditBtl />} />
                    <Route path="/btl-customers" element={<BtlCustomer />} />


                    <Route path="/whats-new" element={<WhatsNew />} />
                    <Route path="/whats-new/add" element={<WhatsBannerAdd />} />
                    <Route path="/whats-new/edit/:bannerId" element={<WhatsBannerEdit />} />

                    <Route path="/our-team" element={<TeamItem />} />
                    <Route path="/our-team/add" element={<AddTeam />} />
                    <Route path="/our-team/edit/:Id" element={<EditTeam />} />
                    <Route path="/business-partner" element={<BusinessPartner />} />
                    <Route path="/home-collection" element={<HomeCollection />} />
                    <Route path="/fellowship-program" element={<FellowshipProgram />} />
                    <Route path="/fellowship-program/view/:Id" element={<FellowshipDetails />} />
                    <Route path="/corporate-tie-up" element={<CorporateTieUp />} />

                    <Route path="/blog-management/add" element={<BlogAttachmentAdd />} />
                    <Route path="/blog-management" element={<BlogAttachment />} />
                    <Route path="/manage-download-report" element={<ManageDownloadReportList />} />
                    <Route path="/manage-download-report/add" element={<AddReport />} />
                    <Route path="/manage-download-report/edit/:Id" element={<EditReport />} />
                    <Route path="/sms-partners" element={<SmsPartness />} />
                    <Route path="/manage-zone" element={<LeadManageZone />} />
                    <Route path="/manage-zone/add" element={<AddManageZone />} />
                    <Route path="/manage-zone/edit/:zoneId" element={<EditManageZone />} />
                    <Route path="/manage-page" element={<ManagePageItem />} />
                    <Route path="/manage-page/add" element={<AddManagePage />} />
                    <Route path="/manage-page/edit/:LeadPageId" element={<EditManagePage />} />
                </Route>
            </Routes>
        </div>
    )
}

export default PageRoutes