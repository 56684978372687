import { Table, Tooltip, message, } from 'antd';
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FileExcelFilled } from '@ant-design/icons';
import HeadingBack from '../../component/HeadingBack';
import { CSVLink } from "react-csv"
import { JobApplicationsServices } from '../../redux/Services';
import CorporateTieUpFilter from './corporateTieUpFilter';
// import URL from "../../../api/url";
import { usePermission } from '../../hooks/usePermission';
import moment from 'moment';
import { getBusinessProfession } from "../../utils";

const CorporateTieUp = () => {

    const permission = usePermission("Corporate Tie Up", "List");
    const navigate = useNavigate();
    const [filter, setFilter] = useState({});
    const [limit, setLimit] = useState(100);
    const [offset, setOffset] = useState(0);
    const [isFilter, setisFilter] = useState(0);
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const CorporateTieUpList = useSelector(state => state.jobAppliction.CorporateTieUpList || []);
    const totalCorporateTieUp = useSelector(state => state.jobAppliction.totalCorporateTieUpList || 0);
    const { mutate: getCorporateTieUpList, isLoading } = useMutation((data) => dispatch(JobApplicationsServices.fetchCorporateTieUpApi(limit, offset, data)));
    useEffect(() => {
        getCorporateTieUpList(filter);
        return () => { }
    }, [filter, offset, limit])
    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
            className: 'table_first_col',
            render: (value, item, index) => (page - 1) * 100 + index + 1
        },

        {
            title: 'First Name',
            dataIndex: 'first_name',
            key: 'first_name',
            className: 'text-capitalize',
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name',
            key: 'last_name',
            className: 'text-capitalize',
        },
        {
            title: 'Email',
            dataIndex: 'email_id',
            key: 'email_id',
            width: '250px'
        },
        {
            title: 'Mobile',
            dataIndex: 'contact_number',
            key: 'contact_number',
        },
        {
            title: 'Corporate Name',
            dataIndex: 'corporate_name',
            key: 'corporate_name',
        },
        {
            title: 'Company Profile/Nature of Business',
            dataIndex: 'company_profile',
            key: 'company_profile',
        },
        {
            title: 'Industry',
            dataIndex: 'industry',
            key: 'industry',
        },
        {
            title: 'Approx Number of Employees PAN INDIA',
            dataIndex: 'employees_count',
            key: 'employees_count',
        },
        {
            title: 'Corporate Address',
            dataIndex: 'corporate_address',
            key: 'corporate_address',
        },
        {
            title: 'State',
            dataIndex: 'state_name',
            key: 'state_name',
        },
        {
            title: 'City',
            dataIndex: 'city_name',
            key: 'city_name',
        },
        {
            title: 'Investigations',
            dataIndex: 'investigations_file',
            key: 'investigations_file',
            render: (value, item, index) => {
                return (
                    item.investigations_file ? <a style={{ textDecoration: "underline" }} href={item.investigations_file} target="_blank">View</a> : 'NA'
                )
            }
        },
        {
            title: 'Corporate Requirement For Tie Up',
            dataIndex: 'corporate_requirement',
            key: 'corporate_requirement',
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
            className: 'text-capitalize',
            render: (value, item, index) => {
                return (
                    <div
                        dangerouslySetInnerHTML={{ __html: item.remarks ? item.remarks : "NA"}}
                    />
                )
            }
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (value, item, index) => {
                return (
                    <>{moment.utc(item.createdAt).format('DD-MM-YYYY H:mm:ss')}</>
                )
            }
        },

    ];
    return (
        <div>
            <div className='position-relative part-filter'>
                <HeadingBack title={"Total Leads"} subHeading={`Total ${totalCorporateTieUp || 0} Leads`} />
                <CorporateTieUpFilter filter={filter} setFilter={setFilter} setPage={setPage} setOffset={setOffset} limit={setLimit} isFilter={setisFilter} />


            </div>
            <div className='tableResponsive mt-10'>
                <Table
                    rowKey='UsrId'
                    className={isLoading ? 'table antd_table no_data_found_hide' : 'table antd_table'}
                    bordered
                    columns={columns}
                    dataSource={Array.isArray(CorporateTieUpList) && CorporateTieUpList?.length > 0 ? CorporateTieUpList : []}
                    pagination={{
                        current: page,
                        pageSize: limit,
                        total: totalCorporateTieUp,
                        onChange(current, pageSize) {
                            setPage(current);
                            setOffset(current == 1 ? 0 : (((current - 1) * limit)));
                        },
                        hideOnSinglePage: true
                    }}

                />
            </div>
        </div>
    )
}

export default CorporateTieUp