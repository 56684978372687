import { notification } from "antd";
import Api from "../../api";
import API_URL from "../../api/url";
import { setJobApplicationsList, setJobApplicationsDetailsApi,setStateCityList, setjobApplicationsAllList, setJobRoleList,setBecomePartnerList, setHomeCollectionList, setCorporateTieUpList} from "../Slices/jobApplicationsSlice";

export const fetchJobApplicationsListApi = (limit, offset, data) => async (dispatch) => {
    try {
        data.Limit = limit;
        data.Offset = offset;
        const res = await Api.post(API_URL.JOBAPPLICATIONS_LIST, data);
        dispatch(setJobApplicationsList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchJobApplicationsListAllApi = (limit, offset, data) => async (dispatch) => {
    try {
        data.Limit = limit;
        data.Offset = offset;
        const res = await Api.post(API_URL.JOBAPPLICATIONS_LIST, data);
        dispatch(setjobApplicationsAllList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchJobApplicationsDetailsApi = (id) => async (dispatch) => {
    try {
        var url = API_URL.JOBAPPLICATIONS_DETAILS + '/' + id;
        const params = {
            Limit: 1,
            Offset: 0
        };
        const res = await Api.post(url, params);
        dispatch(setJobApplicationsDetailsApi({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const updateJobApplicationApi = (data, id, navigate) => async (dispatch) => {
    try {
        const res = await Api.put(`${API_URL.JOB_APPLICATION_UPDATE}/${id}`, data);
        navigate("/job-applications");
        notification["success"]({
            message: "Job application updated successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const fetchStateCityListApi = (limit, offset, data) => async (dispatch) => {
    try {
        data.Limit = limit;
        data.Offset = offset;   
        const res = await Api.post(API_URL.STATE_CITY, data);
        dispatch(setStateCityList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchJobRoleListApi = (limit, offset, data) => async (dispatch) => {
    try {
        data.Limit = limit;
        data.Offset = offset;   
        const res = await Api.post(API_URL.JOB_ROLE, data);
        dispatch(setJobRoleList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchBecomeListApi = (limit, offset, data) => async (dispatch) => {
    try {
        data.Limit = limit;
        data.Offset = offset;
        const res = await Api.post(API_URL.BECOME_PARTNER_LIST, data);
        dispatch(setBecomePartnerList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const HomeCollectionApi = (limit, offset, data) => async (dispatch) => {
    try {
        data.Limit = limit;
        data.Offset = offset;
        const res = await Api.post(API_URL.HOME_COLLECTION_LIST, data);
        dispatch(setHomeCollectionList({ list: res.data.Data.records || [], total: res.data.TotalRecord}));
        return res;
    } catch (error) {
        return error
    }
};

export const fetchCorporateTieUpApi = (limit, offset, data) => async (dispatch) => {
    try {
        data.Limit = limit;
        data.Offset = offset;
        const res = await Api.post(API_URL.CORPORATE_TIEUP_LIST, data);
        dispatch(setCorporateTieUpList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};