import React, { useEffect } from 'react'
import { Form, Input, Select, Row, Col } from 'antd'
import CustomEditor from '../../component/CustomEditor/CustomEditor';
import { GetLoggedInUser } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { DepartmentServices } from '../../redux/Services';
import { FaqServices } from '../../redux/Services';
import { TestDepartmentServices } from '../../redux/Services';
import HeadingBack from '../../component/HeadingBack';
import { usePermission } from '../../hooks/usePermission';
const { Option } = Select;

const AddTestManagement = () => {
    const permission = usePermission("Test Management", "Add");
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const faqCategoryList = useSelector(state => state.faq.faqCategoryList || []);
    const departmentList = useSelector(state => state.department.departmentList || []);
    const { mutate: addTestDepartment } = useMutation((data) => dispatch(TestDepartmentServices.addTestDepartmentApi(data, navigate)));
    const { mutate: fetchFaqCategory } = useMutation((data) => dispatch(FaqServices.fetchFaqCategoryApi(data)));
    const { mutate: fetchDepartmentList, isLoading } = useMutation((data) => dispatch(DepartmentServices.fetchDeepartmentListApi(data)));
    useEffect(() => {
        fetchFaqCategory({ isActive: "1" });
        return () => { }
    }, [])
    useEffect(() => {
        fetchDepartmentList({ params: { isActive: "1" } });
        return () => { }
    }, [])
    const handleSubmit = () => {
        form.validateFields().then((values) => {
            let _data = { ...values };
            _data.UserId = LOG_IN_USER?.id;
            addTestDepartment(_data)
        })
    }
    return (
        <>
            <HeadingBack title={"Test Add"} subHeading="" />
            <div className='p-20'>
                < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                    initialValues={{ Status: "1" }}
                >
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Department"
                                name="DepartmentId"
                                rules={[{ required: true, message: 'Department is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Department'
                                    autoComplete="new-off"
                                    name="DepartmentId"
                                >
                                    <Option className='text-capitalize' value={""}>Select Department</Option>
                                    {departmentList?.length > 0 && departmentList?.map((item, idx) =>
                                        <Option key={idx} className='text-capitalize' value={item.Id}>{item.DepartmentName}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Category"
                                name="CategoryId"
                                rules={[{ required: true, message: 'Category is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Category'
                                    autoComplete="new-off"
                                    name="CategoryId"

                                >
                                    <Option className='text-capitalize' value={""}>Select Category</Option>
                                    {faqCategoryList?.length > 0 && faqCategoryList?.map((item, idx) =>
                                        <Option key={idx} className='text-capitalize' value={item.Id}>{item.Category}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Test Name"
                                name="TestName"
                                rules={[{ required: true, message: 'Test Name is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Test Name' />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Test Code"
                                name="TestCode"
                                rules={[{ required: true, message: 'Test Code is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Test Code' />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Related Test Codes (Comma Separated)"
                                name="RelatedTestCode"
                                rules={[{ required: true, message: 'Related Test Code is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Related Test Codes' />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                    name="Status"
                                >
                                    <Option className='text-capitalize' value={"0"}>Inactive</Option>
                                    <Option className='text-capitalize' value={"1"}>Active</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        label="Test Overview"
                        name="TestOverview"
                        rules={[{ required: true, message: 'Test Overview is required' }]}
                    >
                        <CustomEditor value={form.getFieldValue("TextOverviw")} onEditorChange={(value) => form.setFieldsValue({ TestOverview: value })} />
                    </Form.Item>
                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit}>
                            Submit
                        </button>
                    </div>
                </Form >

            </div>
        </>
    )
}

export default AddTestManagement