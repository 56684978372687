import React, { useEffect, useState } from 'react'
import { Col, Form, Input, Row, Select, AutoComplete } from 'antd'
import CustomEditor from '../../../component/CustomEditor/CustomEditor';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { GetLoggedInUser } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import HeadingBack from '../../../component/HeadingBack';
import FileUpload from './FileUpload';
import { BannerServices } from '../../../redux/Services';
import { CouponServices,TestDepartmentServices } from '../../../redux/Services';
import { usePermission } from '../../../hooks/usePermission';


const { Option } = Select;
const AddOffers = () => {

    const permission = usePermission("Coupon Management", "Add");
    var newOptions = [];
    const [referenceId, setReferenceId] = useState('');
    const [value, setValue] = useState('');
    const [file, setFile] = useState("");
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const couponCategoryList = useSelector(state => state.coupon.couponCategoryList || []);
    const TestdepartmentList = useSelector(state => state.Testdepartment.TestdepartmentList || []);
    const { mutate: fetchCouponCategory, isLoading } = useMutation((data) => dispatch(CouponServices.fetchCouponCategoryApi(data)));
    const { mutate: addCoupon } = useMutation((data) => dispatch(CouponServices.addCouponApi(data, navigate)));
    const { mutate: BannerImageAdd } = useMutation((data) => dispatch(BannerServices.BannerImageAddApi(data)));
    const { mutate: fetchTestDepartmentList } = useMutation((data) => dispatch(TestDepartmentServices.fetchTestDeepartmentListApi(data)));
    const [options, setOptions] = useState([]);
    useEffect(() => {
        fetchCouponCategory({ isActive: "1" });
        return () => { }
    }, [])
    const handleUpload = (file) => {
        const formData = new FormData();
        formData.append("image", file);
        BannerImageAdd(formData, {
            onSuccess: (res) => {
                var fileName = res.data?.data?.key;
                setFile(fileName)
            },
        })
    }
    useEffect(() => {
        form.setFieldsValue({
            Banner: file,
        })
    }, [file])
    const handleSubmit = () => {
        form.validateFields().then((values) => {
            let _data = { ...values };
            _data.UserId = LOG_IN_USER?.id;
          
            addCoupon(_data)
        })
    }
    const onInputChange = (value) => {
        if (value && value.length >= 2) {
            fetchTestDepartmentList({ "TestCode": value });
            // setValue(value);
        }
    };
    const handleOnSelect = (item) => {
        console.log('item', item);
        setReferenceId(item);
    }
  
 
    function handleOnFocus() {
        setReferenceId(null);
    }
    useEffect(() => {
        newOptions = [];
        TestdepartmentList.map((value, key) => {
            // newOptions.push({
            //     id: value.Id,
            //     name: value.TestCode
            // })  ;
            newOptions.push({
                value: value.TestCode
            })
        })
        setOptions(newOptions);
        return () => { }
    }, [TestdepartmentList])
    
    return (
        <>
            <HeadingBack title={"Offers Add"} subHeading="" />
            <div className='p-20'>

                < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                    initialValues={{ Status: "1" }}
                >
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Category"
                                name="CategoryId"
                                rules={[{ required: true, message: 'Category is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Category'
                                    autoComplete="new-off"
                                    name="CategoryId"
                                >
                                    <Option className='text-capitalize' value={""}>Select Category</Option>
                                    {couponCategoryList?.length > 0 && couponCategoryList?.map((item, idx) =>
                                        <><Option key={idx} className='text-capitalize' value={item.Id}>{item.Name}</Option></>
                                    )}
                                    
                                </Select>
                               
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Coupon Name"
                                name="Name"
                                rules={[{ required: true, message: 'Label is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Label' />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                        <Form.Item
                                label="Test Code"
                            // name="ReferenceId"
                            // rules={[{ required: true, message: 'Test Id is required' }]}
                            >
                             
                                <AutoComplete

                                    options={options}
                                    onSearch={onInputChange}
                                    onSelect={handleOnSelect}
                                    placeholder="Test Code"
                                    onFocus={handleOnFocus}
                                
                                />

                                {/* <ReactSearchAutocomplete
                                    items={options}
                                    onSearch={onInputChange}
                                    // onHover={handleOnHover}
                                    onSelect={handleOnSelect}
                                    onFocus={handleOnFocus}
                                    autoFocus
                                    formatResult={formatResult}
                                /> */}
                                <div id="testid_erro">{value}</div>
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Coupon Code"
                                name="Code"
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Coupon Code' />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                        <Form.Item
                                label="Offer Image"
                                name="Banner"
                                rules={[{ required: true, message: 'Offer Image is required' }]}
                            >
                                <FileUpload file={file} setFile={setFile} handleUpload={handleUpload} />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                        <Form.Item
                                label="Alt Tag"
                                name="BannerAltTag"
                                rules={[{ required: true, message: 'Alt Tag is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Alt Tag' />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                    name="Status"
                                >
                                    <Option className='text-capitalize' value={"0"}>Inactive</Option>
                                    <Option className='text-capitalize' value={"1"}>Active</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Description"
                                name="Description"
                                rules={[{ required: true, message: 'Description is required' }]}
                            >
                                <CustomEditor value={form.getFieldValue("Description")} onEditorChange={(value) => form.setFieldsValue({ Description: value })} />
                            </Form.Item>
                        </Col>
                    </Row >
                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit}>
                            Submit
                        </button>
                    </div>
                </Form>
            </div >
        </>
    )
}

export default AddOffers