import { notification } from "antd";
import Api from "../../api";
import API_URL from "../../api/url";
import { setTeamMemberList, setTeamMemberDetail} from "../Slices/teamSlice";

export const fetchTeamMemberApi = (data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.TEAM_MEMBERS, data);
        dispatch(setTeamMemberList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const addTeamApi = (data, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.TEAM_ADD, data);
        navigate("/our-team");
        notification["success"]({
            message: "Team Member added successfully"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const fetchTeamMemberByIdApi = (data, Id) => async (dispatch) => {
    try {
        // const res = await Api.post(`${API_URL.TEAM_DETAIL}/${id}`);
        const res = await Api.post(API_URL.TEAM_DETAIL, Id);
        dispatch(setTeamMemberDetail(res.data.Data));
        return res;
    } catch (error) {
        return error
    }
};
export const updateTeamMemberApi = (data, id, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(`${API_URL.TEAM_UPDATE}`, data);
        navigate("/our-team");
        notification["success"]({
            message: "Team Member updated successfully"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};

