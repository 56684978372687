import React from 'react'
import { Col, Form, Input, Row, Select } from 'antd'
// import CustomEditor from '../../../component/CustomEditor/CustomEditor';
import { GetLoggedInUser, onlyMobileInput } from '../../../utils';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { InvestorCategoriesServices } from '../../../redux/Services';
import HeadingBack from '../../../component/HeadingBack';
import { usePermission } from '../../../hooks/usePermission';

const { Option } = Select;

const AddInvestorCategory = () => {
    const permission = usePermission("Seo Pages", "Add");
    onlyMobileInput();
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { mutate: addInvesetorCategory, isLoading: addLoading } = useMutation((data) => dispatch(InvestorCategoriesServices.addInvesetorCategoryApi(data, navigate)));
    const handleSubmit = () => {
        form.validateFields().then((values) => {
            let _data = { ...values };
            _data.UserId = LOG_IN_USER?.id;
            addInvesetorCategory(_data)
        })
    }
    return (
        <>
            <HeadingBack title={"Investor Category Add"} subHeading="" />
            <div className='p-20'>
                < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                >
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Category Name"
                                name="Category"
                                rules={[{ required: true, message: 'Category is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Category Name' />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Category Slug"
                                name="Slug"
                                rules={[{ required: true, message: 'Slug is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Category Slug' />
                            </Form.Item>
                        </Col > 
                        {/* <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Category URL"
                                name="Url"
                                rules={[{ required: true, message: 'Category url is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Category URL' />
                            </Form.Item>
                        </Col > */}
                        <Col lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                            label="Status"
                            name="Status"
                            rules={[{ required: true, message: 'Status is required' }]}
                        >
                            <Select
                                className='from-control text-capitalize'
                                placeholder='Select Status'
                                autoComplete="new-off"
                                name="Status"
                            >
                                <Option className='text-capitalize' value={"0"}>Inactive</Option>
                                <Option className='text-capitalize' value={"1"}>Active</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    </Row>
                  
                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit} disabled={addLoading}>
                            Submit
                        </button>
                    </div>
                </Form >
            </div>
        </>
    )
}

export default AddInvestorCategory