import { notification } from "antd";
import Api from "../../api";
import API_URL from "../../api/url";
import { setItemList, setInvestorItemDetails, setInvesoterInfo } from "../Slices/InvesotroItemSlice";

export const addInvesotorItemApi = (data, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.INVESTOR_ITEM_ADD, data);
        navigate("/investor-financials");
        notification["success"]({
            message: "Financials added successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const addInvesotorItemInformationApi = (data, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.INVESTOR_ITEM_ADD, data);
        navigate("/investor-information");
        notification["success"]({
            message: "Investor Information added successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const fetchInvestorListApi = (limit, offset,data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.INVESTOR_ITEM_LIST, data);
        dispatch(setItemList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchInvestorListItem2Api = (limit, offset,data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.INVESTOR_ITEM_LIST2, data);
        dispatch(setItemList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
}
export const fetchInvestorListItem3Api = (limit, offset,data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.INVESTOR_ITEM_LIST3, data);
        dispatch(setItemList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchInvestorItemDetailsApi = (id) => async (dispatch) => {
    try {
        var url = API_URL.INVESTOR_ITEM_DETAILS + '/'+ id;
        const res = await Api.get(url);
        dispatch(setInvestorItemDetails({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchInvestorItemDetails2Api = (id) => async (dispatch) => {
    try {
        var url = API_URL.INVESTOR_ITEM_DETAILS2 + '/'+ id;
        const res = await Api.get(url);
        dispatch(setInvestorItemDetails({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchInvestorItemDetails3Api = (id) => async (dispatch) => {
    try {
        var url = API_URL.INVESTOR_ITEM_DETAILS3 + '/'+ id;
        const res = await Api.get(url);
        dispatch(setInvestorItemDetails({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const InvestorItemlUpdateApi = (data, id, navigate) => async (dispatch) => {
    try {
        const res = await Api.put(`${API_URL.INVESTOR_ITEM_UPDATE}`, data);
        navigate("/investor-financials");
        notification["success"]({
            message: "Financials updated successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const InvestorItemlUpdate2Api = (data, id, navigate) => async (dispatch) => {
    try {
        const res = await Api.put(`${API_URL.INVESTOR_ITEM_UPDATE2}`, data);
        navigate("/investor-information");
        notification["success"]({
            message: "Investor Information updated successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const InvestorItemlUpdate3Api = (data, id, navigate) => async (dispatch) => {
    try {
        const res = await Api.put(`${API_URL.INVESTOR_ITEM_UPDATE2}`, data);
        navigate("/investor-corporate-governance");
        notification["success"]({
            message: "Corporate Governance updated successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const addInvesotorItemGovernanceApi = (data, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.INVESTOR_ITEM_ADD, data);
        navigate("/investor-corporate-governance");
        notification["success"]({
            message: "Corporate Governance added successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const fetchInvestorInformation = (data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.FETCH_INVESTOR_INFO, data,{headers: {token:"60f291aa46ea447060f291aa46ea447019d83ba30be508e419d83ba30be508e4"}});
        dispatch(setInvesoterInfo(res.data.Data));
        return res;
    } catch (error) {
        return error
    }
}
export const updateInvestorInfoJson = (data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.INVESTOR_UPDATE_JSON, data);
        notification["success"]({
            message: "updated successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};