import { Col, Input, Row, Select } from 'antd'
import React, { useState, useEffect } from 'react'
const { Option } = Select;
const JobAppFilter = ({ filter, setFilter, setPage, setOffset }) => {
    const [values, setValues] = useState({
        Profile: "",
        Location: "",
        FromDate: "",
        ToDate: "",
        IsActive: "",
    });

    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value });
    }
    const handleFilter = () => {
        setFilter({ ...filter, ...values });
        setPage(1);
        setOffset(0);
    }
    const handleFilterReset = () => {
        setFilter({});
        setValues({
            ...values,
            Profile: "",
            Location: "",
            FromDate: "",
            ToDate: "",
            IsActive: "",
        })
        setPage(1);
    }
    return (
        <div className='table_filter'>
            <Row gutter={[10, 20]} className="ml-10">
                <Col md={3} sm={4} xs={24}>
                    <Input
                        type="text"
                        autoComplete="new-off"
                        className='form-control'
                        onChange={(e) => handleChange("Profile", e.target.value)}
                        placeholder='Profile'
                        value={values?.Profile}
                    />
                </Col>
                <Col md={3} sm={12} xs={24}>
                    <Input
                        type="text"
                        autoComplete="new-off"
                        className='form-control'
                        onChange={(e) => handleChange("Location", e.target.value)}
                        placeholder='Location'
                        value={values?.Location}
                    />
                </Col>  
                <Col md={4} sm={12} xs={24}>
                    <Input
                        type="date"
                        id="fromdate"
                        autoComplete="new-off"
                        className='form-control'
                        onChange={(e) => handleChange("FromDate", e.target.value)}
                        placeholder='From Date'
                        value={values?.FromDate}
                    />
                </Col>
                <Col md={4} sm={12} xs={24}>
                    <Input
                        type="date"
                        id="todate"
                        autoComplete="todate"
                        className='form-control'
                        onChange={(e) => handleChange("ToDate", e.target.value)}
                        placeholder='To Date'
                        value={values?.ToDate}
                    />
                </Col>
                <Col md={4} sm={12} xs={24}>
                    <Select
                        className='text-capitalize w-100'
                        placeholder='Select Status'
                        autoComplete="new-off"
                        onChange={(value) => handleChange("IsActive", value)}
                        value={values?.IsActive}
                    >
                        <Option className='text-capitalize' value="">All</Option>
                        <Option className='text-capitalize' value={"0"}>Inactive</Option>
                        <Option className='text-capitalize' value={"1"}>Active</Option>
                    </Select>
                </Col>
                <Col md={3} sm={12} xs={24}>
                    <div className='flex gap10'>
                        <button className='filter-button' onClick={() => handleFilter()}>Filter</button>
                        <button className='clear-button' onClick={() => handleFilterReset()}>Reset</button>
                    </div>
                </Col>
             </Row>
        </div >
    )
}

export default JobAppFilter