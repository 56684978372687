import moment from 'moment';
import React, { useEffect, useState } from 'react'
import Chart from 'react-google-charts'
import { useSelector } from 'react-redux';

const CenteralHealthLineChart = () => {
  const MonthWiseFaqs = useSelector(state => state.dashboard.totalMonthWiseFaqs || []);
  const [chartData, setChartData] = useState([]);
  const customTooltip = (date, type, val, color) => {
    return (
      `<div class='text-center p-10 fw-600'>
        <div>${date || ""}</div>
        <div ><strong>${type || ""}</strong></div>
        <div class='fw-700 fs-20' style="color: ${color}">${val}</div>
      </div>`
    )
  }
  const getMonthwiseData = (_date) => {
    let obj = MonthWiseFaqs.find((_ele) => moment(_ele.Date).format('MMM, YYYY') == _date);
    return obj
  }
  useEffect(() => {
    let tool = { 'type': "string", 'role': "tooltip", 'p': { html: true } };
    let _data = [["", "Active", tool, "Inactive", tool]];
    [...Array(12).keys()].map(index => {
      let date = moment().subtract(11 - index, 'months').format('MMM, YYYY');
      let mData = getMonthwiseData(date) || {};
      _data.push([
        date,
        Number(mData?.TotalActiveFaqs || 0),
        customTooltip(date, "Active", mData?.TotalActiveFaqs || 0, '#fecc4e'),
        Number(mData?.TotalInActiveFaqs || 0),
        customTooltip(date, "Inactive", mData?.TotalInActiveFaqs || 0, '#1559a2')
      ]);
    })
    setChartData(_data);
  }, [MonthWiseFaqs])

  return (
    <div>
      {chartData?.length > 0 &&
        <Chart
          chartType="ColumnChart"
          data={chartData}
          height={400}
          width="100%"
          options={
            {
              fontName: 'Open Sans',
              fontSize: 14,
              color: '#212529',
              tooltip: { isHtml: true },
              legend: { position: 'none' },
              colors: ['#fecc4e', '#1559a2'],
              hAxis: {
                textStyle: {
                  whiteSpace: 'wrap',
                  fontName: 'Open Sans',
                  fontSize: 14,
                  color: '#212529',
                },
                /* slantedText: true,
                slantedTextAngle: 90 */
              },
              chartArea: { left: 40, top: 10, bottom: 40, width: "100%", height: "100%" }
            }
          }
        />
      }
    </div>
  )
}

export default CenteralHealthLineChart