import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    jobPostList: [],
    totalAddJobPost: 0, 
    JobAddDetails: [],
}

export const jobPostSlice = createSlice({
    name: 'jobPost',
    initialState,
    reducers: {
        setJobPostList: (state, action) => { 
            state.jobPostList = action.payload.list;
            state.totalAddJobPost = action.payload.total;
        },
        setJobAddDetails: (state, action) => {
            state.JobAddDetails = action.payload;
            state.totalJobApp = action.payload.total;
        },


    },
})

export const { setJobPostList,setJobAddDetails} = jobPostSlice.actions

export default jobPostSlice.reducer