import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    departmentList: [],
    TotalRecord: '',
    departmentDetail: {},
  
}

export const departmentSlice = createSlice({
    name: 'department',
    initialState,
    reducers: {
        setDepartmentList: (state, action) => {
            state.departmentList = action.payload.list;
          state.TotalRecord = action.payload.total;
        },
      
        setDepartmentDetail: (state, action) => {
            state.departmentDetail = action.payload;
        },
    },
})

export const { setDepartmentList, setDepartmentDetail} = departmentSlice.actions

export default departmentSlice.reducer