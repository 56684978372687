import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    totalUsers: "",
    totalMonthWiseUsers: [],
    totalFaqs: "",
    totalMonthWiseFaqs: [],
    TotalJobsApp: "",
    TotalJobs: "",
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setUsersCount: (state, action) => {
            state.totalUsers = action.payload?.TotalUsers;
            state.totalMonthWiseUsers = action.payload?.TotalMonthWiseUsers;
        },
        setFaqsCount: (state, action) => {
            state.totalFaqs = action.payload?.TotalFaqs;
            state.totalMonthWiseFaqs = action.payload?.TotalMonthWiseFaqs;
        },
        setJobAddCount: (state, action) => {
            state.TotalJobs = action.payload?.TotalJobs;
        },
        setJobAppCount: (state, action) => {
            state.TotalJobsApp = action.payload?.TotalJobs;
    
        }
    },
})

export const { setUsersCount, setFaqsCount, setJobAppCount,setJobAddCount } = dashboardSlice.actions

export default dashboardSlice.reducer