import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { Pagination, Table, Tooltip } from 'antd';
import { Col, Form, Input, Row, Select } from 'antd'
import { useNavigate, useParams } from 'react-router-dom';
import CustomEditor from '../../component/CustomEditor/CustomEditor';
import { useDispatch, useSelector } from 'react-redux';
import { TicketServices } from '../../redux/Services';
import { useMutation } from 'react-query';
import { GetLoggedInUser, isJSON } from '../../utils';
import HeadingBack from '../../component/HeadingBack';
import { usePermission } from '../../hooks/usePermission';
import API_URL from "../../api/url";
import IMAGES from '../../const/Image';
import JsonShow from './JsonShow';
const { Option } = Select;

const TicketStatus = {
    "1": 'Open',
    "2": 'InProgress',
    "3": 'InTesting',
    "4": 'Completed',
    "5": 'Parked',
    "6": 'Revoke',
    "7": 'Re Open',
}

const EditTicket = () => {
    const permission = usePermission("Ticket Management", "Edit");
    const { Id } = useParams();
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [historyList, setHistoryList] = useState([]);
    const [updatedby, setupdatedby] = useState([]);
    const TicketDetails = useSelector(state => state.Ticket.TicketDetails);
    const { mutate: TicketDetail, isLoading } = useMutation((data) => dispatch(TicketServices.fetchTicketDetailsApi(data, Id)));
    const { mutate: TicketDetailUpdateApi, isLoading: addLoading } = useMutation((data) => dispatch(TicketServices.TicketDetailUpdateApi(data, Id, navigate)));
    const [values, setValues] = useState({
        AssignedTo: "",

    });
    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value });
    }
    useEffect(() => {
        TicketDetail(Id);
        return () => { }
    }, [])
    useEffect(() => {
        if (TicketDetails && Object.keys(TicketDetails).length > 0 && Object.keys(TicketDetails.list)?.length > 0) {
            const TicketData = TicketDetails.list;

            form.setFieldValue('Id', TicketData?.Id);
            form.setFieldValue('Subject', TicketData?.Subject);
            form.setFieldValue('Description', TicketData?.Description);
            form.setFieldValue('Attachments', TicketData?.Attachments);
            setHistoryList(TicketData?.TicketHistories);
            setupdatedby(TicketData?.Updated?.Name)

        }
    }, [TicketDetails])
    // console.log(TicketDetails?:TicketData)
    const handleSubmit = () => {
        form.validateFields().then((values) => {
            if (TicketDetailUpdateApi) {
                let _data = { ...values };
                _data.UserId = LOG_IN_USER?.id;
                TicketDetailUpdateApi(_data);
            }
        })
    }
    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
            render: (value, item, index) => (page - 1) * 10 + index + 1

        },

        {
            title: 'Assigned To',
            dataIndex: 'assigned_to',
            key: 'assigned_to',
            className: 'text-capitalize',
        },
        {
            title: 'Assigned Date',
            dataIndex: 'assigned_date',
            key: 'assigned_date',
            className: 'text-capitalize',
            render: (value, item, index) => {
                return (
                    <>{moment(item.assigned_date).format('DD-MM-YYYY H:mm:ss')}</>
                )
            }
        },
        {
            title: 'Updated By',
            dataIndex: 'Name',
            key: 'Name',
            className: 'text-capitalize',
            render: () => {
                return (
                    <span>{updatedby}</span>
                )
            }
        },

        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
            className: 'text-capitalize',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'remarks',
            className: 'text-capitalize',
            render: (value, item, index) => {
                return (
                    <span>{item?.status ? TicketStatus[item?.status] : ""}</span>
                )
            }
        },
        {
            title: 'Updated At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            className: 'text-capitalize',
            render: (value, item, index) => {
                return (
                    <>{moment(item.createdAt).format('DD-MM-YYYY H:mm:ss')}</>
                )
            }
        },
    ];
    const Description = (Description) => {
        if (isJSON(Description)) {
            let des = JSON.parse(Description);
            return (
                <div className='form-control bg_gray' style={{overflowY: 'scroll', height: "350px"}}>
                <JsonShow data={des}/>
                </div>
            )
        }
        else {
            return (
                <textarea className='form-control bg_gray' style={{height: "350px"}} value={form.getFieldValue("Description")} onEditorChange={(value) => form.setFieldsValue({ Description: value })} readOnly />
            )
        }
    }
    return (
        <>
            <HeadingBack title={"Ticket Update"} subHeading="" />
            <div className='p-20'>
                < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                >
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Subject"
                                name="Subject"
                                rules={[{ required: true, message: 'Subject is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Subject' readOnly />
                            </Form.Item>
                        </Col >
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label="Description"
                                name="Description"
                                rules={[{ required: true, message: 'Description is required' }]}
                            >
                                {Description(form.getFieldValue("Description"))}
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Attachment File"
                                name="Attachments"
                                rules={[{ required: true, message: 'Attachment File is required' }]}
                            >
                                <div> {TicketDetails.list?.Attachments ? JSON.parse(TicketDetails.list?.Attachments).map((v, i) => {
                                    return (<a target="_blank" className="view-attachment" href={API_URL.S3_BANNER_BASE+ v}><img className='doc_icon' src={IMAGES.Documenticon} /></a>)
                                }) : 'NA'}</div>
                            </Form.Item>
                        </Col >

                    </Row>
                    <hr />
                    <Row className="pt-15" gutter={[24, 0]}>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Assignee"
                                name="AssignedTo"
                                onChange={(e) => handleChange("AssignedDate", e.target.value)}
                                rules={[{ required: true, message: 'Assignee is required' }]}
                            >

                                <Input autoComplete="new-off" className='form-control' placeholder='Assignee' />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Assigned Date"
                                name="AssignedDate"
                                rules={[{ required: true, message: 'Assigned Date is required' }]}
                            >

                                <Input type="date" autoComplete="new-off" className='form-control' placeholder='Assigne to' />
                            </Form.Item>
                        </Col>

                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Due Date"
                                name="DueDate"
                                min={values.AssignedTo}
                                rules={[{ required: true, message: 'Due Date is required' }]}
                            >

                                <Input type="date" autoComplete="new-off" className='form-control' placeholder='' />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Priority"
                                name="Priority"
                                rules={[{ required: true, message: 'Priority is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Priority'
                                    autoComplete="new-off"
                                    name="Priority"
                                >
                                    <Option className='text-capitalize' value={"1"}>Critical</Option>
                                    <Option className='text-capitalize' value={"2"}>High</Option>
                                    <Option className='text-capitalize' value={"3"}>Mid</Option>
                                    <Option className='text-capitalize' value={"4"}>Low</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                    name="Status"
                                >
                                    <Option className='text-capitalize' value={"1"}>New</Option>
                                    <Option className='text-capitalize' value={"2"}>InProgress</Option>
                                    <Option className='text-capitalize' value={"3"}>InTesting</Option>
                                    <Option className='text-capitalize' value={"4"}>Completed</Option>
                                    <Option className='text-capitalize' value={"5"}>Parked</Option>
                                    <Option className='text-capitalize' value={"6"}>Revoke</Option>
                                    <Option className='text-capitalize' value={"7"}>Re Open</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label="Remark"
                                name="Remarks"
                                rules={[{ required: false, message: 'Remarks is required' }]}
                            >
                                <textarea className='form-control' value={form.getFieldValue("Remarks ")} onEditorChange={(value) => form.setFieldsValue({ Remarks: value })} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit} disabled={addLoading}>
                            Update
                        </button>
                    </div>
                    <hr></hr>
                    <div className='tableResponsive mt-10'>
                        <h1><strong>History Log</strong></h1>
                        <Table
                            rowKey='UsrId'
                            className={isLoading ? 'table antd_table no_data_found_hide' : 'table antd_table'}
                            bordered
                            columns={columns}
                            dataSource={Array.isArray(historyList) && historyList?.length > 0 ? historyList : []}
                            pagination={{
                                current: page,
                                pageSize: limit,
                                onChange(current, pageSize) {
                                    setPage(current);
                                    setOffset(current == 1 ? 0 : (limit * current));
                                },
                                hideOnSinglePage: true
                            }}
                        />
                    </div>
                </Form>
            </div>
        </>
    )
}

export default EditTicket