import { current } from '@reduxjs/toolkit';
import { Col, Input, Row, Select, message, Tooltip } from 'antd'
import React, { useState, useEffect } from 'react'
import { FileExcelFilled } from '@ant-design/icons';
import { CSVLink } from "react-csv"
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getBusinessProfession } from "../../utils";

const { Option } = Select;
const CorporateTieUpFilter = ({ filter, setFilter, setPage, setOffset, limit, isFilter, }) => {
    const [values, setValues] = useState({
        CorporateName: "",
        Industry: "",

    });
    const [FilteredData, setFilteredData] = useState([]);
    const CorporateTieUpList = useSelector(state => state.jobAppliction.CorporateTieUpList || []);
    useEffect(() => {
        
        if (CorporateTieUpList && CorporateTieUpList.length > 0) {
            var data = [];
            CorporateTieUpList.map((value, index) => {
                var item = {
                    "ID": value?.ID,
                    "first_name": value?.first_name,
                    "last_name": value?.last_name,
                    "contact_number": value?.contact_number,
                    "email_id": value?.email_id,
                    "corporate_name": value?.corporate_name,
                    "company_profile": value?.company_profile,
                    "Industry": value?.Industry,
                    "employees_count": value?.employees_count,
                    "corporate_address": value?.corporate_address,
                    "investigations_file": value?.investigations_file,
                    "corporate_requirement": value?.corporate_requirement,
                    "Pincode": value?.Pincode,
                    "state_name": value?.state_name,
                    "city_name": value?.city_name,
                    "createdAt": value?.createdAt,
                } 

                data.push(item);
                // setFilteredDat([...item])
            })
            setFilteredData(data);
        }
        return () => { }
    }, [CorporateTieUpList])
    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value });
    }
    const handleFilter = () => {
        setFilter({ ...filter, ...values });
        setPage(1);
        setOffset(0);
        limit(100);
        isFilter(1);
    }
    const handleFilterReset = () => {
        setFilter({});
        limit(100);
        setValues({
            ...values,
            CorporateName: "",
            Industry: "",

        })
        setPage(1);
        isFilter(0);
    },
        headers = [
            { label: "First Name", key: "first_name" },
            { label: "Last Name", key: "last_name" },
            { label: "Email Id", key: "email_id" },
            { label: "Mobile Number", key: "contact_number" },
            { label: "Corporate Name", key: "corporate_name" },
            { label: "Corporate Profile", key: "company_profile" },
            { label: "Industry", key: "Industry" },
            { label: "Approx Number of Employees PAN INDIA", key: "employees_count" },
            { label: "Corporate Address", key: "corporate_address" },
            { label: "Investigations", key: "investigations_file" },
            { label: "Corporate Requirement For Tie UP", key: "corporate_requirement" },
            { label: "State", key: "state_name" },
            { label: "City", key: "city_name" },
            { label: "Created Date", key: "createdAt" },
        ];
    return (
        <div className='table_filter w-80'>
            <Row gutter={[10, 20]} className="ml-10">
                {/* <Col md={8} sm={12} xs={24}>
                    <strong>  <p className='mb-2'>From Date</p></strong>
                    <Input
                        type="date"
                        id="fromdate"
                        autoComplete="new-off"
                        className='form-control'
                        onChange={(e) => handleChange("FromDate", e.target.value)}
                        max={moment().format("YYYY-MM-DD")}
                        placeholder='From Date'
                        value={values?.FromDate}
                    />
                </Col> */}
                <Col md={6} sm={6} xs={24}>
                <strong>  <p className='mb-2'>Corporate Name</p></strong>
                    <Input
                        type="text"
                        autoComplete="new-off"
                        className='form-control'
                        onChange={(e) => handleChange("CorporateName", e.target.value)}
                        placeholder='Search by Corporate Name'
                        value={values?.CorporateName}
                    />
                </Col>
                <Col md={6} sm={6} xs={24}>
                <strong>  <p className='mb-2'>Industry</p></strong>
                    <Input
                        type="text"
                        autoComplete="new-off"
                        className='form-control'
                        onChange={(e) => handleChange("Industry", e.target.value)}
                        placeholder='Search by Industry'
                        value={values?.Industry}
                    />
                </Col>
                <Col md={4} sm={12} xs={24}>
                    <div className='flex gap10 mt-20'>
                        <button className='filter-button' onClick={() => handleFilter()}>Filter</button>
                        <button className='clear-button' onClick={() => handleFilterReset()}>Reset</button>
                    </div>
                </Col>
                <Col md={4} sm={12} xs={24} className='mt-20'>
                    <CSVLink
                        filename={"Corporate Tie Up"}
                        data={FilteredData}
                        headers={headers}
                        className="ex-export2 part-export"
                        onClick={() => {
                            message.success("The file is downloading")
                        }}
                    >
                        <Tooltip placement="top" title={"A user can download only single page data once."} arrow={false}>
                            <FileExcelFilled />
                        </Tooltip>
                    </CSVLink>
                </Col>
            </Row>

        </div >
    )
}

export default CorporateTieUpFilter