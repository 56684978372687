import React, { useEffect, useState } from 'react'
import { Col, Form, Input, Row, Select } from 'antd'
import { useNavigate, useParams } from 'react-router-dom';
import { InvestorCategoriesServices } from '../../../redux/Services';
import { useDispatch, useSelector } from 'react-redux';
import { InvestorItemServices } from '../../../redux/Services';
import { useMutation } from 'react-query';
import { GetLoggedInUser, getNameValue } from '../../../utils';
import HeadingBack from '../../../component/HeadingBack';
import { usePermission } from '../../../hooks/usePermission';
import { Radio } from 'antd';
import AttachmentUpload from '../AttachmentUpload';
const { Option } = Select;
const EditFinancial = () => {
    const permission = usePermission("Seo Pages", "Edit");
    const { itemid } = useParams();
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [count, setCount] = React.useState(0);
    const InvestorItemDetail = useSelector(state => state.investoritem.InvestorItemDetails);
    const [H1List, setH1List] = useState([]);
    const [H2List, setH2List] = useState([]);
    const [H3List, setH3List] = useState([]);
    const [file, setFile] = useState("");
    const [audioFile, setAudioFile] = useState("");
    const { mutate: fetchCategory } = useMutation((data) => InvestorCategoriesServices.getInvestorCategoryList(data));
    const { mutate: InvestorItemDetails } = useMutation((data) => dispatch(InvestorItemServices.fetchInvestorItemDetailsApi(data, itemid)));
    const { mutate: InvestorItemDetailsUpdate, isLoading: addLoading } = useMutation((data) => dispatch(InvestorItemServices.InvestorItemlUpdateApi(data, itemid, navigate)));
    const { seoId } = useParams();
    useEffect(() => {
        InvestorItemDetails(itemid);
        return () => { }
    }, [])
    const [value, setValue] = useState(1);
    const onRadiochange = (e) => {
        // console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };
    useEffect(() => {
        if (InvestorItemDetail && Object.keys(InvestorItemDetail).length > 0 && Object.keys(InvestorItemDetail.list)?.length > 0) {
            const ItemData = InvestorItemDetail.list;
            form.setFieldValue('ItemId', ItemData?.ItemId);
            form.setFieldValue('SubCategoryId1', ItemData?.SubCategoryId1 || "");
            form.setFieldValue('SubCategoryId2', ItemData?.SubCategoryId2 || "");
            form.setFieldValue('SubCategoryId3', ItemData?.SubCategoryId3 || "");
            form.setFieldValue('ItemName', ItemData?.ItemName);
            form.setFieldValue('ItemType', ItemData?.ItemType);
            form.setFieldValue('ItemSlug', ItemData?.ItemSlug);
            form.setFieldValue('ItemSubHeading', ItemData?.ItemSubHeading);
            form.setFieldValue('AttachmentUrl', ItemData?.AttachmentUrl);
            form.setFieldValue('Status', ItemData?.Status);
            form.setFieldValue('AudioUrl', ItemData?.AudioUrl);
            form.setFieldValue('Remarks', ItemData?.Remarks);
            if (ItemData?.AttachmentUrl) {
                setValue(1);
                setFile(ItemData?.AttachmentUrl);
            }
            if (ItemData?.AudioUrl) {
                setValue(2);
                setAudioFile(ItemData?.AudioUrl);
            }
            if (ItemData?.AttachmentUrl && ItemData?.AudioUrl) {
                setValue(3)
            }
            fetchCategory({ ParentId: 1, IsActive: "1" }, {
                onSuccess: (res) => {
                    setH1List(res.data.Data);
                    if (ItemData?.SubCategoryId1) {
                        fetchCategory({ ParentId: ItemData?.SubCategoryId1, IsActive: "1" }, {
                            onSuccess: (res) => {
                                setH2List(res.data.Data);
                                if (ItemData?.SubCategoryId2) {
                                    fetchCategory({ ParentId: ItemData?.SubCategoryId2, IsActive: "1" }, {
                                        onSuccess: (res) => {
                                            setH3List(res.data.Data);
                                        }
                                    })
                                }
                            }
                        })
                    }
                }
            })
        }
    }, [InvestorItemDetail])
    const TypeV = {
        "1": 'Attachment',
        "2": 'Audio',
    }
    const getNestedCategory = (ParentId, type, manually = false) => {
        fetchCategory({ ParentId, IsActive: "1" }, {
            onSuccess: (res) => {
                if (type == "H1") {
                    setH1List(res.data.Data);
                    if (manually) {
                        setH2List([]);
                        setH3List([]);
                    }
                } else if (type == "H2") {
                    setH2List(res.data.Data);
                    if (manually) {
                        form.setFieldValue('SubCategoryId2', "");
                        form.setFieldValue('SubCategoryId3', "");
                        setH3List([]);
                    }
                } else if (type == "H3") {
                    setH3List(res.data.Data);
                    if (manually) {
                        form.setFieldValue('SubCategoryId3', "");
                    }
                }
            }
        })
    }
    const handleSubmit = () => {
        form.validateFields().then((values) => {
            if (InvestorItemDetailsUpdate) {
                let _data = { ...values, ItemId: itemid, CategoryId: value };
                _data.UserId = LOG_IN_USER?.id;
                _data.SubCategoryId1 = values?.SubCategoryId1 || "";
                _data.SubCategoryId2 = values?.SubCategoryId2 || "";
                _data.SubCategoryId3 = values?.SubCategoryId3 || "";
                _data.SubCategoryId1Name = getNameValue(H1List,values?.SubCategoryId1) || "";
                _data.SubCategoryId2Name = getNameValue(H2List,values?.SubCategoryId2) || "";
                _data.SubCategoryId3Name = getNameValue(H3List,values?.SubCategoryId3) || "";
                InvestorItemDetailsUpdate(_data);
            }
        })
    }
    // console.log(InvestorItemDetailsUpdate)
    return (
        <>
            <HeadingBack title={"Financial Edit"} subHeading="" />
            <div className='p-20'>
                < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                >
                    <Row gutter={[24, 0]}>
                        <Col lg={8} md={8} sm={24} xs={24}>
                            <Form.Item
                                label="Sub Category (H1)"
                                name="SubCategoryId1"
                                rules={[{ required: true, message: 'Category is required' }]}
                            >
                                <Select
                                    className='text-capitalize w-100'
                                    placeholder='Select Sub Category (H1)'
                                    autoComplete="new-off"
                                    onChange={(value) => { getNestedCategory(value, "H2", true); }}
                                >
                                    <Option className='text-capitalize' value="" disabled>Select Sub Category (H1)</Option>
                                    {H1List?.length > 0 && H1List?.map((item, idx) =>
                                        <Option key={idx} className='text-capitalize' value={item?.Id}>{item?.Category}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={8} md={8} sm={24} xs={24}>
                            {H2List?.length > 0 &&
                                <Form.Item
                                    label="Sub Category (H2)"
                                    name="SubCategoryId2"
                                    rules=""
                                >
                                    <Select
                                        className='text-capitalize w-100'
                                        placeholder='Select Sub Category (H2)'
                                        autoComplete="new-off"
                                        onChange={(value) => { getNestedCategory(value, "H3", true); }}
                                    >
                                        <Option className='text-capitalize' value="" disabled>Select Sub Category (H2)</Option>
                                        {H2List?.length > 0 && H2List?.map((item, idx) =>
                                            <Option key={idx} className='text-capitalize' value={item?.Id}>{item?.Category}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            }
                        </Col>
                        <Col lg={8} md={8} sm={24} xs={24}>
                            {(H3List?.length > 0) &&
                                <Form.Item
                                    label="Sub Category (H3)"
                                    name="SubCategoryId3"
                                    rules=""
                                >
                                    <Select
                                        className='text-capitalize w-100'
                                        placeholder='Select Sub Category (H3)'
                                    >
                                        <Option className='text-capitalize' value="" disabled>Select Sub Category (H3)</Option>
                                        {H3List?.length > 0 && H3List?.map((item, idx) =>
                                            <Option key={idx} className='text-capitalize' value={item.Id}>{item.Category}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            }
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Item Name"
                                name="ItemName"
                                rules={[{ required: true, message: 'Item Name is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Item Name' />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Item Type"
                                name="ItemType"
                                rules={[{ required: true, message: 'Type is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Type'
                                    autoComplete="new-off"
                                    name="Type"
                                >
                                    <Option className='text-capitalize' value={1}>List</Option>
                                    <Option className='text-capitalize' value={2}>Accordion</Option>{/* 
                                    <Option className='text-capitalize' value={3}>Box</Option> */}
                                </Select>
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Item Slug"
                                name="ItemSlug"
                                rules={[{ required: true, message: 'Item Slug is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Item Slug' />
                            </Form.Item>
                        </Col >


                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Item Sub Heading"
                                name="ItemSubHeading"
                                rules={[{ required: false, message: 'Item Sub Heading is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Item Sub Heading' />
                            </Form.Item>
                        </Col >
                    </Row>
                    <Row gutter={[24, 5]}>

                        <Col lg={24} md={24} sm={24} xs={24}>
                            <Radio.Group onChange={onRadiochange} value={value}>
                                <Radio value={1}>Attachment</Radio>
                                <Radio value={2}>Audio</Radio>
                                <Radio value={3}>Both</Radio>
                            </Radio.Group>
                        </Col >
                        {(value == 1 || value == 3) &&
                            <Col lg={12} md={24} sm={24} xs={24}>
                                <Form.Item
                                    label={TypeV[1]}
                                    name="AttachmentUrl"
                                    rules={[{ required: true, message: 'Attachment is required' }]}
                                >
                                    <AttachmentUpload
                                        file={file}
                                        setFile={setFile}
                                        handleChange={(name) => {
                                            setFile(name);
                                            form.setFieldsValue({ AttachmentUrl: name })
                                        }}
                                        accept="image/jpeg,image/png,image/jpg, application/pdf"
                                    />
                                </Form.Item>
                            </Col >}
                        {(value == 2 || value == 3) &&
                            <Col lg={12} md={24} sm={24} xs={24}>
                                <Form.Item
                                    label={TypeV[2]}
                                    name="AudioUrl"
                                    rules={[{ required: true, message: 'Audio is required' }]}
                                >
                                    <AttachmentUpload
                                        file={audioFile}
                                        setFile={setAudioFile}
                                        handleChange={(name) => {
                                            setAudioFile(name);
                                            form.setFieldsValue({ AudioUrl: name })
                                        }}
                                        accept="audio/mp3,audio/*;capture=microphone"
                                    />
                                </Form.Item>
                            </Col >
                        }
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                    name="Status"
                                >
                                    <Option className='text-capitalize' value={0}>Inactive</Option>
                                    <Option className='text-capitalize' value={1}>Active</Option>
                                </Select>
                            </Form.Item>
                        </Col >
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label="Item Remark"
                                name="Remarks"
                            // rules={[{ required: true, message: 'Item Remark is required' }]}
                            >
                                <textarea className='form-control' value={form.getFieldValue("Remarks ")} onEditorChange={(value) => form.setFieldsValue({ Remarks: value })} />
                            </Form.Item>
                        </Col>

                    </Row>
                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit} disabled={addLoading}>
                            Update
                        </button>
                    </div>
                </Form >
            </div>
        </>
    )
}

export default EditFinancial