import React from 'react'
import CenteralHealthLineChart from './CenteralHealthLineChart';

const CenteralHealthLine = () => {
    return (
        <div>
            <div className='flex justify-content-between'>
                <div>
                    <div className='fw-600'>Total FAQs</div>
                </div>
                <div className='flex justify-content-end gap20 align-items-center'>
                    <div className='flex gap30'>
                        <div className='relative'><span className='ct_chart_status beforeActive'>Active</span></div>
                        <div className='relative'><span className='ct_chart_status beforeInactive'>Inactive</span></div>
                    </div>
                </div>
            </div>
            <div className='p-10'>
                < CenteralHealthLineChart />
            </div>
        </div>
    )
}

export default CenteralHealthLine