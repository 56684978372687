import { Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeadingBack from '../../component/HeadingBack';
import SvgIcon from '../../const/SvgIcon';
import { TicketServices } from '../../redux/Services';
import TicketFilter from './ticketFilter';
import moment from 'moment';
import { usePermission } from '../../hooks/usePermission';
import API_URL from "../../api/url";
import IMAGES from '../../const/Image';
import { isJSON } from '../../utils';
import JsonShow from './JsonShow';
const TicketStatus = {
    "1": 'New',
    "2": 'InProgress',
    "3": 'InTesting',
    "4": 'Completed',
    "5": 'Parked',
    "6": 'Revoke',
    "7": 'Re Open',
}
const TicketPrority = {
    "1": 'Critical',
    "2": 'High',
    "3": 'Mid',
    "4": 'Low',
}
const colors = ['#f10909', '#f10909', '#fe784d', '#1077a1', '#009c00',];
const Ticket = () => {
    const permission = usePermission("Ticket Management", "List");
    const navigate = useNavigate();
    const [filter, setFilter] = useState({});
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const totalTicket = useSelector(state => state.Ticket.TotalRecord || 0);
    const TicketLists = useSelector(state => state.Ticket.TicketList || []);
    const { mutate: TicketList, isLoading } = useMutation((data) => dispatch(TicketServices.fetchTicketListApi(limit, offset, data)));
    useEffect(() => {
        TicketList(filter)
        return () => { }
    }, [filter, offset])
    // console.log(TicketLists)
    
    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
            className: 'table_first_col',
            render: (value, item, index) => (page - 1) * 10 + index + 1
        },

        {
            title: 'Subject',
            dataIndex: 'Subject',
            key: 'Subject',
            className: 'text-capitalize',
        },

        {
            title: 'Description',
            dataIndex: 'Description',
            key: 'Description',
            className: 'desc_tool',
            ellipsis: {
                showTitle: false,
            },
            render: (Description) => {
                if (isJSON(Description)) {
                    let des = JSON.parse(Description);
                    return (
                        <Tooltip overlayInnerStyle={{ width: '800px' }} placement="topLeft" title={<JsonShow data={des}/>}>
                            {Object.keys(des)[0]}{" "}{Object.values(des)[0]}
                        </Tooltip>
                    )
                }
                else {
                    return (
                        <Tooltip overlayInnerStyle={{ width: '800px' }} placement="topLeft" title={Description}>
                            {Description}
                        </Tooltip>
                    )
                }
            },
        },
        {
            title: 'Attachments',
            // dataIndex: 'Attachments',
            key: 'Attachments',
            width: '250px',
            render: (value, item, index) => {
                let data = JSON.parse(item?.Attachments || "[]");
                return (
                    <div> {item?.Attachments ? Array.isArray(data) && data?.length > 0 &&  data.map((v, i) => {
                        return (<a target="_blank" className="view-attachment" href={API_URL.S3_BANNER_BASE + v}><img className='doc_icon-w' src={IMAGES.Documenticon} /></a>)
                    }) : 'NA'}</div>
                )
            }
        },
        {
            title: 'Priority',
            dataIndex: 'Priority',
            key: 'Priority',
            className: 'table_status_col',
            render: (value, item, index, i) => {
                return (
                    <span style={{ color: colors[item?.Priority] }} key={i}>{item?.Priority ? TicketPrority[item?.Priority] : ""}</span>
                    // <span className={item?.Status ? "status_active" : "status_inactive"}>{item?.Status ? "Active" : "Inactive"}</span>

                )
            }
        },
        {
            title: 'Created Date',
            dataIndex: 'CreatedAt',
            key: 'CreatedAt',
            className: 'text-capitalize',
            render: (value, item, index) => {
                return (
                    <>{moment(item.CreatedAt).format('DD-MM-YYYY H:mm:ss')}</>
                )
            }
        },
        {
            title: 'Due Date',
            dataIndex: 'DueDate',
            key: 'DueDate',
            className: 'text-capitalize',
            render: (value, item, index) => {
                return (
                    <>{moment(item.DueDate).format('DD-MM-YYYY')}</>
                )
            }
        },

        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            className: 'table_status_col',
            render: (value, item, index) => {
                return (
                    <span>{item?.Status ? TicketStatus[item?.Status] : ""}</span>
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            className: 'table_action_col',
            render: (value, item, index) => {
                return (
                    <Tooltip title="Edit">
                        <span className='cursor-pointer' onClick={() => navigate(`/ticket-management/edit/${item?.Id}`)}>
                            <SvgIcon.Edit className="scale-icon edit-icon" />
                        </span>
                    </Tooltip>
                )
            }
        },
    ];
    return (
        <div>
            <div className='flex'>
                <HeadingBack title={"Total Tickets"} subHeading={`Total ${totalTicket || 0} Tickets`} />
                <TicketFilter filter={filter} setFilter={setFilter} setPage={setPage} setOffset={setOffset} />
                <div className='flex job-post-add'>
                    <Tooltip title="Add"><span className='jop_btn mt-15' onClick={() => navigate("/ticket-management/add")}>Add<SvgIcon.Add width={40} height={40} className="cursor-pointer" /></span></Tooltip>
                </div>
            </div>
            <div className='tableResponsive mt-10'>
                <Table
                    rowKey='UsrId'
                    className={isLoading ? 'table antd_table no_data_found_hide' : 'table antd_table'}
                    bordered
                    columns={columns}
                    dataSource={Array.isArray(TicketLists) && TicketLists?.length > 0 ? TicketLists : []}
                    pagination={{
                        current: page,
                        pageSize: limit,
                        total: totalTicket,
                        onChange(current, pageSize) {
                            setPage(current);
                            setOffset(current == 1 ? 0 : (limit * current));
                        },
                        hideOnSinglePage: true
                    }}

                />
            </div>
        </div>
    )
}

export default Ticket