import { Table, Tooltip, message } from 'antd';
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FileExcelFilled } from '@ant-design/icons';
import HeadingBack from '../../component/HeadingBack';
import { CSVLink } from "react-csv"
import { JobApplicationsServices } from '../../redux/Services';
import JobAppFilter from './partnerFilter';
// import URL from "../../../api/url";
import { usePermission } from '../../hooks/usePermission';
import moment from 'moment';
import { getBusinessProfession } from "../../utils";

const BusinessPartner = () => {

    const permission = usePermission("Become a Partner", "List");
    const navigate = useNavigate();
    const [filter, setFilter] = useState({});
    const [limit, setLimit] = useState(100);
    const [offset, setOffset] = useState(0);
    const [isFilter, setisFilter] = useState(0);
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const PartnerList = useSelector(state => state.jobAppliction.PartnerList || []);
    const totalPartner = useSelector(state => state.jobAppliction.totalPartnerList || 0);
    const { mutate: getBecomePartnerList, isLoading } = useMutation((data) => dispatch(JobApplicationsServices.fetchBecomeListApi(limit, offset, data)));
    useEffect(() => {
        getBecomePartnerList(filter);
        return () => { }
    }, [filter, offset, limit])
    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
            className: 'table_first_col',
            render: (value, item, index) => (page - 1) * 100 + index + 1
        },

        {
            title: 'First Name',
            dataIndex: 'FristName',
            key: 'FirstName',
            className: 'text-capitalize',
        },
        {
            title: 'Last Name',
            dataIndex: 'LastName',
            key: 'Name',
            className: 'text-capitalize',
        },
        {
            title: 'Email',
            dataIndex: 'EmailID',
            key: 'Email',
            width: '250px'
        },
        {
            title: 'Mobile',
            dataIndex: 'Mobile',
            key: 'Mobile',
        },
        {
            title: 'Profession',
            dataIndex: 'Profession',
            key: 'Profession',
            render: (value, item, index) => {
                return (
                    <span>{getBusinessProfession(item?.Business_profession)}</span>
                )
            }
        },
        {
            title: 'State',
            dataIndex: 'State',
            key: 'State',
        },
        {
            title: 'City',
            dataIndex: 'City',
            key: 'City',
            render: (value, item, index) => {
                return (
                    <span>{(item.City) ? item.City : item.CandidateLocation}</span>
                )
            }
        },
        {
            title: 'Pincode',
            dataIndex: 'Pincode',
            key: 'Pincode',
            render: (value, item, index) => {
                return (
                    <span>{(item.Pincode)}</span>
                )
            }
        },

        {
            title: 'Created Date',
            dataIndex: 'InsertDateTime',
            key: 'Createddate',
            render: (value, item, index) => {
                return (
                    <>{moment.utc(item.InsertDateTime).format('DD-MM-YYYY H:mm:ss')}</>
                )
            }
        },

    ];
    return (
        <div>
            <div className='position-relative part-filter'>
                <HeadingBack title={"Total Leads"} subHeading={`Total ${totalPartner || 0} Leads`} />
                <JobAppFilter filter={filter} setFilter={setFilter} setPage={setPage} setOffset={setOffset} limit={setLimit} isFilter={setisFilter} />


            </div>
            <div className='tableResponsive mt-10'>
                <Table
                    rowKey='UsrId'
                    className={isLoading ? 'table antd_table no_data_found_hide' : 'table antd_table'}
                    bordered
                    columns={columns}
                    dataSource={Array.isArray(PartnerList) && PartnerList?.length > 0 ? PartnerList : []}
                    pagination={{
                        current: page,
                        pageSize: limit,
                        total: totalPartner,
                        onChange(current, pageSize) {
                            setPage(current);
                            setOffset(current == 1 ? 0 : (((current - 1) * limit)));
                        },
                        hideOnSinglePage: true
                    }}

                />
            </div>
        </div>
    )
}

export default BusinessPartner