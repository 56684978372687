import { current } from '@reduxjs/toolkit';
import { Col, Input, Row, Select } from 'antd'
import React, { useState, useEffect } from 'react'
import moment from 'moment';
const { Option } = Select;
const JobAppFilter = ({ filter, setFilter, JobId, setPage, setOffset, StateCityList, jobApplicationsList, limit, isFilter, jobsPostList }) => {
    // let arr = ["Ravi", "Aman", "Surya", "Rohit"];
    const [stateList, setStateList] = useState({});
    const [cityList, setCityList] = useState({});
    const [RList, setRList] = useState([]);
    const [values, setValues] = useState({
        FirstName: "",
        EmailId: "",
        Mobile: "",
        State: "",
        City: "",
        WorkStatus: "",
        Experience: "",
        FromDate: "",
        ToDate: "",
        JobId: "",
        JobRole: "",
    });
    const filterCityList = (stateId) => {
        setCityList(StateCityList.filter(state => state.stateid === stateId));
    }
    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value });
    }
    const handleFilter = () => {
        setFilter({ ...filter, ...values });
        setPage(1);
        setOffset(0);
        limit(20);
        isFilter(1);
    }
    const handleFilterReset = () => {
        setFilter({});
        limit(20);
        setValues({
            ...values,
            FirstName: "",
            EmailId: "",
            Mobile: "",
            State: "",
            City: "",
            WorkStatus: "",
            Experience: "",
            FromDate: "",
            ToDate: "",
            JobId: "",
            JobRole: "",
            Status: ""
        })
        setPage(1);
        isFilter(0);
    }
    useEffect(() => {
        if (jobsPostList && jobsPostList?.length > 0) {
            let nd = [];
            jobsPostList?.length > 0 && jobsPostList?.forEach(element => {
                nd.push(element);
            });
            let dt = nd?.sort((a, b) => {
                if (a["JobTitle"] < b["JobTitle"])
                    return -1;
                if (a["JobTitle"] > b["JobTitle"])
                    return 1;
                return 0;
            });
            // console.log(dt);
            setRList(dt)
        }

    }, [jobsPostList])
    useEffect(() => {
        if (StateCityList) {
         
            setCityList(StateCityList);
            var d = new Map();
            StateCityList.map((value, index) => {
                var x = { "stateid": value.stateid, "cityid": value.cityid, "statename": value.statename, "cityname": value.cityname };
                {
                    d[value.stateid] = [];
                    d[value.stateid] = x;
                }
            }); 
            setStateList(d);;
        } else {
            console.log('ELSE');
        }
    }, [StateCityList]);
    return (
        <div className='table_filter'>
            <Row gutter={[10, 20]} className="ml-10">
                <Col md={4} sm={12} xs={24}>
                    <Input
                        type="text"
                        autoComplete="new-off"
                        className='form-control'
                        onChange={(e) => handleChange("FirstName", e.target.value)}
                        placeholder='First Name'
                        value={values?.FirstName}
                    />
                </Col>
                <Col md={4} sm={12} xs={24}>
                    <Input
                        type="text"
                        autoComplete="new-off"
                        className='form-control'
                        onChange={(e) => handleChange("EmailId", e.target.value)}
                        placeholder='Email'
                        value={values?.EmailId}
                    />
                </Col>
                <Col md={4} sm={12} xs={24}>
                    <Input
                        type="text"
                        autoComplete="new-off"
                        className='form-control'
                        onChange={(e) => handleChange("Mobile", e.target.value)}
                        placeholder='Mobile'
                        value={values?.Mobile}
                    />
                </Col>
                <Col md={4} sm={12} xs={24}>
                    <Select
                        className='text-capitalize w-100'
                        placeholder='Select Role'
                        autoComplete="new-off"
                        onChange={(value) => handleChange("JobRole", value)}
                        value={values?.JobRole}

                    >
                        <Option className='text-capitalize' value="">Select Role</Option>
                        {RList?.length > 0 && RList?.map((item, idx) =>
                            <Option key={idx + "role"} className='text-capitalize' value={item?.JobTitle}>{item?.JobTitle}</Option>
                        )}
                    </Select>
                </Col>

                <Col md={4} sm={12} xs={24}>
                    <Select
                        className='text-capitalize w-100'
                        placeholder='Select State'
                        autoComplete="new-off"
                        onChange={(value) => { handleChange("State", value); filterCityList(value) }}
                        value={values?.State}
                    >
                        <Option className='text-capitalize' value="">Select State</Option>
                        {Object.keys(stateList)?.length > 0 && Object.keys(stateList)?.map((idx, item) =>
                            <Option key={stateList[idx]['stateid']} className='text-capitalize' value={stateList[idx]['stateid']}>{stateList[idx]['statename']}</Option>

                        )}
                    </Select>
                </Col>
                <Col md={4} sm={12} xs={24}>
                    <Select
                        className='text-capitalize w-100'
                        placeholder='Select City'
                        autoComplete="new-off"
                        onChange={(value) => handleChange("City", value)}
                        value={values?.City}
                    >
                        <Option className='text-capitalize' value="">Select City</Option>
                        {cityList?.length > 0 && cityList?.map((item, idx) =>
                            <Option key={idx} className='text-capitalize' value={item.cityid}>{item.cityname}</Option>
                        )}
                    </Select>
                </Col>

            </Row>
            <Row gutter={[10, 30]} className="mt-10 ml-10">
                <Col md={4} sm={12} xs={24}>
                    <Select
                        className='text-capitalize w-100'
                        placeholder='Select Work Status'
                        autoComplete="new-off"
                        onChange={(value) => handleChange("WorkStatus", value)}
                        value={values?.WorkStatus}
                    >
                        <Option className='text-capitalize' value="" Selected>Select Work Status</Option>
                        <Option className='text-capitalize' value="Fresher">Fresher</Option>
                        <Option className='text-capitalize' value="Experience">Experience</Option>
                    </Select>
                </Col>
                <Col md={4} sm={12} xs={24}>
                    <Select
                        className='text-capitalize w-100'
                        onChange={(value) => handleChange("Experience", value)}
                        placeholder='Experience'
                        value={values?.Experience}
                    >
                        <Option className='text-capitalize' value="" Selected>Select Experience</Option>
                        <Option className='text-capitalize' value="1">1 Year</Option>
                        <Option className='text-capitalize' value="2">2 Years</Option>
                        <Option className='text-capitalize' value="3">3 Years</Option>
                        <Option className='text-capitalize' value="4">4 Years</Option>
                        <Option className='text-capitalize' value="5">5 Years</Option>
                        <Option className='text-capitalize' value="6">6 Years</Option>
                        <Option className='text-capitalize' value="7">7 Years</Option>
                        <Option className='text-capitalize' value="8">8 Years</Option>
                        <Option className='text-capitalize' value="9">9 Years</Option>
                        <Option className='text-capitalize' value="10">10 Years</Option>
                        <Option className='text-capitalize' value="11">11 Years</Option>
                        <Option className='text-capitalize' value="12">12 Years</Option>
                    </Select>
                </Col>
                <Col md={4} sm={12} xs={24}>
                    <Input
                        type="date"
                        id="fromdate"
                        autoComplete="new-off"
                        className='form-control'
                        onChange={(e) => handleChange("FromDate", e.target.value)}
                        max={moment().format("YYYY-MM-DD")}
                        placeholder='From Date'
                        value={values?.FromDate}
                    />
                </Col>
                <Col md={4} sm={12} xs={24}>
                    <Input
                        type="date"
                        id="todate"
                        autoComplete="todate"
                        className='form-control'
                        onChange={(e) => handleChange("ToDate", e.target.value)}
                        min={values?.FromDate}
                        placeholder='To Date'
                        value={values?.ToDate}
                    />
                </Col>
                <Col md={4} sm={12} xs={24}>
                    <div className='flex gap10'>
                        <button className='filter-button' onClick={() => handleFilter()}>Filter</button>
                        <button className='clear-button' onClick={() => handleFilterReset()}>Reset</button>
                    </div>
                </Col>
            </Row>
        </div >
    )
}

export default JobAppFilter