import React, { useEffect, useState } from 'react'
import { Form, Input, Select, Row, Col } from 'antd'
import { GetLoggedInUser } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { BlogAttachmentServices } from '../../redux/Services';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import HeadingBack from '../../component/HeadingBack';
import { usePermission } from '../../hooks/usePermission';
import FileUpload from './FileUpload';
const BlogAttachmentAdd = () => {
    const permission = usePermission("Blog Attachment", "Add");
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [file, setFile] = useState("");
    const [mFile, setMFile] = useState("");
    const { mutate: addAttachment } = useMutation((data) => dispatch(BlogAttachmentServices.blogImageAddApi(data)));
    const { mutate: addBlogAttachment, isLoading: isLoading } = useMutation((data) => dispatch(BlogAttachmentServices.addBlogAttachmentApi(data, navigate)));
    const handleSubmit = () => {
        form.validateFields().then((values) => {
            let _data = { ...values };
            _data.UserId = LOG_IN_USER?.id;
            addBlogAttachment(_data)
        })
    }
    const handleUpload = (file) => {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("dir_location", "blog_media");
        addAttachment(formData, {
            onSuccess: (res) => {
                var fileName = res.data?.data?.key;
                setFile(fileName)
            },
        })
    }

    useEffect(() => {
        form.setFieldsValue({
            Attachment: file,
        })
    }, [file])

    return (
        <>
            <HeadingBack title={("Blog Attachment", "Add")} subHeading="" />
            <div className='p-20'>
                < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                >
                    <Row gutter={[24, 0]}>

                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Label"
                                name="Label"
                                rules={[{ required: true, message: 'Label is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Label' />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Alt Tag"
                                name="AtlTag"
                                rules={[{ required: true, message: 'Alt Tag is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Alt Tag' />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Image"
                                name="Attachment"
                                rules={[{ required: false, message: 'Image is required' }]}
                            >
                                <FileUpload file={file} setFile={setFile} handleUpload={handleUpload} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit} disabled={isLoading}>
                            Add
                        </button>
                    </div>
                </Form >
            </div >
        </>
    )
}

export default BlogAttachmentAdd