import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    TestdepartmentList: [],
    TotalRecord: '',
    TestdepartmentDetail: {},
  
}

export const TestdepartmentSlice = createSlice({
    name: 'Testdepartment',
    initialState,
    reducers: {
        setTestDepartmentList: (state, action) => {
            state.TestdepartmentList = action.payload.list;
          state.TotalRecord = action.payload.total;
        },
      
        setTestDepartmentDetail: (state, action) => {
            state.TestdepartmentDetail = action.payload;
        },
    },
})

export const { setTestDepartmentList, setTestDepartmentDetail} = TestdepartmentSlice.actions

export default TestdepartmentSlice.reducer