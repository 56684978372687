import React, { useEffect, useState } from 'react'
import { Col, Form, Input, Row, Select, Grid } from 'antd'
import CustomEditor from '../../../component/CustomEditor/CustomEditor';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { FaqServices } from '../../../redux/Services';
import { GetLoggedInUser } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import HeadingBack from '../../../component/HeadingBack';
import { TestDepartmentServices } from '../../../redux/Services';
import { AutoComplete } from 'antd';

import { usePermission } from '../../../hooks/usePermission';


const { Option } = Select;
const AddFaq = () => {

    const permission = usePermission("Faqs", "Add");
    var newOptions = [];

    const [value, setValue] = useState('');
    const [referenceId, setReferenceId] = useState('');
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const faqCategoryList = useSelector(state => state.faq.faqCategoryList || []);
    const TestdepartmentList = useSelector(state => state.Testdepartment.TestdepartmentList || []);
    const { mutate: fetchFaqCategory } = useMutation((data) => dispatch(FaqServices.fetchFaqCategoryApi(data)));
    const { mutate: addFaqs } = useMutation((data) => dispatch(FaqServices.addFaqsApi(data, navigate)));
    const { mutate: fetchTestDepartmentList, isLoading } = useMutation((data) => dispatch(TestDepartmentServices.fetchTestDeepartmentListApi(data)));
    const [options, setOptions] = useState([]);
    useEffect(() => {
        fetchFaqCategory({ isActive: "1" });
        return () => { }
    }, [])
    useEffect(() => {
        newOptions = [];
        TestdepartmentList.map((value, key) => {
            // newOptions.push({
            //     id: value.Id,
            //     name: value.TestCode
            // })  ;
            newOptions.push({
                value: value.TestCode
            })
        })
        setOptions(newOptions);
        return () => { }
    }, [TestdepartmentList])
    const handleSubmit = () => {
        form.validateFields().then((values) => {
            let _data = { ...values };
            _data.UserId = LOG_IN_USER?.id;
            _data.ReferenceId = referenceId;
            if (!referenceId) {
                setValue('Test id is required.');
                document.getElementById('test_code').focus();
                return false;
            }
            addFaqs(_data)
        })
    }
    const onInputChange = (value) => {
        if (value && value.length >= 2) {
            fetchTestDepartmentList({ "TestCode": value });
            // setValue(value);
        }
    };
    const handleOnSelect = (item) => {
        console.log('item', item);
        setReferenceId(item);
    }
    // const formatResult = (item) => {
    //     return (
    //         <>
    //             {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
    //             <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
    //         </>
    //     )
    // }
    function handleOnFocus() {
        setReferenceId(null);
    }
    // const mockVal = (searchText) => {
    //     let list = TestCode?.filter(ele => ele?.TestCode == searchText);
    //     let newL = list.map(ele => { return { value: ele.TestCode } });
    //     if (newL?.length > 0) {
    //         return newL
    //     } else {
    //         return [{ value: "" }]
    //     }
    // };

    // const onSearch = (searchText) => {
    //     setOptions(
    //         !searchText ? [] : mockVal(searchText),
    //     );
    // };
    // const Option = [
    //     {
    //       value: 'Burns Bay Road',
    //     },
    //     {
    //       value: 'Downing Street',
    //     },
    //     {
    //       value: 'Wall Street',
    //     },
    //   ];
    return (
        <>
            <HeadingBack title={"Faq Add"} subHeading="" />
            <div className='p-20'>

                < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                    initialValues={{ Status: "1" }}
                >
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Category"
                                name="FaqCategoryId"
                                rules={[{ required: true, message: 'Category is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Category'
                                    autoComplete="new-off"
                                    name="FaqCategoryId"
                                >
                                    <Option className='text-capitalize' value={""}>Select Category</Option>
                                    {faqCategoryList?.length > 0 && faqCategoryList?.map((item, idx) =>
                                        <Option key={idx} className='text-capitalize' value={item.Id}>{item.Category}</Option>
                                    )}
                                </Select>

                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Title"
                                name="Title"
                                rules={[{ required: true, message: 'Title is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Title' />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Test Id"
                            // name="ReferenceId"
                            // rules={[{ required: true, message: 'Test Id is required' }]}
                            >
                                <Input type="text" autoComplete="new-offf" className='form-control' placeholder='Test Id' id="test_code" style={{ opacity: 0, height: 0, marginTop: "-10px", position: "absolute" }} />
                                <AutoComplete

                                    options={options}
                                    onSearch={onInputChange}
                                    onSelect={handleOnSelect}
                                    placeholder="Test Id"
                                    onFocus={handleOnFocus}

                                />

                                {/* <ReactSearchAutocomplete
                                    items={options}
                                    onSearch={onInputChange}
                                    // onHover={handleOnHover}
                                    onSelect={handleOnSelect}
                                    onFocus={handleOnFocus}
                                    autoFocus
                                    formatResult={formatResult}
                                /> */}
                                <div id="testid_erro">{value}</div>
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Sequence"
                                name="Sequence"
                            >
                                <Input type="number" autoComplete="new-off" className='form-control' placeholder='Sequence' />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                    name="Status"
                                >
                                    <Option className='text-capitalize' value={"0"}>Inactive</Option>
                                    <Option className='text-capitalize' value={"1"}>Active</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Description"
                                name="Description"
                                rules={[{ required: true, message: 'Description is required' }]}
                            >
                                <CustomEditor value={form.getFieldValue("Description")} onEditorChange={(value) => form.setFieldsValue({ Description: value })} />
                            </Form.Item>
                        </Col>
                    </Row >
                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit}>
                            Submit
                        </button>
                    </div>
                </Form>
            </div >
        </>
    )
}

export default AddFaq