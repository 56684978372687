import { notification } from "antd";
import Api from "../../api";
import API_URL from "../../api/url";
import { setFaqCategoryDetail, setFaqCategoryList, setFaqDetail, setFaqList } from "../Slices/faqSlice";

export const fetchFaqApi = (data) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.FAQ_LIST, data);
        dispatch(setFaqList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const addFaqsApi = (data, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.FAQ_ADD, data);
        navigate("/faqs");
        notification["success"]({
            message: "Faq  created successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const updateFaqsApi = (data, id, navigate) => async (dispatch) => {
    try {
        const res = await Api.put(`${API_URL.FAQ_UPDATE}/${id}`, data);
        navigate("/faqs");
        notification["success"]({
            message: "Faq updated successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const fetchFaqsByIdApi = (data, id) => async (dispatch) => {
    try {
        const res = await Api.post(`${API_URL.FAQ_BY_ID}/${id}`);
        dispatch(setFaqDetail(res.data.Data));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchFaqCategoryApi = (data) => async (dispatch) => {
    try {
        const res = await Api.get(API_URL.FAQ_CATEGORY_LIST, { params: data });
        dispatch(setFaqCategoryList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const addFaqCategoryApi = (data, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.FAQ_CATEGORY_ADD, data);
        navigate("/faq-category");
        notification["success"]({
            message: "Faq Category created successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const updateFaqCategoryApi = (data, id, navigate) => async (dispatch) => {
    try {
        const res = await Api.put(`${API_URL.FAQ_CATEGORY_UPDATE}/${id}`, data);
        navigate("/faq-category");
        notification["success"]({
            message: "Faq Category updated successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};
export const fetchFaqCategoryByIdApi = (data, id) => async (dispatch) => {
    try {
        const res = await Api.post(`${API_URL.FAQ_CATEGORY_BY_ID}/${id}`);
        dispatch(setFaqCategoryDetail(res.data.Data));
        return res;
    } catch (error) {
        return error
    }
};