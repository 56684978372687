import { notification } from "antd";
import Api from "../../api";
import API_URL from "../../api/url";
import { setJobPostList, setJobAddDetails } from "../Slices/jobPostSlice";

export const fetchJobAddListApi = (limit, offset, data) => async (dispatch) => {
    try {
        // var params = params;
        data.Limit = limit;
        data.Offset = offset;
        const res = await Api.post(API_URL.JOBADD_LIST, data);
        dispatch(setJobPostList({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};
export const fetchJobAddDetailsApi = (id) => async (dispatch) => {
    try {
        var url = API_URL.JOBADD_DETAILS + '/' + id;
        const params = {
            Limit: 1,
            Offset: 0
        };
        const res = await Api.post(url, params);
        dispatch(setJobAddDetails({ list: res.data.Data, total: res.data.TotalRecord }));
        return res;
    } catch (error) {
        return error
    }
};

export const addJobsApi = (data, navigate) => async (dispatch) => {
    try {
        const res = await Api.post(API_URL.JOBADD_ADD, data);
        navigate("/jobs");
        notification["success"]({
            message: "Job added successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message?.email || error.message
        });
        return error
    }
};
export const updateJobAddApi = (data, id, navigate) => async (dispatch) => {
    try {
        const res = await Api.put(`${API_URL.JOBADD_UPDATE}/${id}`, data);
        navigate("/jobs");
        notification["success"]({
            message: "Job add updated successfully!"
        });
        return res;
    } catch (error) {
        notification["error"]({
            message: error.response?.data?.Message || error.message
        });
        return error
    }
};

// export const fetchStateCityListApi = (limit, offset, data) => async (dispatch) => {
//     try {
//         data.Limit = limit;
//         data.Offset = offset;   
//         const res = await Api.post(API_URL.STATE_CITY, data);
//         dispatch(setStateCityList({ list: res.data.Data, total: res.data.TotalRecord }));
//         return res;
//     } catch (error) {
//         return error
//     }
// };