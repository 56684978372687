import { Upload,Modal, } from 'antd';
import React, { useEffect, useState } from 'react'
import API_URL from '../../api/url';

const FileUpload = ({ file, setFile, handleUpload }) => {
    const [modal2Open, setModal2Open] = useState(false);
    const [fileList, setFileList] = useState([]);
    const onPreview = async (file) => {
        setModal2Open(true)
    };
    const url = API_URL.S3_BANNER_BASE
    const onRemove = () => {
        setFile("");
        setFileList([]);
    }
    useEffect(() => {
        if (file) {
            setFileList([{
                crossOrigin: "anonymous",
                uid: '-1',
                name: "banner",
                status: 'done',
                url: API_URL.S3_BANNER_BASE + file,
                thumbUrl: API_URL.S3_BANNER_BASE + file
            }])
        }
    }, [file])

    return (
        <>
        <Upload
            className='upload_offers'
            accept="image/jpeg,image/png,image/jpg"
            multiple={false}
            listType="picture-card"
            onPreview={onPreview}
            onRemove={onRemove}
            customRequest={({ file }) => handleUpload(file)}
            fileList={fileList}
        >
            {fileList.length < 1 && '+ Upload'}
        </Upload>
        <Modal
            className='btl_modal'
            centered
            open={modal2Open}
            onOk={() => setModal2Open(false)}
            onCancel={() => setModal2Open(false)}
            footer={null}
        >
                <img src={(url+file)} crossOrigin='anymous' />
            </Modal></>
    )
}

export default FileUpload