import { Table, Tooltip, Typography,message } from 'antd';
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeadingBack from '../../component/HeadingBack';
import SvgIcon from '../../const/SvgIcon'
import { usePermission } from '../../hooks/usePermission';
import { BlogAttachmentServices, UserServices } from '../../redux/Services';
import { DeleteOutlined,CopyTwoTone } from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard'
import moment from 'moment';

const BlogAttachment = () => {
    const permission = usePermission("Blog Attachment", "List");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const attachmentList = useSelector(state => state.BlogAttachment.attachmentList || []);
    const totalBanner = useSelector(state => state.BlogAttachment.TotalRecord || 0);
    const { mutate: fetchattachmentList, isLoading } = useMutation((data) => dispatch(BlogAttachmentServices.fetchBlogAttachmentApi(data)));
    const { mutate: deleteBlogAttachment } = useMutation(({ data, id }) => dispatch(BlogAttachmentServices.deleteBlogAttachmentApi(data, id)));
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    useEffect(() => {
        fetchattachmentList({});
        return () => { }
    }, [])
    // const handleDelete = (status, id) => {
    //     let data = { Status: status ? 0 : 1, id: id,UserId:UserId}
    //     deleteBlogAttachment({ data, id }, {
    //         onSuccess: (res) => {
    //             if (res.status === 200) {
    //                 getCouponList(filter);
    //             }
    //         }
    //     });
    // }
    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
            className: 'table_first_col',
            render: (value, item, index) => (page - 1) * 10 + index + 1
        },
        {
            title: 'Label',
            dataIndex: 'Label',
            key: 'Label',
            className: 'text-capitalize'
        },
        {
            title: 'Alt Tag',
            dataIndex: 'AltTag',
            key: 'AtlTag',
            className: 'text-capitalize'
        },
        {
            title: 'Attachment',
            dataIndex: 'attachment',
            key: 'WebBanner',
            className: 'text-capitalize',
            render: (value, item, index) => {
                return (
                <><span>{item?.Attachment && <Typography.Link href={item?.Attachment} target='_blank'>
                    <img className="btl_img" src={item?.Attachment} crossorigin="anonymous" /></Typography.Link>}</span>
                    <Tooltip title="Copy URL">
                        <CopyToClipboard text={item?.Attachment}
                            onCopy={() => message.success("Copy Successfully")} className="del-icon attach-icon">
                            <CopyTwoTone />
                        </CopyToClipboard>
                    </Tooltip></>
                ) 
            }
        }
        ,

        {
            title: 'Uploaded Date',
            dataIndex: 'Uploaded Date',
            key: 'action',
            className: 'table_action_col text-center',
            render: (value, item, index) => {
                return (
                    <>{moment.utc(item.assigned_date).local().format('DD-MM-YYYY H:mm:ss')}</>
                )
            }
        },
        {
            title: 'Action',
            dataIndex: 'Actions',
            key: 'Actions',
            className: 'table_status_col text-center',
            render: (value, item, index) => {
                return (
                    <Tooltip title="Delete Image">
                    {/* <span onClick={() => handleUpdateStatus(item?.Status, item?.Id)}><DeleteOutlined /> */}
                    <span className='del-icon'><DeleteOutlined /></span>
               </Tooltip> )
            }
        }
      
    ];
    return (
        <div>
            <div className='flex'>
                <div className='w-100'>
                    <HeadingBack title={"Blog Attachments"} subHeading={`Total ${totalBanner || 0} Attachment`} />
                </div>
                <div className=''>
                    <Tooltip title="Add"><span className='jop_btn' onClick={() => navigate("/blog-management/add")}>Add<SvgIcon.Add width={40} height={40} className="cursor-pointer" /></span></Tooltip>
                </div>
            </div>
            <div className='tableResponsive mt-10'>
                <Table
                    rowKey='Id'
                    className={isLoading ? 'table antd_table no_data_found_hide' : 'table antd_table'}
                    bordered
                    columns={columns}
                    dataSource={Array.isArray(attachmentList) && attachmentList?.length > 0 ? attachmentList : []}
                    pagination={{
                        current: page,
                        pageSize: limit,
                        total: totalBanner,
                        onChange(current, pageSize) {
                            console.log(current);
                            setPage(current);
                            setOffset(current == 1 ? 0 : (limit * current));
                        },
                        hideOnSinglePage: true
                    }}
                />
            </div>
        </div>
    )
}

export default BlogAttachment