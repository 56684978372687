import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { validateForgetPassword } from '../../../utils/validation';
import LeftSection from '../Component/LeftSection';
const ForgotPassword = () => {
    const navigate = useNavigate();
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState(undefined);

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const handlepass = () => {
        const validationErrors = validateForgetPassword(values);
        let noErrors = Object.keys(validationErrors).length == 0;
        setErrors(validationErrors);
        if (noErrors) {
            console.log(values)
        }
    }
    return (
        <div>
            <div className='loginPage'>
                {/* <LeftSection /> */}
                <div className='bg-white flex flex3 justify-content-center align-items-center'>
                    <div className='loginFormbox'>
                        <div className='welcomeText'>Welcome to LPL Admin</div>
                        <p className='fw-600 fs-16'>Enter the email address associated with your LPL Admin account.</p>
                        <div className='loginForm'>
                            <div className='form-group'>
                                <div className='input-group'>
                                    <input type="text" className='form-control' autoComplete="new-off" placeholder='Email' name="Email" onChange={handleChange} value={values?.Email || ""} />
                                </div>
                                {errors?.Email && <p className='error'>{errors?.Email}</p>}
                            </div>
                            <button type="button" className='gradient-button w-100' onClick={() => handlepass()}>Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword