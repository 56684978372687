import React, { useEffect, useState } from 'react'
import { Col, Form, Input, notification, Row, Select } from 'antd'
import { useNavigate, useParams } from 'react-router-dom';
import CustomEditor from '../../component/CustomEditor/CustomEditor';
import { useDispatch, useSelector } from 'react-redux';
import { FaqServices } from '../../redux/Services';
import { useMutation } from 'react-query';
import { GetLoggedInUser } from '../../utils';
import HeadingBack from '../../component/HeadingBack';
import { CouponServices,BannerServices } from '../../redux/Services';
import { AutoComplete } from 'antd';
import { usePermission } from '../../hooks/usePermission';
import FileUpload from './FileUpload';
import { TestDepartmentServices } from '../../redux/Services';
import API_URL from '../../api/url';
const { Option } = Select;
const zoneOptions = [
    {
      value: 'ZH1',
    },
    {
      value: 'ZH16',
    },
    {
      value: 'ZH17',
    },
    {
      value: 'ZH18',
    },
    {
      value: 'ZH19',
    },
    {
      value: 'ZH22',
    },
    {
      value: 'ZH24',
    },
    {
      value: 'ZH26',
    },
    {
      value: 'ZH28',
    },
    {
      value: 'ZH29',
    },
    {
      value: 'ZH30',
    },
    {
      value: 'ZH32',
    },
    {
      value: 'ZH33',
    },
    {
      value: 'ZH5',
    },
  ];
const EditBtl = () => {
    const permission = usePermission("BTL Management", "Edit");
    const { CouponId } = useParams();
    var newOptions = [];
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [value, setValue] = useState('');
    const couponData = useSelector(state => state.coupon.couponDetail || {});
    const couponCategoryList = useSelector(state => state.coupon.couponCategoryList || []);
    const TestCodeList = useSelector(state => state.coupon.TestCodeList || []);
    const { mutate: fetchCouponCategory, isLoading } = useMutation((data) => dispatch(CouponServices.fetchCouponCategoryApi(data)));
    const { mutate: fetchCouponById } = useMutation((data) => dispatch(CouponServices.fetchCouponByIdApi(data, CouponId)));
    const { mutate: updateBtlOffer } = useMutation((data) => dispatch(CouponServices.updateBtlOfferApi(data, CouponId, navigate)));
    const { mutate: fetchTestCode } = useMutation((data) => dispatch(CouponServices.fetchTestCode(data)));
    const { mutate: BannerImageAdd } = useMutation((data) => dispatch(BannerServices.BannerImageAddApi(data)));
    const [file, setFile] = useState("");
    const [boxType, setBoxType] = useState("");
    const [options, setOptions] = useState([]);
    const BtlOffersId = [
       4,5 
    ];
    const [referenceId, setReferenceId] = useState('');

    useEffect(() => {
        fetchCouponCategory({ isActive: "1" });
        return () => { }
    }, [])
    useEffect(() => {
        if (CouponId) {
            fetchCouponById({});
        }
        return () => { }
    }, [CouponId])
// console.log(couponData.Code)
    useEffect(() => {
        let Banner = couponData?.Banner?.replace(API_URL.S3_BANNER_BASE?.toString(), "") || "";
        if (couponData && Object.keys(couponData)?.length > 0) {
            form.setFieldValue('CategoryId', couponData?.CategoryId);
            form.setFieldValue('Name', couponData?.Name);
            form.setFieldsValue({
                ...couponData,
                Banner: Banner
            });
            setFile(Banner);
            setBoxType(couponData?.CategoryId);
            form.setFieldValue('Code', couponData?.Code);
            form.setFieldValue('Status', couponData?.Status);
        }
    }, [couponData])

    const handleUpload = (file) => {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("dir_location", "btl-users");
        BannerImageAdd(formData, {
            onSuccess: (res) => {
                var fileName = res.data?.data?.key;
                setFile(fileName)
            },
        })
    }
    useEffect(() => {
        form.setFieldsValue({
            Banner: file,
        })
    }, [file])

    const changeItemType = (id) => {
        setBoxType(id);
    }
   
    const handleSubmit = () => {
        form.validateFields().then((values) => {
            if (CouponId) {
                let _data = { ...values };
                _data.UserId = LOG_IN_USER?.id;
                if (values.Name) {
                } else {
                  _data.Name = "BTL Banners";
                }
                updateBtlOffer(_data);
            }
        })
    }

    useEffect(() => {
        form.setFieldsValue({
            Banner: file,
     
        })
    }, [file,])
    useEffect(() => {
        fetchTestCode({ });
        return () => { }
    }, [])
    return (
        <>
            <HeadingBack title={"Edit BTL/Non BTL Offers"} subHeading="" />
            <div className='p-20'>
            < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                >
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Category"
                                name="CategoryId"
                                rules={[{ required: true, message: 'Category is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Category'
                                    autoComplete="new-off"
                                    name="CategoryId"
                                    onChange={(event) => { changeItemType(event) }}
                                >
                                    <Option className='text-capitalize'>Select Category</Option>
                    
                                    {couponCategoryList?.length > 0 && couponCategoryList?.map((item, idx) =>
                                        (BtlOffersId.includes(item.Id))? 
                                        <Option key={idx} className='text-capitalize' value={item.Id}>{item.Name}</Option>
                                        : ''
                                    )}
                                </Select>

                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Zone"
                                    name="Zone"
                                    rules={[{ required: true, message: 'Zone is required' }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select Zone"
                                        name="Code"
                                    >
                                        {zoneOptions?.length > 0 && zoneOptions?.map((item, idx) =>
                                            <Option key={idx} className='text-capitalize' value={item.value}>{item.value}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col >
                        {(boxType == 5) ?
                       ''
                          : 
                          <Col lg={12} md={24} sm={24} xs={24}>
                         <Form.Item
                                    label="Test Code"
                                    name="Code"
                                    rules={[{ required: true, message: 'Test Code is required' }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select Test Code"
                                        name="Code"
                                    >
                                         {TestCodeList?.length > 0 && TestCodeList?.map((item, idx) =>
                                        <Option key={idx} className='text-capitalize' value={item.TestCode}>{item.TestCode}</Option>
                                    )} 
                                    </Select>
                                </Form.Item>
                      </Col >
                }
                {(boxType == 4) ?
                       ''
                          : 
                          <Col lg={12} md={24} sm={24} xs={24}>
                       <Form.Item
                                label="Name"
                                name="Name"
                                rules={[{ required: true, message: 'Name is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' />
                            </Form.Item>
                      </Col >
                }
                      <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                    name="Status"
                                >
                                    <Option className='text-capitalize' value={0}>Inactive</Option>
                                    <Option className='text-capitalize' value={1}>Active</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                        <Form.Item
                                label="Offer Image"
                                name="Banner"
                                rules={[{ required: true, message: 'Offer Image is required' }]}
                            >
                                <FileUpload file={file} setFile={setFile} handleUpload={handleUpload} />
                                <div className='recom-t'>
                                <p>Accept JPG,PNG Only</p>
                                <p>Recommended Size:350*150</p>
                                <p>Maximum Resolution should be 1MB</p>
                                </div>
                            </Form.Item>
                        </Col >
                      
                       
                       
                    </Row >
                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit}>
                           Update
                        </button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default EditBtl