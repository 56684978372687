import React, { useEffect, useState } from 'react'
import { Col, Form, Input, notification, Row, Select } from 'antd'
import { useNavigate, useParams } from 'react-router-dom';
import CustomEditor from '../../../component/CustomEditor/CustomEditor';
import { useDispatch, useSelector } from 'react-redux';
import { JobPostServices } from '../../../redux/Services';
import { useMutation } from 'react-query';
import { GetLoggedInUser } from '../../../utils';
import URL from "../../../api/url";
import HeadingBack from '../../../component/HeadingBack';
import { usePermission } from '../../../hooks/usePermission';
const { Option } = Select;

const EditPostJob = () => {
    const permission = usePermission("Job Posts", "Edit");
    const { jobsId } = useParams();
    let LOG_IN_USER = GetLoggedInUser();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [JobData, setjobData] = useState({});
    const JobAddData = useSelector(state => state.jobPost.JobAddDetails || {});
    const { mutate: getJobAddDetailsApi } = useMutation((data) => dispatch(JobPostServices.fetchJobAddDetailsApi(data, jobsId)));
    const { mutate: updateJobAddApi,isLoading: addLoading } = useMutation((data) => dispatch(JobPostServices.updateJobAddApi(data, jobsId, navigate)));
    useEffect(() => {
        getJobAddDetailsApi(jobsId);
        return () => { }
    }, [])

    useEffect(() => {
        if (JobAddData && Object.keys(JobAddData).length > 0 && Object.keys(JobAddData.list)?.length > 0) {
            const JobData = JobAddData.list;
            // console.log(JobData)
            setjobData(JobData);
            form.setFieldValue('Id', JobData?.Id);
            form.setFieldValue('JobTitle', JobData?.JobTitle);
            form.setFieldValue('Experience', JobData?.Experience);
            form.setFieldValue('Location', JobData?.Location);
            form.setFieldValue('JobVacancy', JobData?.JobVacancy);
            form.setFieldValue('JobSalary', JobData?.JobSalary);
            form.setFieldValue('JobDetails', JobData?.JobDetails);
            form.setFieldValue('JobPurpose', JobData?.JobPurpose);
            form.setFieldValue('Responsibility', JobData?.Responsibility);
            form.setFieldValue('JobDisclosed', JobData?.JobDisclosed);
            form.setFieldValue('Status', JobData?.Status);
        }
    }, [JobAddData])


    const handleSubmit = () => {
        form.validateFields().then((values) => {
            if (updateJobAddApi) {
                let _data = { ...values };
                _data.UserId = LOG_IN_USER?.id;
                updateJobAddApi(_data);
            }
        })
    }
    
    return (
        <>
            <HeadingBack title={"Job Edit"} subHeading="" />
            <div className='p-20'>
                < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                >
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Profile"
                                name="JobTitle"
                                rules={[{ required: true, message: 'Profile is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Profile' />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Experience (Years)"
                                name="Experience"
                                rules={[{ required: true, message: 'Experience is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Experience' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Location"
                                name="Location"
                                rules={[{ required: true, message: 'Location is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Location' />
                            </Form.Item>
                        </Col >
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Vacancy"
                                name="JobVacancy"
                                rules={[{ required: true, message: 'Vacancy is required' }]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Vacancy' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Salary (Monthly)"
                                name="JobSalary"
                                rules={[{ required: true, message: 'Salary is required' }]}
                            >
                                <Input autoComplete="new-off" className='form-control' placeholder='Salary' />
                            </Form.Item>
                        </Col >
                        <Col span={24}>
                            <Form.Item
                                label="Job Highlights"
                                name="JobDetails"
                                rules={[{ required: true, message: 'Job Highlights is required' }]}
                            >
                                <CustomEditor value={form.getFieldValue("JobDetails")} onEditorChange={(value) => form.setFieldsValue({ JobDetails: value })} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Purpose"
                                name="JobPurpose"
                                rules={[{ required: true, message: 'Purpose is required' }]}
                            >
                                <CustomEditor value={form.getFieldValue("JobPurpose")} onEditorChange={(value) => form.setFieldsValue({ JobPurpose: value })} />
                            </Form.Item>
                        </Col>  
                        <Col span={24}>
                            <Form.Item
                                label="Roles and Responsibilities"
                                name="Responsibility"
                                rules={[{ required: true, message: 'Roles and Responsibilities is required' }]}
                            >
                                <CustomEditor value={form.getFieldValue("Roles and Responsibilities")} onEditorChange={(value) => form.setFieldsValue({ Responsibility: value })} />
                            </Form.Item>
                        </Col> 
                        <Col span={24}>
                            <Form.Item
                                label="Required Candidate Profile"
                                name="JobDisclosed"
                                rules={[{ required: true, message: 'Required Candidate Profile is required' }]}
                            >
                                <CustomEditor value={form.getFieldValue("Required Candidate Profile")} onEditorChange={(value) => form.setFieldsValue({ JobDisclosed: value })} />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                    name="Status"
                                >
                                    <Option className='text-capitalize' value={0}>Inactive</Option>
                                    <Option className='text-capitalize' value={1}>Active</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                
                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit} disabled={addLoading}>
                            Update
                        </button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default EditPostJob