import { Col, Input, Row } from 'antd'
import React from 'react'

const SearchFilter = ({onChange}) => {
    return (
        <>
        <Row gutter={[12, 24]} className="ml-10">
            <Col /* md={6} sm={12} xs={24} */>
                {/* <strong><p className='mb-2 f-14'>Search</p></strong> */}
                <Input
                style={{width: "250px"}}
                    type="text"
                    autoComplete="new-off"
                    className='form-control'
                    onChange={(e)=> onChange(e.target.value)}
                    placeholder='Search'
                />
           </Col>
        </Row>
        </>
    )
}

export default SearchFilter